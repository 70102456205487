import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Autocomplete, Divider, Grid, IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useEffect, useState } from "react";
import { carrierRequest } from "../../../api/CarrierRequest";
import { carrierCarRequest } from "../../../api/CarrierCarRequest";

interface Props {
  open: boolean;
  onClose: AnyFunction;
  onCarrierCarCreated?: AnyFunction;
  onCarrierCreatedIndex?: number;
  carrier?: Carrier | null;
  shouldDisableCarrierSelection?: boolean;
}

export default function AddCarrierCarDialog(props: Props) {
  const {
    open,
    onClose,
    onCarrierCarCreated,
    onCarrierCreatedIndex,
    carrier,
    shouldDisableCarrierSelection,
  } = props;

  const [formValues, setFormValues] = useState<any>({
    vehicleRegistrationPlate: null,
    carrierId: carrier ? carrier.id : null,
  });

  const [carrierValue, setCarrierValue] = useState<Carrier | null>(
    carrier ? carrier : null
  );

  const [error, setError] = useState({
    vehicleRegistrationPlate: false,
    vehicleRegistrationPlateIsDuplicate: false,
    carrierId: false,
  });

  const [carrierCarData, setCarrierCarData] = useState<CarrierCar[]>([]);

  const fetchCarrierCars = () => {
    carrierCarRequest.findAll().then((data) => {
      setCarrierCarData(
        data.results.map((carrierCar: CarrierCar) => ({
          id: carrierCar.id,
          vehicleRegistrationPlate: carrierCar.vehicleRegistrationPlate,
          carrier: carrierCar.carrier,
        }))
      );
    });
  };

  useEffect(() => {
    fetchCarrierCars();
  }, []);

  const checkVehicleRegistrationPlateIsDuplicate = (carrierCar: CarrierCar) =>
    carrierCar.vehicleRegistrationPlate?.toLowerCase() ===
    formValues.vehicleRegistrationPlate?.toLowerCase();

  const handleErrors = () => {
    if (!formValues.vehicleRegistrationPlate) {
      setError((prev: any) => ({
        ...prev,
        vehicleRegistrationPlate: true,
      }));
    } else if (formValues.vehicleRegistrationPlate) {
      setError((prev: any) => ({
        ...prev,
        vehicleRegistrationPlate: false,
      }));
    }
    if (formValues.vehicleRegistrationPlate) {
      setError((prev: any) => ({
        ...prev,
        vehicleRegistrationPlateIsDuplicate: carrierCarData.some(
          checkVehicleRegistrationPlateIsDuplicate
        ),
      }));
    }
    if (!formValues.carrierId) {
      setError((prev: any) => ({
        ...prev,
        carrierId: true,
      }));
    } else if (formValues.carrierId) {
      setError((prev: any) => ({
        ...prev,
        carrierId: false,
      }));
    }
  };

  const handleSubmit = () => {
    handleErrors();
    const formIsValid =
      formValues.vehicleRegistrationPlate &&
      !carrierCarData.some(checkVehicleRegistrationPlateIsDuplicate) &&
      formValues.carrierId;
    if (formIsValid) {
      return carrierCarRequest
        .post(formValues)
        .then((createdCarrierCar) => {
          setCarrierValue(null);
          setFormValues(() => ({
            vehicleRegistrationPlate: null,
            carrierId: null,
          }));
          if (typeof onCarrierCarCreated === "function") {
            onCarrierCarCreated(createdCarrierCar);
          }
        })
        .catch(() => {
          alert("Error creating car");
        });
    }
  };

  const handleCloseAddCarrierCarDialog = () => {
    onClose();
    setFormValues(() => ({
      vehicleRegistrationPlate: null,
      carrierId: null,
    }));
    setCarrierValue(null);
    setError(() => ({
      vehicleRegistrationPlate: false,
      vehicleRegistrationPlateIsDuplicate: false,
      carrierId: false,
    }));
  };

  const [carrierOptions, setCarrierOptions] = useState<Carrier[]>([]);
  const fetchCarriers = () => {
    carrierRequest.findAll().then((data) => {
      setCarrierOptions(
        data.results.map((carrier: Carrier) => ({
          id: carrier.id,
          name: carrier.name,
          tin: carrier.tin,
          city: carrier.city,
          address: carrier.address,
          phone: carrier.phone,
          email: carrier.email,
          contactPerson: carrier.contactPerson,
          isActive: carrier.isActive,
        }))
      );
    });
  };

  // useEffect(() => {
  //   fetchCarriers();
  // }, []);

  useEffect(() => {
    // if (onCarrierCreatedIndex > 0) {
    fetchCarriers();
    // }
  }, [onCarrierCreatedIndex]);

  const sortedCarrierOptions = carrierOptions.sort(function (a, b) {
    if (a.name! < b.name!) {
      return -1;
    }
    if (a.name! > b.name!) {
      return 1;
    }
    return 0;
  });

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="xs">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <DialogTitle sx={{ pb: 0 }}>Adăugare mașină</DialogTitle>
          </Grid>
          <Grid item sx={{ mr: 2, pt: 2 }}>
            <IconButton onClick={handleCloseAddCarrierCarDialog} tabIndex={-1}>
              <ClearIcon />
            </IconButton>
          </Grid>
        </Grid>
        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12}>
              <TextField
                required
                size="small"
                fullWidth
                label="Număr înmatriculare"
                error={
                  error.vehicleRegistrationPlate ||
                  error.vehicleRegistrationPlateIsDuplicate
                }
                helperText={
                  error.vehicleRegistrationPlate
                    ? "Câmp obligatoriu!"
                    : error.vehicleRegistrationPlateIsDuplicate &&
                      "Număr înmatriculare deja existent!"
                }
                onChange={(event) =>
                  setFormValues((prev: any) => ({
                    ...prev,
                    vehicleRegistrationPlate: event.target.value.toUpperCase(),
                  }))
                }
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit()
                      ?.then(handleCloseAddCarrierCarDialog)
                      .then(fetchCarrierCars);
                  }
                }}
                inputProps={{ style: { textTransform: "uppercase" } }}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                value={carrierValue}
                onChange={(event, newValue) => {
                  setCarrierValue(newValue);
                  setFormValues((prev: any) => ({
                    ...prev,
                    carrierId: newValue?.id,
                  }));
                }}
                disabled={Boolean(shouldDisableCarrierSelection)}
                options={sortedCarrierOptions}
                filterSelectedOptions
                getOptionLabel={(option) => `${option.name}`}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                noOptionsText="Fără opțiuni"
                renderInput={(params) => (
                  <form noValidate onSubmit={(event) => event.preventDefault()}>
                    <TextField
                      required
                      {...params}
                      size="small"
                      fullWidth
                      label="Transportator"
                      error={error.carrierId}
                      helperText={error.carrierId && "Câmp obligatoriu!"}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          handleSubmit()
                            ?.then(handleCloseAddCarrierCarDialog)
                            .then(fetchCarrierCars);
                        }
                      }}
                    />
                  </form>
                )}
              />
            </Grid>
          </Grid>
          <Divider sx={{ mb: 2, opacity: 0 }} />
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={12} md={6}>
              <Button
                fullWidth
                variant="contained"
                onClick={() => {
                  handleSubmit()
                    ?.then(handleCloseAddCarrierCarDialog)
                    .then(fetchCarrierCars);
                }}
              >
                Salvează
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                fullWidth
                variant="outlined"
                onClick={handleCloseAddCarrierCarDialog}
              >
                Renunță
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
