import {
  DataGrid,
  GridColDef,
  GridFilterModel,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { Box, darken, Grid, lighten, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import { dataGridRoTextConstants } from "../../constants/dataGridRoTextConstants";
import {
  getCarrierCar,
  getDate,
  getLoadingAddress,
  getLoadingCity,
  getLoadingDate,
  getShipperBranch,
  getShipperInvoice,
  getShipperPaymentTermInDays,
  getShipperReferenceId,
  getUnloadingAddress,
  getUnloadingCity,
  getUnloadingDate,
} from "../../functions/valueGetterFunctions/tripValueGetterFunctions";
import TripDocumentsDialog from "../dialogs/TripDocumentsDialog";
import { InsertDriveFile } from "@mui/icons-material";
import { tripRequest } from "../../api/TripRequest";

const getBackgroundColor = (color: string, mode: string) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

export default function ShipperBeneficiaryTripsDataGrid() {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [searchText, setSearchText] = React.useState("");
  const [pageIsLoading, setPageIsLoading] = useState(false);
  const [rows, setRows] = useState<Trip[]>([]);
  const [rowCount, setRowCount] = useState(0);

  const columns: GridColDef[] = [
    { field: "id", headerName: "Număr", minWidth: 100, flex: 1 },
    {
      field: "date",
      headerName: "Dată",
      minWidth: 90,
      flex: 1,
      valueGetter: getDate,
    },
    {
      field: "loadingDate",
      headerName: "Dată încărcare",
      minWidth: 160,
      flex: 1,
      valueGetter: getLoadingDate,
      hide: true,
    },
    {
      field: "loadingCity",
      headerName: "Plecare",
      minWidth: 200,
      flex: 1,
      valueGetter: getLoadingCity,
    },
    {
      field: "loadingAddress",
      headerName: "Adresă încărcare",
      minWidth: 160,
      flex: 1,
      valueGetter: getLoadingAddress,
      hide: true,
    },
    {
      field: "unloadingDate",
      headerName: "Dată descărcare",
      minWidth: 160,
      flex: 1,
      valueGetter: getUnloadingDate,
      hide: true,
    },
    {
      field: "unloadingCity",
      headerName: "Sosire",
      minWidth: 200,
      flex: 1,
      valueGetter: getUnloadingCity,
    },
    {
      field: "unloadingAddress",
      headerName: "Adresă descărcare",
      minWidth: 160,
      flex: 1,
      valueGetter: getUnloadingAddress,
      hide: true,
    },
    {
      field: "shipperBranch",
      headerName: "Punct de lucru beneficiar",
      minWidth: 160,
      flex: 1,
      valueGetter: getShipperBranch,
    },
    {
      field: "shipperPaymentTermInDays",
      headerName: "Termen beneficiar",
      minWidth: 160,
      flex: 0.7,
      valueGetter: getShipperPaymentTermInDays,
    },
    {
      field: "shipperInvoice",
      headerName: "Fact. ben.",
      minWidth: 125,
      flex: 1,
      valueGetter: getShipperInvoice,
    },
    {
      field: "shipperReferenceId",
      headerName: "Ref.",
      minWidth: 75,
      flex: 0.7,
      valueGetter: getShipperReferenceId,
    },
    {
      field: "carrierCar",
      headerName: "Mașină",
      minWidth: 90,
      flex: 1,
      valueGetter: getCarrierCar,
    },
    {
      field: "actions",
      headerName: "Acțiuni",
      flex: 1,
      minWidth: 60,
      maxWidth: 60,
      filterable: false,
      renderCell: ({ row: trip }) => {
        return [
          <Tooltip title="Documente" placement="bottom">
            <span>
              <IconButton
                onClick={() => {
                  setSelectedTripForDocumentsDialog(trip);
                }}
                color="warning"
                disabled={!trip.files.length}
              >
                <InsertDriveFile fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>,
        ];
      },
    },
  ];

  const fetchTrips = () => {
    setPageIsLoading(true);
    tripRequest
      .searchShipperBeneficiary(pageSize, page * pageSize, searchText)
      .then((data) => {
        setRows(
          data.results.map((trip: Trip) => ({
            id: trip.id,
            date: trip.date,
            loadingDate: trip.loadingDate,
            loadingAddress: trip.loadingAddress,
            loadingCity: trip.loadingCity,
            unloadingDate: trip.unloadingDate,
            unloadingAddress: trip.unloadingAddress,
            unloadingCity: trip.unloadingCity,
            shipper: trip.shipper,
            shipperBranch: trip.shipperBranch,
            shipperReferenceId: trip.shipperReferenceId,
            shipperInvoice: trip.shipperInvoice,
            shipperPaymentTermInDays: trip.shipperPaymentTermInDays,
            carrierCar: trip.carrierCar,
            files: trip.files,
          }))
        );
        setRowCount(data.total);
      })
      .finally(() => {
        setPageIsLoading(false);
      });
  };

  useEffect(() => {
    fetchTrips();
  }, [pageSize, page, searchText]);

  const [selectedTripForDocumentsDialog, setSelectedTripForDocumentsDialog] =
    React.useState<Trip | undefined>(undefined);

  const CustomToolbar = React.useCallback(() => {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <Grid
            item
            sx={{
              ml: 1,
            }}
          >
            <GridToolbarQuickFilter />
          </Grid>
        </Grid>
      </GridToolbarContainer>
    );
  }, []);

  return (
    <Box
      sx={{
        height: 400,
        width: "100%",
      }}
    >
      <Grid item xs={12} sx={{ paddingBottom: 3 }}>
        <div style={{ width: "100%" }}>
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                filterMode="server"
                paginationMode="server"
                loading={pageIsLoading}
                onFilterModelChange={(filterModel: GridFilterModel) => {
                  setSearchText(filterModel.quickFilterValues?.join(" ") || "");
                }}
                onPageChange={(page: number) => {
                  setPage(page);
                }}
                rowCount={rowCount}
                rows={rows}
                getRowId={(row) => row.id}
                columns={columns}
                initialState={{
                  sorting: {
                    sortModel: [{ field: "id", sort: "desc" }],
                  },
                }}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 25, 50, 100]}
                autoHeight
                pagination
                disableSelectionOnClick
                checkboxSelection={false}
                experimentalFeatures={{ newEditingApi: true }}
                localeText={dataGridRoTextConstants}
                components={{ Toolbar: CustomToolbar }}
                componentsProps={{
                  pagination: {
                    labelRowsPerPage: "Număr de înregistrări pe pagină",
                  },
                }}
                sx={{
                  "& .MuiDataGrid-cellContent, & .MuiDataGrid-columnHeaderTitle":
                    {
                      fontSize: 12,
                    },
                  "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within":
                    {
                      outline: "none",
                    },

                  "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus":
                    {
                      outline: "none",
                    },
                  "& .completed": {
                    backgroundColor: (theme) =>
                      getBackgroundColor("#64748B", theme.palette.mode),
                    "&:hover": {
                      backgroundColor: (theme) =>
                        getHoverBackgroundColor("#64748B", theme.palette.mode),
                    },
                  },
                  "& .confirmed": {
                    bgcolor: (theme) =>
                      getBackgroundColor(
                        theme.palette.warning.dark,
                        theme.palette.mode
                      ),
                    "&:hover": {
                      bgcolor: (theme) =>
                        getHoverBackgroundColor(
                          theme.palette.warning.dark,
                          theme.palette.mode
                        ),
                    },
                  },
                  "& .billed": {
                    bgcolor: (theme) =>
                      getBackgroundColor(
                        theme.palette.success.dark,
                        theme.palette.mode
                      ),
                    "&:hover": {
                      bgcolor: (theme) =>
                        getHoverBackgroundColor(
                          theme.palette.success.dark,
                          theme.palette.mode
                        ),
                    },
                  },
                  "& .paid": {
                    bgcolor: (theme) =>
                      getBackgroundColor(
                        theme.palette.info.dark,
                        theme.palette.mode
                      ),
                    "&:hover": {
                      bgcolor: (theme) =>
                        getHoverBackgroundColor(
                          theme.palette.info.dark,
                          theme.palette.mode
                        ),
                    },
                  },
                  "& .blocked": {
                    bgcolor: (theme) =>
                      getBackgroundColor(
                        theme.palette.error.dark,
                        theme.palette.mode
                      ),
                    "&:hover": {
                      bgcolor: (theme) =>
                        getHoverBackgroundColor(
                          theme.palette.error.dark,
                          theme.palette.mode
                        ),
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
      </Grid>
      <TripDocumentsDialog
        open={Boolean(selectedTripForDocumentsDialog)}
        onClose={() => setSelectedTripForDocumentsDialog(undefined)}
        trip={selectedTripForDocumentsDialog}
      />
    </Box>
  );
}
