import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { Button, Grid, Stack, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
// @ts-ignore
import FileViewer from "react-file-viewer";
import { dataGridRoTextConstants } from "../../constants/dataGridRoTextConstants";
import IconButton from "@mui/material/IconButton";
import {
  getCreatedAt,
  getName,
  getSize,
  getType,
} from "../../functions/valueGetterFunctions/tripDocumentGetterFunctions";
import {
  DoDisturbOn,
  Download,
  DownloadForOffline,
  Preview,
  Print,
  Task,
} from "@mui/icons-material";
import { tripRequest } from "../../api/TripRequest";
import { fileRequest } from "../../api/FileRequest";
import { useSelector } from "react-redux";
import { isDispatcherSelector } from "../../redux/slice/selectors";

interface Props {
  trip?: Trip;
}

export default function TripPendingDocumentsDataGrid({ trip }: Props) {
  const isDispatcher = useSelector(isDispatcherSelector);
  const [pageIsLoading, setPageIsLoading] = useState(false);
  const [rows, setRows] = useState<FileDocument[]>([]);
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [previewDocument, setPreviewDocument] = useState<{
    uri: string;
    type: string;
  } | null>(null);

  const fetchTrip = (id: number | undefined) => {
    if (!id) {
      return;
    }
    setPageIsLoading(true);

    return tripRequest
      .find(id)
      .then((trip) => {
        setRows(trip.pendingPublicUploadedFiles);
      })
      .finally(() => setPageIsLoading(false));
  };

  useEffect(() => {
    if (trip?.id) {
      fetchTrip(trip.id);
    }
  }, [trip]);

  const columns: GridColDef[] = [
    {
      field: "shortName",
      headerName: "Nume",
      width: 160,
      flex: 1,
      valueGetter: getName,
    },
    {
      field: "extension",
      headerName: "Tip document",
      width: 160,
      flex: 1,
      valueGetter: getType,
    },
    {
      field: "size",
      headerName: "Mărime document",
      width: 160,
      flex: 1,
      valueGetter: getSize,
    },
    {
      field: "createdAt",
      headerName: "Dată creare",
      width: 160,
      flex: 1,
      valueGetter: getCreatedAt,
    },
    {
      field: "actions",
      headerName: "Acțiuni",
      minWidth: 200,
      maxWidth: 200,
      filterable: false,
      renderCell: ({ row: document }) => {
        return [
          <>
            {!isDispatcher && (
              <>
                <Tooltip title="Acceptă document" placement="bottom">
                  <IconButton
                    onClick={async () => {
                      setPageIsLoading(true);
                      tripRequest
                        .acceptPublicUploadedFile(document.id)
                        .then(() => fetchTrip(trip?.id))
                        .finally(() => setPageIsLoading(false));
                    }}
                    color="success"
                  >
                    <Task fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Respinge document" placement="bottom">
                  <IconButton
                    onClick={async () => {
                      setPageIsLoading(true);
                      tripRequest
                        .rejectPublicUploadedFile(document.id)
                        .then(() => fetchTrip(trip?.id))
                        .finally(() => setPageIsLoading(false));
                    }}
                    color="error"
                  >
                    <DoDisturbOn fontSize="small" />
                  </IconButton>
                </Tooltip>
              </>
            )}
            <Tooltip title="Previzulizează" placement="bottom">
              <IconButton
                onClick={async () => {
                  const blob = await fileRequest.getBlob(document.id);
                  const url = URL.createObjectURL(blob);
                  setPreviewDocument({
                    uri: url,
                    type: document.extension.slice(1),
                  });
                }}
                color="primary"
              >
                <Preview fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Descarcă" placement="bottom">
              <IconButton
                onClick={() => {
                  fileRequest.download(document.id, document.shortName);
                }}
                color="primary"
              >
                <Download fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Printează" placement="bottom">
              <IconButton
                onClick={async () => {
                  const blob = await fileRequest.getBlob(document.id);
                  const url = URL.createObjectURL(blob);

                  const existingIframe =
                    window.document.body.querySelector("#print-iframe");
                  if (existingIframe) {
                    window.document.body.removeChild(existingIframe);
                  }

                  const iframe = window.document.createElement("iframe");
                  window.document.body.appendChild(iframe);

                  iframe.id = "print-iframe";
                  iframe.style.display = "none";
                  iframe.src = url;
                  iframe.onload = () => {
                    setTimeout(() => {
                      iframe.focus();
                      iframe.contentWindow?.print();
                    }, 0);
                  };
                }}
                color="secondary"
              >
                <Print fontSize="small" />
              </IconButton>
            </Tooltip>
          </>,
        ];
      },
    },
  ];

  const [selectedRows, setSelectedRows] = useState<FileDocument[]>([]);

  const [filterButtonEl, setFilterButtonEl] = React.useState(null);

  const CustomToolbar = React.useCallback(() => {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <Grid
            item
            sx={{
              ml: 1,
            }}
          >
            <GridToolbarQuickFilter />
          </Grid>
          <Grid
            item
            sx={{
              mr: 0.3,
            }}
          >
            <Grid container direction="row" spacing={1}>
              <Grid item>
                {/* @ts-ignore */}
                <GridToolbarColumnsButton ref={setFilterButtonEl} />
              </Grid>
              <Grid item>
                {/* @ts-ignore */}
                <GridToolbarDensitySelector ref={setFilterButtonEl} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </GridToolbarContainer>
    );
  }, [selectedRows]);

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <Grid item container direction="row" spacing={1}>
          <Grid item>
            <Button
              size="small"
              disabled={!rows.length || pageIsLoading}
              onClick={() =>
                rows.forEach((row) =>
                  fileRequest.download(row.id, row.shortName)
                )
              }
              variant="contained"
            >
              <Stack
                direction="row"
                sx={{
                  mt: 0.4,
                }}
              >
                <Download
                  fontSize="small"
                  sx={{
                    mr: 0.5,
                  }}
                />
                Descarcă toate documentele
              </Stack>
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="small"
              disabled={!selectedRows.length || pageIsLoading}
              onClick={() =>
                selectedRows.forEach((row) =>
                  fileRequest.download(row.id, row.shortName)
                )
              }
              variant="contained"
            >
              <Stack
                direction="row"
                sx={{
                  mt: 0.4,
                }}
              >
                <DownloadForOffline
                  fontSize="small"
                  sx={{
                    mr: 0.5,
                  }}
                />
                Descarcă documentele selectate
              </Stack>
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="flex-end"
            spacing={1}
          >
            <Grid item xs={12}>
              <div style={{ width: "100%" }}>
                <div style={{ display: "flex", height: "100%" }}>
                  <div style={{ flexGrow: 1 }}>
                    <DataGrid
                      loading={pageIsLoading}
                      rows={rows}
                      getRowId={(row) => row.id}
                      columns={columns}
                      initialState={{
                        sorting: {
                          sortModel: [{ field: "id", sort: "desc" }],
                        },
                      }}
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                      rowsPerPageOptions={[10, 25, 50, 100]}
                      autoHeight
                      pagination
                      disableSelectionOnClick
                      checkboxSelection={true}
                      onSelectionModelChange={(ids) => {
                        const selectedIDs = new Set(ids);
                        const selectedRows = rows.filter((row) =>
                          selectedIDs.has(row.id)
                        );
                        setSelectedRows(selectedRows);
                      }}
                      experimentalFeatures={{ newEditingApi: true }}
                      localeText={dataGridRoTextConstants}
                      components={{ Toolbar: CustomToolbar }}
                      componentsProps={{
                        pagination: {
                          labelRowsPerPage: "Număr de înregistrări pe pagină",
                        },
                        panel: {
                          anchorEl: filterButtonEl,
                          placement: "bottom-end",
                        },
                      }}
                      sx={{
                        "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within":
                          {
                            outline: "none",
                          },

                        "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus":
                          {
                            outline: "none",
                          },
                      }}
                    />
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        {previewDocument && (
          <Grid
            item
            xs={12}
            sx={{
              maxHeight: 720,
              textAlign: "center",
              overflow: "auto",
              ".pg-viewer-wrapper": {
                overflow: "unset !important",
              },
              ".photo-viewer-container": {
                height: "800px !important",
                width: "auto !important",
                margin: "auto",
              },
              img: {
                width: "auto !important",
                height: "800px !important",
              },
            }}
          >
            <FileViewer
              key={previewDocument.uri}
              fileType={previewDocument.type}
              filePath={previewDocument.uri}
              errorComponent={<div>error</div>}
              onError={(err: any) => console.log(err)}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}
