import { GridValueGetterParams } from "@mui/x-data-grid";

export function getName(params: GridValueGetterParams) {
  return `${params.row.name || "-"}`;
}

export function getPhone(params: GridValueGetterParams) {
  return `${params.row.phone || "-"}`;
}

export function getCarrier(params: GridValueGetterParams) {
  return `${params.row.carrier?.name || "-"}`;
}
