import * as React from "react";
import Dialog from "@mui/material/Dialog";
import TripsShippersReportPDF from "../../../pdfRenderer/TripsShippersReportPDF";

interface Props {
  open: boolean;
  onClose: AnyFunction;
  showCarrier: boolean;
  tripsPerShipper: Trip[];
  startDate: Date;
  endDate: Date;
  downloadPdf: boolean;
  onPdfDownloaded: AnyFunction;
}

export default function TripsShippersReportPDFDialog(props: Props) {
  const {
    open,
    onClose,
    showCarrier,
    tripsPerShipper,
    startDate,
    endDate,
    downloadPdf,
    onPdfDownloaded,
  } = props;

  const handleClosePDFDialog = () => {
    onClose();
  };

  const content = (
    <TripsShippersReportPDF
      showCarrier={showCarrier}
      tripsPerShipper={tripsPerShipper}
      startDate={startDate}
      endDate={endDate}
      downloadPdf={downloadPdf}
      onPdfDownloaded={onPdfDownloaded}
    />
  );

  if (open) {
    return (
      <Dialog
        fullWidth
        maxWidth="xl"
        open={open}
        onClose={handleClosePDFDialog}
      >
        {content}
      </Dialog>
    );
  }

  return content;
}
