import ThemeProvider from "../../contexts/ThemeContext";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import * as React from "react";
import Button from "@mui/material/Button";
import { fileRequest } from "../../api/FileRequest";
import { Stack } from "@mui/material";
import { Upload, NotificationImportant } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { tripRequest } from "../../api/TripRequest";

export default function PublicUploadTripFiles() {
  const { uuid } = useParams();
  const [uploadPending, setUploadPending] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [showActivatePublicLinkBtn, setShowActivatePublicLinkBtn] =
    useState(false);

  useEffect(() => {
    if (uuid) {
      tripRequest.findPublicUploadLink(uuid).then((link) => {
        if (!link.expiresAt) {
          setShowActivatePublicLinkBtn(true);
        }
      });
    }
  }, [uuid]);

  return (
    <ThemeProvider>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
          display="flex"
          alignItems="center"
        >
          <Container
            maxWidth={false}
            sx={{
              mt: 3,
              mb: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 1,
              }}
            >
              {showActivatePublicLinkBtn ? (
                <>
                  <Button
                    size="small"
                    onClick={() => {
                      tripRequest
                        .activatePublicUploadLink(uuid || "")
                        .then(() => {
                          setShowActivatePublicLinkBtn(false);
                        });
                    }}
                    variant="contained"
                    color="primary"
                  >
                    <Stack
                      direction="row"
                      sx={{
                        mt: 0.4,
                      }}
                    >
                      <NotificationImportant
                        fontSize="small"
                        sx={{
                          mr: 0.5,
                        }}
                      />
                      Activează link-ul de încărcare
                    </Stack>
                  </Button>
                  <div>
                    Link-ul va fi activ timp de 24 de ore pentru încarcarea
                    documentelor.
                  </div>
                </>
              ) : (
                <Button
                  size="small"
                  onClick={() => {
                    const fileInput = document.createElement("input");
                    fileInput.type = "file";
                    fileInput.multiple = true;
                    fileInput.style.display = "none";
                    document.body.appendChild(fileInput);

                    fileInput.click();
                    fileInput.onchange = async (e: any) => {
                      setUploadPending(true);
                      const promises = [];

                      for (const file of e.target.files) {
                        promises.push(
                          fileRequest.tripPublicUpload(file, uuid || "")
                        );
                      }

                      try {
                        await Promise.all(promises);
                        setUploadSuccess(true);
                      } catch (e: any) {
                        setUploadSuccess(false);
                        if (e?.response?.data?.message === "File too large") {
                          alert("Fișierul este prea mare! (maxim 50MB)");
                        } else {
                          alert("Link-ul nu este valid sau a expirat!");
                        }
                      } finally {
                        setUploadPending(false);
                      }
                    };

                    document.body.removeChild(fileInput);
                  }}
                  variant="contained"
                  color="warning"
                  disabled={uploadPending}
                >
                  <Stack
                    direction="row"
                    sx={{
                      mt: 0.4,
                    }}
                  >
                    <Upload
                      fontSize="small"
                      sx={{
                        mr: 0.5,
                      }}
                    />
                    Încarcă documente
                  </Stack>
                </Button>
              )}
            </Grid>
            {uploadSuccess && (
              <Grid item xs={12}>
                Documentele au fost încărcate cu succes. Puteți continua să
                încărcați și alte documente.
              </Grid>
            )}
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
