import { GridValueGetterParams } from "@mui/x-data-grid";

export function getName(params: GridValueGetterParams) {
  return `${params.row.name || "-"}`;
}

export function getAddress(params: GridValueGetterParams) {
  return `${params.row.address || "-"}`;
}

export function getPhone(params: GridValueGetterParams) {
  return `${params.row.phone || "-"}`;
}

export function getEmail(params: GridValueGetterParams) {
  return `${params.row.email || "-"}`;
}

export function getCity(params: GridValueGetterParams) {
  return `${params.row.city?.name || "-"}`;
}

export function getShipper(params: GridValueGetterParams) {
  return `${params.row.shipper?.name || "-"}`;
}
