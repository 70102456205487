import { GridValueGetterParams } from "@mui/x-data-grid";
import dateFormat from "dateformat";

export function getUsername(params: GridValueGetterParams) {
  return `${params.row.username || "-"}`;
}

export function getLastName(params: GridValueGetterParams) {
  return `${params.row.lastName || "-"}`;
}

export function getFirstName(params: GridValueGetterParams) {
  return `${params.row.firstName || "-"}`;
}

export function getBirthdate(params: GridValueGetterParams) {
  if (params.row.birthdate) {
    return `${dateFormat(params.row.birthdate, "dd/mm/yyyy")}`;
  } else return `-`;
}

export function getRole(params: GridValueGetterParams) {
  if (params.row.role?.name === "admin") {
    return "ADMINISTRATOR";
  } else if (params.row.role?.name === "manager") {
    return "MANAGER";
  } else if (params.row.role?.name === "dispatcher") {
    return "DISPECER";
  } else if (params.row.role?.name === "shipper_beneficiary") {
    return "BENEFICIAR";
  } else return "-";
}

export function getPhone(params: GridValueGetterParams) {
  return `${params.row.phone || "-"}`;
}
