import Grid from "@mui/material/Grid";
import { Checkbox, Radio, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import { reportRequest } from "../../api/ReportRequest";
import Typography from "@mui/material/Typography";
import TopClientsReportTable, { Report } from "../tables/TopClientsReportTable";

export default function TopClientsReportForm() {
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [billed, setBilled] = useState(true);
  const [trips, setTrips] = useState(false);
  const [profit, setProfit] = useState(false);

  const [error, setError] = useState({
    startDate: false,
    endDate: false,
    reportType: false,
  });

  const handleErrors = () => {
    if (!startDate) {
      setError((prev: any) => ({
        ...prev,
        startDate: true,
      }));
    } else if (startDate) {
      setError((prev: any) => ({
        ...prev,
        startDate: false,
      }));
    }
    if (!endDate) {
      setError((prev: any) => ({
        ...prev,
        endDate: true,
      }));
    } else if (endDate) {
      setError((prev: any) => ({
        ...prev,
        endDate: false,
      }));
    }

    setError((prev: any) => ({
      ...prev,
      reportType: !billed && !trips && !profit,
    }));
  };

  const [report, setReport] = useState<Report[]>([]);
  const fetchReport = () => {
    handleErrors();
    const formIsValid = startDate && endDate && (billed || trips || profit);
    if (formIsValid) {
      return reportRequest
        .getTopClients(startDate, endDate, billed, trips, profit)
        .then((data) => {
          setReport(data);
        })
        .catch(() => {
          alert("S-a produs o eroare!");
        });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={4}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Început dată încărcare"
            value={startDate}
            inputFormat="dd/MM/yyyy"
            onChange={(newValue: any) => {
              setStartDate(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                fullWidth
                error={error.startDate}
                helperText={error.startDate && "Câmp obligatoriu!"}
                inputProps={{
                  ...params.inputProps,
                  placeholder: "zz/ll/aaaa",
                }}
              />
            )}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} lg={4}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Sfârșit dată încărcare"
            value={endDate}
            inputFormat="dd/MM/yyyy"
            onChange={(newValue: any) => {
              setEndDate(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                fullWidth
                error={error.endDate}
                helperText={error.endDate && "Câmp obligatoriu!"}
                inputProps={{
                  ...params.inputProps,
                  placeholder: "zz/ll/aaaa",
                }}
              />
            )}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item>
            <Radio
              size="medium"
              checked={billed}
              onChange={(e) => {
                setBilled(e.target.checked);
                setTrips(false);
                setProfit(false);
              }}
            />
          </Grid>
          <Grid item>
            <Typography variant="body1">Sumă facturată</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item>
            <Radio
              size="medium"
              checked={trips}
              onChange={(e) => {
                setBilled(false);
                setTrips(e.target.checked);
                setProfit(false);
              }}
            />
          </Grid>
          <Grid item>
            <Typography variant="body1">Nr. curse efectuate</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item>
            <Radio
              size="medium"
              checked={profit}
              onChange={(e) => {
                setBilled(false);
                setTrips(false);
                setProfit(e.target.checked);
              }}
            />
          </Grid>
          <Grid item>
            <Typography variant="body1">Profitabilitate</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={4}>
        <Button
          fullWidth
          variant="contained"
          onClick={() => {
            fetchReport();
          }}
          sx={{ height: "40px" }}
        >
          Generează raport
        </Button>
      </Grid>
      {report.length > 0 && (
        <Grid item xs={12}>
          <TopClientsReportTable reportValues={report} />
        </Grid>
      )}
    </Grid>
  );
}
