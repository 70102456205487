import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import {
  Box,
  DialogContentText,
  Divider,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

interface Props {
  selectedTrips: Trip[];
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function ShipperInvoiceAccordion(props: Props) {
  const { selectedTrips } = props;

  const [expandedAccordion, setExpandedAccordion] = React.useState<
    string | false
  >(`panel${selectedTrips[0]?.id}`);

  const handleExpandAccordion =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpandedAccordion(newExpanded ? panel : false);
    };

  return (
    <div>
      {selectedTrips.map((trip) => {
        return (
          <Accordion
            key={trip.id}
            expanded={expandedAccordion === `panel${trip.id}`}
            onChange={handleExpandAccordion(`panel${trip.id}`)}
          >
            <AccordionSummary
              aria-controls={`panel${trip.id}d-content`}
              id={`panel${trip.id}d-header`}
              tabIndex={-1}
            >
              <Grid
                container
                direction={{ xs: "column", sm: "row" }}
                justifyContent={{ xs: "center", sm: "space-between" }}
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Typography>Comanda {trip?.id}</Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    Dată încărcare:{" "}
                    {trip?.loadingDate ? `${trip.loadingDate}` : "-"}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    Localitate:{" "}
                    {trip?.loadingCity?.county?.name
                      ? `${trip.loadingCity.county.name}`
                      : "-"}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    Dată descărcare:{" "}
                    {trip?.unloadingDate ? `${trip.unloadingDate}` : "-"}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    Localitate:{" "}
                    {trip?.unloadingCity?.county?.name
                      ? `${trip.unloadingCity.county.name}`
                      : "-"}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    Transportator:{" "}
                    {trip?.carrier?.name ? `${trip.carrier.name}` : "-"}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <DialogContentText>Transportator</DialogContentText>
                  <Divider sx={{ mb: 2 }} />
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        size="small"
                        label="Nume"
                        value={trip?.shipper?.name ?? "-"}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        size="small"
                        label="Punct de lucru"
                        value={trip?.shipperBranch?.name ?? "-"}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        size="small"
                        label="Referință"
                        value={trip?.shipperReferenceId ?? "-"}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        size="small"
                        fullWidth
                        label="Termen de plată (zile)"
                        value={trip?.shipperPaymentTermInDays ?? "-"}
                        disabled
                      ></TextField>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <DialogContentText>Informații factură</DialogContentText>
                  <Divider sx={{ mb: 2 }} />
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item xs={12}>
                      <Grid
                        container
                        direction="row"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                      >
                        {trip.shipperInvoiceRows?.map((shipperInvoiceRow) => {
                          return (
                            <Grid item xs={12} key={shipperInvoiceRow.id}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <TextField
                                  size="small"
                                  fullWidth
                                  label="Valoare"
                                  value={shipperInvoiceRow?.value ?? ""}
                                  disabled
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        RON
                                      </InputAdornment>
                                    ),
                                  }}
                                ></TextField>
                                <CloseOutlinedIcon
                                  fontSize="medium"
                                  sx={{ ml: 1, mr: 1 }}
                                />
                                <TextField
                                  size="small"
                                  fullWidth
                                  label="Distanță"
                                  value={shipperInvoiceRow?.distanceKm ?? ""}
                                  disabled
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        km
                                      </InputAdornment>
                                    ),
                                  }}
                                ></TextField>
                              </Box>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}
