import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authRequest } from "../../api/AuthRequest";

interface AuthState {
  error: any | null;
  user: {
    id: number | null;
    username: string | null;
    role: string | null;
    lastName: string | null;
    firstName: string | null;
    activeInvoiceSeriesId: null;
  };
  isAuthenticated: boolean;
  vat: number | null;
}

export const login: any = createAsyncThunk("auth/login", (loginData) =>
  authRequest.login(loginData)
);

export const logout = createAsyncThunk("auth/logout", () =>
  authRequest.logout()
);

export const me = createAsyncThunk("auth/me", () => authRequest.me());

const initialState: AuthState = {
  error: null,
  user: {
    id: null,
    username: null,
    role: null,
    lastName: null,
    firstName: null,
    activeInvoiceSeriesId: null,
  },
  isAuthenticated: true,
  vat: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, () => ({
      ...initialState,
      isAuthenticated: false,
    }));
    builder.addCase(me.fulfilled, (state, action) => {
      const {
        id,
        username,
        role,
        lastName,
        firstName,
        miscellaneous,
        activeInvoiceSeriesId,
      } = action.payload;
      state.error = null;
      state.user = {
        ...state.user,
        id,
        username,
        role,
        lastName,
        firstName,
        activeInvoiceSeriesId,
      };
      state.isAuthenticated = true;
      state.vat = miscellaneous.vat;
    });
    builder.addCase(me.rejected, (_, action) => ({
      ...initialState,
      error: action.error,
      isAuthenticated: false,
    }));
  },
});

export default authSlice.reducer;
