import { GridValueGetterParams } from "@mui/x-data-grid";

export function getName(params: GridValueGetterParams) {
  return `${params.row.name || "-"}`;
}

export function getTin(params: GridValueGetterParams) {
  return `${params.row.tin || "-"}`;
}

export function getBusinessRegistrationNumber(params: GridValueGetterParams) {
  return `${params.row.businessRegistrationNumber || "-"}`;
}

export function getBank(params: GridValueGetterParams) {
  return `${params.row.bank || "-"}`;
}

export function getIban(params: GridValueGetterParams) {
  return `${params.row.iban || "-"}`;
}

export function getAddress(params: GridValueGetterParams) {
  return `${params.row.address || "-"}`;
}

export function getPhone(params: GridValueGetterParams) {
  return `${params.row.phone || "-"}`;
}

export function getEmail(params: GridValueGetterParams) {
  return `${params.row.email || "-"}`;
}

export function getContactPerson(params: GridValueGetterParams) {
  return `${params.row.contactPerson || "-"}`;
}

export function getCity(params: GridValueGetterParams) {
  return `${params.row.city?.name || "-"}`;
}

export function getPaymentTermInDays(params: GridValueGetterParams) {
  return `${params.row.paymentTermInDays || "-"}`;
}
