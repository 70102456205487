import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid, IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import ConfirmPaymentAccordion from "../accordions/ConfirmPaymentAccordion";
import { tripRequest } from "../../api/TripRequest";

interface Props {
  open: boolean;
  onClose: AnyFunction;
  selectedTrips: Trip[];
  onTripsPaymentConfirmed: AnyFunction;
}

export default function ConfirmPaymentDialog(props: Props) {
  const { open, onClose, selectedTrips, onTripsPaymentConfirmed } = props;

  const handleConfirmPayment = () => {
    if (selectedTrips) {
      return Promise.all(
        selectedTrips.map((trip) => {
          tripRequest
            .confirmPaymentTrip(trip.id)
            .then(() => {
              if (typeof onTripsPaymentConfirmed === "function") {
                onTripsPaymentConfirmed();
              }
            })
            .catch(() => {
              alert("S-a produs o eroare!");
            });
        })
      );
    }
  };

  const handleCloseConfirmPaymentDialog = () => {
    onClose();
  };

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <DialogTitle sx={{ pb: 0 }}>Confirmare plată</DialogTitle>
          </Grid>
          <Grid item sx={{ mr: 2, pt: 2 }}>
            <IconButton onClick={handleCloseConfirmPaymentDialog} tabIndex={-1}>
              <ClearIcon />
            </IconButton>
          </Grid>
        </Grid>
        <DialogContent>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <ConfirmPaymentAccordion selectedTrips={selectedTrips} />
            </Grid>
            <Grid item>
              {/*<Divider sx={{ mb: 2 }} />*/}
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={12} md={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={() => {
                      handleConfirmPayment()?.then(
                        handleCloseConfirmPaymentDialog
                      );
                    }}
                  >
                    Confirmă
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    fullWidth
                    variant="outlined"
                    onClick={handleCloseConfirmPaymentDialog}
                  >
                    Renunță
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
