// @ts-nocheck
import RootRequest from "./RootRequest";

export default class AuthRequest extends RootRequest {
  static LOCAL_STORAGE_TOKEN_KEY = "token";
  private _token: string | null;
  requestInterceptorId: number | null;

  constructor() {
    super();
    this._token = localStorage.getItem(
      this.constructor.LOCAL_STORAGE_TOKEN_KEY
    );
    this.requestInterceptorId = null;
  }

  get token() {
    return this._token;
  }

  set token(token) {
    if (token) {
      localStorage.setItem(this.constructor.LOCAL_STORAGE_TOKEN_KEY, token);
    } else {
      localStorage.removeItem(this.constructor.LOCAL_STORAGE_TOKEN_KEY);
    }

    this._token = token;
  }

  get apiResource() {
    return "api/auth";
  }

  get apiRoutes() {
    return {
      LOGIN: `${this.apiRoute}/login`,
      ME: `${this.apiRoute}/me`,
    };
  }

  setAuthorizationHeader() {
    if (!this.token) {
      return;
    }

    this.requestInterceptorId = this.addRequestInterceptor((config: any) => {
      if (config?.headers) {
        config.headers.Authorization = `Bearer ${this.token}`;
      }

      return config;
    });
  }

  unsetAuthorizationHeader() {
    this.removeRequestInterceptor(this.requestInterceptorId);
    this.requestInterceptorId = null;
  }

  async login(data: any) {
    const response = await this.makeRequest(
      this.constructor.METHOD.POST,
      this.apiRoutes.LOGIN,
      data
    );

    this.token = response.data.access_token;
    this.setAuthorizationHeader();

    return response.data;
  }

  async logout() {
    this.token = null;
    this.unsetAuthorizationHeader();

    return null;
  }

  async me() {
    this.setAuthorizationHeader();
    try {
      const result = await this.makeRequest(
        this.constructor.METHOD.GET,
        this.apiRoutes.ME
      );

      return result.data;
    } catch (e) {
      this.unsetAuthorizationHeader();
      throw e;
    }
  }

  async findAll() {
    throw new Error("Does not apply to this class!");
  }

  async find() {
    throw new Error("Does not apply to this class!");
  }

  async post() {
    throw new Error("Does not apply to this class!");
  }

  async patch() {
    throw new Error("Does not apply to this class!");
  }

  async delete() {
    throw new Error("Does not apply to this class!");
  }
}

export const authRequest = new AuthRequest();
