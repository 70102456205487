import { GridValueGetterParams } from "@mui/x-data-grid";

export function getName(params: GridValueGetterParams) {
  return `${params.row.shortName || "-"}`;
}

export function getType(params: GridValueGetterParams) {
  return `${params.row?.extension?.slice(1)?.toUpperCase() || "-"}`;
}

export function getSize(params: GridValueGetterParams) {
  if (!params.row.size) {
    return "-";
  }
  const oneKiB = 1024;
  const oneMiB = 1024 * oneKiB;
  if (params.row.size < oneMiB) {
    return `${(params.row.size / oneKiB).toFixed(2)} KB`;
  }
  return `${(params.row.size / oneMiB).toFixed(2)} MB`;
}

export function getCreatedAt(params: GridValueGetterParams) {
  if (!params.row.size) {
    return "-";
  }
  const date = new Date(params.row.createdAt);
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
}
