import * as React from "react";
import Dialog from "@mui/material/Dialog";
import OrderPDF from "../../../pdfRenderer/OrderPDF";

interface Props {
  open: boolean;
  onClose: AnyFunction;
  trip?: Trip;
}

export default function OrderPDFDialog(props: Props) {
  const { open, onClose, trip } = props;

  const handleClosePDFDialog = () => {
    onClose();
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={handleClosePDFDialog}
      >
        <OrderPDF trip={trip} />
      </Dialog>
    </>
  );
}
