import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Autocomplete,
  Box,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
} from "@mui/material";

import ClearIcon from "@mui/icons-material/Clear";
import { useEffect, useState } from "react";
import { carrierRequest } from "../../../api/CarrierRequest";
import { countryRequest } from "../../../api/CountryRequest";
import AddIcon from "@mui/icons-material/Add";
import AddCountryDialog from "../country/AddCountryDialog";
import AddCountyDialog from "../country/AddCountyDialog";
import AddCityDialog from "../country/AddCityDialog";

interface Props {
  open: boolean;
  onClose: AnyFunction;
  onCarrierCreated?: AnyFunction;
}

interface CountryValueProps {
  country: Country | null;
  county: County | null;
  city: City | null;
}

export default function AddCarrierDialog(props: Props) {
  const { open, onClose, onCarrierCreated } = props;

  const [formValues, setFormValues] = useState<any>({
    name: null,
    tin: null,
    address: null,
    phone: null,
    email: null,
    contactPerson: null,
    isActive: true,
    cityId: null,
  });

  const [countryValues, setCountryValues] = useState<CountryValueProps>({
    country: null,
    county: null,
    city: null,
  });

  const [error, setError] = useState({
    name: false,
    nameIsDuplicate: false,
    tin: false,
    tinIsDuplicate: false,
    email: false,
    isNotEmail: false,
  });

  const isEmailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
  const isEmail = Boolean(formValues.email?.match(isEmailRegex));

  const [carrierData, setCarrierData] = useState<Carrier[]>([]);

  const fetchCarriers = () => {
    carrierRequest.findAll(-1, 0, false).then((data) => {
      setCarrierData(
        data.results.map((carrier: Carrier) => ({
          id: carrier.id,
          name: carrier.name,
          tin: carrier.tin,
          city: carrier.city,
          address: carrier.address,
          phone: carrier.phone,
          email: carrier.email,
          contactPerson: carrier.contactPerson,
          isActive: carrier.isActive,
        }))
      );
    });
  };

  useEffect(() => {
    fetchCarriers();
  }, []);

  const checkNameIsDuplicate = (carrier: Carrier) =>
    carrier.name?.toLowerCase() === formValues.name?.toLowerCase();

  const checkTinIsDuplicate = (carrier: Carrier) =>
    carrier.tin?.toLowerCase() === formValues.tin?.toLowerCase();

  const handleErrors = () => {
    if (!formValues.name) {
      setError((prev: any) => ({
        ...prev,
        name: true,
      }));
    } else if (formValues.name) {
      setError((prev: any) => ({
        ...prev,
        name: false,
      }));
    }
    if (formValues.name) {
      setError((prev: any) => ({
        ...prev,
        nameIsDuplicate: carrierData.some(checkNameIsDuplicate),
      }));
    }
    if (!formValues.tin) {
      setError((prev: any) => ({
        ...prev,
        tin: true,
      }));
    } else if (formValues.tin) {
      setError((prev: any) => ({
        ...prev,
        tin: false,
      }));
    }
    if (formValues.tin) {
      setError((prev: any) => ({
        ...prev,
        tinIsDuplicate: carrierData.some(checkTinIsDuplicate),
      }));
    }
    if (!formValues.email) {
      setError((prev: any) => ({
        ...prev,
        email: true,
      }));
    } else if (formValues.email) {
      setError((prev: any) => ({
        ...prev,
        email: false,
      }));
    }
    if (!isEmail) {
      setError((prev: any) => ({
        ...prev,
        isNotEmail: true,
      }));
    } else if (isEmail) {
      setError((prev: any) => ({
        ...prev,
        isNotEmail: false,
      }));
    }
  };

  const handleSubmit = () => {
    handleErrors();
    const formIsValid =
      formValues.name &&
      !carrierData.some(checkNameIsDuplicate) &&
      formValues.tin &&
      !carrierData.some(checkTinIsDuplicate) &&
      formValues.email &&
      isEmail;
    if (formIsValid) {
      return carrierRequest
        .post(formValues)
        .then((createdCarrier) => {
          setCountryValues((prev: any) => ({
            ...prev,
            country: null,
            county: null,
            city: null,
          }));
          setSelectedCountryId(null);
          setSelectedCountyId(null);
          if (typeof onCarrierCreated === "function") {
            onCarrierCreated(createdCarrier);
          }
        })
        .catch(() => {
          alert("S-a produs o eroare!");
        });
    }
  };

  const handleCloseAddCarrierDialog = () => {
    onClose();
    setCountryValues((prev: any) => ({
      ...prev,
      country: null,
      county: null,
      city: null,
    }));
    setSelectedCountryId(null);
    setSelectedCountyId(null);
    setFormValues(() => ({
      name: null,
      tin: null,
      address: null,
      phone: null,
      email: null,
      contactPerson: null,
      isActive: false,
      cityId: null,
    }));
    setError(() => ({
      name: false,
      nameIsDuplicate: false,
      tin: false,
      tinIsDuplicate: false,
      email: false,
      isNotEmail: false,
    }));
  };

  const [countryOptions, setCountryOptions] = useState<Country[]>([]);
  const [countyOptions, setCountyOptions] = useState<County[]>([]);
  const [cityOptions, setCityOptions] = useState<City[]>([]);

  const [selectedCountryId, setSelectedCountryId] = useState<any>(null);
  const [selectedCountyId, setSelectedCountyId] = useState<any>(null);

  const fetchCountries = () => {
    countryRequest
      .getCountries()
      .then((countries) => setCountryOptions(countries));
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  const sortedCountryOptions = countryOptions?.sort(function (a, b) {
    if (a.name! < b.name!) {
      return -1;
    }
    if (a.name! > b.name!) {
      return 1;
    }
    return 0;
  });

  const isRomaniaDefaultValue = sortedCountryOptions?.find(
    (country: Country) => country.name?.toUpperCase() === "ROMANIA"
  );

  useEffect(() => {
    setCountryValues((prev: any) => ({
      ...prev,
      country: isRomaniaDefaultValue ? isRomaniaDefaultValue : null,
    }));
    setSelectedCountryId(isRomaniaDefaultValue?.id);
  }, [isRomaniaDefaultValue]);

  const fetchCounties = () => {
    if (selectedCountryId) {
      countryRequest
        .getCounties(selectedCountryId)
        .then((counties) => setCountyOptions(counties));
    }
  };

  useEffect(() => {
    fetchCounties();
  }, [selectedCountryId]);

  const sortedCountyOptions = countyOptions?.sort(function (a, b) {
    if (a.name! < b.name!) {
      return -1;
    }
    if (a.name! > b.name!) {
      return 1;
    }
    return 0;
  });

  const fetchCities = () => {
    if (selectedCountyId) {
      countryRequest
        .getCities(selectedCountyId)
        .then((cities) => setCityOptions(cities));
    }
  };

  useEffect(() => {
    fetchCities();
  }, [selectedCountyId]);

  const sortedCityOptions = cityOptions?.sort(function (a, b) {
    if (a.name! < b.name!) {
      return -1;
    }
    if (a.name! > b.name!) {
      return 1;
    }
    return 0;
  });

  const isBucharestCountyChosen =
    countryValues?.county?.name?.toUpperCase() === "BUCURESTI";

  const isBucharestCityValue = cityOptions?.find(
    (city: City) => city.name?.toUpperCase() === "BUCURESTI"
  );

  useEffect(() => {
    setCountryValues((prev: any) => ({
      ...prev,
      city:
        isBucharestCountyChosen && isBucharestCityValue
          ? isBucharestCityValue
          : null,
    }));
    setFormValues((prev: any) => ({
      ...prev,
      cityId: isBucharestCityValue?.id,
    }));
  }, [isBucharestCountyChosen, isBucharestCityValue]);

  const [openAddCountryDialog, setOpenAddCountryDialog] = React.useState(false);
  const handleOpenAddCountryDialog = () => {
    setOpenAddCountryDialog(true);
  };

  const [openAddCountyDialog, setOpenAddCountyDialog] = React.useState(false);
  const handleOpenAddCountyDialog = () => {
    setOpenAddCountyDialog(true);
  };

  const [openAddCityDialog, setOpenAddCityDialog] = React.useState(false);
  const handleOpenAddCityDialog = () => {
    setOpenAddCityDialog(true);
  };

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="xs">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <DialogTitle sx={{ pb: 0 }}>Adăugare transportator</DialogTitle>
          </Grid>
          <Grid item sx={{ mr: 2, pt: 2 }}>
            <IconButton onClick={handleCloseAddCarrierDialog} tabIndex={-1}>
              <ClearIcon />
            </IconButton>
          </Grid>
        </Grid>
        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12}>
              <TextField
                required
                size="small"
                fullWidth
                label="Nume"
                error={error.name || error.nameIsDuplicate}
                helperText={
                  error.name
                    ? "Câmp obligatoriu!"
                    : error.nameIsDuplicate && "Nume deja existent!"
                }
                onChange={(event) =>
                  setFormValues((prev: any) => ({
                    ...prev,
                    name: event.target.value.toUpperCase(),
                  }))
                }
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit()
                      ?.then(handleCloseAddCarrierDialog)
                      .then(fetchCarriers);
                  }
                }}
                inputProps={{ style: { textTransform: "uppercase" } }}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                size="small"
                fullWidth
                label="CUI"
                error={error.tin || error.tinIsDuplicate}
                helperText={
                  error.tin
                    ? "Câmp obligatoriu!"
                    : error.tinIsDuplicate && "CUI deja existent!"
                }
                onChange={(event) =>
                  setFormValues((prev: any) => ({
                    ...prev,
                    tin: event.target.value.toUpperCase(),
                  }))
                }
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit()
                      ?.then(handleCloseAddCarrierDialog)
                      .then(fetchCarriers);
                  }
                }}
                inputProps={{ style: { textTransform: "uppercase" } }}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                fullWidth
                label="Telefon"
                onChange={(event) =>
                  setFormValues((prev: any) => ({
                    ...prev,
                    phone: event.target.value.toUpperCase(),
                  }))
                }
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit()
                      ?.then(handleCloseAddCarrierDialog)
                      .then(fetchCarriers);
                  }
                }}
                inputProps={{ style: { textTransform: "uppercase" } }}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                size="small"
                fullWidth
                label="Email"
                error={error.email || error.isNotEmail}
                helperText={
                  error.email
                    ? "Câmp obligatoriu!"
                    : error.isNotEmail && "Email invalid!"
                }
                onChange={(event) =>
                  setFormValues((prev: any) => ({
                    ...prev,
                    email: event.target.value.toUpperCase(),
                  }))
                }
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit()
                      ?.then(handleCloseAddCarrierDialog)
                      .then(fetchCarriers);
                  }
                }}
                inputProps={{ style: { textTransform: "uppercase" } }}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                fullWidth
                label="Contact"
                onChange={(event) =>
                  setFormValues((prev: any) => ({
                    ...prev,
                    contactPerson: event.target.value.toUpperCase(),
                  }))
                }
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit()
                      ?.then(handleCloseAddCarrierDialog)
                      .then(fetchCarriers);
                  }
                }}
                inputProps={{ style: { textTransform: "uppercase" } }}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex" }}>
                <Autocomplete
                  value={countryValues.country}
                  onChange={(event, newValue, reason) => {
                    if (reason === "clear") {
                      setCountryValues((prev: any) => ({
                        ...prev,
                        country: null,
                        county: null,
                        city: null,
                      }));
                      setFormValues((prev: any) => ({
                        ...prev,
                        cityId: null,
                      }));
                      setSelectedCountryId(null);
                      setSelectedCountyId(null);
                    } else {
                      setCountryValues((prev: any) => ({
                        ...prev,
                        country: newValue,
                      }));
                      setSelectedCountryId(newValue?.id);
                    }
                  }}
                  options={sortedCountryOptions}
                  filterSelectedOptions
                  getOptionLabel={(option) => `${option.name}`}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  noOptionsText="Fără opțiuni"
                  renderInput={(params) => (
                    <form
                      noValidate
                      onSubmit={(event) => event.preventDefault()}
                    >
                      <TextField
                        {...params}
                        size="small"
                        fullWidth
                        label="Țară"
                        onKeyUp={(e) => {
                          if (e.key === "Enter") {
                            handleSubmit()
                              ?.then(handleCloseAddCarrierDialog)
                              .then(fetchCarriers);
                          }
                        }}
                      />
                    </form>
                  )}
                  sx={{ flexGrow: 1, mr: 1 }}
                />
                <Button
                  color="primary"
                  variant="contained"
                  sx={{
                    maxWidth: "40px",
                    minWidth: "40px",
                    minHeight: "40px",
                    maxHeight: "40px",
                  }}
                  onClick={handleOpenAddCountryDialog}
                  tabIndex={-1}
                >
                  <AddIcon />
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex" }}>
                <Autocomplete
                  value={countryValues.county}
                  onChange={(event, newValue, reason) => {
                    if (reason === "clear") {
                      setCountryValues((prev: any) => ({
                        ...prev,
                        county: null,
                        city: null,
                      }));
                      setFormValues((prev: any) => ({
                        ...prev,
                        cityId: null,
                      }));
                      setSelectedCountyId(null);
                    } else {
                      setCountryValues((prev: any) => ({
                        ...prev,
                        county: newValue,
                      }));
                      setSelectedCountyId(newValue?.id);
                    }
                  }}
                  disabled={!selectedCountryId}
                  options={sortedCountyOptions}
                  filterSelectedOptions
                  getOptionLabel={(option) => `${option.name}`}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  noOptionsText="Fără opțiuni"
                  renderInput={(params) => (
                    <form
                      noValidate
                      onSubmit={(event) => event.preventDefault()}
                    >
                      <TextField
                        {...params}
                        size="small"
                        fullWidth
                        label="Județ"
                        onKeyUp={(e) => {
                          if (e.key === "Enter") {
                            handleSubmit()
                              ?.then(handleCloseAddCarrierDialog)
                              .then(fetchCarriers);
                          }
                        }}
                      />
                    </form>
                  )}
                  sx={{ flexGrow: 1, mr: 1 }}
                />
                <Button
                  color="primary"
                  variant="contained"
                  sx={{
                    maxWidth: "40px",
                    minWidth: "40px",
                    minHeight: "40px",
                    maxHeight: "40px",
                  }}
                  disabled={!selectedCountryId}
                  onClick={handleOpenAddCountyDialog}
                  tabIndex={-1}
                >
                  <AddIcon />
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex" }}>
                <Autocomplete
                  value={countryValues.city}
                  onChange={(event, newValue) => {
                    setCountryValues((prev: any) => ({
                      ...prev,
                      city: newValue,
                    }));
                    setFormValues((prev: any) => ({
                      ...prev,
                      cityId: newValue?.id,
                    }));
                  }}
                  options={sortedCityOptions}
                  filterSelectedOptions
                  disabled={!selectedCountyId}
                  getOptionLabel={(option) => `${option.name}`}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  noOptionsText="Fără opțiuni"
                  renderInput={(params) => (
                    <form
                      noValidate
                      onSubmit={(event) => event.preventDefault()}
                    >
                      <TextField
                        {...params}
                        size="small"
                        fullWidth
                        label="Localitate"
                        onKeyUp={(e) => {
                          if (e.key === "Enter") {
                            handleSubmit()
                              ?.then(handleCloseAddCarrierDialog)
                              .then(fetchCarriers);
                          }
                        }}
                      />
                    </form>
                  )}
                  sx={{ flexGrow: 1, mr: 1 }}
                />
                <Button
                  color="primary"
                  variant="contained"
                  sx={{
                    maxWidth: "40px",
                    minWidth: "40px",
                    minHeight: "40px",
                    maxHeight: "40px",
                  }}
                  disabled={!selectedCountyId}
                  onClick={handleOpenAddCityDialog}
                  tabIndex={-1}
                >
                  <AddIcon />
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                fullWidth
                label="Adresă"
                onChange={(event) =>
                  setFormValues((prev: any) => ({
                    ...prev,
                    address: event.target.value.toUpperCase(),
                  }))
                }
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit()
                      ?.then(handleCloseAddCarrierDialog)
                      .then(fetchCarriers);
                  }
                }}
                inputProps={{ style: { textTransform: "uppercase" } }}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                justifyContent={{ xs: "center", md: "center" }}
                alignItems="center"
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={formValues.isActive || false}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setFormValues((prev: any) => ({
                          ...prev,
                          isActive: event.target.checked,
                        }));
                      }}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          handleSubmit()
                            ?.then(handleCloseAddCarrierDialog)
                            .then(fetchCarriers);
                        }
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  labelPlacement="start"
                  label="Activ"
                />
              </Grid>
            </Grid>
          </Grid>
          <Divider sx={{ mb: 2, opacity: 0 }} />
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={12} md={6}>
              <Button
                fullWidth
                variant="contained"
                onClick={() => {
                  handleSubmit()
                    ?.then(handleCloseAddCarrierDialog)
                    .then(fetchCarriers);
                }}
              >
                Salvează
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                fullWidth
                variant="outlined"
                onClick={handleCloseAddCarrierDialog}
              >
                Renunță
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      {openAddCountryDialog && (
        <AddCountryDialog
          open={openAddCountryDialog}
          onCountryCreated={(createdCountry: Country) => {
            fetchCountries();
            setCountryValues((prev: any) => ({
              ...prev,
              country: createdCountry,
              county: null,
              city: null,
            }));
            setSelectedCountryId(createdCountry.id);
            setSelectedCountyId(null);
            setFormValues((prev: any) => ({
              ...prev,
              cityId: null,
            }));
            setError((prev: any) => ({
              ...prev,
              countryId: false,
              countyId: false,
              cityId: false,
            }));
          }}
          onClose={() => {
            setOpenAddCountryDialog(false);
          }}
        />
      )}
      {openAddCountyDialog && (
        <AddCountyDialog
          open={openAddCountyDialog}
          onCountyCreated={(createdCounty: County) => {
            fetchCounties();
            setCountryValues((prev: any) => ({
              ...prev,
              county: createdCounty,
              city: null,
            }));
            setSelectedCountyId(createdCounty.id);
            setFormValues((prev: any) => ({
              ...prev,
              cityId: null,
            }));
            setError((prev: any) => ({
              ...prev,
              countyId: false,
              cityId: false,
            }));
          }}
          onClose={() => {
            setOpenAddCountyDialog(false);
          }}
          selectedCountryId={selectedCountryId}
        />
      )}
      {openAddCityDialog && (
        <AddCityDialog
          open={openAddCityDialog}
          onCityCreated={(createdCity: City) => {
            fetchCities();
            setCountryValues((prev: any) => ({
              ...prev,
              city: createdCity,
            }));
            setFormValues((prev: any) => ({
              ...prev,
              cityId: createdCity.id,
            }));
            setError((prev: any) => ({
              ...prev,
              cityId: false,
            }));
          }}
          onClose={() => {
            setOpenAddCityDialog(false);
          }}
          selectedCountyId={selectedCountyId}
        />
      )}
    </>
  );
}
