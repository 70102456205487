import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import ThemeProvider from "../../contexts/ThemeContext";
import MenuBar from "../../components/menu/MenuBar";
import TripsDataGrid from "../../components/dataGrids/TripsDataGrid";
import { useSelector } from "react-redux";
import { isShipperBeneficiarySelector } from "../../redux/slice/selectors";
import ShipperBeneficiaryTripsDataGrid from "../../components/dataGrids/ShipperBeneficiaryTripsDataGrid";

export default function Trips() {
  const isShipperBeneficiary = useSelector(isShipperBeneficiarySelector);

  return (
    <ThemeProvider>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <MenuBar />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth={false} sx={{ mt: 3, mb: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {isShipperBeneficiary ? (
                  <ShipperBeneficiaryTripsDataGrid />
                ) : (
                  <TripsDataGrid />
                )}
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
