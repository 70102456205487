import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import ThemeProvider from "../contexts/ThemeContext";
import PATH from "../paths";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isAuthenticatedSelector } from "../redux/slice/selectors";
import { login, me } from "../redux/slice/auth";
import store from "../redux/store";

export default function Login() {
  const dispatch = useDispatch<typeof store.dispatch>();
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    dispatch(
      login({
        username: data.get("username"),
        password: data.get("password"),
      })
    )
      .unwrap()
      .then(() => dispatch(me()))
      .catch(() => {
        alert("Numele de utilizator sau parola sunt incorecte!");
      });
  };

  if (isAuthenticated) {
    return <Navigate to={PATH.TRIP.LIST} />;
  }

  return (
    <ThemeProvider>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="70vh"
        >
          <Box>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              // style={{ minHeight: "80vh" }}
            >
              <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                eTMS
              </Typography>
              <Box
                component="form"
                onSubmit={(event) => {
                  handleSubmit(event);
                }}
                noValidate
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="Nume utilizator"
                  name="username"
                  autoComplete="username"
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Parolă"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
                {/*<Grid*/}
                {/*  container*/}
                {/*  justifyContent="space-between"*/}
                {/*  alignItems="center"*/}
                {/*>*/}
                {/*  <Grid item>*/}
                {/*    <FormControlLabel*/}
                {/*      control={<Checkbox value="remember" color="primary" />}*/}
                {/*      label="Ține-mă minte"*/}
                {/*    />*/}
                {/*  </Grid>*/}
                {/*  <Grid item>*/}
                {/*    <Typography color="primary">*/}
                {/*      <Link color="inherit" href="https://mui.com/">*/}
                {/*        Resetează parola*/}
                {/*      </Link>*/}
                {/*    </Typography>*/}
                {/*  </Grid>*/}
                {/*</Grid>*/}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 2 }}
                >
                  Autentificare
                </Button>
              </Box>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
