import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { Button, darken, Grid, lighten, Stack, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
// @ts-ignore
import FileViewer from "react-file-viewer";
import { dataGridRoTextConstants } from "../../constants/dataGridRoTextConstants";
import IconButton from "@mui/material/IconButton";
import {
  getCreatedAt,
  getName,
  getSize,
  getType,
} from "../../functions/valueGetterFunctions/tripDocumentGetterFunctions";
import {
  Download,
  DownloadForOffline,
  Upload,
  Preview,
  Delete,
  Print,
} from "@mui/icons-material";
import { tripRequest } from "../../api/TripRequest";
import { fileRequest } from "../../api/FileRequest";
import DeleteRowDialog from "../dialogs/DeleteRowDialog";
import { useSelector } from "react-redux";
import { isShipperBeneficiarySelector } from "../../redux/slice/selectors";

interface Props {
  trip?: Trip;
}

const getBackgroundColor = (color: string, mode: string) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

export default function TripDocumentsDataGrid({ trip }: Props) {
  const isShipperBeneficiary = useSelector(isShipperBeneficiarySelector);
  const [pageIsLoading, setPageIsLoading] = useState(false);
  const [rows, setRows] = useState<FileDocument[]>([]);
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [toDeleteDocumentId, setToDeleteDocumentId] = useState<number | null>(
    null
  );
  const [previewDocument, setPreviewDocument] = useState<{
    uri: string;
    type: string;
  } | null>(null);

  const fetchTrip = (id: number | undefined) => {
    if (!id) {
      return;
    }
    setPageIsLoading(true);
    const requestMethod = isShipperBeneficiary
      ? tripRequest.findForShipperBeneficiary.bind(tripRequest)
      : tripRequest.find.bind(tripRequest);

    return requestMethod(id)
      .then((trip) => {
        setRows(trip.files);
      })
      .finally(() => setPageIsLoading(false));
  };

  useEffect(() => {
    if (trip?.id) {
      fetchTrip(trip.id);
    }
  }, [trip]);

  const columns: GridColDef[] = [
    {
      field: "shortName",
      headerName: "Nume",
      width: 160,
      flex: 1,
      valueGetter: getName,
    },
    {
      field: "extension",
      headerName: "Tip document",
      width: 160,
      flex: 1,
      valueGetter: getType,
    },
    {
      field: "size",
      headerName: "Mărime document",
      width: 160,
      flex: 1,
      valueGetter: getSize,
    },
    {
      field: "createdAt",
      headerName: "Dată creare",
      width: 160,
      flex: 1,
      valueGetter: getCreatedAt,
    },
    {
      field: "actions",
      headerName: "Acțiuni",
      minWidth: 160,
      maxWidth: 160,
      filterable: false,
      renderCell: ({ row: document }) => {
        return [
          <>
            <Tooltip title="Previzulizează" placement="bottom">
              <IconButton
                onClick={async () => {
                  const blob = await fileRequest.getBlob(document.id);
                  const url = URL.createObjectURL(blob);
                  setPreviewDocument({
                    uri: url,
                    type: document.extension.slice(1),
                  });
                }}
                color="primary"
              >
                <Preview fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Descarcă" placement="bottom">
              <IconButton
                onClick={() => {
                  fileRequest.download(document.id, document.shortName);
                }}
                color="primary"
              >
                <Download fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Printează" placement="bottom">
              <IconButton
                onClick={async () => {
                  const blob = await fileRequest.getBlob(document.id);
                  const url = URL.createObjectURL(blob);

                  const existingIframe =
                    window.document.body.querySelector("#print-iframe");
                  if (existingIframe) {
                    window.document.body.removeChild(existingIframe);
                  }

                  const iframe = window.document.createElement("iframe");
                  window.document.body.appendChild(iframe);

                  iframe.id = "print-iframe";
                  iframe.style.display = "none";
                  iframe.src = url;
                  iframe.onload = () => {
                    setTimeout(() => {
                      iframe.focus();
                      iframe.contentWindow?.print();
                    }, 0);
                  };
                }}
                color="secondary"
              >
                <Print fontSize="small" />
              </IconButton>
            </Tooltip>
            {!isShipperBeneficiary && (
              <Tooltip title="Șterge" placement="bottom">
                <span>
                  <IconButton
                    onClick={() => {
                      setToDeleteDocumentId(document.id);
                    }}
                    color="error"
                    disabled={document.isShipperInvoice}
                  >
                    <Delete fontSize="small" />
                  </IconButton>
                </span>
              </Tooltip>
            )}
          </>,
        ];
      },
    },
  ];

  const [selectedRows, setSelectedRows] = useState<FileDocument[]>([]);

  const [filterButtonEl, setFilterButtonEl] = React.useState(null);

  const CustomToolbar = React.useCallback(() => {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <Grid
            item
            sx={{
              ml: 1,
            }}
          >
            <GridToolbarQuickFilter />
          </Grid>
          <Grid
            item
            sx={{
              mr: 0.3,
            }}
          >
            <Grid container direction="row" spacing={1}>
              <Grid item>
                {/* @ts-ignore */}
                <GridToolbarColumnsButton ref={setFilterButtonEl} />
              </Grid>
              <Grid item>
                {/* @ts-ignore */}
                <GridToolbarDensitySelector ref={setFilterButtonEl} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </GridToolbarContainer>
    );
  }, [selectedRows]);

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <Grid item container direction="row" spacing={1}>
          {!isShipperBeneficiary && (
            <Grid item>
              <Button
                size="small"
                onClick={() => {
                  const fileInput = document.createElement("input");
                  fileInput.type = "file";
                  fileInput.multiple = true;
                  fileInput.style.display = "none";
                  document.body.appendChild(fileInput);

                  fileInput.click();
                  fileInput.onchange = async (e: any) => {
                    if (!trip?.id) {
                      return;
                    }

                    setPageIsLoading(true);
                    const promises = [];
                    let fileIndex = rows.length
                      ? rows[rows.length - 1].isShipperInvoice
                        ? 0
                        : +rows[rows.length - 1].name
                            .split("-")
                            .reverse()[0]
                            .split(".")[0]
                      : 0;
                    for (const file of e.target.files) {
                      const toUploadFileNameSplit = file.name.split(".");
                      const renamedFile = new File(
                        [file],
                        `${trip.id}${
                          trip.shipperReferenceId
                            ? `-${trip.shipperReferenceId}`
                            : ""
                        }-${++fileIndex}${
                          toUploadFileNameSplit.length === 1
                            ? ""
                            : `.${toUploadFileNameSplit.pop()}`
                        }`,
                        { type: file.type }
                      );
                      promises.push(fileRequest.upload(renamedFile));
                    }

                    try {
                      const uploadedFiles = await Promise.all(promises);
                      await tripRequest.addDocuments(
                        trip.id,
                        uploadedFiles.map((file) => file.id)
                      );

                      fetchTrip(trip.id);
                    } catch (e) {
                      setPageIsLoading(false);
                      alert("S-a produs o eroare!");
                    }
                  };

                  document.body.removeChild(fileInput);
                }}
                variant="contained"
                color="warning"
                disabled={pageIsLoading}
              >
                <Stack
                  direction="row"
                  sx={{
                    mt: 0.4,
                  }}
                >
                  <Upload
                    fontSize="small"
                    sx={{
                      mr: 0.5,
                    }}
                  />
                  Încarcă documente
                </Stack>
              </Button>
            </Grid>
          )}
          <Grid item>
            <Button
              size="small"
              disabled={!rows.length || pageIsLoading}
              onClick={() =>
                rows.forEach((row) =>
                  fileRequest.download(row.id, row.shortName)
                )
              }
              variant="contained"
            >
              <Stack
                direction="row"
                sx={{
                  mt: 0.4,
                }}
              >
                <Download
                  fontSize="small"
                  sx={{
                    mr: 0.5,
                  }}
                />
                Descarcă toate documentele
              </Stack>
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="small"
              disabled={!selectedRows.length || pageIsLoading}
              onClick={() =>
                selectedRows.forEach((row) =>
                  fileRequest.download(row.id, row.shortName)
                )
              }
              variant="contained"
            >
              <Stack
                direction="row"
                sx={{
                  mt: 0.4,
                }}
              >
                <DownloadForOffline
                  fontSize="small"
                  sx={{
                    mr: 0.5,
                  }}
                />
                Descarcă documentele selectate
              </Stack>
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="flex-end"
            spacing={1}
          >
            <Grid item xs={12}>
              <div style={{ width: "100%" }}>
                <div style={{ display: "flex", height: "100%" }}>
                  <div style={{ flexGrow: 1 }}>
                    <DataGrid
                      getRowClassName={(params) => {
                        if (params.row.isExternalUploaded) {
                          return "external-uploaded-file";
                        }
                        return "";
                      }}
                      loading={pageIsLoading}
                      rows={rows}
                      getRowId={(row) => row.id}
                      columns={columns}
                      initialState={{
                        sorting: {
                          sortModel: [{ field: "id", sort: "desc" }],
                        },
                      }}
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                      rowsPerPageOptions={[10, 25, 50, 100]}
                      autoHeight
                      pagination
                      disableSelectionOnClick
                      checkboxSelection={true}
                      onSelectionModelChange={(ids) => {
                        const selectedIDs = new Set(ids);
                        const selectedRows = rows.filter((row) =>
                          selectedIDs.has(row.id)
                        );
                        setSelectedRows(selectedRows);
                      }}
                      experimentalFeatures={{ newEditingApi: true }}
                      localeText={dataGridRoTextConstants}
                      components={{ Toolbar: CustomToolbar }}
                      componentsProps={{
                        pagination: {
                          labelRowsPerPage: "Număr de înregistrări pe pagină",
                        },
                        panel: {
                          anchorEl: filterButtonEl,
                          placement: "bottom-end",
                        },
                      }}
                      sx={{
                        "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within":
                          {
                            outline: "none",
                          },

                        "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus":
                          {
                            outline: "none",
                          },
                        "& .external-uploaded-file": {
                          backgroundColor: (theme) =>
                            getBackgroundColor(
                              theme.palette.warning.dark,
                              theme.palette.mode
                            ),
                          "&:hover": {
                            backgroundColor: (theme) =>
                              getHoverBackgroundColor(
                                theme.palette.warning.dark,
                                theme.palette.mode
                              ),
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        {previewDocument && (
          <Grid
            item
            xs={12}
            sx={{
              maxHeight: 720,
              textAlign: "center",
              overflow: "auto",
              ".pg-viewer-wrapper": {
                overflow: "unset !important",
              },
              ".photo-viewer-container": {
                height: "800px !important",
                width: "auto !important",
                margin: "auto",
              },
              img: {
                width: "auto !important",
                height: "800px !important",
              },
            }}
          >
            <FileViewer
              key={previewDocument.uri}
              fileType={previewDocument.type}
              filePath={previewDocument.uri}
              errorComponent={<div>error</div>}
              onError={(err: any) => console.log(err)}
            />
          </Grid>
        )}
      </Grid>

      <DeleteRowDialog
        open={Boolean(toDeleteDocumentId)}
        onClose={() => setToDeleteDocumentId(null)}
        onDelete={() => {
          if (!toDeleteDocumentId) {
            return;
          }
          setPageIsLoading(true);
          fileRequest
            .delete(toDeleteDocumentId)
            .then(() => fetchTrip(trip?.id))
            .finally(() => setPageIsLoading(false));
        }}
      />
    </>
  );
}
