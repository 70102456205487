import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Divider, Grid, IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useEffect, useState } from "react";
import { cargoTypeRequest } from "../../../api/CargoTypeRequest";

interface Props {
  open: boolean;
  onClose: AnyFunction;
  onCargoTypeCreated: AnyFunction;
}

export default function AddCargoTypeDialog(props: Props) {
  const { open, onClose, onCargoTypeCreated } = props;
  const [formValues, setFormValues] = useState<any>({
    name: null,
  });

  const [error, setError] = useState({
    name: false,
    nameIsDuplicate: false,
  });

  const [cargoTypeData, setCargoTypeData] = useState<CargoType[]>([]);

  const fetchCargoType = () => {
    cargoTypeRequest.findAll().then((data) => {
      setCargoTypeData(
        data.results.map((cargoType: CargoType) => ({
          id: cargoType.id,
          name: cargoType.name,
        }))
      );
    });
  };

  useEffect(() => {
    fetchCargoType();
  }, []);

  const checkNameIsDuplicate = (cargoType: CargoType) =>
    cargoType.name?.toLowerCase() === formValues.name?.toLowerCase();

  const handleErrors = () => {
    if (!formValues.name) {
      setError((prev: any) => ({
        ...prev,
        name: true,
      }));
    } else if (formValues.name) {
      setError((prev: any) => ({
        ...prev,
        name: false,
      }));
    }
    if (formValues.name) {
      setError((prev: any) => ({
        ...prev,
        nameIsDuplicate: cargoTypeData.some(checkNameIsDuplicate),
      }));
    }
  };

  const handleSubmit = () => {
    handleErrors();
    const formIsValid =
      formValues.name && !cargoTypeData.some(checkNameIsDuplicate);
    if (formIsValid) {
      return cargoTypeRequest
        .post(formValues)
        .then((createdCargoType) => {
          if (typeof onCargoTypeCreated === "function") {
            onCargoTypeCreated(createdCargoType);
          }
        })
        .catch(() => {
          alert("S-a produs o eroare!");
        });
    }
  };

  const handleCloseAddCargoTypeDialog = () => {
    onClose();
    setFormValues(() => ({
      name: null,
    }));
    setError(() => ({
      name: false,
      nameIsDuplicate: false,
    }));
  };

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="xs">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <DialogTitle sx={{ pb: 0 }}>Adăugare tip marfă</DialogTitle>
          </Grid>
          <Grid item sx={{ mr: 2, pt: 2 }}>
            <IconButton onClick={handleCloseAddCargoTypeDialog} tabIndex={-1}>
              <ClearIcon />
            </IconButton>
          </Grid>
        </Grid>
        <DialogContent>
          <TextField
            required
            label="Nume"
            size="small"
            fullWidth
            error={error.name || error.nameIsDuplicate}
            helperText={
              error.name
                ? "Câmp obligatoriu!"
                : error.nameIsDuplicate && "Nume deja existent!"
            }
            onChange={(event) =>
              setFormValues((prev: any) => ({
                ...prev,
                name: event.target.value.toUpperCase(),
              }))
            }
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                handleSubmit()
                  ?.then(handleCloseAddCargoTypeDialog)
                  .then(fetchCargoType);
              }
            }}
            inputProps={{ style: { textTransform: "uppercase" } }}
          ></TextField>
          <Divider sx={{ mb: 2, opacity: 0 }} />
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={12} md={6}>
              <Button
                fullWidth
                variant="contained"
                onClick={() => {
                  handleSubmit()
                    ?.then(handleCloseAddCargoTypeDialog)
                    .then(fetchCargoType);
                }}
              >
                Salvează
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                fullWidth
                variant="outlined"
                onClick={handleCloseAddCargoTypeDialog}
              >
                Renunță
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
