import RootRequest, { QueryParam } from "./RootRequest";

export default class CountryRequest extends RootRequest {
  get apiResource() {
    return "api/country";
  }

  get dynamicIds() {
    return {
      COUNTRY_ID: "{countryId}",
      COUNTY_ID: "{countyId}",
      CITY_ID: "{countyId}",
    };
  }

  get apiRoutes() {
    return {
      // ...super.apiRoutes,
      GET_COUNTRIES: this.apiRoute,
      GET_COUNTIES: `${this.apiRoute}/counties/by-country-id/${this.dynamicIds.COUNTRY_ID}`,
      GET_CITIES: `${this.apiRoute}/cities/by-county-id/${this.dynamicIds.COUNTY_ID}`,
      SEARCH_CITIES: `${this.apiRoute}/cities/by-country-id/${this.dynamicIds.COUNTRY_ID}/search/`,
      CREATE_COUNTRY: `${this.apiRoute}/create`,
      CREATE_COUNTY: `${this.apiRoute}/create-county`,
      CREATE_CITY: `${this.apiRoute}/create-city`,
      UPDATE_COUNTRY: `${this.apiRoute}/update/${this.dynamicIds.COUNTRY_ID}`,
      UPDATE_COUNTY: `${this.apiRoute}/update-county/${this.dynamicIds.COUNTY_ID}`,
      UPDATE_CITY: `${this.apiRoute}/update-city/${this.dynamicIds.CITY_ID}`,
      DELETE_COUNTRY: `${this.apiRoute}/delete/${this.dynamicIds.COUNTRY_ID}`,
      DELETE_COUNTY: `${this.apiRoute}/delete-county/${this.dynamicIds.COUNTY_ID}`,
      DELETE_CITY: `${this.apiRoute}/delete-city/${this.dynamicIds.CITY_ID}`,
    };
  }

  async getCountries() {
    const response = await this.makeRequest(
      RootRequest.METHOD.GET,
      this.apiRoutes.GET_COUNTRIES
    );

    return response?.data;
  }

  async getCounties(id: number) {
    const response = await this.makeRequest(
      RootRequest.METHOD.GET,
      this.apiRoutes.GET_COUNTIES.replace(
        this.dynamicIds.COUNTRY_ID,
        id?.toString()
      )
    );

    return response?.data;
  }

  async getCities(id: number) {
    const response = await this.makeRequest(
      RootRequest.METHOD.GET,
      this.apiRoutes.GET_CITIES.replace(
        this.dynamicIds.COUNTY_ID,
        id?.toString()
      )
    );

    return response?.data;
  }

  async createCountry(attributes: any) {
    const response = await this.makeRequest(
      RootRequest.METHOD.POST,
      this.apiRoutes.CREATE_COUNTRY,
      attributes
    );

    return response?.data;
  }

  async createCounty(attributes: any) {
    const response = await this.makeRequest(
      RootRequest.METHOD.POST,
      this.apiRoutes.CREATE_COUNTY,
      attributes
    );

    return response?.data;
  }

  async createCity(attributes: any) {
    const response = await this.makeRequest(
      RootRequest.METHOD.POST,
      this.apiRoutes.CREATE_CITY,
      attributes
    );

    return response?.data;
  }

  async updateCountry(id: number, attributes: any) {
    const response = await this.makeRequest(
      RootRequest.METHOD.PATCH,
      this.apiRoutes.UPDATE_COUNTRY.replace(
        this.dynamicIds.COUNTRY_ID,
        id?.toString()
      ),
      attributes
    );

    return response?.data;
  }

  async updateCounty(id: number, attributes: any) {
    const response = await this.makeRequest(
      RootRequest.METHOD.PATCH,
      this.apiRoutes.UPDATE_COUNTY.replace(
        this.dynamicIds.COUNTY_ID,
        id?.toString()
      ),
      attributes
    );

    return response?.data;
  }

  async updateCity(id: number, attributes: any) {
    const response = await this.makeRequest(
      RootRequest.METHOD.PATCH,
      this.apiRoutes.UPDATE_CITY.replace(
        this.dynamicIds.CITY_ID,
        id?.toString()
      ),
      attributes
    );

    return response?.data;
  }

  async deleteCountry(id: number) {
    const response = await this.makeRequest(
      RootRequest.METHOD.DELETE,
      this.apiRoutes.DELETE_COUNTRY.replace(
        this.dynamicIds.COUNTRY_ID,
        id?.toString()
      )
    );

    return response?.data;
  }

  async deleteCounty(id: number) {
    const response = await this.makeRequest(
      RootRequest.METHOD.DELETE,
      this.apiRoutes.DELETE_COUNTY.replace(
        this.dynamicIds.COUNTY_ID,
        id?.toString()
      )
    );

    return response?.data;
  }

  async deleteCity(id: number) {
    const response = await this.makeRequest(
      RootRequest.METHOD.DELETE,
      this.apiRoutes.DELETE_CITY.replace(
        this.dynamicIds.CITY_ID,
        id?.toString()
      )
    );

    return response?.data;
  }

  async searchCity(countryId: number, searchText: string) {
    const response = await this.makeRequest(
      RootRequest.METHOD.GET,
      `${this.apiRoutes.SEARCH_CITIES.replace(
        this.dynamicIds.COUNTRY_ID,
        countryId?.toString()
      )}${searchText}`
    );

    return response?.data;
  }
}

export const countryRequest = new CountryRequest();
