import { useEffect, useRef } from "react";

export const useSkipFirstRun = (callback: AnyFunction, dependencies: any[]) => {
  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
    } else {
      return callback();
    }
  }, dependencies);
};
