import {
  DataGrid,
  GridColDef,
  GridFilterModel,
  GridRowClassNameParams,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import {
  Badge,
  BadgeProps,
  Box,
  Button,
  Chip,
  darken,
  Grid,
  lighten,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import BlockIcon from "@mui/icons-material/Block";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EmailIcon from "@mui/icons-material/Email";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { dataGridRoTextConstants } from "../../constants/dataGridRoTextConstants";
import AddTripDialog from "../dialogs/trip/AddTripDialog";
import EditTripDialog from "../dialogs/trip/EditTripDialog";
import DeleteRowDialog from "../dialogs/DeleteRowDialog";
import {
  getCargoType,
  getCarrier,
  getCarrierCar,
  getCarrierDriver,
  getCarrierInvoice,
  getCarrierPaymentTermInDays,
  getComments,
  getDate,
  getDispatcher,
  getLoadingAddress,
  getLoadingCity,
  getLoadingDate,
  getObservations,
  getShipper,
  getShipperBranch,
  getShipperInvoice,
  getShipperPaymentTermInDays,
  getShipperReferenceId,
  getStatus,
  getUnloadingAddress,
  getUnloadingCity,
  getUnloadingDate,
} from "../../functions/valueGetterFunctions/tripValueGetterFunctions";
import { TitleContext } from "../../contexts/TitleProviderContext";
import { useNavigate } from "react-router-dom";
import OrderPDFDialog from "../dialogs/PDF/OrderPDFDialog";
import { tripRequest } from "../../api/TripRequest";
import FiltersDialog from "../dialogs/FiltersDialog";
import { styled } from "@mui/material/styles";
import PATH from "../../paths";
import {
  isDispatcherSelector,
  userSelector,
} from "../../redux/slice/selectors";
import { useSelector } from "react-redux";
import UnblockTripDialog from "../dialogs/UnblockTripDialog";
import TripDocumentsDialog from "../dialogs/TripDocumentsDialog";
import { InsertDriveFile } from "@mui/icons-material";
import { AxiosError } from "axios";
import BlockTripDialog from "../dialogs/BlockTripDialog";

export enum TripStatus {
  Inserted = "Inserted",
  Completed = "Completed",
  Confirmed = "Confirmed",
  Billed = "Billed",
  Paid = "Paid",
  Blocked = "Blocked",
}

enum FilterTripStatus {
  Inserted = "Inserted",
  Completed = "Completed",
  Confirmed = "Confirmed",
  Billed = "Billed",
  Paid = "Paid",
  Blocked = "Blocked",
  Deleted = "Deleted",
  HasReceptionProblem = "HasReceptionProblem",
}

const getBackgroundColor = (color: string, mode: string) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    left: -10,
    top: 1,
  },
}));

export default function TripsDataGrid() {
  const navigate = useNavigate();
  const { setTitle } = useContext(TitleContext);
  const user = useSelector(userSelector);
  const isDispatcher = useSelector(isDispatcherSelector);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = React.useState<number>(25);
  const [searchText, setSearchText] = React.useState("");
  const [status, setStatus] = React.useState<FilterTripStatus | undefined>(
    undefined
  );
  const [startDate, setStartDate] = React.useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = React.useState<Date | undefined>(undefined);
  const [shipperId, setShipperId] = React.useState<number | undefined>(
    undefined
  );
  const [carrierId, setCarrierId] = React.useState<number | undefined>(
    undefined
  );
  const [dispatcherId, setDispatcherId] = React.useState<number | undefined>(
    undefined
  );

  const [pageIsLoading, setPageIsLoading] = useState(false);
  const [rows, setRows] = useState<Trip[]>([]);
  const [rowCount, setRowCount] = useState(0);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Număr",
      minWidth: 100,
      flex: 1,
      renderCell: ({ row: trip }) => {
        return (
          <Tooltip title={trip.id} enterDelay={1000} enterNextDelay={1000}>
            <span>{trip.id}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "date",
      headerName: "Dată",
      minWidth: 90,
      flex: 1,
      renderCell: (params) => {
        const value = getDate(params);
        return (
          <Tooltip title={value} enterDelay={1000} enterNextDelay={1000}>
            <span>{value}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "loadingDate",
      headerName: "Dată încărcare",
      minWidth: 160,
      flex: 1,
      renderCell: (params) => {
        const value = getLoadingDate(params);
        return (
          <Tooltip title={value} enterDelay={1000} enterNextDelay={1000}>
            <span>{value}</span>
          </Tooltip>
        );
      },
      hide: true,
    },
    {
      field: "loadingCity",
      headerName: "Plecare",
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        const value = getLoadingCity(params);
        return (
          <Tooltip title={value} enterDelay={1000} enterNextDelay={1000}>
            <span>{value}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "loadingAddress",
      headerName: "Adresă încărcare",
      minWidth: 160,
      flex: 1,
      renderCell: (params) => {
        const value = getLoadingAddress(params);
        return (
          <Tooltip title={value} enterDelay={1000} enterNextDelay={1000}>
            <span>{value}</span>
          </Tooltip>
        );
      },
      hide: true,
    },
    {
      field: "unloadingDate",
      headerName: "Dată descărcare",
      minWidth: 160,
      flex: 1,
      renderCell: (params) => {
        const value = getUnloadingDate(params);
        return (
          <Tooltip title={value} enterDelay={1000} enterNextDelay={1000}>
            <span>{value}</span>
          </Tooltip>
        );
      },
      hide: true,
    },
    {
      field: "unloadingCity",
      headerName: "Sosire",
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        const value = getUnloadingCity(params);
        return (
          <Tooltip title={value} enterDelay={1000} enterNextDelay={1000}>
            <span>{value}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "unloadingAddress",
      headerName: "Adresă descărcare",
      minWidth: 160,
      flex: 1,
      renderCell: (params) => {
        const value = getUnloadingAddress(params);
        return (
          <Tooltip title={value} enterDelay={1000} enterNextDelay={1000}>
            <span>{value}</span>
          </Tooltip>
        );
      },
      hide: true,
    },
    {
      field: "shipper",
      headerName: "Beneficiar",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        const value = getShipper(params);
        return (
          <Tooltip title={value} enterDelay={1000} enterNextDelay={1000}>
            <span>{value}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "carrier",
      headerName: "Transportator",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        const value = getCarrier(params);
        return (
          <Tooltip title={value} enterDelay={1000} enterNextDelay={1000}>
            <span>{value}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "shipperBranch",
      headerName: "Punct de lucru beneficiar",
      minWidth: 160,
      flex: 1,
      renderCell: (params) => {
        const value = getShipperBranch(params);
        return (
          <Tooltip title={value} enterDelay={1000} enterNextDelay={1000}>
            <span>{value}</span>
          </Tooltip>
        );
      },
      hide: true,
    },
    {
      field: "shipperPaymentTermInDays",
      headerName: "Termen beneficiar",
      minWidth: 160,
      flex: 0.7,
      renderCell: (params) => {
        const value = getShipperPaymentTermInDays(params);
        return (
          <Tooltip title={value} enterDelay={1000} enterNextDelay={1000}>
            <span>{value}</span>
          </Tooltip>
        );
      },
      hide: true,
    },
    {
      field: "carrierCar",
      headerName: "Mașină",
      minWidth: 90,
      flex: 1,
      renderCell: (params) => {
        const value = getCarrierCar(params);
        return (
          <Tooltip title={value} enterDelay={1000} enterNextDelay={1000}>
            <span>{value}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "carrierDriver",
      headerName: "Șofer",
      minWidth: 125,
      flex: 1,
      renderCell: (params) => {
        const value = getCarrierDriver(params);
        return (
          <Tooltip title={value} enterDelay={1000} enterNextDelay={1000}>
            <span>{value}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "shipperInvoice",
      headerName: "Fact. ben.",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        return (
          <Tooltip
            title={getShipperInvoice(params, false)}
            enterDelay={1000}
            enterNextDelay={1000}
          >
            <span>{getShipperInvoice(params)}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "carrierInvoice",
      headerName: "Fact. trans.",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        const value = getCarrierInvoice(params);
        return (
          <Tooltip title={value} enterDelay={1000} enterNextDelay={1000}>
            <span>{value}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "carrierPaymentTermInDays",
      headerName: "T.t.",
      minWidth: 60,
      flex: 1,
      renderCell: (params) => {
        const value = getCarrierPaymentTermInDays(params);
        return (
          <Tooltip title={value} enterDelay={1000} enterNextDelay={1000}>
            <span>{value}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "computedStatus",
      headerName: "Status",
      minWidth: 90,
      flex: 1,
      renderCell: (params) => {
        const value = getStatus(params);
        return (
          <Tooltip title={value} enterDelay={1000} enterNextDelay={1000}>
            <span>{value}</span>
          </Tooltip>
        );
      },
      hide: true,
    },
    {
      field: "cargoType",
      headerName: "Tip marfă",
      minWidth: 90,
      flex: 1,
      renderCell: (params) => {
        const value = getCargoType(params);
        return (
          <Tooltip title={value} enterDelay={1000} enterNextDelay={1000}>
            <span>{value}</span>
          </Tooltip>
        );
      },
      hide: true,
    },
    {
      field: "dispatcher",
      headerName: "Dispecer",
      minWidth: 80,
      flex: 1,
      renderCell: (params) => {
        const value = getDispatcher(params);
        return (
          <Tooltip title={value} enterDelay={1000} enterNextDelay={1000}>
            <span>{value}</span>
          </Tooltip>
        );
      },
      hide: true,
    },
    {
      field: "shipperReferenceId",
      headerName: "Ref.",
      minWidth: 75,
      flex: 0.7,
      renderCell: (params) => {
        const value = getShipperReferenceId(params);
        return (
          <Tooltip title={value} enterDelay={1000} enterNextDelay={1000}>
            <span>{value}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "observations",
      headerName: "Observații",
      minWidth: 90,
      flex: 1,
      renderCell: (params) => {
        const value = getObservations(params);
        return (
          <Tooltip title={value} enterDelay={1000} enterNextDelay={1000}>
            <span>{value}</span>
          </Tooltip>
        );
      },
      hide: true,
    },
    {
      field: "comments",
      headerName: "Comentarii",
      minWidth: 80,
      flex: 1,
      renderCell: (params) => {
        const value = getComments(params);
        return (
          <Tooltip title={value} enterDelay={1000} enterNextDelay={1000}>
            <span>{value}</span>
          </Tooltip>
        );
      },
    },
  ];

  status !== FilterTripStatus.Deleted &&
    columns.push({
      field: "actions",
      headerName: "Acțiuni",
      flex: 1,
      minWidth: 294,
      maxWidth: 294,
      filterable: false,
      renderCell: ({ row: trip }) => {
        const isTripDispatcher = trip.dispatcher.id === user.id;
        const isInserted = trip.computedStatus === "Inserted";
        const isBlocked = trip.computedStatus === "Blocked";
        const deleteCondition = isDispatcher
          ? isTripDispatcher
            ? isInserted
            : false
          : !isDispatcher;
        return [
          <Tooltip
            title="Copiază link public încărcare documente"
            placement="bottom"
          >
            <span>
              <IconButton
                onClick={async () => {
                  const baseUrl = `${tripRequest.apiUrl}/incarcare-documente-publice-comanda/`;
                  const link = await tripRequest.generatePublicUploadLink(
                    trip.id
                  );
                  const linkString = baseUrl + link.id;

                  const textarea = document.createElement("textarea");
                  textarea.value = linkString;
                  document.body.appendChild(textarea);
                  textarea.select();
                  document.execCommand("copy");
                  document.body.removeChild(textarea);
                }}
                color="primary"
              >
                <ContentCopyIcon fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>,
          <Tooltip title="Trimite email transporator" placement="bottom">
            <span>
              <IconButton
                onClick={async () => {
                  const baseUrl = `${tripRequest.apiUrl}/incarcare-documente-publice-comanda/`;
                  const link = await tripRequest.generatePublicUploadLink(
                    trip.id
                  );
                  const linkString = baseUrl + link.id;

                  const a = document.createElement("a");
                  a.href = `mailto:${trip.carrier.email}?subject=Comanda%20${trip.id}%20${trip.loadingCity.name}%20(${trip.loadingCity.county.name})%20-%20${trip.unloadingCity.name}%20(${trip.unloadingCity.county.name})&body=Buna%20ziua%2C%0D%0A%0D%0AIn%20urma%20discutiei%20telefonice%2C%20va%20transmitem%20atasat%20comanda%20de%20transport%20agreata%20de%20comun%20acord.%0D%0A%0D%0AVa%20rugam%20sa%20ne%20confirmati%20comanda%20de%20transport%20si%20sa%20ne%20trimiteti%20o%20copie%20dupa%20asigurarea%20CMR%20valabila%20prin%20reply%20la%20acest%20mail.%0D%0A%0D%0ARugam%20trimiteti%20documentele%20confirmate%20la%20adresa%20urmatoare%20%3A%20EFE%20IMPEX%2072%20SRL%2C%20str%20Chiciurei%20nr%2019-21%2C%20sector%203%2C%20Bucuresti%2C%20in%20atentia%20Silviu%20Ananutei%200732%20320%20322.%0D%0A%0D%0ADocumentele%20pot%20fi%20incarcate%20la%20urmatorul%20link%3A%20${linkString}%20insa%20acestea%20trebuie%20trimise%20in%20original%20in%20termen%20de%20maxim%2010%20zile%20de%20la%20data%20descarcarii%20marfii%2C%20astfel%20termenul%20de%20plata%20se%20va%20prelungi%20la%2060%20de%20zile%20de%20la%20data%20sosirii%20documentelor.%0D%0A%0D%0AAvem%20rugamintea%20sa%20nu%20incarcati%20factura%20si%20comanda%20de%20transport!%0D%0A%0D%0APentru%20intrebari%20legate%20de%20plata%20sau%20scadenta%20facturilor%20de%20transport%2C%20va%20rugam%20sa%20folositi%20adresa%20de%20e-mail%20virginia%40efe.ro%20sau%20sa%20sunati%20la%200732%20320%20325%20Virginia%20Moroianu.%0D%0A%0D%0ACONFIRMAREA%20COMENZII%20DE%20TRANSPORT%20SI%20TRIMITEREA%20ASIGURARII%20CMR%20ESTE%20OBLIGATORIE!!!%0D%0A%0D%0AVa%20multumim%20pentru%20colaborare!%0D%0A%0D%0AECHIPA%20EFE%20IMPEX%2072`;
                  a.style.display = "none";
                  document.body.appendChild(a);
                  a.click();
                  document.body.removeChild(a);
                }}
                color="secondary"
              >
                <EmailIcon fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>,
          <Tooltip title="Documente" placement="bottom">
            <span>
              <IconButton
                onClick={() => {
                  setSelectedTripForDocumentsDialog(trip);
                }}
                color="warning"
              >
                <InsertDriveFile fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>,
          <Tooltip title="Deschide PDF" placement="bottom">
            <span>
              <IconButton
                onClick={() => {
                  handleOpenOrderPDFDialog(trip);
                }}
                color="secondary"
                disabled={
                  (isInserted || (trip.status === "Inserted" && isBlocked)) &&
                  true
                }
              >
                <PictureAsPdfIcon fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>,
          <Tooltip title="Vizualizează" placement="bottom">
            <IconButton
              onClick={() => {
                navigate(PATH.TRIP.VIEW.replace(":id", trip.id.toString()));
                setTitle("Detalii comandă");
              }}
              color="primary"
            >
              <VisibilityIcon fontSize="small" />
            </IconButton>
          </Tooltip>,
          <Tooltip title="Editează" placement="bottom">
            <span>
              <IconButton
                onClick={() => {
                  if (trip.computedStatus === "Blocked" && !isDispatcher) {
                    handleOpenUnblockForEditTripDialog(trip);
                  } else {
                    handleOpenEditTripDialog(trip);
                  }
                }}
                disabled={
                  isDispatcher &&
                  !["Inserted", "Completed", "Billed"].includes(
                    trip.computedStatus
                  )
                    ? true
                    : isBlocked && isDispatcher
                }
                color="warning"
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>,
          <>
            {trip.computedStatus === "Blocked" ? (
              <Tooltip title="Deblochează" placement="bottom">
                <span>
                  <IconButton
                    onClick={() => {
                      handleOpenUnblockTripDialog(trip);
                    }}
                    color="success"
                    disabled={isDispatcher && !isInserted}
                  >
                    <CheckCircleIcon fontSize="small" />
                  </IconButton>
                </span>
              </Tooltip>
            ) : (
              <Tooltip title="Blochează" placement="bottom">
                <span>
                  <IconButton
                    onClick={() => {
                      handleOpenBlockTripDialog(trip);
                    }}
                    color="error"
                    disabled={isDispatcher && !isInserted}
                  >
                    <BlockIcon fontSize="small" />
                  </IconButton>
                </span>
              </Tooltip>
            )}
          </>,
          <Tooltip title="Șterge" placement="bottom">
            <span>
              <IconButton
                onClick={() => handleOpenDeleteRowDialog(trip.id)}
                color="error"
                disabled={!deleteCondition}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>,
        ];
      },
    });

  status == FilterTripStatus.Deleted &&
    columns.push({
      field: "actions",
      headerName: "Acțiuni",
      flex: 1,
      minWidth: 90,
      maxWidth: 90,
      filterable: false,
      renderCell: ({ row: trip }) => {
        const isInserted = trip.computedStatus === "Inserted";
        const isBlocked = trip.computedStatus === "Blocked";
        return [
          <Tooltip title="Deschide PDF" placement="bottom">
            <span>
              <IconButton
                onClick={() => {
                  handleOpenOrderPDFDialog(trip);
                }}
                color="secondary"
                disabled={
                  (isInserted || (trip.status === "Inserted" && isBlocked)) &&
                  true
                }
              >
                <PictureAsPdfIcon fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>,
          <Tooltip title="Vizualizează" placement="bottom">
            <IconButton
              onClick={() => {
                navigate(PATH.TRIP.VIEW.replace(":id", trip.id.toString()));
                setTitle("Detalii comandă");
              }}
              color="primary"
            >
              <VisibilityIcon fontSize="small" />
            </IconButton>
          </Tooltip>,
        ];
      },
    });

  const fetchTrips = () => {
    setPageIsLoading(true);
    tripRequest
      .search(
        pageSize,
        page * pageSize,
        searchText,
        status,
        startDate,
        endDate,
        carrierId,
        shipperId,
        dispatcherId
      )
      .then((data) => {
        setRows(
          data.results.map((trip: Trip) => ({
            id: trip.id,
            date: trip.date,
            cargoType: trip.cargoType,
            dispatcher: trip.dispatcher,
            loadingDate: trip.loadingDate,
            loadingAddress: trip.loadingAddress,
            loadingCity: trip.loadingCity,
            intermediaryCities: trip.intermediaryCities,
            unloadingDate: trip.unloadingDate,
            unloadingAddress: trip.unloadingAddress,
            unloadingCity: trip.unloadingCity,
            carrier: trip.carrier,
            carrierCar: trip.carrierCar,
            carrierDriver: trip.carrierDriver,
            carrierInvoice: trip.carrierInvoice,
            carrierInvoiceRows: trip.carrierInvoiceRows,
            carrierPaymentTermInDays: trip.carrierPaymentTermInDays,
            shipper: trip.shipper,
            shipperBranch: trip.shipperBranch,
            shipperReferenceId: trip.shipperReferenceId,
            shipperInvoice: trip.shipperInvoice,
            shipperInvoiceRows: trip.shipperInvoiceRows,
            shipperPaymentTermInDays: trip.shipperPaymentTermInDays,
            observations: trip.observations,
            comments: trip.comments,
            computedStatus: trip.computedStatus,
            status: trip.status,
            publicUploadLink: trip.publicUploadLink,
            hasPublicUploadedFiles: trip.hasPublicUploadedFiles,
            hasReceptionProblem: trip.hasReceptionProblem,
          }))
        );
        setRowCount(data.total);
      })
      .finally(() => {
        setPageIsLoading(false);
      });
  };

  useEffect(() => {
    fetchTrips();
  }, [
    pageSize,
    page,
    searchText,
    status,
    startDate,
    endDate,
    shipperId,
    carrierId,
    dispatcherId,
  ]);

  const [selectedTripForEdit, setSelectedTripForEdit] = useState<
    Trip | undefined
  >(undefined);

  const [selectedTripForUnblock, setSelectedTripForUnblock] = useState<
    Trip | undefined
  >(undefined);

  const [selectedTripForBlock, setSelectedTripForBlock] = useState<
    Trip | undefined
  >(undefined);

  const [selectedTripForOrderPdfView, setSelectedTripForOrderPdfView] =
    useState<Trip | undefined>(undefined);

  const handleOpenOrderPDF = (id: number) => {
    return tripRequest
      .find(id)
      .then(handleOpenOrderPDFDialog)
      .catch(() => {
        alert("S-a produs o eroare și nu este posibilă deschiderea PDF-ului!");
      });
  };

  const [openOrderPDFDialog, setOpenOrderPDFDialog] = React.useState(false);
  const handleOpenOrderPDFDialog = (trip: Trip) => {
    setOpenOrderPDFDialog(true);
    setSelectedTripForOrderPdfView(trip);
  };

  const [openAddTripDialog, setOpenAddTripDialog] = React.useState(false);
  const handleOpenAddTripDialog = () => {
    setOpenAddTripDialog(true);
  };

  const [openEditTripDialog, setOpenEditTripDialog] = React.useState(false);
  const handleOpenEditTripDialog = (trip: Trip) => {
    setOpenEditTripDialog(true);
    setSelectedTripForEdit(trip);
  };

  const [toBeDeleted, setToBeDeleted] = React.useState<number | null>(null);
  const handleOpenDeleteRowDialog = (id: any) => {
    setToBeDeleted(id);
  };
  const handleCloseDeleteRowDialog = () => {
    setToBeDeleted(null);
  };
  const openDeleteRowDialog = Boolean(toBeDeleted);

  const [filterButtonEl, setFilterButtonEl] = React.useState(null);

  const [openFiltersDialog, setOpenFiltersDialog] = React.useState(false);
  const handleOpenFiltersDialog = () => {
    setOpenFiltersDialog(true);
  };

  const [openUnblockForEditTripDialog, setOpenUnblockForEditTripDialog] =
    React.useState(false);
  const handleOpenUnblockForEditTripDialog = (trip: Trip) => {
    setOpenUnblockForEditTripDialog(true);
    setSelectedTripForEdit(trip);
  };

  const [openUnblockTripDialog, setOpenUnblockTripDialog] =
    React.useState(false);
  const handleOpenUnblockTripDialog = (trip: Trip) => {
    setOpenUnblockTripDialog(true);
    setSelectedTripForUnblock(trip);
  };

  const [openBlockTripDialog, setOpenBlockTripDialog] = React.useState(false);
  const handleOpenBlockTripDialog = (trip: Trip) => {
    setOpenBlockTripDialog(true);
    setSelectedTripForBlock(trip);
  };

  const [activeFilters, setActiveFilters] = React.useState<number>(0);
  useEffect(() => {
    setActiveFilters(
      Number(Boolean(startDate)) +
        Number(Boolean(endDate)) +
        Number(Boolean(dispatcherId)) +
        Number(Boolean(shipperId)) +
        Number(Boolean(carrierId))
    );
  }, [startDate, endDate, dispatcherId, shipperId, carrierId]);

  const [selectedTripForDocumentsDialog, setSelectedTripForDocumentsDialog] =
    React.useState<Trip | undefined>(undefined);

  const CustomToolbar = React.useCallback(() => {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <Grid
            item
            sx={{
              ml: 1,
            }}
          >
            <GridToolbarQuickFilter />
          </Grid>
          <Grid
            item
            sx={{
              mr: 0.3,
            }}
          >
            <Grid container direction="row" spacing={1}>
              <Grid item>
                {/*<Button*/}
                {/*  size="small"*/}
                {/*  onClick={handleOpenAddTripDialog}*/}
                {/*  variant="outlined"*/}
                {/*>*/}
                {/*  <Stack direction="row">*/}
                {/*    <AddIcon*/}
                {/*      fontSize="small"*/}
                {/*      sx={{*/}
                {/*        mr: 1,*/}
                {/*      }}*/}
                {/*    />*/}
                {/*    Adaugă comandă*/}
                {/*  </Stack>*/}
                {/*</Button>*/}
              </Grid>
              <Grid item>
                <Button
                  size="small"
                  onClick={() => {
                    handleOpenFiltersDialog();
                  }}
                >
                  <Stack direction="row">
                    <FilterListIcon fontSize="small" sx={{ mr: 1 }} />
                    <StyledBadge
                      // variant="dot"
                      badgeContent={activeFilters}
                      color="primary"
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      FILTRE
                    </StyledBadge>
                  </Stack>
                </Button>
              </Grid>
              {/*<Grid item>*/}
              {/*@ts-ignore*/}
              {/*<GridToolbarFilterButton ref={setFilterButtonEl} />*/}
              {/*</Grid>*/}
              <Grid item>
                {/* @ts-ignore */}
                <GridToolbarColumnsButton ref={setFilterButtonEl} />
              </Grid>
              <Grid item>
                {/* @ts-ignore */}
                <GridToolbarDensitySelector ref={setFilterButtonEl} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </GridToolbarContainer>
    );
  }, [activeFilters]);

  return (
    <Box
      sx={{
        height: 400,
        width: "100%",
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <Grid item>
          <Button
            size="small"
            onClick={handleOpenAddTripDialog}
            variant="contained"
          >
            <Stack
              direction="row"
              sx={{
                mt: 0.4,
              }}
            >
              <AddIcon
                fontSize="small"
                sx={{
                  mr: 0.5,
                }}
              />
              Adaugă comandă
            </Stack>
          </Button>
        </Grid>
        <Grid item>
          <Grid container direction="row" spacing={1} alignItems="center">
            <Grid item>
              <Typography variant="body2">Status comenzi:</Typography>
            </Grid>
            <Grid item>
              <Badge
                variant="dot"
                color="primary"
                invisible={!Boolean(status === FilterTripStatus.Inserted)}
              >
                <Chip
                  label="Introduse"
                  variant="outlined"
                  size="small"
                  color="default"
                  onClick={() => {
                    setStatus(FilterTripStatus.Inserted);
                  }}
                />
              </Badge>
            </Grid>
            <Grid item>
              <Badge
                variant="dot"
                color="primary"
                invisible={!Boolean(status === FilterTripStatus.Completed)}
              >
                <Chip
                  label="Finalizate"
                  variant="outlined"
                  size="small"
                  sx={{
                    backgroundColor: (theme) =>
                      getBackgroundColor("#64748B", theme.palette.mode),
                    "&&:hover": {
                      backgroundColor: (theme) =>
                        getHoverBackgroundColor("#64748B", theme.palette.mode),
                    },
                  }}
                  onClick={() => {
                    setStatus(FilterTripStatus.Completed);
                  }}
                />
              </Badge>
            </Grid>
            <Grid item>
              <Badge
                variant="dot"
                color="primary"
                invisible={!Boolean(status === FilterTripStatus.Confirmed)}
              >
                <Chip
                  label="Operate"
                  variant="outlined"
                  size="small"
                  sx={{
                    backgroundColor: (theme) =>
                      getBackgroundColor(
                        theme.palette.warning.dark,
                        theme.palette.mode
                      ),
                    "&&:hover": {
                      backgroundColor: (theme) =>
                        getHoverBackgroundColor(
                          theme.palette.warning.dark,
                          theme.palette.mode
                        ),
                    },
                  }}
                  onClick={() => {
                    setStatus(FilterTripStatus.Confirmed);
                  }}
                />
              </Badge>
            </Grid>
            <Grid item>
              <Badge
                variant="dot"
                color="primary"
                invisible={!Boolean(status === FilterTripStatus.Billed)}
              >
                <Chip
                  label="Facturate"
                  variant="outlined"
                  size="small"
                  sx={{
                    backgroundColor: (theme) =>
                      getBackgroundColor(
                        theme.palette.success.dark,
                        theme.palette.mode
                      ),
                    "&&:hover": {
                      backgroundColor: (theme) =>
                        getHoverBackgroundColor(
                          theme.palette.success.dark,
                          theme.palette.mode
                        ),
                    },
                  }}
                  onClick={() => {
                    setStatus(FilterTripStatus.Billed);
                  }}
                />
              </Badge>
            </Grid>
            <Grid item>
              <Badge
                variant="dot"
                color="primary"
                invisible={!Boolean(status === FilterTripStatus.Paid)}
              >
                <Chip
                  label="Plătite"
                  variant="outlined"
                  size="small"
                  sx={{
                    backgroundColor: (theme) =>
                      getBackgroundColor(
                        theme.palette.info.dark,
                        theme.palette.mode
                      ),
                    "&&:hover": {
                      backgroundColor: (theme) =>
                        getHoverBackgroundColor(
                          theme.palette.info.dark,
                          theme.palette.mode
                        ),
                    },
                  }}
                  onClick={() => {
                    setStatus(FilterTripStatus.Paid);
                  }}
                />
              </Badge>
            </Grid>
            <Grid item>
              <Badge
                variant="dot"
                color="primary"
                invisible={!Boolean(status === FilterTripStatus.Blocked)}
              >
                <Chip
                  label="Blocate"
                  variant="outlined"
                  size="small"
                  sx={{
                    backgroundColor: (theme) =>
                      getBackgroundColor(
                        theme.palette.error.dark,
                        theme.palette.mode
                      ),
                    "&&:hover": {
                      backgroundColor: (theme) =>
                        getHoverBackgroundColor(
                          theme.palette.error.dark,
                          theme.palette.mode
                        ),
                    },
                  }}
                  onClick={() => {
                    setStatus(FilterTripStatus.Blocked);
                  }}
                />
              </Badge>
            </Grid>
            <Grid item>
              <Badge
                variant="dot"
                color="primary"
                invisible={
                  !Boolean(status === FilterTripStatus.HasReceptionProblem)
                }
              >
                <Chip
                  label="Probleme recepție"
                  variant="outlined"
                  size="small"
                  sx={{
                    backgroundColor: (theme) =>
                      getBackgroundColor("#ff0000", theme.palette.mode),
                    "&&:hover": {
                      backgroundColor: (theme) =>
                        getHoverBackgroundColor("#ff0000", theme.palette.mode),
                    },
                  }}
                  onClick={() => {
                    setStatus(FilterTripStatus.HasReceptionProblem);
                  }}
                />
              </Badge>
            </Grid>
            <Grid item>
              <Badge
                variant="dot"
                color="primary"
                invisible={!Boolean(status === FilterTripStatus.Deleted)}
              >
                <Chip
                  label="Șterse"
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    setStatus(FilterTripStatus.Deleted);
                  }}
                />
              </Badge>
            </Grid>
            <Grid item>
              <Badge variant="dot" color="primary" invisible={Boolean(status)}>
                <Chip
                  label="Toate (neșterse)"
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    setStatus(undefined);
                  }}
                />
              </Badge>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ paddingBottom: 3 }}>
          <div style={{ width: "100%" }}>
            <div style={{ display: "flex", height: "100%" }}>
              <div style={{ flexGrow: 1 }}>
                <DataGrid
                  density="compact"
                  filterMode="server"
                  paginationMode="server"
                  loading={pageIsLoading}
                  onFilterModelChange={(filterModel: GridFilterModel) => {
                    setSearchText(
                      filterModel.quickFilterValues?.join(" ") || ""
                    );
                  }}
                  onPageChange={(page: number) => {
                    setPage(page);
                  }}
                  rowCount={rowCount}
                  rows={rows}
                  getRowId={(row) => row.id}
                  columns={columns}
                  initialState={{
                    sorting: {
                      sortModel: [{ field: "id", sort: "desc" }],
                    },
                  }}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  autoHeight
                  pagination
                  disableSelectionOnClick
                  checkboxSelection={false}
                  experimentalFeatures={{ newEditingApi: true }}
                  localeText={dataGridRoTextConstants}
                  components={{ Toolbar: CustomToolbar }}
                  componentsProps={{
                    pagination: {
                      labelRowsPerPage: "Număr de înregistrări pe pagină",
                    },
                    panel: {
                      anchorEl: filterButtonEl,
                      placement: "bottom-end",
                    },
                  }}
                  getRowClassName={(params: GridRowClassNameParams) => {
                    return params.row.hasReceptionProblem
                      ? "has-reception-problem"
                      : params.row.computedStatus === TripStatus.Inserted
                      ? "inserted"
                      : params.row.computedStatus === TripStatus.Completed
                      ? "completed"
                      : params.row.computedStatus === TripStatus.Confirmed
                      ? "confirmed"
                      : params.row.computedStatus === TripStatus.Billed
                      ? "billed"
                      : params.row.computedStatus === TripStatus.Paid
                      ? "paid"
                      : params.row.computedStatus === TripStatus.Blocked
                      ? "blocked"
                      : "";
                  }}
                  sx={{
                    "& .MuiDataGrid-cellContent, & .MuiDataGrid-columnHeaderTitle":
                      {
                        fontSize: 12,
                      },
                    "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within":
                      {
                        outline: "none",
                      },

                    "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus":
                      {
                        outline: "none",
                      },
                    "& .completed": {
                      backgroundColor: (theme) =>
                        getBackgroundColor("#64748B", theme.palette.mode),
                      "&:hover": {
                        backgroundColor: (theme) =>
                          getHoverBackgroundColor(
                            "#64748B",
                            theme.palette.mode
                          ),
                      },
                    },
                    "& .confirmed": {
                      bgcolor: (theme) =>
                        getBackgroundColor(
                          theme.palette.warning.dark,
                          theme.palette.mode
                        ),
                      "&:hover": {
                        bgcolor: (theme) =>
                          getHoverBackgroundColor(
                            theme.palette.warning.dark,
                            theme.palette.mode
                          ),
                      },
                    },
                    "& .billed": {
                      bgcolor: (theme) =>
                        getBackgroundColor(
                          theme.palette.success.dark,
                          theme.palette.mode
                        ),
                      "&:hover": {
                        bgcolor: (theme) =>
                          getHoverBackgroundColor(
                            theme.palette.success.dark,
                            theme.palette.mode
                          ),
                      },
                    },
                    "& .paid": {
                      bgcolor: (theme) =>
                        getBackgroundColor(
                          theme.palette.info.dark,
                          theme.palette.mode
                        ),
                      "&:hover": {
                        bgcolor: (theme) =>
                          getHoverBackgroundColor(
                            theme.palette.info.dark,
                            theme.palette.mode
                          ),
                      },
                    },
                    "& .blocked": {
                      bgcolor: (theme) =>
                        getBackgroundColor(
                          theme.palette.error.dark,
                          theme.palette.mode
                        ),
                      "&:hover": {
                        bgcolor: (theme) =>
                          getHoverBackgroundColor(
                            theme.palette.error.dark,
                            theme.palette.mode
                          ),
                      },
                    },
                    "& .has-reception-problem": {
                      bgcolor: (theme) =>
                        getBackgroundColor("#ff0000", theme.palette.mode),
                      "&:hover": {
                        bgcolor: (theme) =>
                          getHoverBackgroundColor(
                            "#ff0000",
                            theme.palette.mode
                          ),
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      {openOrderPDFDialog && (
        <OrderPDFDialog
          open={openOrderPDFDialog}
          trip={selectedTripForOrderPdfView}
          onClose={() => setOpenOrderPDFDialog(false)}
        />
      )}
      {openAddTripDialog && (
        <AddTripDialog
          open={openAddTripDialog}
          onTripCreated={fetchTrips}
          onOrderInPDF={(id) => {
            handleOpenOrderPDF(id);
          }}
          onClose={() => setOpenAddTripDialog(false)}
        />
      )}
      {openUnblockForEditTripDialog && (
        <UnblockTripDialog
          open={openUnblockForEditTripDialog}
          trip={selectedTripForEdit}
          onTripUnblocked={() => {
            if (selectedTripForEdit) {
              handleOpenEditTripDialog(selectedTripForEdit);
              fetchTrips();
            }
          }}
          onClose={() => {
            if (openUnblockForEditTripDialog) {
              setOpenUnblockForEditTripDialog(false);
            }
          }}
        />
      )}
      {openUnblockTripDialog && (
        <UnblockTripDialog
          open={openUnblockTripDialog}
          trip={selectedTripForUnblock}
          onTripUnblocked={() => {
            if (selectedTripForUnblock) {
              fetchTrips();
            }
          }}
          onClose={() => {
            if (openUnblockTripDialog) {
              setOpenUnblockTripDialog(false);
            }
          }}
        />
      )}
      {openBlockTripDialog && (
        <BlockTripDialog
          open={openBlockTripDialog}
          trip={selectedTripForBlock}
          onTripBlocked={() => {
            if (selectedTripForBlock) {
              fetchTrips();
            }
          }}
          onClose={() => {
            setOpenBlockTripDialog(false);
          }}
        />
      )}
      {openEditTripDialog && (
        <EditTripDialog
          open={openEditTripDialog}
          trip={selectedTripForEdit}
          onTripEdited={fetchTrips}
          onOrderInPDF={(id) => {
            handleOpenOrderPDF(id);
          }}
          onClose={() => {
            setOpenEditTripDialog(false);
            setSelectedTripForEdit(undefined);
          }}
        />
      )}
      {openDeleteRowDialog && (
        <DeleteRowDialog
          open={openDeleteRowDialog}
          onClose={handleCloseDeleteRowDialog}
          onDelete={() => {
            if (toBeDeleted) {
              tripRequest
                .delete(toBeDeleted)
                .then(fetchTrips)
                .catch((e: unknown) => {
                  console.error(e);
                  let message = "S-a produs o eroare!";
                  if (
                    e instanceof AxiosError &&
                    e.response?.data?.code === 971
                  ) {
                    message =
                      "Nu se pot șterge curse facturate! Trebuie să o anulați înainte.";
                  }
                  alert(message);
                });
            }
          }}
        />
      )}
      {openFiltersDialog && (
        <FiltersDialog
          open={openFiltersDialog}
          onClose={() => {
            setOpenFiltersDialog(false);
          }}
          onFilter={(filterData) => {
            setStartDate(
              filterData.startDate ? filterData.startDate : undefined
            );
            setEndDate(filterData.endDate ? filterData.endDate : undefined);
            setShipperId(filterData.shipperId);
            setCarrierId(filterData.carrierId);
            setDispatcherId(filterData.dispatcherId);
          }}
        />
      )}
      <TripDocumentsDialog
        open={Boolean(selectedTripForDocumentsDialog)}
        onClose={() => setSelectedTripForDocumentsDialog(undefined)}
        trip={selectedTripForDocumentsDialog}
      />
    </Box>
  );
}
