import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Autocomplete,
  Box,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useEffect, useState } from "react";
import { shipperRequest } from "../../../api/ShipperRequest";
import { countryRequest } from "../../../api/CountryRequest";
import AddCountryDialog from "../country/AddCountryDialog";
import AddCountyDialog from "../country/AddCountyDialog";
import AddCityDialog from "../country/AddCityDialog";
import AddIcon from "@mui/icons-material/Add";
import { AxiosError } from "axios";

interface Props {
  open: boolean;
  onClose: AnyFunction;
  shipper?: Shipper;
  onShipperEdited: AnyFunction;
}

interface FormValues {
  name: string | null;
  tin: string | null;
  businessRegistrationNumber: string | null;
  bank: string | null;
  iban: string | null;
  address: string | null;
  phone: string | null;
  email: string | null;
  contactPerson: string | null;
  isActive: boolean | null;
  cityId: number | null;
  paymentTermInDays: number | null;
}

interface CountryValues {
  country: Country | null;
  county: County | null;
  city: City | null;
}

export default function EditShipperDialog(props: Props) {
  const { open, onClose, shipper, onShipperEdited } = props;

  const [formValues, setFormValues] = useState<FormValues>({
    name: "",
    tin: "",
    businessRegistrationNumber: "",
    bank: "",
    iban: "",
    address: "",
    phone: "",
    email: "",
    contactPerson: "",
    isActive: false,
    cityId: null,
    paymentTermInDays: null,
  });

  const [countryValues, setCountryValues] = useState<CountryValues>({
    country: null,
    county: null,
    city: null,
  });

  const [error, setError] = useState({
    name: false,
    nameIsDuplicate: false,
    tin: false,
    tinIsDuplicate: false,
    businessRegistrationNumber: false,
    businessRegistrationNumberIsDuplicate: false,
    address: false,
    phone: false,
    email: false,
    isNotEmail: false,
    contactPerson: false,
    countryId: false,
    countyId: false,
    cityId: false,
    paymentTermInDays: false,
    paymentTermInDaysMin0: false,
  });

  const isEmailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
  const isEmail = Boolean(formValues.email?.match(isEmailRegex));

  const [shipperData, setShipperData] = useState<Shipper[]>([]);

  const fetchShippers = () => {
    shipperRequest.findAll(-1, 0, false).then((data) => {
      setShipperData(
        data.results.map((shipper: Shipper) => ({
          id: shipper.id,
          name: shipper.name,
          tin: shipper.tin,
          businessRegistrationNumber: shipper.businessRegistrationNumber,
          bank: shipper.bank,
          iban: shipper.iban,
          city: shipper.city,
          address: shipper.address,
          phone: shipper.phone,
          email: shipper.email,
          contactPerson: shipper.contactPerson,
          isActive: shipper.isActive,
          paymentTermInDays: shipper.paymentTermInDays,
        }))
      );
    });
  };

  useEffect(() => {
    fetchShippers();
  }, []);

  const checkNameIsDuplicate = (shipper: Shipper) =>
    shipper.name?.toLowerCase() === formValues.name?.toLowerCase();

  const checkTinIsDuplicate = (shipper: Shipper) =>
    shipper.tin?.toLowerCase() === formValues.tin?.toLowerCase();

  const checkBusinessRegistrationNumberIsDuplicate = (shipper: Shipper) =>
    shipper.businessRegistrationNumber?.toLowerCase() ===
    formValues.businessRegistrationNumber?.toLowerCase();

  const handleErrors = () => {
    if (!formValues.name) {
      setError((prev: any) => ({
        ...prev,
        name: true,
      }));
    } else if (formValues.name) {
      setError((prev: any) => ({
        ...prev,
        name: false,
      }));
    }
    if (formValues.name === shipper?.name) {
      setError((prev: any) => ({
        ...prev,
        nameIsDuplicate: false,
      }));
    } else if (formValues.name !== shipper?.name) {
      setError((prev: any) => ({
        ...prev,
        nameIsDuplicate: shipperData.some(checkNameIsDuplicate),
      }));
    }
    if (!formValues.tin) {
      setError((prev: any) => ({
        ...prev,
        tin: true,
      }));
    } else if (formValues.tin) {
      setError((prev: any) => ({
        ...prev,
        tin: false,
      }));
    }
    if (formValues.tin === shipper?.tin) {
      setError((prev: any) => ({
        ...prev,
        tinIsDuplicate: false,
      }));
    } else if (formValues.tin !== shipper?.tin) {
      setError((prev: any) => ({
        ...prev,
        tinIsDuplicate: shipperData.some(checkTinIsDuplicate),
      }));
    }
    if (!formValues.businessRegistrationNumber) {
      setError((prev: any) => ({
        ...prev,
        businessRegistrationNumber: true,
      }));
    } else if (formValues.businessRegistrationNumber) {
      setError((prev: any) => ({
        ...prev,
        businessRegistrationNumber: false,
      }));
    }
    if (
      formValues.businessRegistrationNumber ===
      shipper?.businessRegistrationNumber
    ) {
      setError((prev: any) => ({
        ...prev,
        businessRegistrationNumberIsDuplicate: false,
      }));
    } else if (
      formValues.businessRegistrationNumber !==
      shipper?.businessRegistrationNumber
    ) {
      setError((prev: any) => ({
        ...prev,
        businessRegistrationNumberIsDuplicate:
          shipperData.some(checkNameIsDuplicate),
      }));
    }
    if (!formValues.address) {
      setError((prev: any) => ({
        ...prev,
        address: true,
      }));
    } else if (formValues.address) {
      setError((prev: any) => ({
        ...prev,
        address: false,
      }));
    }
    if (!formValues.phone) {
      setError((prev: any) => ({
        ...prev,
        phone: true,
      }));
    } else if (formValues.phone) {
      setError((prev: any) => ({
        ...prev,
        phone: false,
      }));
    }
    if (!formValues.email) {
      setError((prev: any) => ({
        ...prev,
        email: true,
      }));
    } else if (formValues.email) {
      setError((prev: any) => ({
        ...prev,
        email: false,
      }));
    }
    if (!isEmail) {
      setError((prev: any) => ({
        ...prev,
        isNotEmail: true,
      }));
    } else if (isEmail) {
      setError((prev: any) => ({
        ...prev,
        isNotEmail: false,
      }));
    }
    if (!formValues.contactPerson) {
      setError((prev: any) => ({
        ...prev,
        contactPerson: true,
      }));
    } else if (formValues.contactPerson) {
      setError((prev: any) => ({
        ...prev,
        contactPerson: false,
      }));
    }
    if (!countryValues.country) {
      setError((prev: any) => ({
        ...prev,
        countryId: true,
      }));
    } else if (countryValues.country) {
      setError((prev: any) => ({
        ...prev,
        countryId: false,
      }));
    }
    if (!countryValues.county) {
      setError((prev: any) => ({
        ...prev,
        countyId: true,
      }));
    } else if (countryValues.county) {
      setError((prev: any) => ({
        ...prev,
        countyId: false,
      }));
    }
    if (!formValues.cityId) {
      setError((prev: any) => ({
        ...prev,
        cityId: true,
      }));
    } else if (formValues.cityId) {
      setError((prev: any) => ({
        ...prev,
        cityId: false,
      }));
    }
    setError((prev: any) => ({
      ...prev,
      paymentTermInDays: typeof formValues.paymentTermInDays !== "number",
      paymentTermInDaysMin0:
        typeof formValues.paymentTermInDays === "number" &&
        formValues.paymentTermInDays < 0,
    }));
  };

  const handleSubmit = () => {
    if (shipper && shipper.id) {
      handleErrors();
      const nameCondition =
        formValues.name === shipper?.name ||
        !shipperData.some(checkNameIsDuplicate);
      const tinCondition =
        formValues.tin === shipper?.tin ||
        !shipperData.some(checkTinIsDuplicate);
      const businessRegistrationNumberCondition =
        formValues.businessRegistrationNumber ===
          shipper?.businessRegistrationNumber ||
        !shipperData.some(checkBusinessRegistrationNumberIsDuplicate);
      const formIsValid =
        formValues.name &&
        nameCondition &&
        formValues.tin &&
        tinCondition &&
        formValues.businessRegistrationNumber &&
        businessRegistrationNumberCondition &&
        formValues.address &&
        formValues.phone &&
        formValues.email &&
        isEmail &&
        formValues.contactPerson &&
        formValues.cityId &&
        typeof formValues.paymentTermInDays === "number" &&
        formValues.paymentTermInDays >= 0;
      if (formIsValid) {
        return shipperRequest
          .patch(shipper.id, formValues)
          .then(() => {
            setCountryValues((prev: any) => ({
              ...prev,
              country: null,
              county: null,
              city: null,
            }));
            if (typeof onShipperEdited === "function") {
              onShipperEdited();
            }
          })
          .catch((e: unknown) => {
            if (e instanceof AxiosError && e.response?.data?.code === 23505) {
              alert("Un alt beneficiar exista deja cu acest email!");
              throw e;
            }
            alert("S-a produs o eroare");
          });
      }
    }
  };

  const handleCloseEditShipperDialog = () => {
    onClose();
    setError(() => ({
      name: false,
      nameIsDuplicate: false,
      tin: false,
      tinIsDuplicate: false,
      businessRegistrationNumber: false,
      businessRegistrationNumberIsDuplicate: false,
      address: false,
      phone: false,
      email: false,
      isNotEmail: false,
      contactPerson: false,
      countryId: false,
      countyId: false,
      cityId: false,
      paymentTermInDays: false,
      paymentTermInDaysMin0: false,
    }));
  };

  const [countryOptions, setCountryOptions] = useState<Country[]>([]);
  const fetchCountries = () => {
    countryRequest
      .getCountries()
      .then((countries) => setCountryOptions(countries));
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  const sortedCountryOptions = countryOptions?.sort(function (a, b) {
    if (a.name! < b.name!) {
      return -1;
    }
    if (a.name! > b.name!) {
      return 1;
    }
    return 0;
  });

  const [countyOptions, setCountyOptions] = useState<County[]>([]);
  const [selectedCountryId, setSelectedCountryId] = useState<any>(null);
  const fetchCounties = () => {
    if (selectedCountryId) {
      countryRequest
        .getCounties(selectedCountryId)
        .then((counties) => setCountyOptions(counties));
    }
  };

  useEffect(() => {
    fetchCounties();
  }, [selectedCountryId]);

  const sortedCountyOptions = countyOptions?.sort(function (a, b) {
    if (a.name! < b.name!) {
      return -1;
    }
    if (a.name! > b.name!) {
      return 1;
    }
    return 0;
  });

  const [cityOptions, setCityOptions] = useState<City[]>([]);
  const [selectedCountyId, setSelectedCountyId] = useState<any>(null);
  const fetchCities = () => {
    if (selectedCountyId) {
      countryRequest
        .getCities(selectedCountyId)
        .then((cities) => setCityOptions(cities));
    }
  };

  useEffect(() => {
    fetchCities();
  }, [selectedCountyId]);

  const sortedCityOptions = cityOptions?.sort(function (a, b) {
    if (a.name! < b.name!) {
      return -1;
    }
    if (a.name! > b.name!) {
      return 1;
    }
    return 0;
  });

  useEffect(() => {
    if (shipper) {
      setFormValues({
        name: shipper.name,
        tin: shipper.tin,
        businessRegistrationNumber: shipper.businessRegistrationNumber,
        bank: shipper.bank,
        iban: shipper.iban,
        address: shipper.address,
        phone: shipper.phone,
        email: shipper.email,
        contactPerson: shipper.contactPerson,
        isActive: shipper.isActive,
        cityId: shipper.city?.id || null,
        paymentTermInDays: shipper.paymentTermInDays,
      });
      setCountryValues({
        country: shipper.city?.county?.country || null,
        county: shipper.city?.county || null,
        city: shipper.city || null,
      });
      setSelectedCountryId(shipper.city?.county?.country?.id);
      setSelectedCountyId(shipper.city?.county?.id);
    }
  }, [shipper]);

  const [openAddCountryDialog, setOpenAddCountryDialog] = React.useState(false);
  const handleOpenAddCountryDialog = () => {
    setOpenAddCountryDialog(true);
  };

  const [openAddCountyDialog, setOpenAddCountyDialog] = React.useState(false);
  const handleOpenAddCountyDialog = () => {
    setOpenAddCountyDialog(true);
  };

  const [openAddCityDialog, setOpenAddCityDialog] = React.useState(false);
  const handleOpenAddCityDialog = () => {
    setOpenAddCityDialog(true);
  };

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="md">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <DialogTitle sx={{ pb: 0 }}>Modificare beneficiar</DialogTitle>
          </Grid>
          <Grid item sx={{ mr: 2, pt: 2 }}>
            <IconButton onClick={handleCloseEditShipperDialog} tabIndex={-1}>
              <ClearIcon />
            </IconButton>
          </Grid>
        </Grid>
        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} md={6}>
              <TextField
                required
                size="small"
                fullWidth
                label="Nume"
                error={error.name || error.nameIsDuplicate}
                helperText={
                  error.name
                    ? "Câmp obligatoriu!"
                    : error.nameIsDuplicate && "Nume deja existent!"
                }
                value={formValues?.name ?? ""}
                onChange={(event) =>
                  setFormValues((prev: any) => ({
                    ...prev,
                    name: event.target.value.toUpperCase(),
                  }))
                }
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit()?.then(handleCloseEditShipperDialog);
                  }
                }}
                inputProps={{ style: { textTransform: "uppercase" } }}
              ></TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                size="small"
                fullWidth
                label="CUI"
                error={error.tin || error.tinIsDuplicate}
                helperText={
                  error.tin
                    ? "Câmp obligatoriu!"
                    : error.tinIsDuplicate && "CUI deja existent!"
                }
                value={formValues?.tin ?? ""}
                onChange={(event) =>
                  setFormValues((prev: any) => ({
                    ...prev,
                    tin: event.target.value.toUpperCase(),
                  }))
                }
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit()?.then(handleCloseEditShipperDialog);
                  }
                }}
                inputProps={{ style: { textTransform: "uppercase" } }}
              ></TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                size="small"
                fullWidth
                label="Registrul comerțului"
                error={
                  error.businessRegistrationNumber ||
                  error.businessRegistrationNumberIsDuplicate
                }
                helperText={
                  error.businessRegistrationNumber
                    ? "Câmp obligatoriu!"
                    : error.businessRegistrationNumberIsDuplicate &&
                      "Registrul comerțului deja existent!"
                }
                value={formValues?.businessRegistrationNumber ?? ""}
                onChange={(event) =>
                  setFormValues((prev: any) => ({
                    ...prev,
                    businessRegistrationNumber:
                      event.target.value.toUpperCase(),
                  }))
                }
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit()?.then(handleCloseEditShipperDialog);
                  }
                }}
                inputProps={{ style: { textTransform: "uppercase" } }}
              ></TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                fullWidth
                label="Bancă"
                value={formValues?.bank}
                onChange={(event) =>
                  setFormValues((prev: any) => ({
                    ...prev,
                    bank: event.target.value
                      ? event.target.value.toUpperCase()
                      : null,
                  }))
                }
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit()?.then(handleCloseEditShipperDialog);
                  }
                }}
                inputProps={{ style: { textTransform: "uppercase" } }}
              ></TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                fullWidth
                label="IBAN"
                value={formValues?.iban}
                onChange={(event) =>
                  setFormValues((prev: any) => ({
                    ...prev,
                    iban: event.target.value
                      ? event.target.value.toUpperCase()
                      : null,
                  }))
                }
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit()?.then(handleCloseEditShipperDialog);
                  }
                }}
                inputProps={{ style: { textTransform: "uppercase" } }}
              ></TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                size="small"
                fullWidth
                label="Telefon"
                error={error.phone}
                helperText={error.phone && "Câmp obligatoriu!"}
                value={formValues?.phone ?? ""}
                onChange={(event) =>
                  setFormValues((prev: any) => ({
                    ...prev,
                    phone: event.target.value.toUpperCase(),
                  }))
                }
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit()?.then(handleCloseEditShipperDialog);
                  }
                }}
                inputProps={{ style: { textTransform: "uppercase" } }}
              ></TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                size="small"
                fullWidth
                label="Email"
                error={error.email || error.isNotEmail}
                helperText={
                  error.email
                    ? "Câmp obligatoriu!"
                    : error.isNotEmail && "Email invalid!"
                }
                value={formValues?.email ?? ""}
                onChange={(event) =>
                  setFormValues((prev: any) => ({
                    ...prev,
                    email: event.target.value.toUpperCase(),
                  }))
                }
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit()?.then(handleCloseEditShipperDialog);
                  }
                }}
                inputProps={{ style: { textTransform: "uppercase" } }}
              ></TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                size="small"
                fullWidth
                label="Contact"
                error={error.contactPerson}
                helperText={error.contactPerson && "Câmp obligatoriu!"}
                value={formValues?.contactPerson ?? ""}
                onChange={(event) =>
                  setFormValues((prev: any) => ({
                    ...prev,
                    contactPerson: event.target.value.toUpperCase(),
                  }))
                }
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit()?.then(handleCloseEditShipperDialog);
                  }
                }}
                inputProps={{ style: { textTransform: "uppercase" } }}
              ></TextField>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ display: "flex" }}>
                <Autocomplete
                  value={countryValues?.country ?? null}
                  onChange={(event, newValue, reason) => {
                    if (reason === "clear") {
                      setCountryValues((prev: any) => ({
                        ...prev,
                        country: null,
                        county: null,
                        city: null,
                      }));
                      setFormValues((prev: any) => ({
                        ...prev,
                        cityId: null,
                      }));
                      setSelectedCountryId(null);
                      setSelectedCountyId(null);
                    } else {
                      setCountryValues((prev: any) => ({
                        ...prev,
                        country: newValue,
                      }));
                      setSelectedCountryId(newValue?.id);
                    }
                  }}
                  options={sortedCountryOptions}
                  filterSelectedOptions
                  noOptionsText="Fără opțiuni"
                  getOptionLabel={(option) => `${option.name}`}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <form
                      noValidate
                      onSubmit={(event) => event.preventDefault()}
                    >
                      <TextField
                        required
                        {...params}
                        size="small"
                        fullWidth
                        label="Țară"
                        error={error.countryId}
                        helperText={error.countryId && "Câmp obligatoriu!"}
                        onKeyUp={(e) => {
                          if (e.key === "Enter") {
                            handleSubmit()?.then(handleCloseEditShipperDialog);
                          }
                        }}
                      />
                    </form>
                  )}
                  sx={{ flexGrow: 1, mr: 1 }}
                />
                <Button
                  color="primary"
                  variant="contained"
                  sx={{
                    maxWidth: "40px",
                    minWidth: "40px",
                    minHeight: "40px",
                    maxHeight: "40px",
                  }}
                  onClick={handleOpenAddCountryDialog}
                  tabIndex={-1}
                >
                  <AddIcon />
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ display: "flex" }}>
                <Autocomplete
                  value={countryValues?.county ?? null}
                  onChange={(event, newValue, reason) => {
                    if (reason === "clear") {
                      setCountryValues((prev: any) => ({
                        ...prev,
                        county: null,
                        city: null,
                      }));
                      setFormValues((prev: any) => ({
                        ...prev,
                        cityId: null,
                      }));
                      setSelectedCountyId(null);
                    } else {
                      setCountryValues((prev: any) => ({
                        ...prev,
                        county: newValue,
                      }));
                      setSelectedCountyId(newValue?.id);
                    }
                  }}
                  disabled={!selectedCountryId}
                  options={sortedCountyOptions}
                  filterSelectedOptions
                  noOptionsText="Fără opțiuni"
                  getOptionLabel={(option) => `${option.name}`}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <form
                      noValidate
                      onSubmit={(event) => event.preventDefault()}
                    >
                      <TextField
                        required
                        {...params}
                        size="small"
                        fullWidth
                        label="Județ"
                        error={error.countyId}
                        helperText={error.countyId && "Câmp obligatoriu!"}
                        onKeyUp={(e) => {
                          if (e.key === "Enter") {
                            handleSubmit()?.then(handleCloseEditShipperDialog);
                          }
                        }}
                      />
                    </form>
                  )}
                  sx={{ flexGrow: 1, mr: 1 }}
                />
                <Button
                  color="primary"
                  variant="contained"
                  sx={{
                    maxWidth: "40px",
                    minWidth: "40px",
                    minHeight: "40px",
                    maxHeight: "40px",
                  }}
                  disabled={!selectedCountryId}
                  onClick={handleOpenAddCountyDialog}
                  tabIndex={-1}
                >
                  <AddIcon />
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ display: "flex" }}>
                <Autocomplete
                  value={countryValues?.city ?? null}
                  onChange={(event, newValue) => {
                    setCountryValues((prev: any) => ({
                      ...prev,
                      city: newValue,
                    }));
                    setFormValues((prev: any) => ({
                      ...prev,
                      cityId: newValue?.id,
                    }));
                  }}
                  options={sortedCityOptions}
                  filterSelectedOptions
                  disabled={!selectedCountyId}
                  noOptionsText="Fără opțiuni"
                  getOptionLabel={(option) => `${option.name}`}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <form
                      noValidate
                      onSubmit={(event) => event.preventDefault()}
                    >
                      <TextField
                        required
                        {...params}
                        size="small"
                        fullWidth
                        label="Localitate"
                        error={error.cityId}
                        helperText={error.cityId && "Câmp obligatoriu!"}
                        onKeyUp={(e) => {
                          if (e.key === "Enter") {
                            handleSubmit()?.then(handleCloseEditShipperDialog);
                          }
                        }}
                      />
                    </form>
                  )}
                  sx={{ flexGrow: 1, mr: 1 }}
                />
                <Button
                  color="primary"
                  variant="contained"
                  sx={{
                    maxWidth: "40px",
                    minWidth: "40px",
                    minHeight: "40px",
                    maxHeight: "40px",
                  }}
                  disabled={!selectedCountyId}
                  onClick={handleOpenAddCityDialog}
                  tabIndex={-1}
                >
                  <AddIcon />
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                size="small"
                fullWidth
                label="Adresă"
                error={error.address}
                helperText={error.address && "Câmp obligatoriu!"}
                value={formValues?.address ?? ""}
                onChange={(event) =>
                  setFormValues((prev: any) => ({
                    ...prev,
                    address: event.target.value.toUpperCase(),
                  }))
                }
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit()?.then(handleCloseEditShipperDialog);
                  }
                }}
                inputProps={{ style: { textTransform: "uppercase" } }}
              ></TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                type="number"
                size="small"
                fullWidth
                label="Termen de plată"
                value={formValues?.paymentTermInDays ?? ""}
                error={error.paymentTermInDays || error.paymentTermInDaysMin0}
                helperText={
                  error.paymentTermInDays
                    ? "Câmp obligatoriu!"
                    : error.paymentTermInDaysMin0 && "Valoare minimă este 0"
                }
                onChange={(event) =>
                  setFormValues((prev: any) => ({
                    ...prev,
                    paymentTermInDays:
                      event.target.value === "" ? null : +event.target.value,
                  }))
                }
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit()?.then(handleCloseEditShipperDialog);
                  }
                }}
                inputProps={{ min: 0 }}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                justifyContent={{ xs: "center", md: "center" }}
                alignItems="center"
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={formValues?.isActive || false}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setFormValues((prev: any) => ({
                          ...prev,
                          isActive: event.target.checked,
                        }));
                      }}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          handleSubmit()?.then(handleCloseEditShipperDialog);
                        }
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  labelPlacement="start"
                  label="Activ"
                />
              </Grid>
            </Grid>
          </Grid>
          <Divider sx={{ mb: 2, opacity: 0 }} />
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={12} md={6}>
              <Button
                fullWidth
                variant="contained"
                onClick={() => {
                  handleSubmit()?.then(handleCloseEditShipperDialog);
                }}
              >
                Salvează
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                fullWidth
                variant="outlined"
                onClick={handleCloseEditShipperDialog}
              >
                Renunță
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      {openAddCountryDialog && (
        <AddCountryDialog
          open={openAddCountryDialog}
          onCountryCreated={(createdCountry: Country) => {
            fetchCountries();
            setCountryValues((prev: any) => ({
              ...prev,
              country: createdCountry,
              county: null,
              city: null,
            }));
            setSelectedCountryId(createdCountry.id);
            setSelectedCountyId(null);
            setFormValues((prev: any) => ({
              ...prev,
              cityId: null,
            }));
            setError((prev: any) => ({
              ...prev,
              countryId: false,
              countyId: false,
              cityId: false,
            }));
          }}
          onClose={() => {
            setOpenAddCountryDialog(false);
          }}
        />
      )}
      {openAddCountyDialog && (
        <AddCountyDialog
          open={openAddCountyDialog}
          onCountyCreated={(createdCounty: County) => {
            fetchCounties();
            setCountryValues((prev: any) => ({
              ...prev,
              county: createdCounty,
              city: null,
            }));
            setSelectedCountyId(createdCounty.id);
            setFormValues((prev: any) => ({
              ...prev,
              cityId: null,
            }));
            setError((prev: any) => ({
              ...prev,
              countyId: false,
              cityId: false,
            }));
          }}
          onClose={() => {
            setOpenAddCountyDialog(false);
          }}
          selectedCountryId={selectedCountryId}
        />
      )}
      {openAddCityDialog && (
        <AddCityDialog
          open={openAddCityDialog}
          onCityCreated={(createdCity: City) => {
            fetchCities();
            setCountryValues((prev: any) => ({
              ...prev,
              city: createdCity,
            }));
            setFormValues((prev: any) => ({
              ...prev,
              cityId: createdCity.id,
            }));
            setError((prev: any) => ({
              ...prev,
              cityId: false,
            }));
          }}
          onClose={() => {
            setOpenAddCityDialog(false);
          }}
          selectedCountyId={selectedCountyId}
        />
      )}
    </>
  );
}
