import * as React from "react";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Autocomplete, Divider, Grid } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import TextField from "@mui/material/TextField";
import { invoiceSeriesRequest } from "../../api/InvoiceSeriesRequest";
import { shipperInvoiceRequest } from "../../api/ShipperInvoiceRequest";
import { addHours, addMinutes, addSeconds } from "date-fns";

interface Props {
  open: boolean;
  onClose: AnyFunction;
}

interface FilterForm {
  startDate: Date | null;
  endDate: Date | null;
  invoiceSeriesId: number | undefined;
}

export default function GenerateSagaXmlDialog(props: Props) {
  const { open, onClose } = props;

  const [filterValues, setFilterValues] = useState<FilterForm>({
    startDate: null,
    endDate: null,
    invoiceSeriesId: undefined,
  });
  const [errors, setErrors] = useState({
    startDate: false,
    endDate: false,
    invoiceSeries: false,
  });

  const closeDialog = () => {
    setFilterValues({
      startDate: null,
      endDate: null,
      invoiceSeriesId: undefined,
    });
    setErrors({
      startDate: false,
      endDate: false,
      invoiceSeries: false,
    });
    setInvoiceSeriesValue(null);
    onClose();
  };

  const [invoiceSeriesValue, setInvoiceSeriesValue] =
    useState<InvoiceSeries | null>(null);
  const [invoiceSeriesOptions, setInvoiceSeriesOptions] = useState<
    InvoiceSeries[]
  >([]);

  const fetchInvoiceSeries = () => {
    invoiceSeriesRequest.findAll().then((data) => {
      setInvoiceSeriesOptions(
        data.results.map((invoiceSeries: InvoiceSeries) => ({
          id: invoiceSeries.id,
          name: invoiceSeries.name,
          intervalLowerBound: invoiceSeries.intervalLowerBound,
          intervalUpperBound: invoiceSeries.intervalUpperBound,
          isActive: invoiceSeries.isActive,
        }))
      );
    });
  };

  const sortedInvoiceSeriesOptions = invoiceSeriesOptions.sort(function (a, b) {
    if (a.name! < b.name!) {
      return -1;
    }
    if (a.name! > b.name!) {
      return 1;
    }
    return 0;
  });

  useEffect(() => {
    fetchInvoiceSeries();
  }, []);

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="xs">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <DialogTitle sx={{ pb: 0 }}>
              Export Facturi SAGA - Format XML
            </DialogTitle>
          </Grid>
        </Grid>
        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Dată inițială"
                  value={filterValues.startDate}
                  inputFormat="dd/MM/yyyy"
                  onChange={(newValue) => {
                    setFilterValues((prev: any) => ({
                      ...prev,
                      startDate: newValue,
                      ...(newValue && prev.endDate && prev.endDate < newValue
                        ? { endDate: null }
                        : {}),
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      fullWidth
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "zz/ll/aaaa",
                      }}
                      required
                      error={errors.startDate}
                      helperText={errors.startDate && "Câmp obligatoriu!"}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Dată finală"
                  value={filterValues.endDate}
                  inputFormat="dd/MM/yyyy"
                  onChange={(newValue) => {
                    setFilterValues((prev: any) => ({
                      ...prev,
                      endDate: newValue
                        ? addHours(addMinutes(addSeconds(newValue, 59), 59), 23)
                        : null,
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      fullWidth
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "zz/ll/aaaa",
                      }}
                      required
                      error={errors.endDate}
                      helperText={errors.endDate && "Câmp obligatoriu!"}
                    />
                  )}
                  disabled={!filterValues.startDate}
                  minDate={filterValues.startDate}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                value={invoiceSeriesValue}
                onChange={(event, newValue) => {
                  setInvoiceSeriesValue(newValue);
                  setFilterValues((prev: any) => ({
                    ...prev,
                    invoiceSeriesId: newValue?.id,
                  }));
                }}
                options={sortedInvoiceSeriesOptions}
                filterSelectedOptions
                getOptionLabel={(option) => option.name!}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    size="small"
                    label="Serie factură"
                    required
                    error={errors.invoiceSeries}
                    helperText={errors.invoiceSeries && "Câmp obligatoriu!"}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Divider sx={{ mb: 2, opacity: 0 }} />
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={12} sm={6}>
              <Button
                variant="contained"
                fullWidth
                size="large"
                color="primary"
                onClick={() => {
                  if (
                    !filterValues.startDate ||
                    !filterValues.endDate ||
                    !filterValues.invoiceSeriesId
                  ) {
                    setErrors({
                      startDate: !filterValues.startDate,
                      endDate: !filterValues.endDate,
                      invoiceSeries: !filterValues.invoiceSeriesId,
                    });
                    return;
                  }

                  shipperInvoiceRequest
                    .getSagaXml(
                      filterValues.startDate,
                      filterValues.endDate,
                      filterValues.invoiceSeriesId
                    )
                    .then(() => {
                      closeDialog();
                    })
                    .catch(() => {
                      alert("S-a produs o eroare!");
                    });
                }}
                autoFocus
              >
                Exportă
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant="outlined"
                fullWidth
                size="large"
                onClick={() => {
                  closeDialog();
                }}
                autoFocus
              >
                Renunță
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
