import * as React from "react";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

interface Props {
  open: boolean;
  onClose: AnyFunction;
  onDelete?: AnyFunction;
}

export default function DeleteRowDialog(props: Props) {
  const { open, onClose, onDelete } = props;

  const handleCloseDeleteRowDialog = () => {
    onClose();
  };

  const handleDeleteRow = () => {
    if (typeof onDelete === "function") {
      onDelete();
    }
    handleCloseDeleteRowDialog();
  };

  return (
    <>
      <Dialog open={open}>
        <Box
          sx={{
            mt: 2,
            mb: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <DeleteIcon sx={{ fontSize: "75px" }} />
          <DialogTitle id="alert-dialog-title">
            {"Sunteți sigur că doriți să ștergeți această înregistrare?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Această înregistrare va fi ștearsă imediat. Acțiunea este
              ireversibilă.
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              minWidth: 520,
            }}
          >
            <Button
              variant="contained"
              fullWidth
              size="large"
              color="error"
              onClick={handleDeleteRow}
              autoFocus
            >
              Șterge
            </Button>
            <Button
              variant="outlined"
              fullWidth
              size="large"
              onClick={handleCloseDeleteRowDialog}
            >
              Renunță
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}
