import { createContext, useState } from "react";
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material/styles";
import { LocalStorage } from "../localStorage/LocalStorage";
import { CssBaseline } from "@mui/material";

export const ThemeContext = createContext<{
  isLightTheme: boolean;
  isDarkTheme: boolean;
  setLightTheme: CallableFunction;
  setDarkTheme: CallableFunction;
} | null>(null);

const lightTheme = createTheme({
  palette: { mode: "light" },
});
const darkTheme = createTheme({ palette: { mode: "dark" } });

export default function ThemeProvider({ children }: any) {
  const [theme, setTheme] = useState(
    LocalStorage.get(LocalStorage.KEYS.DARK_MODE) ? darkTheme : lightTheme
  );

  const setLightTheme: any = () => {
    LocalStorage.unset(LocalStorage.KEYS.DARK_MODE);
    setTheme(lightTheme);
  };
  const setDarkTheme: any = () => {
    LocalStorage.set(LocalStorage.KEYS.DARK_MODE, "true");
    setTheme(darkTheme);
  };

  const isLightTheme = theme.palette.mode === "light";
  const isDarkTheme = theme.palette.mode === "dark";

  return (
    <ThemeContext.Provider
      value={{ isLightTheme, isDarkTheme, setLightTheme, setDarkTheme }}
    >
      <MuiThemeProvider theme={theme}>
        {children}
        <CssBaseline enableColorScheme />
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
}
