import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid, IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import TripPendingDocumentsDataGrid from "../dataGrids/TripPendingDocumentsDataGrid";

interface Props {
  open: boolean;
  onClose: AnyFunction;
  trip?: Trip;
}

export default function TripPendingDocumentsDialog({
  open,
  onClose,
  trip,
}: Props) {
  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth="xl"
        PaperProps={{
          sx: {
            maxHeight: "100%",
          },
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <DialogTitle sx={{ pb: 0 }}>
              Validare documente - Comanda {`${trip?.id}/${trip?.date}`}
            </DialogTitle>
          </Grid>
          <Grid item sx={{ mr: 2, pt: 2 }}>
            <IconButton onClick={onClose}>
              <ClearIcon />
            </IconButton>
          </Grid>
        </Grid>
        <DialogContent sx={{ paddingTop: 1 }}>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <TripPendingDocumentsDataGrid trip={trip} />
            </Grid>
            <Grid item>
              <Button variant="contained" fullWidth onClick={onClose}>
                Închide
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
