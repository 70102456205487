import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Autocomplete, Grid, IconButton } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ClearIcon from "@mui/icons-material/Clear";
import { useEffect, useState } from "react";
import { userRequest } from "../../../api/UserRequest";

interface Props {
  open: boolean;
  onClose: AnyFunction;
  user?: User;
  onUserEdited: AnyFunction;
}

interface FormValues {
  username: string | null;
  firstName: string | null;
  lastName: string | null;
  password: string | null;
  birthdate: Date | null;
  phone: string | null;
  role: string | null;
}

export default function EditUserDialog(props: Props) {
  const { open, onClose, user, onUserEdited } = props;

  const [formValues, setFormValues] = useState<FormValues>({
    username: "",
    firstName: "",
    lastName: "",
    password: "",
    birthdate: null,
    phone: "",
    role: "",
  });

  const [roleValue, setRoleValue] = useState<Role | null>(null);

  const [error, setError] = useState({
    username: false,
    usernameIsDuplicate: false,
    password: false,
    passwordTooWeak: false,
    firstName: false,
    lastName: false,
    role: false,
  });

  const strongPasswordRegex =
    /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
  const isStrongPassword = Boolean(
    formValues.password?.match(strongPasswordRegex)
  );

  const [userData, setUserData] = useState<User[]>([]);

  const fetchUsers = () => {
    userRequest.findAll().then((data) => {
      setUserData(
        data.results.map((user: User) => ({
          id: user.id,
          username: user.username,
          password: user.password,
          firstName: user.firstName,
          lastName: user.lastName,
          birthdate: user.birthdate,
          phone: user.phone,
          role: user.role,
        }))
      );
    });
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const checkUsernameIsDuplicate = (user: User) =>
    user.username?.toLowerCase() === formValues.username?.toLowerCase();

  const handleErrors = (password: string | null) => {
    if (!formValues.username) {
      setError((prev: any) => ({
        ...prev,
        username: true,
      }));
    } else if (formValues.username) {
      setError((prev: any) => ({
        ...prev,
        username: false,
      }));
    }
    if (formValues.username === user?.username) {
      setError((prev: any) => ({
        ...prev,
        usernameIsDuplicate: false,
      }));
    } else if (formValues.username !== user?.username) {
      setError((prev: any) => ({
        ...prev,
        usernameIsDuplicate: userData.some(checkUsernameIsDuplicate),
      }));
    }
    if (password && !isStrongPassword) {
      setError((prev: any) => ({
        ...prev,
        passwordTooWeak: true,
      }));
    } else if (isStrongPassword) {
      setError((prev: any) => ({
        ...prev,
        passwordTooWeak: false,
      }));
    }
    if (!formValues.firstName) {
      setError((prev: any) => ({
        ...prev,
        firstName: true,
      }));
    } else if (formValues.firstName) {
      setError((prev: any) => ({
        ...prev,
        firstName: false,
      }));
    }
    if (!formValues.lastName) {
      setError((prev: any) => ({
        ...prev,
        lastName: true,
      }));
    } else if (formValues.lastName) {
      setError((prev: any) => ({
        ...prev,
        lastName: false,
      }));
    }
    if (!formValues.role) {
      setError((prev: any) => ({
        ...prev,
        role: true,
      }));
    } else if (formValues.role) {
      setError((prev: any) => ({
        ...prev,
        role: false,
      }));
    }
  };

  useEffect(() => {
    if (user) {
      setFormValues({
        username: user.username,
        password: user.password,
        firstName: user.firstName,
        lastName: user.lastName,
        birthdate: user.birthdate,
        phone: user.phone,
        role: user.role?.name || null,
      });
      setRoleValue(user.role || null);
    }
  }, [user]);

  const handleSubmit = () => {
    if (user && user.id) {
      handleErrors(formValues.password);
      const nameCondition =
        formValues.username === user?.username ||
        !userData.some(checkUsernameIsDuplicate);
      const passwordCondition = !formValues.password || isStrongPassword;
      const formIsValid =
        formValues.username &&
        nameCondition &&
        passwordCondition &&
        formValues.firstName &&
        formValues.lastName &&
        formValues.role;
      if (formIsValid) {
        const requestData: any = { ...formValues };
        if (!requestData.password) {
          delete requestData.password;
        }
        return userRequest
          .patch(user.id, requestData)
          .then(() => {
            setRoleValue(null);
            if (typeof onUserEdited === "function") {
              onUserEdited();
            }
          })
          .catch(() => {
            alert("S-a produs o eroare!");
          });
      }
    }
  };

  const handleCloseEditUserDialog = () => {
    onClose();
    setError(() => ({
      username: false,
      usernameIsDuplicate: false,
      password: false,
      passwordTooWeak: false,
      firstName: false,
      lastName: false,
      role: false,
    }));
  };

  const roleOptions: Role[] = [
    {
      name: "admin",
      translation: "Administrator",
    },
    {
      name: "manager",
      translation: "Manager",
    },
    {
      name: "dispatcher",
      translation: "Dispecer",
    },
    {
      name: "shipper_beneficiary",
      translation: "Beneficiar",
    },
  ];

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="xs">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <DialogTitle sx={{ pb: 0 }}>Modificare utilizator</DialogTitle>
          </Grid>
          <Grid item sx={{ mr: 2, pt: 2 }}>
            <IconButton onClick={handleCloseEditUserDialog} tabIndex={-1}>
              <ClearIcon />
            </IconButton>
          </Grid>
        </Grid>
        <DialogContent>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    label="Nume utilizator"
                    fullWidth
                    error={error.username || error.usernameIsDuplicate}
                    helperText={
                      error.username
                        ? "Câmp obligatoriu!"
                        : error.usernameIsDuplicate &&
                          "Utilizator deja existent!"
                    }
                    value={formValues?.username ?? ""}
                    onChange={(event) =>
                      setFormValues((prev: any) => ({
                        ...prev,
                        username: event.target.value,
                      }))
                    }
                    inputProps={{ style: { textTransform: "uppercase" } }}
                  ></TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    label="Parolă nouă"
                    fullWidth
                    error={error.passwordTooWeak}
                    helperText={
                      error.passwordTooWeak && (
                        <>
                          Parola trebuie sa îndeplinească urmatoarele condiții:
                          <br />
                          - să conțină minim 8 caractere
                          <br />
                          - să conțină minim o literă mică
                          <br />
                          - să conțină minim o majusculă
                          <br />- să conțină minim o cifră sau un caracter
                          special
                        </>
                      )
                    }
                    value={formValues?.password ?? ""}
                    onChange={(event) =>
                      setFormValues((prev: any) => ({
                        ...prev,
                        password: event.target.value,
                      }))
                    }
                  ></TextField>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    value={
                      roleOptions.find((roleOption) => {
                        return roleOption.name === roleValue?.name;
                      }) ?? null
                    }
                    onChange={(event, newValue) => {
                      setRoleValue(newValue);
                      setFormValues((prev: any) => ({
                        ...prev,
                        role: newValue?.name,
                      }));
                    }}
                    options={roleOptions}
                    filterSelectedOptions
                    noOptionsText="Fără opțiuni"
                    getOptionLabel={(option) =>
                      `${option.translation.toUpperCase()}`
                    }
                    isOptionEqualToValue={(option, value) =>
                      option?.name === value?.name
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        error={error.role}
                        helperText={error.role && "Câmp obligatoriu!"}
                        size="small"
                        label="Rol"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    label="Nume"
                    fullWidth
                    error={error.lastName}
                    helperText={error.lastName && "Câmp obligatoriu!"}
                    value={formValues?.lastName ?? ""}
                    onChange={(event) =>
                      setFormValues((prev: any) => ({
                        ...prev,
                        lastName: event.target.value,
                      }))
                    }
                    inputProps={{ style: { textTransform: "uppercase" } }}
                  ></TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    label="Prenume"
                    fullWidth
                    error={error.firstName}
                    helperText={error.firstName && "Câmp obligatoriu!"}
                    value={formValues?.firstName ?? ""}
                    onChange={(event) =>
                      setFormValues((prev: any) => ({
                        ...prev,
                        firstName: event.target.value,
                      }))
                    }
                    inputProps={{ style: { textTransform: "uppercase" } }}
                  ></TextField>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Data nașterii"
                      value={
                        formValues?.birthdate
                          ? new Date(formValues?.birthdate)
                          : null
                      }
                      inputFormat="dd/MM/yyyy"
                      onChange={(newValue) => {
                        setFormValues((prev) => ({
                          ...prev,
                          birthdate: newValue,
                        }));
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          fullWidth
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "zz/ll/aaaa",
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    label="Telefon"
                    fullWidth
                    value={formValues?.phone ?? ""}
                    onChange={(event) =>
                      setFormValues((prev: any) => ({
                        ...prev,
                        phone: event.target.value ? event.target.value : null,
                      }))
                    }
                  ></TextField>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={12} md={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={() => {
                      handleSubmit()
                        ?.then(handleCloseEditUserDialog)
                        .then(fetchUsers);
                    }}
                  >
                    Salvează
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    fullWidth
                    variant="outlined"
                    onClick={handleCloseEditUserDialog}
                  >
                    Renunță
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
