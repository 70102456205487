import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

interface Props {
  open: boolean;
  onClose: AnyFunction;
  onDelete?: AnyFunction;
}

export default function CancelShipperInvoiceDialog(props: Props) {
  const { open, onClose, onDelete } = props;

  const handleCloseCancelShipperInvoiceDialog = () => {
    onClose();
  };

  const handleDeleteRow = () => {
    if (typeof onDelete === "function") {
      onDelete();
    }
    handleCloseCancelShipperInvoiceDialog();
  };

  return (
    <>
      <Dialog open={open}>
        <Box
          sx={{
            mt: 2,
            mb: 1,
            ml: 1,
            mr: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CancelIcon sx={{ fontSize: "75px" }} />
          <DialogTitle id="alert-dialog-title">
            {"Sunteți sigur că doriți să anulați factura?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              sx={{ textAlign: "center", whiteSpace: "pre-wrap" }}
            >
              {`Factura va fi anulată.\nNumărul asociat acesteia va putea fi refolosit.\nToate cursele asociate facturii vor reveni în stadiul "Finalizată".\nAcțiunea este ireversibilă.`}
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              minWidth: 520,
            }}
          >
            <Button
              variant="contained"
              fullWidth
              size="large"
              color="error"
              onClick={handleDeleteRow}
              autoFocus
            >
              Anulează&nbsp;factura
            </Button>
            <Button
              variant="outlined"
              fullWidth
              size="large"
              onClick={handleCloseCancelShipperInvoiceDialog}
            >
              Renunță
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}
