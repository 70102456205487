export const isAuthenticatedSelector = ({ auth }: { auth: any }) =>
  auth.isAuthenticated;

export const isAdminSelector = ({ auth }: { auth: any }) =>
  auth.user.role?.name === "admin";

export const isManagerSelector = ({ auth }: { auth: any }) =>
  auth.user.role?.name === "manager";

export const isAdminOrManagerSelector = ({ auth }: { auth: any }) =>
  auth.user.role?.name === "admin" || auth.user.role?.name === "manager";

export const isDispatcherSelector = ({ auth }: { auth: any }) =>
  auth.user.role?.name === "dispatcher";

export const isShipperBeneficiarySelector = ({ auth }: { auth: any }) =>
  auth.user.role?.name === "shipper_beneficiary";

export const vatSelector = ({ auth }: { auth: any }) => auth.vat;

export const userSelector = ({ auth }: { auth: any }) => auth.user;

export const userActiveInvoiceSeriesIdSelector = ({ auth }: { auth: any }) =>
  auth.user.activeInvoiceSeriesId;
