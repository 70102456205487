import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { DialogContentText, Divider, Grid } from "@mui/material";
import dateFormat from "dateformat";

enum TripStatus {
  Inserted = "Inserted",
  Completed = "Completed",
  Confirmed = "Confirmed",
  Billed = "Billed",
  Paid = "Paid",
  Blocked = "Blocked",
}

interface Props {
  selectedTrips: Trip[];
}

const GreyTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[500],
}));

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function ConfirmPaymentAccordion(props: Props) {
  const { selectedTrips } = props;

  const [expandedAccordion, setExpandedAccordion] = React.useState<
    string | false
  >(`panel${selectedTrips[0]?.id}`);

  const handleExpandAccordion =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpandedAccordion(newExpanded ? panel : false);
    };

  return (
    <>
      {selectedTrips.map((trip) => {
        return (
          <Accordion
            key={trip.id}
            expanded={expandedAccordion === `panel${trip.id}`}
            onChange={handleExpandAccordion(`panel${trip.id}`)}
          >
            <AccordionSummary
              aria-controls={`panel${trip.id}d-content`}
              id={`panel${trip.id}d-header`}
              tabIndex={-1}
            >
              <Grid
                container
                direction={{ xs: "column", sm: "row" }}
                justifyContent={{ xs: "center", sm: "space-between" }}
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Typography>Comanda {trip?.id}</Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    Dată: {trip?.date ? `${trip.date}` : "-"}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    Beneficiar:{" "}
                    {trip?.shipper?.name ? `${trip.shipper.name}` : "-"}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    Transportator:{" "}
                    {trip?.carrier?.name ? `${trip.carrier.name}` : "-"}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={1}
              >
                <Grid item xs={12}>
                  <DialogContentText>Detalii comandă</DialogContentText>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <GreyTypography variant="body2">
                    Număr: {trip?.id ? `${trip.id}` : "-"}
                  </GreyTypography>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <GreyTypography variant="body2">
                    Dată: {trip?.date ? `${trip.date}` : "-"}
                  </GreyTypography>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <GreyTypography variant="body2">
                    Status:&nbsp;
                    {trip?.computedStatus &&
                    trip.computedStatus === TripStatus.Inserted
                      ? "Introdusă"
                      : trip.computedStatus === TripStatus.Completed
                      ? "Finalizată"
                      : trip.computedStatus === TripStatus.Confirmed
                      ? "Operată"
                      : trip.computedStatus === TripStatus.Billed
                      ? "Facturată"
                      : trip.computedStatus === TripStatus.Paid
                      ? "Plătită"
                      : trip.computedStatus === TripStatus.Blocked
                      ? "Blocată"
                      : "-"}
                  </GreyTypography>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <GreyTypography variant="body2">
                    Dispecer:{" "}
                    {trip?.date
                      ? `${trip.dispatcher?.lastName} ${trip.dispatcher?.firstName}`
                      : "-"}
                  </GreyTypography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">Încărcare:</Typography>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <GreyTypography variant="body2">
                    Dată: {trip?.loadingDate ? `${trip.loadingDate}` : "-"}
                  </GreyTypography>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <GreyTypography variant="body2">
                    Adresă:{" "}
                    {trip?.loadingAddress ? `${trip.loadingAddress}` : "-"}
                  </GreyTypography>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <GreyTypography variant="body2">
                    Localitate:{" "}
                    {trip?.loadingCity
                      ? `${trip.loadingCity.name} (${trip.loadingCity.county?.name})`
                      : "-"}
                  </GreyTypography>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <GreyTypography variant="body2">
                    Țară:{" "}
                    {trip?.loadingCity
                      ? `${trip.loadingCity.county?.country?.name}`
                      : "-"}
                  </GreyTypography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">Descărcare:</Typography>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <GreyTypography variant="body2">
                    Dată: {trip?.unloadingDate ? `${trip.unloadingDate}` : "-"}
                  </GreyTypography>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <GreyTypography variant="body2">
                    Adresă:{" "}
                    {trip?.unloadingAddress ? `${trip.unloadingAddress}` : "-"}
                  </GreyTypography>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <GreyTypography variant="body2">
                    Localitate:{" "}
                    {trip?.unloadingCity?.name
                      ? `${trip.unloadingCity.name} (${trip.unloadingCity.county?.name})`
                      : "-"}
                  </GreyTypography>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <GreyTypography variant="body2">
                    Țară:{" "}
                    {trip?.unloadingCity
                      ? `${trip.unloadingCity.county?.country?.name}`
                      : "-"}
                  </GreyTypography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">Beneficiar:</Typography>
                </Grid>
                <Grid item xs={12} md={6} lg={2.4}>
                  <GreyTypography variant="body2">
                    Nume: {trip?.shipper?.name ? `${trip.shipper.name}` : "-"}
                  </GreyTypography>
                </Grid>
                <Grid item xs={12} md={6} lg={2.4}>
                  <GreyTypography variant="body2">
                    Punct de lucru:{" "}
                    {trip?.shipperBranch?.name
                      ? `${trip.shipperBranch.name}`
                      : "-"}
                  </GreyTypography>
                </Grid>
                <Grid item xs={12} md={6} lg={2.4}>
                  <GreyTypography variant="body2">
                    Referință:{" "}
                    {trip?.shipperReferenceId
                      ? `${trip.shipperReferenceId}`
                      : "-"}
                  </GreyTypography>
                </Grid>
                <Grid item xs={12} md={6} lg={2.4}>
                  <GreyTypography variant="body2">
                    Factură:{" "}
                    {trip?.shipperInvoice
                      ? `${trip.shipperInvoice?.invoiceSeries?.name}${trip.shipperInvoice?.number}`
                      : "-"}
                  </GreyTypography>
                </Grid>
                <Grid item xs={12} md={6} lg={2.4}>
                  <GreyTypography variant="body2">
                    Termen:{" "}
                    {trip?.shipperPaymentTermInDays
                      ? `${trip.shipperPaymentTermInDays}`
                      : "-"}
                  </GreyTypography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">Transportator:</Typography>
                </Grid>
                <Grid item xs={12} md={6} lg={2.4}>
                  <GreyTypography variant="body2">
                    Nume: {trip?.carrier?.name ? `${trip.carrier.name}` : null}
                  </GreyTypography>
                </Grid>
                <Grid item xs={12} md={6} lg={2.4}>
                  <GreyTypography variant="body2">
                    Mașină:{" "}
                    {trip?.carrierCar?.vehicleRegistrationPlate
                      ? `${trip.carrierCar.vehicleRegistrationPlate}`
                      : "-"}
                  </GreyTypography>
                </Grid>
                <Grid item xs={12} md={6} lg={2.4}>
                  <GreyTypography variant="body2">
                    Șofer:{" "}
                    {trip?.carrierDriver?.name
                      ? `${trip.carrierDriver.name}`
                      : "-"}
                  </GreyTypography>
                </Grid>
                <Grid item xs={12} md={6} lg={2.4}>
                  <GreyTypography variant="body2">
                    Factură:{" "}
                    {trip?.carrierInvoice
                      ? `${trip.carrierInvoice?.invoiceData} / ${dateFormat(
                          trip.carrierInvoice?.date?.toString(),
                          "dd.mm.yyyy"
                        )}`
                      : "-"}
                  </GreyTypography>
                </Grid>
                <Grid item xs={12} md={6} lg={2.4}>
                  <GreyTypography variant="body2">
                    Termen:{" "}
                    {trip?.carrierPaymentTermInDays
                      ? `${trip.carrierPaymentTermInDays}`
                      : "-"}
                  </GreyTypography>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  direction="row"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography variant="body2" align="justify">
                      Observații:&nbsp;
                    </Typography>
                  </Grid>
                  <Grid item>
                    <GreyTypography align="justify">
                      {trip?.observations ? `${trip.observations}` : "-"}
                    </GreyTypography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  direction="row"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography variant="body2" align="justify">
                      Comentarii:&nbsp;
                    </Typography>
                  </Grid>
                  <Grid item>
                    <GreyTypography align="justify">
                      {trip?.comments ? `${trip.comments}` : "-"}
                    </GreyTypography>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
}
