import * as React from "react";
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

interface TripRow {
  tripNumber: number;
  shipperName: string;
  carrierName: string;
  loadingCity: string;
  unloadingCity: string;
  dispatcherId: number;
  dispatcherFullName: string;
  shipperTotal: string;
  carrierTotal: string;
  profit: string;
}

interface Report {
  dispatcherId: number;
  dispatcherFullName: string;
  trips: TripRow[];
}

interface Props {
  reportValues: Report[];
}

const getBackgroundColor = (mode: string) =>
  mode === "dark" ? "#2d2d2d" : "#ececec";

export default function LoserTripsReportTable(props: Props) {
  const { reportValues } = props;
  const [open, setOpen] = React.useState(true);
  // const [open, setOpen] = React.useState<boolean[]>([]);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow
            sx={{
              backgroundColor: (theme) =>
                getBackgroundColor(theme.palette.mode),
            }}
          >
            <TableCell />
            <TableCell>Dispecer</TableCell>
            <TableCell align="right">Profit</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reportValues.map((reportValue: Report) => {
            let totalProfitPerDispatcher = 0;
            {
              reportValue.trips.map((trip) => {
                totalProfitPerDispatcher += Number(trip.profit);
              });
            }
            return (
              <>
                <TableRow
                  sx={{
                    "&& > *": { borderBottom: 0 },
                    backgroundColor: (theme) =>
                      getBackgroundColor(theme.palette.mode),
                  }}
                  onClick={() => setOpen(!open)}
                >
                  <TableCell sx={{ width: 50 }} size="small">
                    <IconButton aria-label="expand row" size="small">
                      {open ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell component="th" scope="row" size="small">
                    {`${reportValue.dispatcherFullName}`}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    size="small"
                    align="right"
                  >
                    {`${totalProfitPerDispatcher}`}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={4}
                  >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                      <Box sx={{ marginBottom: 2 }}>
                        <Table size="small" aria-label="purchases">
                          <TableHead>
                            <TableRow>
                              <TableCell size="medium">Număr comandă</TableCell>
                              <TableCell size="medium" align="right">
                                Beneficiar
                              </TableCell>
                              <TableCell size="medium" align="right">
                                Transportator
                              </TableCell>
                              <TableCell size="medium" align="right">
                                Localitate încărcare
                              </TableCell>
                              <TableCell size="medium" align="right">
                                Localitate descărcare
                              </TableCell>
                              <TableCell size="medium" align="right">
                                Valoare beneficiar
                              </TableCell>
                              <TableCell size="medium" align="right">
                                Valoare transportator
                              </TableCell>
                              <TableCell size="medium" align="right">
                                Profit
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {reportValue.trips &&
                              reportValue.trips?.map((tripRow: TripRow) => {
                                return (
                                  <>
                                    <TableRow key={tripRow.tripNumber}>
                                      <TableCell component="th" scope="row">
                                        {tripRow.tripNumber}
                                      </TableCell>
                                      <TableCell align="right">
                                        {tripRow.shipperName}
                                      </TableCell>
                                      <TableCell align="right">
                                        {tripRow.carrierName}
                                      </TableCell>
                                      <TableCell align="right">
                                        {tripRow.loadingCity}
                                      </TableCell>
                                      <TableCell align="right">
                                        {tripRow.unloadingCity}
                                      </TableCell>
                                      <TableCell align="right">
                                        {tripRow.shipperTotal}
                                      </TableCell>
                                      <TableCell align="right">
                                        {tripRow.carrierTotal}
                                      </TableCell>
                                      <TableCell align="right">
                                        {tripRow.profit}
                                      </TableCell>
                                    </TableRow>
                                  </>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
