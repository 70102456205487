import axios, { AxiosRequestConfig } from "axios";
import adjustForTimezone from "../functions/adjustForTimezone";

export interface QueryParam {
  key: string;
  value: string;
}

export default class RootRequest {
  static METHOD = {
    GET: "GET",
    POST: "POST",
    PATCH: "PATCH",
    DELETE: "DELETE",
  };

  get apiProtocol() {
    return "https";
  }

  get apiHost() {
    return "etms.efe.ro";
  }

  get apiPort() {
    return "443";
  }

  get apiUrl() {
    return `${this.apiProtocol}://${this.apiHost}:${this.apiPort}`;
  }

  get apiResource() {
    return "";
  }

  get apiRoute() {
    return `${this.apiUrl}/${this.apiResource}`;
  }

  get apiRoutes(): Record<string, string> {
    return {
      FIND_ALL: this.apiRoute,
      FIND: `${this.apiRoute}/`,
      POST: this.apiRoute,
      PATCH: `${this.apiRoute}/`,
      DELETE: `${this.apiRoute}/`,
    };
  }

  async makeRequest(
    method: any,
    url: any,
    data?: any,
    extraConfig?: AxiosRequestConfig
  ) {
    return axios({ method, url, data, ...extraConfig });
  }

  getRequestInterceptors() {
    return axios.interceptors.request;
  }

  addRequestInterceptor(handler: any) {
    return this.getRequestInterceptors().use(handler);
  }

  removeRequestInterceptor(id: any) {
    this.getRequestInterceptors().eject(id);
  }

  buildQueryParams(queryParams: QueryParam[]): string {
    let queryString = "";
    queryParams.forEach((queryParam) => {
      if (queryString) {
        queryString += `&${queryParam.key}=${queryParam.value}`;
      } else {
        queryString = `?${queryParam.key}=${queryParam.value}`;
      }
    });

    return queryString;
  }

  async findAll(limit: number = -1, offset: number = 0) {
    const queryParams: QueryParam[] = [
      { key: "limit", value: limit.toString() },
      { key: "offset", value: offset.toString() },
    ];

    const response = await this.makeRequest(
      RootRequest.METHOD.GET,
      this.apiRoutes.FIND_ALL + this.buildQueryParams(queryParams)
    );

    return response?.data;
  }

  async find(id: number) {
    const response = await this.makeRequest(
      RootRequest.METHOD.GET,
      this.apiRoutes.FIND + id
    );

    return response?.data;
  }
  async post(attributes: any) {
    Object.entries(attributes).forEach(([key, value]) => {
      if (value instanceof Date) {
        attributes[key] = adjustForTimezone(value);
      }
    });
    const response = await this.makeRequest(
      RootRequest.METHOD.POST,
      this.apiRoutes.POST,
      attributes
    );

    return response?.data;
  }

  async patch(id: number, attributes: any) {
    const response = await this.makeRequest(
      RootRequest.METHOD.PATCH,
      this.apiRoutes.PATCH + id,
      attributes
    );

    return response?.data;
  }

  async delete(id: number) {
    const response = await this.makeRequest(
      RootRequest.METHOD.DELETE,
      this.apiRoutes.DELETE + id
    );

    return response?.data;
  }
}
