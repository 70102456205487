import * as React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

export interface Report {
  shipperName: string;
  tripCount: string;
  billedTotal: string;
  profit: string;
}

interface Props {
  reportValues: Report[];
}

const getBackgroundColor = (mode: string) =>
  mode === "dark" ? "#2d2d2d" : "#ececec";

export default function TopClientsReportTable(props: Props) {
  const { reportValues } = props;
  const [open, setOpen] = React.useState(true);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow
            sx={{
              backgroundColor: (theme) =>
                getBackgroundColor(theme.palette.mode),
            }}
          >
            <TableCell>Loc</TableCell>
            <TableCell>Nume beneficiar</TableCell>
            <TableCell>Nr. curse efectuate</TableCell>
            <TableCell>Sumă facturată (RON)</TableCell>
            <TableCell>Profit total (RON)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reportValues.map((reportValue: Report, index) => {
            return (
              <TableRow
                key={index}
                sx={{
                  "&& > *": { borderBottom: 0 },
                  backgroundColor: (theme) =>
                    getBackgroundColor(theme.palette.mode),
                }}
                onClick={() => setOpen(!open)}
              >
                <TableCell sx={{ width: 50 }} size="small">
                  {index + 1}
                </TableCell>
                <TableCell component="th" scope="row" size="small">
                  {reportValue.shipperName}
                </TableCell>
                <TableCell align="right" size="small">
                  {reportValue.tripCount}
                </TableCell>
                <TableCell align="right" size="small">
                  {reportValue.billedTotal}
                </TableCell>
                <TableCell align="right" size="small">
                  {reportValue.profit}
                </TableCell>
              </TableRow>
            );
          })}
          <TableRow
            sx={{
              backgroundColor: (theme) =>
                getBackgroundColor(theme.palette.mode),
            }}
          >
            <TableCell align="right" />
            <TableCell align="right" />
            <TableCell align="right" />
            <TableCell align="right" />
            <TableCell align="right">
              Total clienți: {reportValues.length}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
