import * as React from "react";
import { useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import ThemeProvider from "../../contexts/ThemeContext";
import MenuBar from "../../components/menu/MenuBar";
import Typography from "@mui/material/Typography";
import { useNavigate, useParams } from "react-router-dom";
import { tripRequest } from "../../api/TripRequest";
import { Button, Divider, Paper, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import dateFormat from "dateformat";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ConstructionIcon from "@mui/icons-material/Construction";
import InsertDriveFile from "@mui/icons-material/InsertDriveFile";
import InvoicePDFDialog from "../../components/dialogs/PDF/InvoicePDFDialog";
import OrderPDFDialog from "../../components/dialogs/PDF/OrderPDFDialog";
import ReceiptIcon from "@mui/icons-material/Receipt";
import CancelIcon from "@mui/icons-material/Cancel";
import ChooseInvoicePDFTemplateDialog from "../../components/dialogs/PDF/ChooseInvoicePDFTemplateDialog";
import CancelShipperInvoiceDialog from "../../components/dialogs/CancelShipperInvoiceDialog";
import { shipperInvoiceRequest } from "../../api/ShipperInvoiceRequest";
import TripDocumentsDialog from "../../components/dialogs/TripDocumentsDialog";
import { useSelector } from "react-redux";
import { isAdminOrManagerSelector } from "../../redux/slice/selectors";

const GreyTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[600],
}));

export default function TripView() {
  const isAdminOrManager = useSelector(isAdminOrManagerSelector);
  const { id } = useParams();
  const navigate = useNavigate();

  const [tripViewData, setTripViewData] = useState<Trip>();
  const fetchTrip = (id: number) => {
    return tripRequest.find(id).then((trip) => {
      setTripViewData({ ...trip });
    });
  };
  useEffect(() => {
    fetchTrip(Number(id));
  }, []);

  const [trips, setTrips] = useState<Trip[]>();
  const fetchShipperInvoiceTrips = () => {
    if (!tripViewData?.shipperInvoice?.id) {
      return;
    }
    tripRequest
      .search(
        -1,
        0,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        tripViewData.shipperInvoice.id
      )
      .then((data) => {
        setTrips(
          data.results.map((trip: Trip) => ({
            id: trip.id,
            date: trip.date,
            cargoType: trip.cargoType,
            dispatcher: trip.dispatcher,
            loadingDate: trip.loadingDate,
            loadingAddress: trip.loadingAddress,
            loadingCity: trip.loadingCity,
            unloadingDate: trip.unloadingDate,
            unloadingAddress: trip.unloadingAddress,
            unloadingCity: trip.unloadingCity,
            carrier: trip.carrier,
            carrierCar: trip.carrierCar,
            carrierDriver: trip.carrierDriver,
            carrierInvoice: trip.carrierInvoice,
            carrierInvoiceRows: trip.carrierInvoiceRows,
            carrierPaymentTermInDays: trip.carrierPaymentTermInDays,
            shipper: trip.shipper,
            shipperBranch: trip.shipperBranch,
            shipperReferenceId: trip.shipperReferenceId,
            shipperInvoice: trip.shipperInvoice,
            shipperInvoiceRows: trip.shipperInvoiceRows,
            shipperPaymentTermInDays: trip.shipperPaymentTermInDays,
            observations: trip.observations,
            comments: trip.comments,
            status: trip.status,
            computedStatus: trip.computedStatus,
            shipperInvoiceDueDate: trip.shipperInvoiceDueDate,
            carrierInvoiceDueDate: trip.carrierInvoiceDueDate,
          }))
        );
      });
  };
  useEffect(() => {
    fetchShipperInvoiceTrips();
  }, [tripViewData]);

  const [profitNoVat, setProfitNoVat] = useState<number>(0);
  const [shipperTotal, setShipperTotal] = useState<number>(0);
  const [carrierTotal, setCarrierTotal] = useState<number>(0);

  useEffect(() => {
    let shipperInvoiceRowsTotal: number = 0;
    let carrierInvoiceRowsTotal: number = 0;
    tripViewData?.shipperInvoiceRows?.map((invoiceRow) => {
      shipperInvoiceRowsTotal +=
        Number(invoiceRow.value) * Number(invoiceRow.distanceKm);
    });
    tripViewData?.carrierInvoiceRows?.map((invoiceRow) => {
      carrierInvoiceRowsTotal +=
        Number(invoiceRow.value) * Number(invoiceRow.distanceKm);
    });
    setProfitNoVat(shipperInvoiceRowsTotal - carrierInvoiceRowsTotal);
    setShipperTotal(shipperInvoiceRowsTotal);
    setCarrierTotal(carrierInvoiceRowsTotal);
  }, [tripViewData?.carrierInvoiceRows, tripViewData?.shipperInvoiceRows]);

  const [openInvoicePDFDialog, setOpenInvoicePDFDialog] = React.useState(false);
  const [uploadShipperInvoice, setUploadShipperInvoice] = React.useState(false);
  const handleOpenInvoicePDFDialog = () => {
    setOpenInvoicePDFDialog(true);
  };

  const [openOrderPDFDialog, setOpenOrderPDFDialog] = React.useState(false);
  const handleOpenOrderPDFDialog = () => {
    setOpenOrderPDFDialog(true);
  };

  const [
    openChooseInvoicePDFTemplateDialog,
    setOpenChooseInvoicePDFTemplateDialog,
  ] = React.useState(false);
  const handleOpenChooseInvoicePDFTemplateDialog = () => {
    setUploadShipperInvoice(true);
    setOpenChooseInvoicePDFTemplateDialog(true);
  };

  const [openTripDocumentsDialog, setOpenTripDocumentsDialog] =
    React.useState<boolean>(false);

  const isMinCompleted =
    tripViewData?.computedStatus === "Completed" ||
    tripViewData?.computedStatus === "Confirmed" ||
    tripViewData?.computedStatus === "Billed" ||
    tripViewData?.computedStatus === "Paid" ||
    tripViewData?.computedStatus === "Blocked";

  const [invoiceTemplate, setInvoiceTemplate] = useState("");

  const [shipperInvoiceToBeDeleted, setShipperInvoiceToBeDeleted] =
    React.useState<number | null>(null);
  const handleOpenCancelShipperInvoiceDialog = (id: number) => {
    setShipperInvoiceToBeDeleted(id);
  };
  const handleCloseCancelShipperInvoiceDialog = () => {
    setShipperInvoiceToBeDeleted(null);
  };
  const openCancelShipperInvoiceDialog = Boolean(shipperInvoiceToBeDeleted);

  return (
    <ThemeProvider>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <MenuBar />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="md" sx={{ mt: 3, mb: 3 }}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <Tooltip title="Înapoi" placement="bottom">
                  <IconButton
                    onClick={() => {
                      navigate(-1);
                    }}
                    color="primary"
                  >
                    <ArrowBackIcon fontSize="medium" />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={1} alignItems="center">
                  <Grid item>
                    <Button
                      size="small"
                      variant="contained"
                      color="error"
                      onClick={() => {
                        tripRequest
                          .toggleReceptionProblemTrip(Number(id))
                          .then(() => fetchTrip(Number(id)));
                      }}
                    >
                      <ConstructionIcon fontSize="small" sx={{ mr: 1 }} />
                      {tripViewData?.hasReceptionProblem
                        ? "Set Probleme recepție OFF"
                        : "Set Probleme recepție ON"}
                    </Button>
                  </Grid>
                  {isMinCompleted && (
                    <Grid item>
                      <Button
                        size="small"
                        variant="contained"
                        onClick={() => {
                          handleOpenOrderPDFDialog();
                        }}
                      >
                        <PictureAsPdfIcon fontSize="small" sx={{ mr: 1 }} />
                        PDF&nbsp;comandă
                      </Button>
                    </Grid>
                  )}
                  {tripViewData?.status === "Billed" && (
                    <>
                      <Grid item>
                        <Button
                          size="small"
                          variant="contained"
                          onClick={() => {
                            if (
                              tripViewData?.shipperInvoice?.metadata
                                ?.isGenerated
                            ) {
                              setInvoiceTemplate(
                                tripViewData?.shipperInvoice?.metadata
                                  ?.generatedInvoiceType
                              );
                              setOpenInvoicePDFDialog(true);
                            } else {
                              handleOpenChooseInvoicePDFTemplateDialog();
                            }
                          }}
                        >
                          <ReceiptIcon fontSize="small" sx={{ mr: 1 }} />
                          PDF&nbsp;factură
                        </Button>
                      </Grid>
                      {isAdminOrManager && (
                        <Grid item>
                          <Button
                            size="small"
                            variant="contained"
                            color="error"
                            onClick={() => {
                              handleOpenCancelShipperInvoiceDialog(
                                tripViewData?.shipperInvoice?.id || 0
                              );
                            }}
                          >
                            <CancelIcon fontSize="small" sx={{ mr: 1 }} />
                            Anulează&nbsp;factura
                          </Button>
                        </Grid>
                      )}
                    </>
                  )}
                  <Grid item>
                    <Button
                      size="small"
                      variant="contained"
                      color="warning"
                      onClick={() => {
                        setOpenTripDocumentsDialog(true);
                      }}
                    >
                      <InsertDriveFile fontSize="small" sx={{ mr: 1 }} />
                      Documente
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Paper sx={{ paddingTop: 1, paddingBottom: 1 }}>
                  <Grid container direction="column" spacing={1}>
                    <Grid item>
                      <Typography
                        variant="h6"
                        sx={{ paddingLeft: 2, paddingRight: 2 }}
                      >
                        {tripViewData?.id ? `Comanda #${tripViewData.id}` : "-"}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Divider />
                    </Grid>
                    <Grid
                      item
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      sx={{ paddingLeft: 2, paddingRight: 2 }}
                    >
                      <Grid
                        item
                        xs={12}
                        lg={3}
                        sx={{ paddingLeft: 2, paddingRight: 2 }}
                      >
                        <Typography>Dată</Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        lg={9}
                        sx={{ paddingLeft: 2, paddingRight: 2 }}
                      >
                        <GreyTypography>
                          {tripViewData?.date
                            ? `${dateFormat(tripViewData.date, "dd/mm/yyyy")}`
                            : "-"}
                        </GreyTypography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Divider />
                    </Grid>
                    <Grid
                      item
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      sx={{ paddingLeft: 2, paddingRight: 2 }}
                    >
                      <Grid
                        item
                        xs={12}
                        lg={3}
                        sx={{ paddingLeft: 2, paddingRight: 2 }}
                      >
                        <Typography variant="body1">Tip&nbsp;marfă</Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        lg={9}
                        sx={{ paddingLeft: 2, paddingRight: 2 }}
                      >
                        <GreyTypography variant="body1">
                          {tripViewData?.cargoType
                            ? `${tripViewData.cargoType.name}`
                            : "-"}
                        </GreyTypography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Divider />
                    </Grid>
                    <Grid
                      item
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      sx={{ paddingLeft: 2, paddingRight: 2 }}
                    >
                      <Grid
                        item
                        xs={12}
                        lg={3}
                        sx={{ paddingLeft: 2, paddingRight: 2 }}
                      >
                        <Typography variant="body1">Dispecer</Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        lg={9}
                        sx={{ paddingLeft: 2, paddingRight: 2 }}
                      >
                        <GreyTypography variant="body1">
                          {tripViewData?.dispatcher
                            ? `${tripViewData.dispatcher.lastName} ${tripViewData.dispatcher.firstName}`
                            : "-"}
                        </GreyTypography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth="md" sx={{ mt: 3, mb: 3 }}>
            <Paper sx={{ paddingTop: 1, paddingBottom: 1 }}>
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  <Typography
                    variant="h6"
                    sx={{ paddingLeft: 2, paddingRight: 2 }}
                  >
                    Încărcare
                  </Typography>
                </Grid>
                <Grid item>
                  <Divider />
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  sx={{ paddingLeft: 2, paddingRight: 2 }}
                >
                  <Grid
                    item
                    xs={12}
                    lg={3}
                    sx={{ paddingLeft: 2, paddingRight: 2 }}
                  >
                    <Typography variant="body1">Date&nbsp;încărcare</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={9}
                    sx={{ paddingLeft: 2, paddingRight: 2 }}
                  >
                    <GreyTypography variant="body1">
                      {tripViewData?.loadingDate
                        ? `${dateFormat(
                            tripViewData.loadingDate,
                            "dd/mm/yyyy"
                          )}`
                        : "-"}
                    </GreyTypography>
                    <GreyTypography variant="body1">
                      {tripViewData?.loadingAddress
                        ? `${tripViewData.loadingAddress}`
                        : "-"}
                    </GreyTypography>
                    <GreyTypography variant="body1">
                      {tripViewData?.loadingCity
                        ? `${tripViewData.loadingCity.name} (${tripViewData.loadingCity.county?.name}), ${tripViewData.loadingCity.county?.country?.name}`
                        : "-"}
                    </GreyTypography>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Container>
          <Container maxWidth="md" sx={{ mt: 3, mb: 3 }}>
            <Paper sx={{ paddingTop: 1, paddingBottom: 1 }}>
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  <Typography
                    variant="h6"
                    sx={{ paddingLeft: 2, paddingRight: 2 }}
                  >
                    Puncte intermediare
                  </Typography>
                </Grid>
                <Grid item>
                  <Divider />
                </Grid>
                {tripViewData?.intermediaryCities?.length ? (
                  tripViewData.intermediaryCities.map(
                    (intermediaryCity, index) => {
                      return (
                        <Grid
                          item
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                          sx={{ paddingLeft: 2, paddingRight: 2 }}
                        >
                          <Grid
                            item
                            xs={12}
                            lg={3}
                            sx={{ paddingLeft: 2, paddingRight: 2 }}
                          >
                            <Typography variant="body1">
                              {`${index + 1}.`}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            lg={9}
                            sx={{ paddingLeft: 2, paddingRight: 2 }}
                          >
                            <GreyTypography variant="body1">
                              {`${
                                intermediaryCity.address
                                  ? `${intermediaryCity.address}, `
                                  : ""
                              }${intermediaryCity.name} (${
                                intermediaryCity.county?.name
                              }), ${intermediaryCity.county?.country?.name}`}
                            </GreyTypography>
                          </Grid>
                        </Grid>
                      );
                    }
                  )
                ) : (
                  <Grid item>
                    <Typography
                      variant="body1"
                      sx={{ paddingLeft: 2, paddingRight: 2 }}
                    >
                      -
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Container>
          <Container maxWidth="md" sx={{ mt: 3, mb: 3 }}>
            <Paper sx={{ paddingTop: 1, paddingBottom: 1 }}>
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  <Typography
                    variant="h6"
                    sx={{ paddingLeft: 2, paddingRight: 2 }}
                  >
                    Descărcare
                  </Typography>
                </Grid>
                <Grid item>
                  <Divider />
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  sx={{ paddingLeft: 2, paddingRight: 2 }}
                >
                  <Grid
                    item
                    xs={12}
                    lg={3}
                    sx={{ paddingLeft: 2, paddingRight: 2 }}
                  >
                    <Typography variant="body1">
                      Date&nbsp;descărcare
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={9}
                    sx={{ paddingLeft: 2, paddingRight: 2 }}
                  >
                    <GreyTypography variant="body1">
                      {tripViewData?.unloadingDate
                        ? `${dateFormat(
                            tripViewData.unloadingDate,
                            "dd/mm/yyyy"
                          )}`
                        : "-"}
                    </GreyTypography>
                    <GreyTypography variant="body1">
                      {tripViewData?.unloadingAddress
                        ? `${tripViewData.unloadingAddress}`
                        : "-"}
                    </GreyTypography>
                    <GreyTypography variant="body1">
                      {tripViewData?.unloadingCity
                        ? `${tripViewData.unloadingCity.name} (${tripViewData.unloadingCity.county?.name}), ${tripViewData.unloadingCity.county?.country?.name}`
                        : "-"}
                    </GreyTypography>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Container>
          <Container maxWidth="md" sx={{ mt: 3, mb: 3 }}>
            <Paper sx={{ paddingTop: 1, paddingBottom: 1 }}>
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  <Typography
                    variant="h6"
                    sx={{ paddingLeft: 2, paddingRight: 2 }}
                  >
                    Beneficiar
                  </Typography>
                </Grid>
                <Grid item>
                  <Divider />
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  sx={{ paddingLeft: 2, paddingRight: 2 }}
                >
                  <Grid
                    item
                    xs={12}
                    lg={3}
                    sx={{ paddingLeft: 2, paddingRight: 2 }}
                  >
                    <Typography variant="body1">Nume</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={9}
                    sx={{ paddingLeft: 2, paddingRight: 2 }}
                  >
                    <GreyTypography variant="body1">
                      {tripViewData?.shipper?.name ?? "-"}
                    </GreyTypography>
                  </Grid>
                </Grid>
                <Grid item>
                  <Divider />
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  sx={{ paddingLeft: 2, paddingRight: 2 }}
                >
                  <Grid
                    item
                    xs={12}
                    lg={3}
                    sx={{ paddingLeft: 2, paddingRight: 2 }}
                  >
                    <Typography variant="body1">
                      Punct&nbsp;de&nbsp;lucru
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={9}
                    sx={{ paddingLeft: 2, paddingRight: 2 }}
                  >
                    <GreyTypography variant="body1">
                      {tripViewData?.shipperBranch
                        ? `${tripViewData.shipperBranch.name}`
                        : "-"}
                    </GreyTypography>
                  </Grid>
                </Grid>
                <Grid item>
                  <Divider />
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  sx={{ paddingLeft: 2, paddingRight: 2 }}
                >
                  <Grid
                    item
                    xs={12}
                    lg={3}
                    sx={{ paddingLeft: 2, paddingRight: 2 }}
                  >
                    <Typography variant="body1">Referință</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={9}
                    sx={{ paddingLeft: 2, paddingRight: 2 }}
                  >
                    <GreyTypography variant="body1">
                      {tripViewData?.shipperReferenceId
                        ? `${tripViewData.shipperReferenceId}`
                        : "-"}
                    </GreyTypography>
                  </Grid>
                </Grid>
                <Grid item>
                  <Divider />
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  sx={{ paddingLeft: 2, paddingRight: 2 }}
                >
                  <Grid
                    item
                    xs={12}
                    lg={3}
                    sx={{ paddingLeft: 2, paddingRight: 2 }}
                  >
                    <Typography variant="body1">
                      Termen&nbsp;de&nbsp;plată
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={9}
                    sx={{ paddingLeft: 2, paddingRight: 2 }}
                  >
                    <GreyTypography variant="body1">
                      {tripViewData?.shipperPaymentTermInDays
                        ? `${tripViewData?.shipperPaymentTermInDays} zile calendaristice`
                        : tripViewData?.shipperPaymentTermInDays === 0
                        ? "0 zile calendaristice"
                        : "-"}
                    </GreyTypography>
                  </Grid>
                </Grid>
                <Grid item>
                  <Divider />
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  sx={{ paddingLeft: 2, paddingRight: 2 }}
                >
                  <Grid
                    item
                    xs={12}
                    lg={3}
                    sx={{ paddingLeft: 2, paddingRight: 2 }}
                  >
                    <Typography variant="body1">Venit</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={9}
                    sx={{ paddingLeft: 2, paddingRight: 2 }}
                  >
                    <GreyTypography variant="body1">
                      {`${shipperTotal.toFixed(2)} RON`}
                    </GreyTypography>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Container>
          <Container maxWidth="md" sx={{ mt: 3, mb: 3 }}>
            <Paper sx={{ paddingTop: 1, paddingBottom: 1 }}>
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  <Typography
                    variant="h6"
                    sx={{ paddingLeft: 2, paddingRight: 2 }}
                  >
                    Transportator
                  </Typography>
                </Grid>
                <Grid item>
                  <Divider />
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  sx={{ paddingLeft: 2, paddingRight: 2 }}
                >
                  <Grid
                    item
                    xs={12}
                    lg={3}
                    sx={{ paddingLeft: 2, paddingRight: 2 }}
                  >
                    <Typography variant="body1">Nume</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={9}
                    sx={{ paddingLeft: 2, paddingRight: 2 }}
                  >
                    <GreyTypography variant="body1">
                      {tripViewData?.carrier
                        ? `${tripViewData.carrier.name}`
                        : "-"}
                    </GreyTypography>
                  </Grid>
                </Grid>
                <Grid item>
                  <Divider />
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  sx={{ paddingLeft: 2, paddingRight: 2 }}
                >
                  <Grid
                    item
                    xs={12}
                    lg={3}
                    sx={{ paddingLeft: 2, paddingRight: 2 }}
                  >
                    <Typography variant="body1">Mașină</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={9}
                    sx={{ paddingLeft: 2, paddingRight: 2 }}
                  >
                    <GreyTypography variant="body1">
                      {tripViewData?.carrierCar
                        ? `${tripViewData.carrierCar.vehicleRegistrationPlate}`
                        : "-"}
                    </GreyTypography>
                  </Grid>
                </Grid>
                <Grid item>
                  <Divider />
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  sx={{ paddingLeft: 2, paddingRight: 2 }}
                >
                  <Grid
                    item
                    xs={12}
                    lg={3}
                    sx={{ paddingLeft: 2, paddingRight: 2 }}
                  >
                    <Typography variant="body1">Șofer</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={9}
                    sx={{ paddingLeft: 2, paddingRight: 2 }}
                  >
                    <GreyTypography variant="body1">
                      {tripViewData?.carrierDriver
                        ? `${tripViewData.carrierDriver.name}`
                        : "-"}
                    </GreyTypography>
                  </Grid>
                </Grid>
                <Grid item>
                  <Divider />
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  sx={{ paddingLeft: 2, paddingRight: 2 }}
                >
                  <Grid
                    item
                    xs={12}
                    lg={3}
                    sx={{ paddingLeft: 2, paddingRight: 2 }}
                  >
                    <Typography variant="body1">
                      Termen&nbsp;de&nbsp;plată
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={9}
                    sx={{ paddingLeft: 2, paddingRight: 2 }}
                  >
                    <GreyTypography variant="body1">
                      {tripViewData?.carrierPaymentTermInDays
                        ? `${tripViewData?.carrierPaymentTermInDays} zile calendaristice`
                        : tripViewData?.carrierPaymentTermInDays === 0
                        ? "0 zile calendaristice"
                        : "-"}
                    </GreyTypography>
                  </Grid>
                </Grid>
                <Grid item>
                  <Divider />
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  sx={{ paddingLeft: 2, paddingRight: 2 }}
                >
                  <Grid
                    item
                    xs={12}
                    lg={3}
                    sx={{ paddingLeft: 2, paddingRight: 2 }}
                  >
                    <Typography variant="body1">Cheltuială</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={9}
                    sx={{ paddingLeft: 2, paddingRight: 2 }}
                  >
                    <GreyTypography variant="body1">
                      {`${carrierTotal.toFixed(2)} RON`}
                    </GreyTypography>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Container>
          <Container maxWidth="md" sx={{ mt: 3, mb: 3 }}>
            <Paper sx={{ paddingTop: 1, paddingBottom: 1 }}>
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  <Typography
                    variant="h6"
                    sx={{ paddingLeft: 2, paddingRight: 2 }}
                  >
                    Factură&nbsp;beneficiar
                  </Typography>
                </Grid>
                <Grid item>
                  <Divider />
                </Grid>
                {tripViewData?.shipperInvoice ? (
                  <>
                    <Grid
                      item
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      sx={{ paddingLeft: 2, paddingRight: 2 }}
                    >
                      <Grid
                        item
                        xs={12}
                        lg={3}
                        sx={{ paddingLeft: 2, paddingRight: 2 }}
                      >
                        <Typography variant="body1">Dată</Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        lg={9}
                        sx={{ paddingLeft: 2, paddingRight: 2 }}
                      >
                        <GreyTypography variant="body1">
                          {tripViewData?.shipperInvoice
                            ? `${dateFormat(
                                tripViewData.shipperInvoice.date?.toString(),
                                "dd/mm/yyyy"
                              )}`
                            : "-"}
                        </GreyTypography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Divider />
                    </Grid>
                    <Grid
                      item
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      sx={{ paddingLeft: 2, paddingRight: 2 }}
                    >
                      <Grid
                        item
                        xs={12}
                        lg={3}
                        sx={{ paddingLeft: 2, paddingRight: 2 }}
                      >
                        <Typography variant="body1">Serie</Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        lg={9}
                        sx={{ paddingLeft: 2, paddingRight: 2 }}
                      >
                        <GreyTypography variant="body1">
                          {tripViewData?.shipperInvoice
                            ? `${tripViewData.shipperInvoice.invoiceSeries?.name}`
                            : "-"}
                        </GreyTypography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Divider />
                    </Grid>
                    <Grid
                      item
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      sx={{ paddingLeft: 2, paddingRight: 2 }}
                    >
                      <Grid
                        item
                        xs={12}
                        lg={3}
                        sx={{ paddingLeft: 2, paddingRight: 2 }}
                      >
                        <Typography variant="body1">Număr</Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        lg={9}
                        sx={{ paddingLeft: 2, paddingRight: 2 }}
                      >
                        <GreyTypography variant="body1">
                          {tripViewData?.shipperInvoice
                            ? `${tripViewData.shipperInvoice.number}`
                            : "-"}
                        </GreyTypography>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    sx={{ paddingLeft: 2, paddingRight: 2 }}
                  >
                    <Grid
                      item
                      xs={12}
                      lg={3}
                      sx={{ paddingLeft: 2, paddingRight: 2 }}
                    >
                      <Typography variant="body1">
                        Factură&nbsp;emisă
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={9}
                      sx={{ paddingLeft: 2, paddingRight: 2 }}
                    >
                      <GreyTypography variant="body1">-</GreyTypography>
                    </Grid>
                  </Grid>
                )}
                <Grid item>
                  <Divider />
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  sx={{ paddingLeft: 2, paddingRight: 2 }}
                >
                  <Grid
                    item
                    xs={12}
                    lg={3}
                    sx={{ paddingLeft: 2, paddingRight: 2 }}
                  >
                    <Typography variant="body1">Total beneficiar</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={9}
                    sx={{ paddingLeft: 2, paddingRight: 2 }}
                  >
                    {tripViewData?.shipperInvoiceRows
                      ? tripViewData?.shipperInvoiceRows.map(
                          (shipperInvoiceRow) => {
                            return (
                              <GreyTypography variant="body1">
                                {`${shipperInvoiceRow.value} RON x ${shipperInvoiceRow.distanceKm} km`}
                              </GreyTypography>
                            );
                          }
                        )
                      : "-"}
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Container>
          <Container maxWidth="md" sx={{ mt: 3, mb: 3 }}>
            <Paper sx={{ paddingTop: 1, paddingBottom: 1 }}>
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  <Typography
                    variant="h6"
                    sx={{ paddingLeft: 2, paddingRight: 2 }}
                  >
                    Factură&nbsp;transportator
                  </Typography>
                </Grid>
                <Grid item>
                  <Divider />
                </Grid>
                {tripViewData?.carrierInvoice ? (
                  <>
                    <Grid
                      item
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      sx={{ paddingLeft: 2, paddingRight: 2 }}
                    >
                      <Grid
                        item
                        xs={12}
                        lg={3}
                        sx={{ paddingLeft: 2, paddingRight: 2 }}
                      >
                        <Typography variant="body1">Dată</Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        lg={9}
                        sx={{ paddingLeft: 2, paddingRight: 2 }}
                      >
                        <GreyTypography variant="body1">
                          {tripViewData?.carrierInvoice
                            ? `${dateFormat(
                                tripViewData.carrierInvoice.date?.toString(),
                                "dd/mm/yyyy"
                              )}`
                            : "-"}
                        </GreyTypography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Divider />
                    </Grid>
                    <Grid
                      item
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      sx={{ paddingLeft: 2, paddingRight: 2 }}
                    >
                      <Grid
                        item
                        xs={12}
                        lg={3}
                        sx={{ paddingLeft: 2, paddingRight: 2 }}
                      >
                        <Typography variant="body1">
                          Date&nbsp;factură
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        lg={9}
                        sx={{ paddingLeft: 2, paddingRight: 2 }}
                      >
                        <GreyTypography variant="body1">
                          {tripViewData?.carrierInvoice
                            ? `${tripViewData.carrierInvoice.invoiceData}`
                            : "-"}
                        </GreyTypography>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    sx={{ paddingLeft: 2, paddingRight: 2 }}
                  >
                    <Grid
                      item
                      xs={12}
                      lg={3}
                      sx={{ paddingLeft: 2, paddingRight: 2 }}
                    >
                      <Typography variant="body1">
                        Factură&nbsp;emisă
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={9}
                      sx={{ paddingLeft: 2, paddingRight: 2 }}
                    >
                      <GreyTypography variant="body1">-</GreyTypography>
                    </Grid>
                  </Grid>
                )}
                <Grid item>
                  <Divider />
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  sx={{ paddingLeft: 2, paddingRight: 2 }}
                >
                  <Grid
                    item
                    xs={12}
                    lg={3}
                    sx={{ paddingLeft: 2, paddingRight: 2 }}
                  >
                    <Typography variant="body1">Total transportator</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={9}
                    sx={{ paddingLeft: 2, paddingRight: 2 }}
                  >
                    {tripViewData?.carrierInvoiceRows
                      ? tripViewData?.carrierInvoiceRows.map(
                          (carrierInvoiceRow) => {
                            return (
                              <GreyTypography variant="body1">
                                {`${carrierInvoiceRow.value} RON x ${carrierInvoiceRow.distanceKm} km`}
                              </GreyTypography>
                            );
                          }
                        )
                      : "-"}
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Container>
          <Container maxWidth="md" sx={{ mt: 3, mb: 3 }}>
            <Paper sx={{ paddingTop: 1, paddingBottom: 1 }}>
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  <Typography
                    variant="h6"
                    sx={{ paddingLeft: 2, paddingRight: 2 }}
                  >
                    Informații&nbsp;suplimentare
                  </Typography>
                </Grid>
                <Grid item>
                  <Divider />
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  sx={{
                    paddingLeft: 2,
                    paddingRight: 2,
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    lg={3}
                    sx={{ paddingLeft: 2, paddingRight: 2 }}
                  >
                    <Typography variant="body1">Observații</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={9}
                    sx={{
                      wordBreak: "break-all",
                      whiteSpace: "pre-wrap",
                      paddingLeft: 2,
                      paddingRight: 2,
                    }}
                    // sx={{ paddingLeft: 2, paddingRight: 2 }}
                    // sx={{
                    //   wordBreak: "break-all",
                    //   whiteSpace: "pre-wrap",
                    // }}
                  >
                    <GreyTypography variant="body1">
                      {tripViewData?.observations
                        ? `${tripViewData.observations}`
                        : "-"}
                    </GreyTypography>
                  </Grid>
                </Grid>
                <Grid item>
                  <Divider />
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  sx={{
                    paddingLeft: 2,
                    paddingRight: 2,
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    lg={3}
                    sx={{ paddingLeft: 2, paddingRight: 2 }}
                  >
                    <Typography variant="body1">Comentarii</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={9}
                    sx={{
                      wordBreak: "break-all",
                      whiteSpace: "pre-wrap",
                      paddingLeft: 2,
                      paddingRight: 2,
                    }}
                    // sx={{ paddingLeft: 2, paddingRight: 2 }}
                    // sx={{
                    //   wordBreak: "break-all",
                    //   whiteSpace: "pre-wrap",
                    // }}
                  >
                    <GreyTypography variant="body1">
                      {tripViewData?.comments
                        ? `${tripViewData.comments}`
                        : "-"}
                    </GreyTypography>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Container>
        </Box>
        {openOrderPDFDialog && (
          <OrderPDFDialog
            open={openOrderPDFDialog}
            trip={tripViewData}
            onClose={() => setOpenOrderPDFDialog(false)}
          />
        )}
        {openChooseInvoicePDFTemplateDialog && (
          <ChooseInvoicePDFTemplateDialog
            open={openChooseInvoicePDFTemplateDialog}
            onClose={() => setOpenChooseInvoicePDFTemplateDialog(false)}
            trip={tripViewData}
            trips={trips}
            toGenerateInvoiceId={tripViewData?.shipperInvoice?.id}
            onTemplateSubmit={(invoiceTemplate) => {
              setInvoiceTemplate(invoiceTemplate ? invoiceTemplate : "");
              handleOpenInvoicePDFDialog();
              fetchTrip(Number(id));
            }}
          />
        )}
        {openInvoicePDFDialog && (
          <InvoicePDFDialog
            open={openInvoicePDFDialog}
            trip={tripViewData}
            trips={trips}
            invoiceTemplate={invoiceTemplate}
            onClose={() => {
              setOpenInvoicePDFDialog(false);
              setUploadShipperInvoice(false);
            }}
            uploadShipperInvoice={uploadShipperInvoice}
          />
        )}
        {openCancelShipperInvoiceDialog && (
          <CancelShipperInvoiceDialog
            open={openCancelShipperInvoiceDialog}
            onClose={handleCloseCancelShipperInvoiceDialog}
            onDelete={() => {
              if (shipperInvoiceToBeDeleted) {
                shipperInvoiceRequest
                  .cancelInvoice(shipperInvoiceToBeDeleted)
                  .then(() => fetchTrip(Number(id)))
                  .catch(() => alert("S-a produs o eroare!"));
              }
            }}
          />
        )}
        <TripDocumentsDialog
          open={openTripDocumentsDialog}
          onClose={() => setOpenTripDocumentsDialog(false)}
          trip={tripViewData}
        />
      </Box>
    </ThemeProvider>
  );
}
