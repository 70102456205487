import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Divider, Grid, IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useEffect, useState } from "react";
import { invoiceSeriesRequest } from "../../../api/InvoiceSeriesRequest";
import blockInvalidCharNumberInput from "../../../functions/blockInvalidCharNumberInput";

interface Props {
  open: boolean;
  onClose: AnyFunction;
  onInvoiceSeriesCreated?: AnyFunction;
}

export default function AddInvoiceSeriesDialog(props: Props) {
  const { open, onClose, onInvoiceSeriesCreated } = props;

  const [formValues, setFormValues] = useState<any>({
    name: null,
    intervalLowerBound: null,
    intervalUpperBound: null,
  });

  const [error, setError] = useState({
    name: false,
    nameIsDuplicate: false,
    intervalLowerBound: false,
    intervalUpperBound: false,
    boundsAreEqual: false,
    lowerBoundHigherThanUpperBound: false,
  });

  const [invoiceSeriesData, setInvoiceSeriesData] = useState<InvoiceSeries[]>(
    []
  );

  const fetchInvoiceSeries = () => {
    invoiceSeriesRequest.findAll().then((data) => {
      setInvoiceSeriesData(
        data.results.map((invoiceSeries: InvoiceSeries) => ({
          id: invoiceSeries.id,
          name: invoiceSeries.name,
          intervalLowerBound: invoiceSeries.intervalLowerBound,
          intervalUpperBound: invoiceSeries.intervalUpperBound,
          isActive: invoiceSeries.isActive,
        }))
      );
    });
  };

  useEffect(() => {
    fetchInvoiceSeries();
  }, []);

  const checkNameIsDuplicate = (invoiceSeries: InvoiceSeries) =>
    invoiceSeries.name?.toLowerCase() === formValues.name?.toLowerCase();

  const handleErrors = () => {
    if (!formValues.name) {
      setError((prev: any) => ({
        ...prev,
        name: true,
      }));
    } else if (formValues.name) {
      setError((prev: any) => ({
        ...prev,
        name: false,
      }));
    }
    if (formValues.name) {
      setError((prev: any) => ({
        ...prev,
        nameIsDuplicate: invoiceSeriesData.some(checkNameIsDuplicate),
      }));
    }
    if (!formValues.intervalLowerBound) {
      setError((prev: any) => ({
        ...prev,
        intervalLowerBound: true,
      }));
    } else if (formValues.intervalLowerBound) {
      setError((prev: any) => ({
        ...prev,
        intervalLowerBound: false,
      }));
    }
    if (!formValues.intervalUpperBound) {
      setError((prev: any) => ({
        ...prev,
        intervalUpperBound: true,
      }));
    } else if (formValues.intervalUpperBound) {
      setError((prev: any) => ({
        ...prev,
        intervalUpperBound: false,
      }));
    }
    if (formValues.intervalLowerBound === formValues.intervalUpperBound) {
      setError((prev: any) => ({
        ...prev,
        boundsAreEqual: true,
      }));
    } else if (
      formValues.intervalLowerBound !== formValues.intervalUpperBound
    ) {
      setError((prev: any) => ({
        ...prev,
        boundsAreEqual: false,
      }));
    }
    if (formValues.intervalLowerBound > formValues.intervalUpperBound) {
      setError((prev: any) => ({
        ...prev,
        lowerBoundHigherThanUpperBound: true,
      }));
    } else if (formValues.intervalLowerBound < formValues.intervalUpperBound) {
      setError((prev: any) => ({
        ...prev,
        lowerBoundHigherThanUpperBound: false,
      }));
    }
  };

  const handleSubmit = () => {
    handleErrors();
    const formIsValid =
      formValues.name &&
      !invoiceSeriesData.some(checkNameIsDuplicate) &&
      formValues.intervalLowerBound &&
      formValues.intervalUpperBound &&
      formValues.intervalLowerBound !== formValues.intervalUpperBound &&
      formValues.intervalLowerBound < formValues.intervalUpperBound;
    if (formIsValid) {
      return invoiceSeriesRequest
        .post(formValues)
        .then(() => {
          if (typeof onInvoiceSeriesCreated === "function") {
            onInvoiceSeriesCreated();
          }
        })
        .catch(() => {
          alert("S-a produs o eroare!");
        });
    }
  };

  const handleCloseAddInvoiceSeriesDialog = () => {
    onClose();
    setFormValues((prev: any) => ({
      ...prev,
      name: null,
      intervalLowerBound: null,
      intervalUpperBound: null,
    }));
    setError(() => ({
      name: false,
      nameIsDuplicate: false,
      intervalLowerBound: false,
      intervalUpperBound: false,
      boundsAreEqual: false,
      lowerBoundHigherThanUpperBound: false,
    }));
  };

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="xs">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          // sx={{ mb: -2.5 }}
        >
          <Grid item>
            <DialogTitle sx={{ pb: 0 }}>Adăugare serie</DialogTitle>
          </Grid>
          <Grid item sx={{ mr: 2, pt: 2 }}>
            <IconButton
              onClick={handleCloseAddInvoiceSeriesDialog}
              tabIndex={-1}
            >
              <ClearIcon />
            </IconButton>
          </Grid>
        </Grid>
        <DialogContent>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                size="small"
                label="Nume"
                fullWidth
                error={error.name || error.nameIsDuplicate}
                helperText={
                  error.name
                    ? "Câmp obligatoriu!"
                    : error.nameIsDuplicate && "Nume deja existent!"
                }
                onChange={(event) =>
                  setFormValues((prev: any) => ({
                    ...prev,
                    name: event.target.value.toUpperCase(),
                  }))
                }
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit()
                      ?.then(handleCloseAddInvoiceSeriesDialog)
                      .then(fetchInvoiceSeries);
                  }
                }}
                inputProps={{ style: { textTransform: "uppercase" } }}
              ></TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                size="small"
                label="Valoare inferioară"
                fullWidth
                type="number"
                error={
                  error.intervalLowerBound ||
                  error.boundsAreEqual ||
                  error.lowerBoundHigherThanUpperBound
                }
                helperText={
                  error.intervalLowerBound
                    ? "Câmp obligatoriu!"
                    : error.boundsAreEqual
                    ? "Valorile nu pot fi egale!"
                    : error.lowerBoundHigherThanUpperBound &&
                      "Valoarea inferioară trebuie să fie mai mică decât valoarea superioară!"
                }
                onKeyDown={blockInvalidCharNumberInput}
                inputProps={{
                  min: 0,
                  max: formValues?.intervalUpperBound ?? null,
                }}
                onChange={(event) =>
                  setFormValues((prev: any) => ({
                    ...prev,
                    intervalLowerBound: +event.target.value,
                  }))
                }
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit()
                      ?.then(handleCloseAddInvoiceSeriesDialog)
                      .then(fetchInvoiceSeries);
                  }
                }}
              ></TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                size="small"
                label="Valoare superioară"
                fullWidth
                type="number"
                error={
                  error.intervalUpperBound ||
                  error.boundsAreEqual ||
                  error.lowerBoundHigherThanUpperBound
                }
                helperText={
                  error.intervalUpperBound
                    ? "Câmp obligatoriu!"
                    : error.boundsAreEqual
                    ? "Valorile nu pot fi egale!"
                    : error.lowerBoundHigherThanUpperBound &&
                      "Valoarea superioară trebuie să fie mai mare decât valoarea inferioară!"
                }
                onKeyDown={blockInvalidCharNumberInput}
                inputProps={{ min: formValues?.intervalLowerBound ?? 0 }}
                onChange={(event) =>
                  setFormValues((prev: any) => ({
                    ...prev,
                    intervalUpperBound: +event.target.value,
                  }))
                }
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit()
                      ?.then(handleCloseAddInvoiceSeriesDialog)
                      .then(fetchInvoiceSeries);
                  }
                }}
              ></TextField>
            </Grid>
          </Grid>
          <Divider sx={{ mb: 2, opacity: 0 }} />
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={12} md={6}>
              <Button
                fullWidth
                variant="contained"
                onClick={() => {
                  handleSubmit()
                    ?.then(handleCloseAddInvoiceSeriesDialog)
                    .then(fetchInvoiceSeries);
                }}
              >
                Salvează
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                fullWidth
                variant="outlined"
                onClick={handleCloseAddInvoiceSeriesDialog}
              >
                Renunță
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
