export class LocalStorage {
  static KEYS = {
    DARK_MODE: "dark_mode",
    SIDEBAR_IS_OPEN: "sidebar_is_open",
    MANAGEMENT_IS_OPEN: "management_is_open",
    REPORTS_IS_OPEN: "reports_is_open",
  };

  static get innerStorage() {
    return localStorage;
  }

  static get(key: any) {
    const localStorageItem = localStorage.getItem(key);
    if (localStorageItem === "true") {
      return true;
    }
    if (localStorageItem === "false") {
      return false;
    }
    return localStorageItem;
  }

  static set(key: any, value: any) {
    localStorage.setItem(key, value);
  }

  static unset(key: any) {
    localStorage.removeItem(key);
  }
}
