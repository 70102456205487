import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { Box, Button, darken, Grid, lighten, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddInvoiceSeriesDialog from "../../dialogs/invoiceSeries/AddInvoiceSeriesDialog";
import EditInvoiceSeriesDialog from "../../dialogs/invoiceSeries/EditInvoiceSeriesDialog";
import DeleteRowDialog from "../../dialogs/DeleteRowDialog";
import { dataGridRoTextConstants } from "../../../constants/dataGridRoTextConstants";
import {
  getInterval,
  getName,
} from "../../../functions/valueGetterFunctions/invoiceSeriesGetterFunctions";
import { invoiceSeriesRequest } from "../../../api/InvoiceSeriesRequest";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { useDispatch, useSelector } from "react-redux";
import {
  isAdminOrManagerSelector,
  userActiveInvoiceSeriesIdSelector,
} from "../../../redux/slice/selectors";
import { me } from "../../../redux/slice/auth";
import store from "../../../redux/store";

const getBackgroundColor = (color: string, mode: string) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

export default function InvoiceSeriesDataGrid() {
  const isAdminOrManager = useSelector(isAdminOrManagerSelector);
  const activeInvoiceSeriesId = useSelector(userActiveInvoiceSeriesIdSelector);
  const dispatch = useDispatch<typeof store.dispatch>();

  console.log(activeInvoiceSeriesId);
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 90, flex: 1, hide: true },
    {
      field: "name",
      headerName: "Nume",
      width: 150,
      flex: 1,
      valueGetter: getName,
    },
    {
      field: "interval",
      headerName: "Interval",
      width: 150,
      flex: 1,
      valueGetter: getInterval,
    },
    {
      field: "isActive",
      headerName: "Activ",
      flex: 1,
      // minWidth: 75,
      // maxWidth: 75,
      filterable: false,
      renderCell: (params: GridRenderCellParams) => {
        return params.row.isActive ? (
          <CheckCircleOutlineIcon
            fontSize="small"
            color="success"
            sx={{ ml: 0.5 }}
          />
        ) : (
          <HighlightOffRoundedIcon
            fontSize="small"
            color="error"
            sx={{ ml: 0.5 }}
          />
        );
      },
    },
  ];

  isAdminOrManager &&
    columns.push({
      field: "actiuni",
      headerName: "Acțiuni",
      minWidth: 125,
      maxWidth: 125,
      renderCell: ({ row: invoiceSeries }) => {
        const buttons = [
          <IconButton>
            <DoneOutlineIcon
              fontSize="small"
              color="success"
              onClick={() => {
                handleMakeActive(invoiceSeries)?.then(() => dispatch(me()));
              }}
            />
          </IconButton>,
          <IconButton>
            <EditIcon
              fontSize="small"
              color="warning"
              onClick={() => {
                handleOpenEditInvoiceSeriesDialog(invoiceSeries);
              }}
            />
          </IconButton>,
          <IconButton>
            <DeleteIcon
              fontSize="small"
              color="error"
              onClick={() => handleOpenDeleteRowDialog(invoiceSeries.id)}
            />
          </IconButton>,
        ];

        return buttons;
      },
    });

  const [pageSize, setPageSize] = React.useState<number>(10);
  const [rows, setRows] = useState<InvoiceSeries[]>([]);

  const fetchInvoiceSeries = () => {
    invoiceSeriesRequest.findAll().then((data) => {
      setRows(
        data.results.map((invoiceSeries: InvoiceSeries) => ({
          id: invoiceSeries.id,
          name: invoiceSeries.name,
          intervalLowerBound: invoiceSeries.intervalLowerBound,
          intervalUpperBound: invoiceSeries.intervalUpperBound,
          isActive: invoiceSeries.id === activeInvoiceSeriesId,
        }))
      );
    });
  };

  useEffect(() => {
    fetchInvoiceSeries();
  }, [activeInvoiceSeriesId]);

  const [selectedInvoiceSeriesForEdit, setSelectedInvoiceSeriesForEdit] =
    useState<InvoiceSeries | undefined>(undefined);

  const [openAddInvoiceSeriesDialog, setOpenAddInvoiceSeriesDialog] =
    React.useState(false);
  const handleOpenAddInvoiceSeriesDialog = () => {
    setOpenAddInvoiceSeriesDialog(true);
  };

  const handleMakeActive = (invoiceSeries: InvoiceSeries) => {
    if (invoiceSeries && invoiceSeries.id) {
      return invoiceSeriesRequest.makeActive(invoiceSeries.id);
    }
  };

  const [openEditInvoiceSeriesDialog, setOpenEditInvoiceSeriesDialog] =
    React.useState(false);
  const handleOpenEditInvoiceSeriesDialog = (invoiceSeries: InvoiceSeries) => {
    setOpenEditInvoiceSeriesDialog(true);
    setSelectedInvoiceSeriesForEdit(invoiceSeries);
  };

  const [toBeDeleted, setToBeDeleted] = React.useState<number | null>(null);
  const handleOpenDeleteRowDialog = (id: any) => {
    setToBeDeleted(id);
  };
  const handleCloseDeleteRowDialog = () => {
    setToBeDeleted(null);
  };
  const openDeleteRowDialog = Boolean(toBeDeleted);

  const [filterButtonEl, setFilterButtonEl] = React.useState(null);

  const CustomToolbar = React.useCallback(() => {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <Grid
            item
            sx={{
              ml: 1,
            }}
          >
            <GridToolbarQuickFilter />
          </Grid>
          <Grid
            item
            sx={{
              mr: 0.3,
            }}
          >
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <Button size="small" onClick={handleOpenAddInvoiceSeriesDialog}>
                  <Stack direction="row">
                    <AddIcon
                      fontSize="small"
                      sx={{
                        mr: 1,
                      }}
                    />
                    Adaugă serie
                  </Stack>
                </Button>
              </Grid>
              <Grid item>
                {/* @ts-ignore */}
                <GridToolbarFilterButton ref={setFilterButtonEl} />
              </Grid>
              <Grid item>
                {/* @ts-ignore */}
                <GridToolbarColumnsButton ref={setFilterButtonEl} />
              </Grid>
              <Grid item>
                {/* @ts-ignore */}
                <GridToolbarDensitySelector ref={setFilterButtonEl} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </GridToolbarContainer>
    );
  }, []);

  return (
    <Box
      sx={{
        height: 400,
        width: "100%",
        "& .super-app-theme--Open": {
          bgcolor: (theme) =>
            getBackgroundColor(theme.palette.info.main, theme.palette.mode),
          "&:hover": {
            bgcolor: (theme) =>
              getHoverBackgroundColor(
                theme.palette.info.main,
                theme.palette.mode
              ),
          },
        },
        "& .super-app-theme--Filled": {
          bgcolor: (theme) =>
            getBackgroundColor(theme.palette.success.main, theme.palette.mode),
          "&:hover": {
            bgcolor: (theme) =>
              getHoverBackgroundColor(
                theme.palette.success.main,
                theme.palette.mode
              ),
          },
        },
        "& .super-app-theme--PartiallyFilled": {
          bgcolor: (theme) =>
            getBackgroundColor(theme.palette.warning.main, theme.palette.mode),
          "&:hover": {
            bgcolor: (theme) =>
              getHoverBackgroundColor(
                theme.palette.warning.main,
                theme.palette.mode
              ),
          },
        },
        "& .super-app-theme--Rejected": {
          bgcolor: (theme) =>
            getBackgroundColor(theme.palette.error.main, theme.palette.mode),
          "&:hover": {
            bgcolor: (theme) =>
              getHoverBackgroundColor(
                theme.palette.error.main,
                theme.palette.mode
              ),
          },
        },
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="flex-end"
        spacing={1}
      >
        <Grid item xs={12}>
          <div style={{ width: "100%" }}>
            <div style={{ display: "flex", height: "100%" }}>
              <div style={{ flexGrow: 1 }}>
                <DataGrid
                  rows={rows}
                  getRowId={(row) => row.id}
                  columns={columns}
                  initialState={{
                    sorting: {
                      sortModel: [{ field: "id", sort: "desc" }],
                    },
                  }}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  autoHeight
                  pagination
                  disableSelectionOnClick
                  checkboxSelection={false}
                  experimentalFeatures={{ newEditingApi: true }}
                  localeText={dataGridRoTextConstants}
                  components={{ Toolbar: CustomToolbar }}
                  componentsProps={{
                    pagination: {
                      labelRowsPerPage: "Număr de înregistrări pe pagină",
                    },
                    panel: {
                      anchorEl: filterButtonEl,
                      placement: "bottom-end",
                    },
                  }}
                  getRowClassName={(params) =>
                    `super-app-theme--${params.row.status}`
                  }
                  sx={{
                    "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within":
                      {
                        outline: "none",
                      },

                    "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus":
                      {
                        outline: "none",
                      },
                  }}
                />
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      {openAddInvoiceSeriesDialog && (
        <AddInvoiceSeriesDialog
          open={openAddInvoiceSeriesDialog}
          onInvoiceSeriesCreated={fetchInvoiceSeries}
          onClose={() => {
            setOpenAddInvoiceSeriesDialog(false);
          }}
        />
      )}
      {openEditInvoiceSeriesDialog && (
        <EditInvoiceSeriesDialog
          open={openEditInvoiceSeriesDialog}
          invoiceSeries={selectedInvoiceSeriesForEdit}
          onInvoiceSeriesEdited={fetchInvoiceSeries}
          onClose={() => {
            setOpenEditInvoiceSeriesDialog(false);
            setSelectedInvoiceSeriesForEdit(undefined);
          }}
        />
      )}
      {openDeleteRowDialog && (
        <DeleteRowDialog
          open={openDeleteRowDialog}
          onClose={handleCloseDeleteRowDialog}
          onDelete={() => {
            if (toBeDeleted) {
              invoiceSeriesRequest
                .delete(toBeDeleted)
                .then(fetchInvoiceSeries)
                .catch(() =>
                  alert(
                    "S-a produs o eroare! Este posibil ca înregistrarea aleasă să fie asociată cu alte tipuri de înregistrări și nu este permisă ștergerea!"
                  )
                );
            }
          }}
        />
      )}
    </Box>
  );
}
