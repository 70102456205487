import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import ShipperInvoiceAccordion from "../accordions/ShipperInvoiceAccordion";
import { useEffect, useState } from "react";
import { shipperInvoiceRequest } from "../../api/ShipperInvoiceRequest";
import { tripRequest } from "../../api/TripRequest";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

export interface OnTripsBilledMetadata {
  mailToShipperInvoice: ShipperInvoice;
  isGenerating: boolean;
}

interface Props {
  open: boolean;
  onClose: AnyFunction;
  selectedTrips: Trip[];
  onTripsBilled(metadata?: OnTripsBilledMetadata): void;
  onBillInPDF: AnyFunction;
}

interface NextInvoiceData {
  invoiceSeries: string;
  number: number;
}

export default function ShipperInvoiceDialog(props: Props) {
  const { open, onClose, selectedTrips, onTripsBilled, onBillInPDF } = props;

  const [date, setDate] = useState<Date | null>(new Date());
  const [sendDocumentsAsZipToBeneficiary, setSendDocumentsAsZipToBeneficiary] =
    useState(true);

  const handleBill = (isGenerating?: boolean) => {
    return tripRequest
      .billTrip({
        tripIds: selectedTrips.map((trip) => trip.id),
        date: date,
      })
      .then((result) => {
        if (typeof onTripsBilled === "function") {
          onTripsBilled(
            sendDocumentsAsZipToBeneficiary
              ? {
                  mailToShipperInvoice: result.shipperInvoice,
                  isGenerating: Boolean(isGenerating),
                }
              : undefined
          );
          return result.shipperInvoice;
        }
      })
      .catch((error) => {
        if (error.response.status === 422) {
          alert("Trebuie să aveți o serie factură activă pentru a factura!");
        } else {
          alert("S-a produs o eroare!");
        }
        throw error;
      });
  };

  const handleBillInPDF = () => {
    return handleBill(true).then((shipperInvoice) => {
      if (typeof onBillInPDF === "function") {
        onBillInPDF(shipperInvoice);
      }
    });
  };

  const handleCloseShipperInvoiceDialog = () => {
    onClose();
  };

  const [nextInvoiceData, setNextInvoiceData] = useState<
    NextInvoiceData | undefined
  >(undefined);

  useEffect(() => {
    shipperInvoiceRequest
      .getNextInvoiceData()
      .then((data) => {
        setNextInvoiceData(data);
      })
      .catch((e) => {
        alert(
          `Nu se poate factura! Ori există deja o factură cu numărul ${
            e.response?.data?.nextInvoiceNumber || ""
          }, ori s-a atins limita superioară a seriei active!\n\nModificați sau schimbați seria activă pentru a putea factura.`
        );
        handleCloseShipperInvoiceDialog();
      });
  }, []);

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <DialogTitle sx={{ pb: 0 }}>Emitere factură</DialogTitle>
          </Grid>
          <Grid item sx={{ mr: 2, pt: 2 }}>
            <IconButton onClick={handleCloseShipperInvoiceDialog} tabIndex={-1}>
              <ClearIcon />
            </IconButton>
          </Grid>
        </Grid>
        <DialogContent>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              {/*<DialogContentText>Factură</DialogContentText>*/}
              {/*<Divider sx={{ mb: 2 }} />*/}
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={12} md={4}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Dată"
                      value={date}
                      inputFormat="dd/MM/yyyy"
                      onChange={(newValue) => {
                        setDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          size="small"
                          fullWidth
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "zz/ll/aaaa",
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    size="small"
                    fullWidth
                    label="Serie"
                    disabled
                    value={nextInvoiceData?.invoiceSeries || ""}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    size="small"
                    fullWidth
                    label="Număr"
                    disabled
                    value={nextInvoiceData?.number || ""}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={sendDocumentsAsZipToBeneficiary}
                          onChange={(e) =>
                            setSendDocumentsAsZipToBeneficiary(e.target.checked)
                          }
                        />
                      }
                      label="Trimiți documentele pe mail clientului?"
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <ShipperInvoiceAccordion selectedTrips={selectedTrips} />
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={12} md={4}>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={() => {
                      handleBillInPDF()?.then(handleCloseShipperInvoiceDialog);
                    }}
                  >
                    Emite&nbsp;și&nbsp;generează&nbsp;PDF
                  </Button>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={() => {
                      handleBill()?.then(handleCloseShipperInvoiceDialog);
                    }}
                  >
                    Emite
                  </Button>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Button
                    fullWidth
                    variant="outlined"
                    onClick={handleCloseShipperInvoiceDialog}
                  >
                    Renunță
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
