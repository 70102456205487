import { GridValueGetterParams } from "@mui/x-data-grid";

export function getName(params: GridValueGetterParams) {
  return `${params.row.name || "-"}`;
}

export function getInterval(params: GridValueGetterParams) {
  return `${params.row.intervalLowerBound || ""} - ${
    params.row.intervalUpperBound || ""
  }`;
}
