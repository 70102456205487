import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Autocomplete, Divider, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import TextField from "@mui/material/TextField";
import { userRequest } from "../../api/UserRequest";
import { carrierRequest } from "../../api/CarrierRequest";
import { shipperRequest } from "../../api/ShipperRequest";
import { useSkipFirstRun } from "../../helpers/hooks";

interface Props {
  open: boolean;
  onClose: AnyFunction;
  onFilter?: (filterData: FilterForm) => void;
}

interface FilterForm {
  startDate: Date | null;
  endDate: Date | null;
  shipperId: number | undefined;
  carrierId: number | undefined;
  dispatcherId: number | undefined;
}

export default function FiltersDialog(props: Props) {
  const { open, onClose, onFilter } = props;

  const [filterValues, setFilterValues] = useState<FilterForm>({
    startDate: null,
    endDate: null,
    dispatcherId: undefined,
    carrierId: undefined,
    shipperId: undefined,
  });

  const handleApplyFilters = () => {
    handleCloseFiltersDialog();
    if (typeof onFilter === "function") {
      onFilter(filterValues);
    }
  };

  const handleCloseFiltersDialog = () => {
    onClose();
  };

  const handleResetFilters = () => {
    setFilterValues({
      startDate: null,
      endDate: null,
      dispatcherId: undefined,
      carrierId: undefined,
      shipperId: undefined,
    });
    setDispatcherValue(null);
    setCarrierValue(null);
    setShipperValue(null);
  };

  const [dispatcherValue, setDispatcherValue] = useState<User | null>(null);
  const [carrierValue, setCarrierValue] = useState<Carrier | null>(null);
  const [shipperValue, setShipperValue] = useState<Shipper | null>(null);
  const [dispatcherOptions, setDispatcherOptions] = useState<User[]>([]);
  const [carrierOptions, setCarrierOptions] = useState<Carrier[]>([]);
  const [shipperOptions, setShipperOptions] = useState<Shipper[]>([]);

  const fetchDispatchers = () => {
    userRequest.findAll().then((data) => {
      setDispatcherOptions(
        data.results
          .map((user: User) => ({
            id: user.id,
            username: user.username,
            password: user.password,
            firstName: user.firstName,
            lastName: user.lastName,
            birthdate: user.birthdate,
            phone: user.phone,
            role: user.role,
          }))
          .filter((user: User) => user.role?.name === "dispatcher")
      );
    });
  };

  const fetchCarriers = () => {
    carrierRequest.findAll(10, 0).then((data) => {
      setCarrierOptions(
        data.results.map((carrier: Carrier) => ({
          id: carrier.id,
          name: carrier.name,
          tin: carrier.tin,
          city: carrier.city,
          address: carrier.address,
          phone: carrier.phone,
          email: carrier.email,
          contactPerson: carrier.contactPerson,
          isActive: carrier.isActive,
        }))
      );
    });
  };

  const fetchShippers = () => {
    shipperRequest.findAll(10, 0).then((data) => {
      setShipperOptions(
        data.results.map((shipper: Shipper) => ({
          id: shipper.id,
          name: shipper.name,
          tin: shipper.tin,
          businessRegistrationNumber: shipper.businessRegistrationNumber,
          bank: shipper.bank,
          iban: shipper.iban,
          city: shipper.city,
          address: shipper.address,
          phone: shipper.phone,
          email: shipper.email,
          contactPerson: shipper.contactPerson,
          isActive: shipper.isActive,
        }))
      );
    });
  };

  const sortedDispatcherOptions = dispatcherOptions.sort(function (a, b) {
    if (a.lastName! < b.lastName!) {
      return -1;
    }
    if (a.lastName! > b.lastName!) {
      return 1;
    }
    return 0;
  });

  const sortedCarrierOptions = carrierOptions.sort(function (a, b) {
    if (a.name! < b.name!) {
      return -1;
    }
    if (a.name! > b.name!) {
      return 1;
    }
    return 0;
  });

  const sortedShipperOptions = shipperOptions.sort(function (a, b) {
    if (a.name! < b.name!) {
      return -1;
    }
    if (a.name! > b.name!) {
      return 1;
    }
    return 0;
  });

  useEffect(() => {
    fetchDispatchers();
  }, []);

  useEffect(() => {
    fetchCarriers();
  }, []);

  useEffect(() => {
    fetchShippers();
  }, []);

  const [searchCarrierTextInput, setSearchCarrierTextInput] = useState("");
  const [searchShipperTextInput, setSearchShipperTextInput] = useState("");

  useSkipFirstRun(() => {
    if (!searchCarrierTextInput) {
      fetchCarriers();
      return;
    }

    const timeoutId = setTimeout(() => {
      carrierRequest
        .searchByName(searchCarrierTextInput)
        .then((results) => setCarrierOptions(results));
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchCarrierTextInput]);

  useSkipFirstRun(() => {
    if (!searchShipperTextInput) {
      fetchShippers();
      return;
    }

    const timeoutId = setTimeout(() => {
      shipperRequest
        .searchByName(searchShipperTextInput)
        .then((results) => setShipperOptions(results));
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchShipperTextInput]);

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="xs">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <DialogTitle sx={{ pb: 0 }}>Filtrare comenzi</DialogTitle>
          </Grid>
        </Grid>
        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Dată inițială"
                  value={filterValues.startDate}
                  inputFormat="dd/MM/yyyy"
                  onChange={(newValue) => {
                    setFilterValues((prev: any) => ({
                      ...prev,
                      startDate: newValue,
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      fullWidth
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "zz/ll/aaaa",
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Dată finală"
                  value={filterValues.endDate}
                  inputFormat="dd/MM/yyyy"
                  onChange={(newValue) => {
                    setFilterValues((prev: any) => ({
                      ...prev,
                      endDate: newValue,
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      fullWidth
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "zz/ll/aaaa",
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                value={dispatcherValue}
                onChange={(event, newValue) => {
                  setDispatcherValue(newValue);
                  setFilterValues((prev: any) => ({
                    ...prev,
                    dispatcherId: newValue?.id,
                  }));
                }}
                options={sortedDispatcherOptions}
                filterSelectedOptions
                getOptionLabel={(option) =>
                  `${option.lastName} ${option.firstName}`
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    size="small"
                    label="Dispecer"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                value={carrierValue}
                onChange={(event, newValue, reason) => {
                  if (reason === "clear") {
                    setCarrierValue(null);
                    setFilterValues((prev: any) => ({
                      ...prev,
                      carrierId: null,
                    }));
                    fetchCarriers();
                  } else {
                    setCarrierValue(newValue);
                    setFilterValues((prev: any) => ({
                      ...prev,
                      carrierId: newValue?.id,
                    }));
                  }
                }}
                options={sortedCarrierOptions}
                filterSelectedOptions
                getOptionLabel={(option) => `${option.name}`}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={({
                  inputProps: { onChange, ...restInputProps },
                  ...params
                }) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...restInputProps,
                      onChange: (...args) => {
                        setSearchCarrierTextInput(
                          // @ts-ignore
                          args[0].target.value
                        );
                        // @ts-ignore
                        onChange(...args);
                      },
                    }}
                    fullWidth
                    size="small"
                    label="Transportator"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                value={shipperValue}
                onChange={(event, newValue, reason) => {
                  if (reason === "clear") {
                    setShipperValue(null);
                    setFilterValues((prev: any) => ({
                      ...prev,
                      shipperId: null,
                    }));
                    fetchShippers();
                  } else {
                    setShipperValue(newValue);
                    setFilterValues((prev: any) => ({
                      ...prev,
                      shipperId: newValue?.id,
                    }));
                  }
                }}
                options={sortedShipperOptions}
                filterSelectedOptions
                getOptionLabel={(option) => `${option.name}`}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={({
                  inputProps: { onChange, ...restInputProps },
                  ...params
                }) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...restInputProps,
                      onChange: (...args) => {
                        setSearchShipperTextInput(
                          // @ts-ignore
                          args[0].target.value
                        );
                        // @ts-ignore
                        onChange(...args);
                      },
                    }}
                    fullWidth
                    size="small"
                    label="Beneficiar"
                  />
                )}
              />
            </Grid>
          </Grid>
          <Divider sx={{ mb: 2, opacity: 0 }} />
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={12} sm={6}>
              <Button
                variant="contained"
                fullWidth
                size="large"
                color="primary"
                onClick={() => {
                  handleApplyFilters();
                }}
                autoFocus
              >
                Aplică
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant="outlined"
                fullWidth
                size="large"
                onClick={() => {
                  handleResetFilters();
                }}
                autoFocus
              >
                Resetează&nbsp;filtre
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
