import PATH from "../paths";

const routeTitleMapObject = {
  [PATH.TRIP.LIST]: "Comenzi",
  [PATH.TRIP.VIEW]: "Detalii comandă",
  [PATH.CONFIRM_TRIP]: "Operare comenzi",
  [PATH.SHIPPER_INVOICE]: "Facturare comenzi",
  [PATH.VALIDATE_TRIP_PUBLIC_UPLOADED_FILES]: "Validare documente",
  [PATH.DUE_INVOICES]: "Facturi scadente",
  [PATH.REPORTS.TRIPS_SHIPPERS]: "Raport comenzi/beneficiari",
  [PATH.REPORTS.TRIPS_DISPATCHERS]: "Raport comenzi/dispeceri",
  [PATH.REPORTS.LOSER_TRIPS]: "Raport comenzi în pierdere",
  [PATH.REPORTS.PROFIT_LESS_THAN_ONE_HUNDRED]:
    "Raport comenzi cu profit sub 100",
  [PATH.REPORTS.TOP_CLIENTS]: "Top clienți",
  [PATH.USERS]: "Utilizatori",
  [PATH.INVOICE_SERIES]: "Serii facturare",
  [PATH.SHIPPERS]: "Beneficiari",
  [PATH.SHIPPER_BRANCHES]: "Puncte de lucru",
  [PATH.CARRIERS]: "Transportatori",
  [PATH.CARGO_TYPES]: "Tipuri de marfă",
  [PATH.CARRIER_CARS]: "Mașini",
  [PATH.CARRIER_DRIVERS]: "Șoferi",
  [PATH.COUNTRIES]: "Țări",
  [PATH.COUNTIES]: "Județe",
  [PATH.CITIES]: "Localități",
};

export default routeTitleMapObject;
