import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Autocomplete, Box, Divider, Grid, IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useEffect, useState } from "react";
import { countryRequest } from "../../../api/CountryRequest";
import { shipperRequest } from "../../../api/ShipperRequest";
import AddCountryDialog from "../country/AddCountryDialog";
import AddCountyDialog from "../country/AddCountyDialog";
import AddCityDialog from "../country/AddCityDialog";
import AddIcon from "@mui/icons-material/Add";
import { shipperBranchRequest } from "../../../api/ShipperBranchRequest";
import AddShipperDialog from "./AddShipperDialog";

interface Props {
  open: boolean;
  onClose: AnyFunction;
  shipperBranch?: ShipperBranch;
  onShipperBranchEdited: AnyFunction;
}

interface FormValues {
  name: string | null;
  shipperId: number | null;
  cityId: number | null;
  address: string | null;
  phone: string | null;
  email: string | null;
}

interface CountryValues {
  country: Country | null;
  county: County | null;
  city: City | null;
}

export default function EditShipperBranchDialog(props: Props) {
  const { open, onClose, shipperBranch, onShipperBranchEdited } = props;

  const [formValues, setFormValues] = useState<FormValues>({
    name: "",
    shipperId: null,
    cityId: null,
    address: "",
    phone: "",
    email: "",
  });

  const [countryValues, setCountryValues] = useState<CountryValues>({
    country: null,
    county: null,
    city: null,
  });

  const [shipperValue, setShipperValue] = useState<Shipper | null>(null);

  const [error, setError] = useState({
    name: false,
    nameIsDuplicate: false,
    shipperId: false,
    address: false,
    phone: false,
    email: false,
    isNotEmail: false,
    countryId: false,
    countyId: false,
    cityId: false,
  });

  const isEmailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
  const isEmail = Boolean(formValues.email?.match(isEmailRegex));

  const [shipperBranchData, setShipperBranchData] = useState<ShipperBranch[]>(
    []
  );

  const fetchShipperBranches = () => {
    shipperBranchRequest.findAll().then((data) => {
      setShipperBranchData(
        data.results.map((shipperBranch: ShipperBranch) => ({
          id: shipperBranch.id,
          name: shipperBranch.name,
          shipper: shipperBranch.shipper,
          phone: shipperBranch.phone,
          email: shipperBranch.email,
          city: shipperBranch.city,
          address: shipperBranch.address,
        }))
      );
    });
  };

  useEffect(() => {
    fetchShipperBranches();
  }, []);

  const checkNameIsDuplicate = (shipperBranch: ShipperBranch) =>
    shipperBranch.name?.toLowerCase() === formValues.name?.toLowerCase() &&
    shipperBranch.shipper?.id === formValues.shipperId;

  const handleErrors = () => {
    if (!formValues.name) {
      setError((prev: any) => ({
        ...prev,
        name: true,
      }));
    } else if (formValues.name) {
      setError((prev: any) => ({
        ...prev,
        name: false,
      }));
    }
    if (formValues.name === shipperBranch?.name) {
      setError((prev: any) => ({
        ...prev,
        nameIsDuplicate: false,
      }));
    } else if (formValues.name !== shipperBranch?.name) {
      setError((prev: any) => ({
        ...prev,
        nameIsDuplicate: shipperBranchData.some(checkNameIsDuplicate),
      }));
    }
    if (!formValues.shipperId) {
      setError((prev: any) => ({
        ...prev,
        shipperId: true,
      }));
    } else if (formValues.shipperId) {
      setError((prev: any) => ({
        ...prev,
        shipperId: false,
      }));
    }
    if (!formValues.address) {
      setError((prev: any) => ({
        ...prev,
        address: true,
      }));
    } else if (formValues.address) {
      setError((prev: any) => ({
        ...prev,
        address: false,
      }));
    }
    if (!formValues.phone) {
      setError((prev: any) => ({
        ...prev,
        phone: true,
      }));
    } else if (formValues.phone) {
      setError((prev: any) => ({
        ...prev,
        phone: false,
      }));
    }
    if (!formValues.email) {
      setError((prev: any) => ({
        ...prev,
        email: true,
      }));
    } else if (formValues.email) {
      setError((prev: any) => ({
        ...prev,
        email: false,
      }));
    }
    if (!isEmail) {
      setError((prev: any) => ({
        ...prev,
        isNotEmail: true,
      }));
    } else if (isEmail) {
      setError((prev: any) => ({
        ...prev,
        isNotEmail: false,
      }));
    }
    if (!countryValues.country) {
      setError((prev: any) => ({
        ...prev,
        countryId: true,
      }));
    } else if (countryValues.country) {
      setError((prev: any) => ({
        ...prev,
        countryId: false,
      }));
    }
    if (!countryValues.county) {
      setError((prev: any) => ({
        ...prev,
        countyId: true,
      }));
    } else if (countryValues.county) {
      setError((prev: any) => ({
        ...prev,
        countyId: false,
      }));
    }
    if (!formValues.cityId) {
      setError((prev: any) => ({
        ...prev,
        cityId: true,
      }));
    } else if (formValues.cityId) {
      setError((prev: any) => ({
        ...prev,
        cityId: false,
      }));
    }
  };

  const handleSubmit = () => {
    if (shipperBranch && shipperBranch.id) {
      handleErrors();
      const nameCondition =
        formValues.name === shipperBranch?.name ||
        !shipperBranchData.some(checkNameIsDuplicate);
      const formIsValid =
        formValues.name &&
        nameCondition &&
        formValues.shipperId &&
        formValues.address &&
        formValues.phone &&
        formValues.email &&
        isEmail &&
        formValues.cityId;
      if (formIsValid) {
        return shipperBranchRequest
          .patch(shipperBranch.id, formValues)
          .then(() => {
            setShipperValue(null);
            setCountryValues((prev: any) => ({
              ...prev,
              country: null,
              county: null,
              city: null,
            }));
            if (typeof onShipperBranchEdited === "function") {
              onShipperBranchEdited();
            }
          })
          .catch(() => {
            alert("S-a produs o eroare!");
          });
      }
    }
  };

  const handleCloseEditShipperBranchDialog = () => {
    onClose();
    setError(() => ({
      name: false,
      nameIsDuplicate: false,
      shipperId: false,
      address: false,
      phone: false,
      email: false,
      isNotEmail: false,
      countryId: false,
      countyId: false,
      cityId: false,
    }));
  };

  const [shipperOptions, setShipperOptions] = useState<Shipper[]>([]);
  const fetchShippers = () => {
    shipperRequest.findAll().then((data) => {
      setShipperOptions(
        data.results.map((shipper: Shipper) => ({
          id: shipper.id,
          name: shipper.name,
          tin: shipper.tin,
          businessRegistrationNumber: shipper.businessRegistrationNumber,
          bank: shipper.bank,
          iban: shipper.iban,
          city: shipper.city,
          address: shipper.address,
          phone: shipper.phone,
          email: shipper.email,
          contactPerson: shipper.contactPerson,
          isActive: shipper.isActive,
        }))
      );
    });
  };

  useEffect(() => {
    fetchShippers();
  }, []);

  const sortedShipperOptions = shipperOptions.sort(function (a, b) {
    if (a.name! < b.name!) {
      return -1;
    }
    if (a.name! > b.name!) {
      return 1;
    }
    return 0;
  });

  const [countryOptions, setCountryOptions] = useState<Country[]>([]);
  const fetchCountries = () => {
    countryRequest
      .getCountries()
      .then((countries) => setCountryOptions(countries));
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  const sortedCountryOptions = countryOptions?.sort(function (a, b) {
    if (a.name! < b.name!) {
      return -1;
    }
    if (a.name! > b.name!) {
      return 1;
    }
    return 0;
  });

  const [countyOptions, setCountyOptions] = useState<County[]>([]);
  const [selectedCountryId, setSelectedCountryId] = useState<any>(null);
  const fetchCounties = () => {
    if (selectedCountryId) {
      countryRequest
        .getCounties(selectedCountryId)
        .then((counties) => setCountyOptions(counties));
    }
  };

  useEffect(() => {
    fetchCounties();
  }, [selectedCountryId]);

  const sortedCountyOptions = countyOptions?.sort(function (a, b) {
    if (a.name! < b.name!) {
      return -1;
    }
    if (a.name! > b.name!) {
      return 1;
    }
    return 0;
  });

  const [cityOptions, setCityOptions] = useState<City[]>([]);
  const [selectedCountyId, setSelectedCountyId] = useState<any>(null);
  const fetchCities = () => {
    if (selectedCountyId) {
      countryRequest
        .getCities(selectedCountyId)
        .then((cities) => setCityOptions(cities));
    }
  };

  useEffect(() => {
    fetchCities();
  }, [selectedCountyId]);

  const sortedCityOptions = cityOptions?.sort(function (a, b) {
    if (a.name! < b.name!) {
      return -1;
    }
    if (a.name! > b.name!) {
      return 1;
    }
    return 0;
  });

  useEffect(() => {
    if (shipperBranch) {
      setFormValues({
        name: shipperBranch.name,
        shipperId: shipperBranch.shipper?.id || null,
        cityId: shipperBranch.city?.id || null,
        address: shipperBranch.address,
        phone: shipperBranch.phone,
        email: shipperBranch.email,
      });
      setShipperValue(shipperBranch.shipper || null);
      setCountryValues({
        country: shipperBranch.city?.county?.country || null,
        county: shipperBranch.city?.county || null,
        city: shipperBranch.city || null,
      });
      setSelectedCountryId(shipperBranch.city?.county?.country?.id);
      setSelectedCountyId(shipperBranch.city?.county?.id);
    }
  }, [shipperBranch]);

  const [openAddShipperDialog, setOpenAddShipperDialog] = React.useState(false);
  const handleOpenAddShipperDialog = () => {
    setOpenAddShipperDialog(true);
  };

  const [openAddCountryDialog, setOpenAddCountryDialog] = React.useState(false);
  const handleOpenAddCountryDialog = () => {
    setOpenAddCountryDialog(true);
  };

  const [openAddCountyDialog, setOpenAddCountyDialog] = React.useState(false);
  const handleOpenAddCountyDialog = () => {
    setOpenAddCountyDialog(true);
  };

  const [openAddCityDialog, setOpenAddCityDialog] = React.useState(false);
  const handleOpenAddCityDialog = () => {
    setOpenAddCityDialog(true);
  };

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="xs">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <DialogTitle sx={{ pb: 0 }}>Modificare punct de lucru</DialogTitle>
          </Grid>
          <Grid item sx={{ mr: 2, pt: 2 }}>
            <IconButton
              onClick={handleCloseEditShipperBranchDialog}
              tabIndex={-1}
            >
              <ClearIcon />
            </IconButton>
          </Grid>
        </Grid>
        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12}>
              <TextField
                required
                size="small"
                fullWidth
                label="Nume"
                error={error.name || error.nameIsDuplicate}
                helperText={
                  error.name
                    ? "Câmp obligatoriu!"
                    : error.nameIsDuplicate && "Nume deja existent!"
                }
                value={formValues?.name ?? ""}
                onChange={(event) =>
                  setFormValues((prev: any) => ({
                    ...prev,
                    name: event.target.value.toUpperCase(),
                  }))
                }
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit()
                      ?.then(handleCloseEditShipperBranchDialog)
                      .then(fetchShipperBranches);
                  }
                }}
                inputProps={{ style: { textTransform: "uppercase" } }}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex" }}>
                <Autocomplete
                  value={shipperValue ?? null}
                  onChange={(event, newValue) => {
                    setShipperValue(newValue);
                    setFormValues((prev: any) => ({
                      ...prev,
                      shipperId: newValue?.id,
                    }));
                  }}
                  options={sortedShipperOptions}
                  filterSelectedOptions
                  getOptionLabel={(option) => `${option.name}`}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  noOptionsText="Fără opțiuni"
                  renderInput={(params) => (
                    <form
                      noValidate
                      onSubmit={(event) => event.preventDefault()}
                    >
                      <TextField
                        required
                        {...params}
                        size="small"
                        fullWidth
                        label="Beneficiar"
                        error={error.shipperId}
                        helperText={error.shipperId && "Câmp obligatoriu!"}
                        onKeyUp={(e) => {
                          if (e.key === "Enter") {
                            handleSubmit()
                              ?.then(handleCloseEditShipperBranchDialog)
                              .then(fetchShipperBranches);
                          }
                        }}
                      />
                    </form>
                  )}
                  sx={{ flexGrow: 1, mr: 1 }}
                />
                <Button
                  color="primary"
                  variant="contained"
                  sx={{
                    maxWidth: "40px",
                    minWidth: "40px",
                    minHeight: "40px",
                    maxHeight: "40px",
                  }}
                  onClick={handleOpenAddShipperDialog}
                  tabIndex={-1}
                >
                  <AddIcon />
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                size="small"
                fullWidth
                label="Telefon"
                error={error.phone}
                helperText={error.phone && "Câmp obligatoriu!"}
                value={formValues?.phone ?? ""}
                onChange={(event) =>
                  setFormValues((prev: any) => ({
                    ...prev,
                    phone: event.target.value.toUpperCase(),
                  }))
                }
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit()
                      ?.then(handleCloseEditShipperBranchDialog)
                      .then(fetchShipperBranches);
                  }
                }}
                inputProps={{ style: { textTransform: "uppercase" } }}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                size="small"
                fullWidth
                label="Email"
                error={error.email || error.isNotEmail}
                helperText={
                  error.email
                    ? "Câmp obligatoriu!"
                    : error.isNotEmail && "Email invalid!"
                }
                value={formValues?.email ?? ""}
                onChange={(event) =>
                  setFormValues((prev: any) => ({
                    ...prev,
                    email: event.target.value.toUpperCase(),
                  }))
                }
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit()
                      ?.then(handleCloseEditShipperBranchDialog)
                      .then(fetchShipperBranches);
                  }
                }}
                inputProps={{ style: { textTransform: "uppercase" } }}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex" }}>
                <Autocomplete
                  value={countryValues?.country ?? null}
                  onChange={(event, newValue, reason) => {
                    if (reason === "clear") {
                      setCountryValues((prev: any) => ({
                        ...prev,
                        country: null,
                        county: null,
                        city: null,
                      }));
                      setFormValues((prev: any) => ({
                        ...prev,
                        cityId: null,
                      }));
                      setSelectedCountryId(null);
                      setSelectedCountyId(null);
                    } else {
                      setCountryValues((prev: any) => ({
                        ...prev,
                        country: newValue,
                      }));
                      setSelectedCountryId(newValue?.id);
                    }
                  }}
                  options={sortedCountryOptions}
                  filterSelectedOptions
                  noOptionsText="Fără opțiuni"
                  getOptionLabel={(option) => `${option.name}`}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <form
                      noValidate
                      onSubmit={(event) => event.preventDefault()}
                    >
                      <TextField
                        required
                        {...params}
                        size="small"
                        fullWidth
                        label="Țară"
                        error={error.countryId}
                        helperText={error.countryId && "Câmp obligatoriu!"}
                        onKeyUp={(e) => {
                          if (e.key === "Enter") {
                            handleSubmit()
                              ?.then(handleCloseEditShipperBranchDialog)
                              .then(fetchShipperBranches);
                          }
                        }}
                      />
                    </form>
                  )}
                  sx={{ flexGrow: 1, mr: 1 }}
                />
                <Button
                  color="primary"
                  variant="contained"
                  sx={{
                    maxWidth: "40px",
                    minWidth: "40px",
                    minHeight: "40px",
                    maxHeight: "40px",
                  }}
                  onClick={handleOpenAddCountryDialog}
                  tabIndex={-1}
                >
                  <AddIcon />
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex" }}>
                <Autocomplete
                  value={countryValues?.county ?? null}
                  onChange={(event, newValue, reason) => {
                    if (reason === "clear") {
                      setCountryValues((prev: any) => ({
                        ...prev,
                        county: null,
                        city: null,
                      }));
                      setFormValues((prev: any) => ({
                        ...prev,
                        cityId: null,
                      }));
                      setSelectedCountyId(null);
                    } else {
                      setCountryValues((prev: any) => ({
                        ...prev,
                        county: newValue,
                      }));
                      setSelectedCountyId(newValue?.id);
                    }
                  }}
                  disabled={!selectedCountryId}
                  options={sortedCountyOptions}
                  filterSelectedOptions
                  noOptionsText="Fără opțiuni"
                  getOptionLabel={(option) => `${option.name}`}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <form
                      noValidate
                      onSubmit={(event) => event.preventDefault()}
                    >
                      <TextField
                        required
                        {...params}
                        size="small"
                        fullWidth
                        label="Județ"
                        error={error.countyId}
                        helperText={error.countyId && "Câmp obligatoriu!"}
                        onKeyUp={(e) => {
                          if (e.key === "Enter") {
                            handleSubmit()
                              ?.then(handleCloseEditShipperBranchDialog)
                              .then(fetchShipperBranches);
                          }
                        }}
                      />
                    </form>
                  )}
                  sx={{ flexGrow: 1, mr: 1 }}
                />
                <Button
                  color="primary"
                  variant="contained"
                  sx={{
                    maxWidth: "40px",
                    minWidth: "40px",
                    minHeight: "40px",
                    maxHeight: "40px",
                  }}
                  disabled={!selectedCountryId}
                  onClick={handleOpenAddCountyDialog}
                  tabIndex={-1}
                >
                  <AddIcon />
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex" }}>
                <Autocomplete
                  value={countryValues?.city ?? null}
                  onChange={(event, newValue) => {
                    setCountryValues((prev: any) => ({
                      ...prev,
                      city: newValue,
                    }));
                    setFormValues((prev: any) => ({
                      ...prev,
                      cityId: newValue?.id,
                    }));
                  }}
                  options={sortedCityOptions}
                  filterSelectedOptions
                  disabled={!selectedCountyId}
                  noOptionsText="Fără opțiuni"
                  getOptionLabel={(option) => `${option.name}`}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <form
                      noValidate
                      onSubmit={(event) => event.preventDefault()}
                    >
                      <TextField
                        required
                        {...params}
                        size="small"
                        fullWidth
                        label="Localitate"
                        error={error.cityId}
                        helperText={error.cityId && "Câmp obligatoriu!"}
                        onKeyUp={(e) => {
                          if (e.key === "Enter") {
                            handleSubmit()
                              ?.then(handleCloseEditShipperBranchDialog)
                              .then(fetchShipperBranches);
                          }
                        }}
                      />
                    </form>
                  )}
                  sx={{ flexGrow: 1, mr: 1 }}
                />
                <Button
                  color="primary"
                  variant="contained"
                  sx={{
                    maxWidth: "40px",
                    minWidth: "40px",
                    minHeight: "40px",
                    maxHeight: "40px",
                  }}
                  disabled={!selectedCountyId}
                  onClick={handleOpenAddCityDialog}
                  tabIndex={-1}
                >
                  <AddIcon />
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                size="small"
                fullWidth
                label="Adresă"
                error={error.address}
                helperText={error.address && "Câmp obligatoriu!"}
                value={formValues?.address ?? ""}
                onChange={(event) =>
                  setFormValues((prev: any) => ({
                    ...prev,
                    address: event.target.value.toUpperCase(),
                  }))
                }
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit()
                      ?.then(handleCloseEditShipperBranchDialog)
                      .then(fetchShipperBranches);
                  }
                }}
                inputProps={{ style: { textTransform: "uppercase" } }}
              ></TextField>
            </Grid>
          </Grid>
          <Divider sx={{ mb: 2, opacity: 0 }} />
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={12} md={6}>
              <Button
                fullWidth
                variant="contained"
                onClick={() => {
                  handleSubmit()
                    ?.then(handleCloseEditShipperBranchDialog)
                    .then(fetchShipperBranches);
                }}
              >
                Salvează
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                fullWidth
                variant="outlined"
                onClick={handleCloseEditShipperBranchDialog}
              >
                Renunță
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      {openAddShipperDialog && (
        <AddShipperDialog
          open={openAddShipperDialog}
          onShipperCreated={(createdShipper: Shipper) => {
            fetchShippers();
            setShipperValue(createdShipper);
            setFormValues((prev: any) => ({
              ...prev,
              shipperId: createdShipper?.id,
            }));
            setError((prev: any) => ({
              ...prev,
              shipperId: false,
            }));
          }}
          onClose={() => setOpenAddShipperDialog(false)}
        />
      )}
      {openAddCountryDialog && (
        <AddCountryDialog
          open={openAddCountryDialog}
          onCountryCreated={(createdCountry: Country) => {
            fetchCountries();
            setCountryValues((prev: any) => ({
              ...prev,
              country: createdCountry,
              county: null,
              city: null,
            }));
            setSelectedCountryId(createdCountry.id);
            setSelectedCountyId(null);
            setFormValues((prev: any) => ({
              ...prev,
              cityId: null,
            }));
            setError((prev: any) => ({
              ...prev,
              countryId: false,
              countyId: false,
              cityId: false,
            }));
          }}
          onClose={() => {
            setOpenAddCountryDialog(false);
          }}
        />
      )}
      {openAddCountyDialog && (
        <AddCountyDialog
          open={openAddCountyDialog}
          onCountyCreated={(createdCounty: County) => {
            fetchCounties();
            setCountryValues((prev: any) => ({
              ...prev,
              county: createdCounty,
              city: null,
            }));
            setSelectedCountyId(createdCounty.id);
            setFormValues((prev: any) => ({
              ...prev,
              cityId: null,
            }));
            setError((prev: any) => ({
              ...prev,
              countyId: false,
              cityId: false,
            }));
          }}
          onClose={() => {
            setOpenAddCountyDialog(false);
          }}
          selectedCountryId={selectedCountryId}
        />
      )}
      {openAddCityDialog && (
        <AddCityDialog
          open={openAddCityDialog}
          onCityCreated={(createdCity: City) => {
            fetchCities();
            setCountryValues((prev: any) => ({
              ...prev,
              city: createdCity,
            }));
            setFormValues((prev: any) => ({
              ...prev,
              cityId: createdCity.id,
            }));
            setError((prev: any) => ({
              ...prev,
              cityId: false,
            }));
          }}
          onClose={() => {
            setOpenAddCityDialog(false);
          }}
          selectedCountyId={selectedCountyId}
        />
      )}
    </>
  );
}
