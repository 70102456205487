import { saveAs } from "file-saver";
import RootRequest from "./RootRequest";
import { authRequest } from "./AuthRequest";

export default class FileRequest extends RootRequest {
  get apiResource() {
    return "api/file";
  }

  get apiRoutes() {
    return {
      // @ts-ignore
      ...super.apiRoutes,
      UPLOAD: `${this.apiRoute}/upload`,
      TRIP_PUBLIC_UPLOAD: `${this.apiRoute}/trip-public-upload/`,
      DELETE: `${this.apiRoute}/`,
      DOWNLOAD: `${this.apiRoute}/download/`,
      PUBLIC_DOWNLOAD_SHIPPER_INVOICE_FILES: `${this.apiRoute}/public-download-shipper-invoice-files/`,
    };
  }

  async upload(file: File, onUploadProgress?: AnyFunction) {
    const formData = new FormData();
    formData.append("file", file);

    const response = await this.makeRequest(
      RootRequest.METHOD.POST,
      this.apiRoutes.UPLOAD,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${authRequest.token}`,
        },
        onUploadProgress,
      }
    );

    return response?.data;
  }

  async tripPublicUpload(
    file: File,
    uuid: string,
    onUploadProgress?: AnyFunction
  ) {
    const formData = new FormData();
    formData.append("file", file);

    const response = await this.makeRequest(
      RootRequest.METHOD.POST,
      this.apiRoutes.TRIP_PUBLIC_UPLOAD + uuid,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${authRequest.token}`,
        },
        onUploadProgress,
      }
    );

    return response?.data;
  }

  downloadShipperInvoiceFiles(shipperInvoiceUuid: string) {
    window.location.href =
      this.apiRoutes.PUBLIC_DOWNLOAD_SHIPPER_INVOICE_FILES + shipperInvoiceUuid;
  }

  async delete(id: number) {
    const response = await this.makeRequest(
      RootRequest.METHOD.DELETE,
      this.apiRoutes.DELETE + id
    );

    return response?.data;
  }

  async getBlob(id: number) {
    const response = await this.makeRequest(
      RootRequest.METHOD.GET,
      this.apiRoutes.DOWNLOAD + id,
      undefined,
      { responseType: "blob" }
    );

    return response.data;
  }

  async download(id: number, filename?: string) {
    saveAs(await this.getBlob(id), filename);
  }
}

export const fileRequest = new FileRequest();
