export default function adjustForTimezone(date: Date): Date {
  const adjustedDate = new Date(date);
  let timezoneOffset: number = date.getTimezoneOffset();
  if (timezoneOffset < 0) {
    adjustedDate.setMinutes(date.getMinutes() - timezoneOffset);
    return adjustedDate;
  }
  adjustedDate.setMinutes(date.getMinutes() + timezoneOffset);
  return adjustedDate;
}
