import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Divider, Grid, IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useEffect, useState } from "react";
import { countryRequest } from "../../../api/CountryRequest";

interface Props {
  open: boolean;
  onClose: AnyFunction;
  county?: County;
  onCountyEdited: AnyFunction;
  selectedCountryId: number;
}

interface FormValues {
  name: string;
}

export default function EditCountyDialog(props: Props) {
  const { open, onClose, county, onCountyEdited, selectedCountryId } = props;
  const [formValues, setFormValues] = useState<FormValues>({
    name: "",
  });

  const [error, setError] = useState({
    name: false,
    nameIsDuplicate: false,
  });

  const [countyData, setCountyData] = useState<County[]>([]);

  const fetchCounties = () => {
    countryRequest
      .getCounties(selectedCountryId)
      .then((counties) => setCountyData(counties));
  };

  useEffect(() => {
    fetchCounties();
  }, []);

  const checkNameIsDuplicate = (county: County) =>
    county.name?.toLowerCase() === formValues.name?.toLowerCase();

  const handleErrors = () => {
    if (!formValues.name) {
      setError((prev: any) => ({
        ...prev,
        name: true,
      }));
    } else if (formValues.name) {
      setError((prev: any) => ({
        ...prev,
        name: false,
      }));
    }
    if (formValues.name === county?.name) {
      setError((prev: any) => ({
        ...prev,
        nameIsDuplicate: false,
      }));
    } else if (formValues.name !== county?.name) {
      setError((prev: any) => ({
        ...prev,
        nameIsDuplicate: countyData.some(checkNameIsDuplicate),
      }));
    }
  };

  const handleSubmit = () => {
    if (county && county.id) {
      handleErrors();
      const nameCondition =
        formValues.name === county?.name ||
        !countyData.some(checkNameIsDuplicate);
      const formIsValid = formValues.name && nameCondition;
      if (formIsValid) {
        return countryRequest
          .updateCounty(county.id, formValues)
          .then(() => {
            if (typeof onCountyEdited === "function") {
              onCountyEdited();
            }
          })
          .catch(() => {
            alert("S-a produs o eroare!");
          });
      }
    }
  };

  const handleCloseAddCountyDialog = () => {
    onClose();
    setError(() => ({
      name: false,
      nameIsDuplicate: false,
    }));
  };

  useEffect(() => {
    if (county && county.name) {
      setFormValues({ name: county.name });
    }
  }, [county]);

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="xs">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <DialogTitle sx={{ pb: 0 }}>Adăugare județ</DialogTitle>
          </Grid>
          <Grid item sx={{ mr: 2, pt: 2 }}>
            <IconButton onClick={handleCloseAddCountyDialog} tabIndex={-1}>
              <ClearIcon />
            </IconButton>
          </Grid>
        </Grid>
        <DialogContent>
          <TextField
            required
            label="Nume"
            size="small"
            fullWidth
            error={error.name || error.nameIsDuplicate}
            helperText={
              error.name
                ? "Câmp obligatoriu!"
                : error.nameIsDuplicate && "Nume deja existent!"
            }
            value={formValues?.name ?? ""}
            onChange={(event) =>
              setFormValues((prev: any) => ({
                ...prev,
                name: event.target.value.toUpperCase(),
              }))
            }
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                handleSubmit()
                  ?.then(handleCloseAddCountyDialog)
                  .then(fetchCounties);
              }
            }}
            inputProps={{ style: { textTransform: "uppercase" } }}
          ></TextField>
          <Divider sx={{ mb: 2, opacity: 0 }} />
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={12} md={6}>
              <Button
                fullWidth
                variant="contained"
                onClick={() => {
                  handleSubmit()
                    ?.then(handleCloseAddCountyDialog)
                    .then(fetchCounties);
                }}
                disabled={!formValues.name}
              >
                Salvează
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                fullWidth
                variant="outlined"
                onClick={handleCloseAddCountyDialog}
              >
                Renunță
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
