import { GridLocaleText } from "@mui/x-data-grid";

export const dataGridRoTextConstants: GridLocaleText = {
  // Root
  noRowsLabel: "Nu există înregistrări",
  noResultsOverlayLabel: "Niciun rezultat.",
  errorOverlayDefaultLabel: "A apărut o eroare.",

  // Density selector toolbar button text
  toolbarDensity: "Dimensiuni",
  toolbarDensityLabel: "Dimensiuni",
  toolbarDensityCompact: "Mic",
  toolbarDensityStandard: "Mediu",
  toolbarDensityComfortable: "Mare",

  // Columns selector toolbar button text
  toolbarColumns: "Coloane",
  toolbarColumnsLabel: "Selectează coloane",

  // Filters toolbar button text
  toolbarFilters: "Filtre",
  toolbarFiltersLabel: "Arată filtre",
  toolbarFiltersTooltipHide: "Ascunde filtre",
  toolbarFiltersTooltipShow: "Arată filtre",
  toolbarFiltersTooltipActive: (count) =>
    count !== 1 ? `${count} filtre active` : `${count} filtre active`,

  // Quick filter toolbar field
  toolbarQuickFilterPlaceholder: "Caută…",
  toolbarQuickFilterLabel: "Caută",
  toolbarQuickFilterDeleteIconLabel: "Șterge",

  // Export selector toolbar button text
  toolbarExport: "Exportă",
  toolbarExportLabel: "Exportă",
  toolbarExportCSV: "Descarcă CSV",
  toolbarExportPrint: "Printează",
  toolbarExportExcel: "Descarcă Excel",

  // Columns panel text
  columnsPanelTextFieldLabel: "Caută coloană",
  columnsPanelTextFieldPlaceholder: "Denumire coloană",
  columnsPanelDragIconLabel: "Reordonează coloană",
  columnsPanelShowAllButton: "Arată tot",
  columnsPanelHideAllButton: "Ascunde tot",

  // Filter panel text
  filterPanelAddFilter: "Adaugă filtru",
  filterPanelDeleteIconLabel: "Șterge",
  filterPanelLinkOperator: "Operator logic",
  filterPanelOperators: "Operator", // TODO v6: rename to filterPanelOperator
  filterPanelOperatorAnd: "Și",
  filterPanelOperatorOr: "Sau",
  filterPanelColumns: "Coloană",
  filterPanelInputLabel: "Valoare",
  filterPanelInputPlaceholder: "Valoare filtru",

  // Filter operators text
  filterOperatorContains: "conține",
  filterOperatorEquals: "egal cu",
  filterOperatorStartsWith: "începe cu",
  filterOperatorEndsWith: "se termină cu",
  filterOperatorIs: "este",
  filterOperatorNot: "nu este",
  filterOperatorAfter: "este după",
  filterOperatorOnOrAfter: "is on or after",
  filterOperatorBefore: "este înainte",
  filterOperatorOnOrBefore: "is on or before",
  filterOperatorIsEmpty: "este necompletat",
  filterOperatorIsNotEmpty: "este completat",
  filterOperatorIsAnyOf: "este oricare dintre",

  // Filter values text
  filterValueAny: "oricare",
  filterValueTrue: "adevărat",
  filterValueFalse: "fals",

  // Column menu text
  columnMenuLabel: "Meniu",
  columnMenuShowColumns: "Arată coloane",
  columnMenuFilter: "Filtrează",
  columnMenuHideColumn: "Ascunde coloană",
  columnMenuUnsort: "Anulează sortare",
  columnMenuSortAsc: "Sortează crescător",
  columnMenuSortDesc: "Sortează descrescător",

  // Column header text
  columnHeaderFiltersTooltipActive: (count) =>
    count !== 1 ? `${count} filtre active` : `${count} filtre active`,
  columnHeaderFiltersLabel: "Arată filtre",
  columnHeaderSortIconLabel: "Sortează",

  // Rows selected footer text
  footerRowSelected: (count) =>
    count !== 1
      ? `${count.toLocaleString()} înregistrări selectate`
      : `${count.toLocaleString()} înregistrare selectată`,

  // Total row amount footer text
  footerTotalRows: "Număr total de înregistrări:",

  // Total visible row amount footer text
  footerTotalVisibleRows: (visibleCount, totalCount) =>
    `${visibleCount.toLocaleString()} din ${totalCount.toLocaleString()}`,

  // Checkbox selection text
  checkboxSelectionHeaderName: "Selectează casete",
  checkboxSelectionSelectAllRows: "Selectează toate înregistrările",
  checkboxSelectionUnselectAllRows: "Deselectează toate înregistrările",
  checkboxSelectionSelectRow: "Selectează înregistrare",
  checkboxSelectionUnselectRow: "Deselectează înregistrare",

  // Boolean cell text
  booleanCellTrueLabel: "da",
  booleanCellFalseLabel: "nu",

  // Actions cell more text
  actionsCellMore: "mai multe",

  // Column pinning text
  pinToLeft: "Fixează la stânga",
  pinToRight: "Fixează la dreapte",
  unpin: "Anulează fixare coloană",

  // Tree Data
  treeDataGroupingHeaderName: "Grupează",
  treeDataExpand: "arată descendenți",
  treeDataCollapse: "ascunde descendenți",

  // Grouping columns
  groupingColumnHeaderName: "Grupează",
  groupColumn: (name) => `Grupează după ${name}`,
  unGroupColumn: (name) => `Anulează grupare după ${name}`,

  // Master/detail
  detailPanelToggle: "Comutator panou de detalii",
  expandDetailPanel: "Extinde",
  collapseDetailPanel: "Restrânge",

  // Used core components translation keys
  MuiTablePagination: {},

  // Row reordering text
  rowReorderingHeaderName: "Reordonare înregistrări",

  // Aggregation
  aggregationMenuItemHeader: "Agregare",
  aggregationFunctionLabelSum: "sumă",
  aggregationFunctionLabelAvg: "medie",
  aggregationFunctionLabelMin: "minim",
  aggregationFunctionLabelMax: "maxim",
  aggregationFunctionLabelSize: "dimensiune",
};
