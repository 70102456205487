import RootRequest from "./RootRequest";

export default class InvoiceSeriesRequest extends RootRequest {
  get apiResource() {
    return "api/invoice-series";
  }

  get dynamicIds() {
    return {
      INVOICE_SERIES_ID: "{invoiceSeriesId}",
    };
  }

  get apiRoutes() {
    return {
      // @ts-ignore
      ...super.apiRoutes,
      MAKE_ACTIVE: `${this.apiRoute}/${this.dynamicIds.INVOICE_SERIES_ID}/make-active`,
    };
  }

  async makeActive(id: number) {
    const response = await this.makeRequest(
      RootRequest.METHOD.POST,
      this.apiRoutes.MAKE_ACTIVE.replace(
        this.dynamicIds.INVOICE_SERIES_ID,
        id?.toString()
      )
    );

    return response?.data;
  }
}

export const invoiceSeriesRequest = new InvoiceSeriesRequest();
