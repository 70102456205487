import RootRequest, { QueryParam } from "./RootRequest";
import { saveAs } from "file-saver";

export default class ShipperInvoiceRequest extends RootRequest {
  get apiResource() {
    return "api/shipper-invoice";
  }

  get dynamicIds() {
    return {
      SHIPPER_INVOICE_ID: "{shipperInvoiceId}",
    };
  }

  get apiRoutes() {
    return {
      // @ts-ignore
      ...super.apiRoutes,
      GET_NEXT_INVOICE_DATA: `${this.apiRoute}/next-invoice-data`,
      GENERATE_INVOICE_TYPE: `${this.apiRoute}/${this.dynamicIds.SHIPPER_INVOICE_ID}/generate`,
      CANCEL_INVOICE: `${this.apiRoute}/${this.dynamicIds.SHIPPER_INVOICE_ID}/cancel`,
      ADD_DOCUMENT: `${this.apiRoute}/${this.dynamicIds.SHIPPER_INVOICE_ID}/add-document`,
      GET_SAGA_XML: `${this.apiRoute}/get-saga-xml`,
      GET_SAGA_XML_DOWNLOAD_KEY: `${this.apiRoute}/get-saga-xml-download-key`,
    };
  }

  async getNextInvoiceData() {
    const response = await this.makeRequest(
      RootRequest.METHOD.GET,
      this.apiRoutes.GET_NEXT_INVOICE_DATA
    );

    return response?.data;
  }

  async generateInvoiceType(id: number, attributes: any) {
    const response = await this.makeRequest(
      RootRequest.METHOD.POST,
      this.apiRoutes.GENERATE_INVOICE_TYPE.replace(
        this.dynamicIds.SHIPPER_INVOICE_ID,
        id?.toString()
      ),
      attributes
    );

    return response?.data;
  }

  async cancelInvoice(id: number) {
    const response = await this.makeRequest(
      RootRequest.METHOD.DELETE,
      this.apiRoutes.CANCEL_INVOICE.replace(
        this.dynamicIds.SHIPPER_INVOICE_ID,
        id?.toString()
      )
    );

    return response?.data;
  }

  async addDocument(id: number, fileId: number) {
    const response = await this.makeRequest(
      RootRequest.METHOD.POST,
      this.apiRoutes.ADD_DOCUMENT.replace(
        this.dynamicIds.SHIPPER_INVOICE_ID,
        id?.toString()
      ),
      { fileId }
    );

    return response?.data;
  }

  async getSagaXml(startDate: Date, endDate: Date, invoiceSeriesId: number) {
    const downloadKey = (
      await this.makeRequest(
        RootRequest.METHOD.GET,
        this.apiRoutes.GET_SAGA_XML_DOWNLOAD_KEY
      )
    ).data.key;

    const queryParams: QueryParam[] = [
      { key: "startDate", value: startDate.toISOString() },
      { key: "endDate", value: endDate.toISOString() },
      { key: "invoiceSeriesId", value: invoiceSeriesId.toString() },
      {
        key: "key",
        value: downloadKey,
      },
    ];

    window.location.href =
      this.apiRoutes.GET_SAGA_XML + this.buildQueryParams(queryParams);
  }
}

export const shipperInvoiceRequest = new ShipperInvoiceRequest();
