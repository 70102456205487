import Grid from "@mui/material/Grid";
import { TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import { reportRequest } from "../../api/ReportRequest";
import TripsDispatchersReportTable from "../tables/TripsDispatchersReportTable";

interface ShipperRow {
  shipperName: string;
  tripCount: number;
  profit: number;
}

interface Report {
  dispatcherId: number;
  dispatcherFullName: string;
  tripCountPerShipper: ShipperRow[];
  totalTripCount: number;
  totalProfit: number;
}

export default function TripsDispatchersReportForm() {
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);

  const [error, setError] = useState({
    startDate: false,
    endDate: false,
  });

  const handleErrors = () => {
    if (!startDate) {
      setError((prev: any) => ({
        ...prev,
        startDate: true,
      }));
    } else if (startDate) {
      setError((prev: any) => ({
        ...prev,
        startDate: false,
      }));
    }
    if (!endDate) {
      setError((prev: any) => ({
        ...prev,
        endDate: true,
      }));
    } else if (endDate) {
      setError((prev: any) => ({
        ...prev,
        endDate: false,
      }));
    }
  };

  const [report, setReport] = useState<Report[]>([]);
  const fetchReport = () => {
    handleErrors();
    const formIsValid = startDate && endDate;
    if (formIsValid) {
      return reportRequest
        .getTripCountPerDispatcherPerShipper(startDate, endDate)
        .then((data) => {
          setReport(
            data?.map((report: Report) => ({
              dispatcherId: report.dispatcherId,
              dispatcherFullName: report.dispatcherFullName,
              tripCountPerShipper: report.tripCountPerShipper,
              totalTripCount: report.totalTripCount,
              totalProfit: report.totalProfit,
            }))
          );
        })
        .catch(() => {
          alert("S-a produs o eroare!");
        });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={4}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Început dată încărcare"
            value={startDate}
            inputFormat="dd/MM/yyyy"
            onChange={(newValue: any) => {
              setStartDate(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                fullWidth
                error={error.startDate}
                helperText={error.startDate && "Câmp obligatoriu!"}
                inputProps={{
                  ...params.inputProps,
                  placeholder: "zz/ll/aaaa",
                }}
              />
            )}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} lg={4}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Sfârșit dată încărcare"
            value={endDate}
            inputFormat="dd/MM/yyyy"
            onChange={(newValue: any) => {
              setEndDate(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                fullWidth
                error={error.endDate}
                helperText={error.endDate && "Câmp obligatoriu!"}
                inputProps={{
                  ...params.inputProps,
                  placeholder: "zz/ll/aaaa",
                }}
              />
            )}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} lg={4}>
        <Button
          fullWidth
          variant="contained"
          onClick={() => {
            fetchReport();
          }}
          sx={{ height: "40px" }}
        >
          Generează raport
        </Button>
      </Grid>
      {report.length > 0 && (
        <Grid item xs={12}>
          <TripsDispatchersReportTable reportValues={report} />
        </Grid>
      )}
    </Grid>
  );
}
