import {
  Document,
  Font,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import * as React from "react";
import { useEffect } from "react";
import dateFormat from "dateformat";
import { vatSelector } from "../redux/slice/selectors";
import { useSelector } from "react-redux";
import { saveAs } from "file-saver";

Font.registerHyphenationCallback((word) => {
  if (word.length >= 10) {
    const part1 = word.slice(0, word.length / 2);
    const part2 = word.slice(word.length / 2, word.length);

    return [part1, part2];
  }
  return [word];
});

const styles = StyleSheet.create({
  viewer: {
    width: "1536px",
    height: window.innerHeight,
  },
  page: {
    backgroundColor: "white",
    color: "black",
    padding: "20px 50px 50px 50px",
  },
  section: {
    marginBottom: 10,
  },
  bold9: {
    fontSize: 12,
    textAlign: "justify",
    fontFamily: "Helvetica-Bold",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#E5E4E2",
    // borderColor: "#000000",
    // borderWidth: 1,
    alignItems: "flex-start",
    textAlign: "left",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#E5E4E2",
    borderColor: "#000000",
    borderTopWidth: 1,
    alignItems: "flex-start",
    textAlign: "left",
  },
  headerFlex: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexGrow: 1,
    borderColor: "#000000",
    borderLeftWidth: 1,
    borderRightWidth: 1,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#ffffff",
    borderColor: "#000000",
    borderTopWidth: 0.5,
    borderBottomWidth: 0.5,
    alignItems: "flex-start",
    textAlign: "left",
  },
  rowFlex: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    borderColor: "#000000",
    borderLeftWidth: 1,
    borderRightWidth: 1,
    flexGrow: 1,
  },
});

interface Props {
  showCarrier: boolean;
  tripsPerShipper: Trip[];
  startDate: Date;
  endDate: Date;
  downloadPdf: boolean;
  onPdfDownloaded: AnyFunction;
}

export default function TripsShippersReportPDF(props: Props) {
  const {
    showCarrier,
    tripsPerShipper,
    startDate,
    endDate,
    downloadPdf,
    onPdfDownloaded,
  } = props;
  const vat = useSelector(vatSelector);

  const aggregatedPerShipper = tripsPerShipper.reduce(
    (accumulator: any, trip: Trip) => {
      if (trip.shipper?.id) {
        if (!accumulator[trip.shipper.id]) {
          accumulator[trip.shipper.id] = {
            shipperId: trip.shipper.id,
            shipperName: trip.shipper.name,
            trips: [trip],
          };
        } else {
          accumulator[trip.shipper.id].trips.push(trip);
        }
      }
      return accumulator;
    },
    {}
  );

  let shipperReportTotalKm = 0;
  let shipperReportTotalValue = 0;
  let shipperReportTotalVat = 0;
  let carrierReportTotalKm = 0;
  let carrierReportTotalValue = 0;
  let carrierReportTotalVat = 0;

  Object.values(aggregatedPerShipper).map((result: any) => {
    result.trips.length > 0 &&
      result.trips.map((trip: Trip) => {
        let shipperKm = 0;
        let shipperValue = 0.0;
        let carrierKm = 0;
        let carrierValue = 0.0;
        trip?.shipperInvoiceRows &&
          trip.shipperInvoiceRows.map((shipperInvoiceRow) => {
            shipperKm += Number(shipperInvoiceRow.distanceKm);
            shipperValue +=
              Number(shipperInvoiceRow.distanceKm) *
              Number(shipperInvoiceRow.value);
            shipperReportTotalKm += Number(shipperInvoiceRow.distanceKm);
            shipperReportTotalValue +=
              Number(shipperInvoiceRow.distanceKm) *
              Number(shipperInvoiceRow.value);
            shipperReportTotalVat += (shipperValue * vat) / 100;
          });
        trip?.carrierInvoiceRows &&
          trip.carrierInvoiceRows.map((carrierInvoiceRow) => {
            carrierKm += Number(carrierInvoiceRow.distanceKm);
            carrierValue +=
              Number(carrierInvoiceRow.distanceKm) *
              Number(carrierInvoiceRow.value);
            carrierReportTotalKm += Number(carrierInvoiceRow.distanceKm);
            carrierReportTotalValue +=
              Number(carrierInvoiceRow.distanceKm) *
              Number(carrierInvoiceRow.value);
            carrierReportTotalVat += (carrierValue * vat) / 100;
          });
      });
  });

  const document = (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <div>
          <View style={styles.section}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                alignContent: "center",
              }}
            >
              <Text style={styles.bold9}>LISTA&nbsp;COMENZI</Text>
              <Text style={styles.bold9}>
                {`Comenzi create intre ${dateFormat(
                  startDate?.toString(),
                  "dd/mm/yyyy"
                )} si ${dateFormat(endDate?.toString(), "dd/mm/yyyy")}`}
              </Text>
            </div>
          </View>
          <View
            style={
              {
                // borderColor: "#000000",
                // borderWidth: 1
              }
            }
          >
            <View style={styles.header}>
              <div style={styles.headerFlex}>
                <Text
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: showCarrier ? "6.66%" : "10%",
                    borderRightColor: "#000000",
                    borderRightWidth: 1,
                    fontSize: 8,
                    padding: 1,
                  }}
                >
                  Nr.{"\n"}comanda
                </Text>
                <Text
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: showCarrier ? "6.66%" : "10%",
                    borderRightColor: "#000000",
                    borderRightWidth: 1,
                    fontSize: 8,
                    padding: 1,
                  }}
                >
                  Data
                </Text>
                <Text
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: showCarrier ? "6.66%" : "10%",
                    borderRightColor: "#000000",
                    borderRightWidth: 1,
                    fontSize: 8,
                    padding: 1,
                  }}
                >
                  Referinta{"\n"}beneficiar
                </Text>
                <Text
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: showCarrier ? "6.66%" : "10%",
                    borderRightColor: "#000000",
                    borderRightWidth: 1,
                    fontSize: 8,
                    padding: 1,
                  }}
                >
                  Ruta{"\n"}beneficiar
                </Text>
                <Text
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: showCarrier ? "6.66%" : "10%",
                    borderRightColor: "#000000",
                    borderRightWidth: 1,
                    fontSize: 8,
                    padding: 1,
                  }}
                >
                  Nr.{"\n"}auto
                </Text>
                <Text
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: showCarrier ? "6.66%" : "10%",
                    borderRightColor: "#000000",
                    borderRightWidth: 1,
                    fontSize: 8,
                    padding: 1,
                  }}
                >
                  Sofer
                </Text>
                <div
                  style={{
                    width: showCarrier ? "26.64%" : "40%",
                    backgroundColor: "#E5E4E2",
                    borderRightColor: "#000000",
                    borderRightWidth: showCarrier ? 1 : 0,
                  }}
                >
                  <View
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <Text
                      style={{ fontSize: 8, textAlign: "center", padding: 1 }}
                    >
                      Beneficiar
                    </Text>
                    <View
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        backgroundColor: "#E5E4E2",
                        alignItems: "flex-start",
                        textAlign: "left",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          // flexGrow: 1,
                          borderTopColor: "#000000",
                          borderTopWidth: 1,
                        }}
                      >
                        <Text
                          style={{
                            width: "25%",
                            fontSize: 8,
                            textAlign: "center",
                            padding: 1,
                            // flexGrow: 1,
                            borderRightColor: "#000000",
                            borderRightWidth: 1,
                          }}
                        >
                          Denumire
                        </Text>
                        <Text
                          style={{
                            width: "25%",
                            fontSize: 8,
                            textAlign: "center",
                            borderRightColor: "#000000",
                            borderRightWidth: 1,
                            padding: 1,
                            // flexGrow: 1,
                          }}
                        >
                          Total KM
                        </Text>
                        <Text
                          style={{
                            width: "25%",
                            fontSize: 8,
                            textAlign: "center",
                            borderRightColor: "#000000",
                            borderRightWidth: 1,
                            padding: 1,
                            // flexGrow: 1,
                          }}
                        >
                          Valoare
                        </Text>
                        <Text
                          style={{
                            width: "25%",
                            fontSize: 8,
                            textAlign: "center",
                            padding: 1,
                            // flexGrow: 1,
                          }}
                        >
                          Valoare{"\n"}TVA
                        </Text>
                      </div>
                    </View>
                  </View>
                </div>
                {showCarrier && (
                  <>
                    <div
                      style={{
                        width: showCarrier ? "26.64%" : "40%",
                        backgroundColor: "#E5E4E2",
                        borderRightColor: "#000000",
                        borderRightWidth: 1,
                      }}
                    >
                      <View
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          textAlign: "center",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 8,
                            textAlign: "center",
                            padding: 1,
                          }}
                        >
                          Transportator
                        </Text>
                        <View
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            backgroundColor: "#E5E4E2",
                            alignItems: "flex-start",
                            textAlign: "left",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              // flexGrow: 1,
                              borderTopColor: "#000000",
                              borderTopWidth: 1,
                            }}
                          >
                            <Text
                              style={{
                                width: "25%",
                                fontSize: 8,
                                textAlign: "center",
                                padding: 1,
                                // flexGrow: 1,
                                borderRightColor: "#000000",
                                borderRightWidth: 1,
                              }}
                            >
                              Denumire
                            </Text>
                            <Text
                              style={{
                                width: "25%",
                                fontSize: 8,
                                textAlign: "center",
                                borderRightColor: "#000000",
                                borderRightWidth: 1,
                                padding: 1,
                                // flexGrow: 1,
                              }}
                            >
                              Total KM
                            </Text>
                            <Text
                              style={{
                                width: "25%",
                                fontSize: 8,
                                textAlign: "center",
                                borderRightColor: "#000000",
                                borderRightWidth: 1,
                                padding: 1,
                                // flexGrow: 1,
                              }}
                            >
                              Valoare
                            </Text>
                            <Text
                              style={{
                                width: "25%",
                                fontSize: 8,
                                textAlign: "center",
                                padding: 1,
                                // flexGrow: 1,
                              }}
                            >
                              Valoare{"\n"}TVA
                            </Text>
                          </div>
                        </View>
                      </View>
                    </div>
                  </>
                )}
                {showCarrier && (
                  <>
                    <div style={{ width: "6.66%" }}>
                      <Text
                        style={{
                          fontSize: 8,
                          textAlign: "center",
                          padding: 1,
                          // flexGrow: 1,
                        }}
                      >
                        Total profit fara{"\n"}TVA
                      </Text>
                    </div>
                  </>
                )}
              </div>
            </View>
            {Object.values(aggregatedPerShipper).length > 0 &&
              Object.values(aggregatedPerShipper).map((result: any) => {
                let shipperFinalTotalKm = 0;
                let shipperFinalTotalValue = 0;
                let shipperFinalTotalVat = 0;
                let carrierFinalTotalKm = 0;
                let carrierFinalTotalValue = 0;
                let carrierFinalTotalVat = 0;
                return (
                  <>
                    <View
                      style={{
                        padding: 1,
                        borderColor: "#000000",
                        borderTopWidth: 1,
                        borderBottomWidth: 0.5,
                        borderLeftWidth: 1,
                        borderRightWidth: 1,
                      }}
                    >
                      <Text style={styles.bold9}>
                        {`${result.shipperName.toUpperCase()}`}
                      </Text>
                    </View>
                    {result.trips.length > 0 &&
                      result.trips.map((trip: Trip) => {
                        let shipperKm = 0;
                        let shipperValue = 0.0;
                        let carrierKm = 0;
                        let carrierValue = 0.0;
                        trip?.shipperInvoiceRows &&
                          trip.shipperInvoiceRows.map((shipperInvoiceRow) => {
                            shipperKm += Number(shipperInvoiceRow.distanceKm);
                            shipperValue +=
                              Number(shipperInvoiceRow.distanceKm) *
                              Number(shipperInvoiceRow.value);
                            shipperFinalTotalKm += Number(
                              shipperInvoiceRow.distanceKm
                            );
                            shipperFinalTotalValue +=
                              Number(shipperInvoiceRow.distanceKm) *
                              Number(shipperInvoiceRow.value);
                            shipperFinalTotalVat += (shipperValue * vat) / 100;
                          });
                        trip?.carrierInvoiceRows &&
                          trip.carrierInvoiceRows.map((carrierInvoiceRow) => {
                            carrierKm += Number(carrierInvoiceRow.distanceKm);
                            carrierValue +=
                              Number(carrierInvoiceRow.distanceKm) *
                              Number(carrierInvoiceRow.value);
                            carrierFinalTotalKm += Number(
                              carrierInvoiceRow.distanceKm
                            );
                            carrierFinalTotalValue +=
                              Number(carrierInvoiceRow.distanceKm) *
                              Number(carrierInvoiceRow.value);
                            carrierFinalTotalVat += (carrierValue * vat) / 100;
                          });

                        let shipperReferenceId = "-";
                        if (trip.shipperReferenceId) {
                          const letters = trip.shipperReferenceId.split("");
                          letters.splice(12, 0, "\n");

                          shipperReferenceId = letters.join("");
                        }

                        return (
                          <View style={styles.row} wrap={false}>
                            <div style={styles.rowFlex}>
                              <Text
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  width: showCarrier ? "6.66%" : "10%",
                                  borderRightColor: "#000000",
                                  borderRightWidth: 1,
                                  fontSize: 8,
                                  padding: 1,
                                }}
                              >
                                {trip?.id ? `${trip.id}` : "-"}
                              </Text>
                              <Text
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  width: showCarrier ? "6.66%" : "10%",
                                  borderRightColor: "#000000",
                                  borderRightWidth: 1,
                                  fontSize: 8,
                                  padding: 1,
                                }}
                              >
                                {trip?.date
                                  ? `${dateFormat(trip.date, "dd-mm-yyyy")}`
                                  : "-"}
                              </Text>
                              <Text
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  width: showCarrier ? "6.66%" : "10%",
                                  borderRightColor: "#000000",
                                  borderRightWidth: 1,
                                  fontSize: 8,
                                  padding: 1,
                                }}
                              >
                                {shipperReferenceId}
                              </Text>
                              <Text
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  width: showCarrier ? "6.66%" : "10%",
                                  borderRightColor: "#000000",
                                  borderRightWidth: 1,
                                  fontSize: 8,
                                  padding: 1,
                                }}
                              >
                                {trip?.loadingCity &&
                                  `${trip.loadingCity.name?.toUpperCase()} (${trip.loadingCity.county?.name?.toUpperCase()})`}
                                {"\n"}-{"\n"}
                                {trip?.unloadingCity &&
                                  `${trip.unloadingCity.name?.toUpperCase()} (${trip.unloadingCity.county?.name?.toUpperCase()})`}
                              </Text>
                              <Text
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  width: showCarrier ? "6.66%" : "10%",
                                  borderRightColor: "#000000",
                                  borderRightWidth: 1,
                                  fontSize: 8,
                                  padding: 1,
                                }}
                              >
                                {trip?.carrierCar
                                  ? `${trip.carrierCar.vehicleRegistrationPlate
                                      ?.toUpperCase()
                                      .split("-")
                                      .join("-\n")}`
                                  : "-"}
                              </Text>
                              <Text
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  width: showCarrier ? "6.66%" : "10%",
                                  borderRightColor: "#000000",
                                  borderRightWidth: 1,
                                  fontSize: 8,
                                  padding: 1,
                                }}
                              >
                                {trip?.carrierDriver
                                  ? `${trip.carrierDriver.name?.toUpperCase()}`
                                  : "-"}
                              </Text>
                              <Text
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  width: showCarrier ? "6.66%" : "10%",
                                  borderRightColor: "#000000",
                                  borderRightWidth: 1,
                                  fontSize: 8,
                                  padding: 1,
                                }}
                              >
                                {trip?.shipper
                                  ? `${trip.shipper.name?.toUpperCase()}`
                                  : "-"}
                              </Text>
                              <Text
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  width: showCarrier ? "6.66%" : "10%",
                                  borderRightColor: "#000000",
                                  borderRightWidth: 1,
                                  fontSize: 8,
                                  padding: 1,
                                }}
                              >
                                {trip?.shipperInvoiceRows &&
                                  trip.shipperInvoiceRows.map(
                                    (shipperInvoiceRow) => {
                                      return `${shipperInvoiceRow.distanceKm} km x ${shipperInvoiceRow.value};\n`;
                                    }
                                  )}
                              </Text>
                              {/*<Text*/}
                              {/*  style={{    display: "flex",
                      flexDirection: "row",
                      width: showCarrier ? "6.66%" : "10%",
                      borderRightColor: "#000000",
                      borderRightWidth: 1,
                      fontSize: 8,
                      padding: 1,}}*/}
                              {/*>{`${shipperKm} x ${Number(shipperValue).toFixed(2)}`}</Text>*/}
                              <Text
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  width: showCarrier ? "6.66%" : "10%",
                                  borderRightColor: "#000000",
                                  borderRightWidth: 1,
                                  fontSize: 8,
                                  padding: 1,
                                }}
                              >
                                {Number(shipperValue).toFixed(2)}
                              </Text>
                              <Text
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  width: showCarrier ? "6.66%" : "10%",
                                  borderRightColor: "#000000",
                                  borderRightWidth: showCarrier ? 1 : 0,
                                  fontSize: 8,
                                  padding: 1,
                                }}
                              >
                                {Number((shipperValue * vat) / 100).toFixed(2)}
                              </Text>
                              {showCarrier && (
                                <>
                                  <Text
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      width: showCarrier ? "6.66%" : "10%",
                                      borderRightColor: "#000000",
                                      borderRightWidth: 1,
                                      fontSize: 8,
                                      padding: 1,
                                    }}
                                  >
                                    {trip?.carrier
                                      ? `${trip.carrier.name?.toUpperCase()}`
                                      : "-"}
                                  </Text>
                                  <Text
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      width: showCarrier ? "6.66%" : "10%",
                                      borderRightColor: "#000000",
                                      borderRightWidth: 1,
                                      fontSize: 8,
                                      padding: 1,
                                    }}
                                  >
                                    {trip?.carrierInvoiceRows &&
                                      trip.carrierInvoiceRows.map(
                                        (carrierInvoiceRow) => {
                                          return `${carrierInvoiceRow.distanceKm} km x ${carrierInvoiceRow.value};\n`;
                                        }
                                      )}
                                  </Text>
                                  {/*<Text*/}
                                  {/*  style={{    display: "flex",
                      flexDirection: "row",
                      width: showCarrier ? "6.66%" : "10%",
                      borderRightColor: "#000000",
                      borderRightWidth: 1,
                      fontSize: 8,
                      padding: 1,}}*/}
                                  {/*>{`${carrierKm} x ${Number(carrierValue).toFixed(2)}`}</Text>*/}
                                  <Text
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      width: showCarrier ? "6.66%" : "10%",
                                      borderRightColor: "#000000",
                                      borderRightWidth: 1,
                                      fontSize: 8,
                                      padding: 1,
                                    }}
                                  >
                                    {Number(carrierValue).toFixed(2)}
                                  </Text>
                                  <Text
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      width: showCarrier ? "6.66%" : "10%",
                                      borderRightColor: "#000000",
                                      borderRightWidth: 1,
                                      fontSize: 8,
                                      padding: 1,
                                    }}
                                  >
                                    {Number((carrierValue * vat) / 100).toFixed(
                                      2
                                    )}
                                  </Text>
                                  <div style={{ width: "6.66%" }}>
                                    <Text
                                      style={{
                                        fontSize: 8,
                                        textAlign: "center",
                                        padding: 1,
                                      }}
                                    >
                                      {Number(
                                        shipperValue - carrierValue
                                      ).toFixed(2)}
                                    </Text>
                                  </div>
                                </>
                              )}
                            </div>
                          </View>
                        );
                      })}
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        // flexGrow: 1,
                        backgroundColor: "#E5E4E2",
                        borderTopColor: "#000000",
                        borderTopWidth: 0.5,
                        borderLeftWidth: 1,
                        borderRightWidth: 1,
                        padding: 1,
                      }}
                    >
                      <Text
                        style={{
                          width: showCarrier ? "46.62%" : "70%",
                          fontSize: 8,
                          textAlign: "justify",
                          fontFamily: "Helvetica-Bold",
                        }}
                      >
                        {`Total comenzi ${result.shipperName.toUpperCase()}: ${
                          result.trips.length
                        }`}
                      </Text>
                      <Text
                        style={{
                          width: showCarrier ? "6.66%" : "10%",
                          fontSize: 8,
                          textAlign: "center",
                          fontFamily: "Helvetica-Bold",
                        }}
                      >
                        {shipperFinalTotalKm}
                      </Text>
                      <Text
                        style={{
                          width: showCarrier ? "6.66%" : "10%",
                          fontSize: 8,
                          textAlign: "center",
                          fontFamily: "Helvetica-Bold",
                        }}
                      >
                        {Number(shipperFinalTotalValue).toFixed(2)}
                      </Text>
                      <Text
                        style={{
                          width: showCarrier ? "6.66%" : "10%",
                          fontSize: 8,
                          textAlign: "center",
                          fontFamily: "Helvetica-Bold",
                        }}
                      >
                        {Number(shipperFinalTotalVat).toFixed(2)}
                      </Text>
                      {showCarrier && (
                        <>
                          <Text
                            style={{
                              width: showCarrier ? "6.66%" : "10%",
                              fontSize: 8,
                              textAlign: "center",
                              fontFamily: "Helvetica-Bold",
                            }}
                          ></Text>
                          <Text
                            style={{
                              width: showCarrier ? "6.66%" : "10%",
                              fontSize: 8,
                              textAlign: "center",
                              fontFamily: "Helvetica-Bold",
                            }}
                          >
                            {carrierFinalTotalKm}
                          </Text>
                          <Text
                            style={{
                              width: showCarrier ? "6.66%" : "10%",
                              fontSize: 8,
                              textAlign: "center",
                              fontFamily: "Helvetica-Bold",
                            }}
                          >
                            {Number(carrierFinalTotalValue).toFixed(2)}
                          </Text>
                          <Text
                            style={{
                              width: showCarrier ? "6.66%" : "10%",
                              fontSize: 8,
                              textAlign: "center",
                              fontFamily: "Helvetica-Bold",
                            }}
                          >
                            {Number(carrierFinalTotalVat).toFixed(2)}
                          </Text>
                        </>
                      )}
                      {showCarrier && (
                        <>
                          <Text
                            style={{
                              width: showCarrier ? "6.66%" : "10%",
                              fontSize: 8,
                              textAlign: "center",
                              fontFamily: "Helvetica-Bold",
                            }}
                          >
                            {Number(
                              shipperFinalTotalValue - carrierFinalTotalValue
                            ).toFixed(2)}
                          </Text>
                        </>
                      )}
                    </View>
                  </>
                );
              })}

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                flexGrow: 1,
                backgroundColor: "#E5E4E2",
                borderTopColor: "#000000",
                borderTopWidth: 1,
                borderBottomWidth: 1,
                borderLeftWidth: 1,
                borderRightWidth: 1,
                padding: 1,
              }}
            >
              <Text
                style={{
                  width: showCarrier ? "46.62%" : "70%",
                  fontSize: 8,
                  textAlign: "justify",
                  fontFamily: "Helvetica-Bold",
                }}
              >
                Total comenzi: {tripsPerShipper.length}
              </Text>
              <Text
                style={{
                  width: showCarrier ? "6.66%" : "10%",
                  fontSize: 8,
                  textAlign: "center",
                  fontFamily: "Helvetica-Bold",
                }}
              >
                {shipperReportTotalKm}
              </Text>
              <Text
                style={{
                  width: showCarrier ? "6.66%" : "10%",
                  fontSize: 8,
                  textAlign: "center",
                  fontFamily: "Helvetica-Bold",
                }}
              >
                {Number(shipperReportTotalValue).toFixed(2)}
              </Text>
              <Text
                style={{
                  width: showCarrier ? "6.66%" : "10%",
                  fontSize: 8,
                  textAlign: "center",
                  fontFamily: "Helvetica-Bold",
                }}
              >
                {Number(shipperReportTotalVat).toFixed(2)}
              </Text>
              {showCarrier && (
                <>
                  <Text
                    style={{
                      width: showCarrier ? "6.66%" : "10%",
                      fontSize: 8,
                      textAlign: "center",
                      fontFamily: "Helvetica-Bold",
                    }}
                  ></Text>
                  <Text
                    style={{
                      width: showCarrier ? "6.66%" : "10%",
                      fontSize: 8,
                      textAlign: "center",
                      fontFamily: "Helvetica-Bold",
                    }}
                  >
                    {carrierReportTotalKm}
                  </Text>
                  <Text
                    style={{
                      width: showCarrier ? "6.66%" : "10%",
                      fontSize: 8,
                      textAlign: "center",
                      fontFamily: "Helvetica-Bold",
                    }}
                  >
                    {Number(carrierReportTotalValue).toFixed(2)}
                  </Text>
                  <Text
                    style={{
                      width: showCarrier ? "6.66%" : "10%",
                      fontSize: 8,
                      textAlign: "center",
                      fontFamily: "Helvetica-Bold",
                    }}
                  >
                    {Number(carrierReportTotalVat).toFixed(2)}
                  </Text>
                </>
              )}
              {showCarrier && (
                <>
                  <Text
                    style={{
                      width: showCarrier ? "6.66%" : "10%",
                      fontSize: 8,
                      textAlign: "center",
                      fontFamily: "Helvetica-Bold",
                    }}
                  >
                    {Number(
                      shipperReportTotalValue - carrierReportTotalValue
                    ).toFixed(2)}
                  </Text>
                </>
              )}
            </View>
          </View>
        </div>
      </Page>
    </Document>
  );

  useEffect(() => {
    if (downloadPdf) {
      const worker = new Worker(
        new URL("./pdfRendererWebWorker.js", import.meta.url)
      );

      worker.postMessage({
        tripsPerShipper,
        aggregatedPerShipper,
        showCarrier,
        startDate,
        endDate,
        vat,
        shipperReportTotalKm,
        shipperReportTotalValue,
        shipperReportTotalVat,
        carrierReportTotalKm,
        carrierReportTotalValue,
        carrierReportTotalVat,
      });

      worker.onmessage = (e) => {
        onPdfDownloaded();

        saveAs(e.data, "raport.pdf");
        URL.revokeObjectURL(e.data);

        worker.terminate();
      };
    }
  }, [downloadPdf]);

  if (downloadPdf) {
    return null;
  }

  return <PDFViewer style={styles.viewer}>{document}</PDFViewer>;
}
