import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { Box, Button, Grid, Stack, Tooltip } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { dataGridRoTextConstants } from "../../../constants/dataGridRoTextConstants";
import DeleteRowDialog from "../../dialogs/DeleteRowDialog";
import { getName } from "../../../functions/valueGetterFunctions/countryCountyCityGetterFunctions";
import { countryRequest } from "../../../api/CountryRequest";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { TitleContext } from "../../../contexts/TitleProviderContext";
import PATH from "../../../paths";
import { useNavigate, useParams } from "react-router-dom";
import AddCountyDialog from "../../dialogs/country/AddCountyDialog";
import EditCountyDialog from "../../dialogs/country/EditCountyDialog";

export default function CountiesDataGrid() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { setTitle } = useContext(TitleContext);
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 90, flex: 1, hide: true },
    {
      field: "name",
      headerName: "Nume",
      width: 150,
      flex: 1,
      valueGetter: getName,
    },
    {
      field: "actions",
      headerName: "Acțiuni",
      minWidth: 125,
      maxWidth: 125,
      filterable: false,
      renderCell: ({ row: county }) => {
        return [
          <IconButton
            onClick={() => {
              navigate(PATH.CITIES.replace(":id", county.id.toString()));
              setTitle(county.name);
            }}
          >
            <Tooltip title="Vizualizează" placement="bottom">
              <VisibilityIcon fontSize="small" color="primary" />
            </Tooltip>
          </IconButton>,
          <Tooltip title="Editează" placement="bottom">
            <IconButton
              onClick={() => {
                handleOpenEditCountyDialog(county);
              }}
            >
              <EditIcon fontSize="small" color="warning" />
            </IconButton>
          </Tooltip>,
          <Tooltip title="Șterge" placement="bottom">
            <IconButton onClick={() => handleOpenDeleteRowDialog(county.id)}>
              <DeleteIcon fontSize="small" color="error" />
            </IconButton>
          </Tooltip>,
        ];
      },
    },
  ];

  const [pageSize, setPageSize] = React.useState<number>(10);
  const [rows, setRows] = useState<County[]>([]);

  const fetchCounties = () => {
    countryRequest
      .getCounties(Number(id))
      .then((counties) => setRows(counties));
  };

  useEffect(() => {
    fetchCounties();
  }, []);

  const [selectedCountyForEdit, setSelectedCountyForEdit] = useState<
    County | undefined
  >(undefined);

  const [openAddCountyDialog, setOpenAddCountyDialog] = React.useState(false);
  const handleOpenAddCountyDialog = () => {
    setOpenAddCountyDialog(true);
  };

  const [openEditCountyDialog, setOpenEditCountyDialog] = React.useState(false);
  const handleOpenEditCountyDialog = (county: County) => {
    setOpenEditCountyDialog(true);
    setSelectedCountyForEdit(county);
  };

  const [toBeDeleted, setToBeDeleted] = React.useState<number | null>(null);
  const handleOpenDeleteRowDialog = (id: any) => {
    setToBeDeleted(id);
  };
  const handleCloseDeleteRowDialog = () => {
    setToBeDeleted(null);
  };
  const openDeleteRowDialog = Boolean(toBeDeleted);

  const [filterButtonEl, setFilterButtonEl] = React.useState(null);

  const CustomToolbar = React.useCallback(() => {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <Grid
            item
            sx={{
              ml: 1,
            }}
          >
            <GridToolbarQuickFilter />
          </Grid>
          <Grid
            item
            sx={{
              mr: 0.3,
            }}
          >
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <Button size="small" onClick={handleOpenAddCountyDialog}>
                  <Stack direction="row">
                    <AddIcon
                      fontSize="small"
                      sx={{
                        mr: 1,
                      }}
                    />
                    Adaugă județ
                  </Stack>
                </Button>
              </Grid>
              <Grid item>
                {/* @ts-ignore */}
                <GridToolbarColumnsButton ref={setFilterButtonEl} />
              </Grid>
              <Grid item>
                {/* @ts-ignore */}
                <GridToolbarDensitySelector ref={setFilterButtonEl} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </GridToolbarContainer>
    );
  }, []);

  return (
    <Box
      sx={{
        height: 400,
        width: "100%",
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="flex-end"
        spacing={1}
      >
        <Grid item xs={12}>
          <div style={{ width: "100%" }}>
            <div style={{ display: "flex", height: "100%" }}>
              <div style={{ flexGrow: 1 }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  getRowId={(row) => row.id}
                  initialState={{
                    sorting: {
                      sortModel: [{ field: "name", sort: "asc" }],
                    },
                  }}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  autoHeight
                  pagination
                  disableSelectionOnClick
                  checkboxSelection={false}
                  experimentalFeatures={{ newEditingApi: true }}
                  localeText={dataGridRoTextConstants}
                  components={{ Toolbar: CustomToolbar }}
                  componentsProps={{
                    pagination: {
                      labelRowsPerPage: "Număr de înregistrări pe pagină",
                    },
                    panel: {
                      anchorEl: filterButtonEl,
                      placement: "bottom-end",
                    },
                  }}
                  sx={{
                    "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within":
                      {
                        outline: "none",
                      },

                    "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus":
                      {
                        outline: "none",
                      },
                  }}
                />
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      {openAddCountyDialog && (
        <AddCountyDialog
          open={openAddCountyDialog}
          onCountyCreated={fetchCounties}
          onClose={() => {
            setOpenAddCountyDialog(false);
          }}
          selectedCountryId={Number(id)}
        />
      )}
      {openEditCountyDialog && (
        <EditCountyDialog
          open={openEditCountyDialog}
          county={selectedCountyForEdit}
          onCountyEdited={fetchCounties}
          onClose={() => {
            setOpenEditCountyDialog(false);
            setSelectedCountyForEdit(undefined);
          }}
          selectedCountryId={Number(id)}
        />
      )}
      {openDeleteRowDialog && (
        <DeleteRowDialog
          open={openDeleteRowDialog}
          onClose={handleCloseDeleteRowDialog}
          onDelete={() => {
            if (toBeDeleted) {
              countryRequest
                .deleteCounty(toBeDeleted)
                .then(fetchCounties)
                .catch(() =>
                  alert(
                    "S-a produs o eroare! Este posibil ca înregistrarea aleasă să fie asociată cu alte tipuri de înregistrări și nu este permisă ștergerea!"
                  )
                );
            }
          }}
        />
      )}
    </Box>
  );
}
