import * as React from "react";
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useSelector } from "react-redux";
import { isAdminSelector } from "../../redux/slice/selectors";

interface ShipperRow {
  shipperName: string;
  tripCount: number;
  profit: number;
}

interface Report {
  dispatcherId: number;
  dispatcherFullName: string;
  tripCountPerShipper: ShipperRow[];
  totalTripCount: number;
  totalProfit: number;
}

interface Props {
  reportValues: Report[];
}

const getBackgroundColor = (mode: string) =>
  mode === "dark" ? "#2d2d2d" : "#ececec";

export default function TripsDispatchersReportTable(props: Props) {
  const { reportValues } = props;
  const isAdmin = useSelector(isAdminSelector);
  const [open, setOpen] = React.useState(true);
  const maxTripCount = reportValues.reduce(
    (acc, value) => (acc += value.totalTripCount),
    0
  );
  const maxProfit = reportValues.reduce(
    (acc, value) => (acc += value.totalProfit),
    0
  );

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow
            sx={{
              backgroundColor: (theme) =>
                getBackgroundColor(theme.palette.mode),
            }}
          >
            <TableCell />
            <TableCell>Dispecer</TableCell>
            {isAdmin && (
              <TableCell align="right">Total profit dispecer (RON)</TableCell>
            )}
            <TableCell align="right">Total comenzi dispecer</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reportValues.map((reportValue: Report) => {
            return (
              <>
                <TableRow
                  sx={{
                    "&& > *": { borderBottom: 0 },
                    backgroundColor: (theme) =>
                      getBackgroundColor(theme.palette.mode),
                  }}
                  onClick={() => setOpen(!open)}
                >
                  <TableCell sx={{ width: 50 }} size="small">
                    <IconButton aria-label="expand row" size="small">
                      {open ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell component="th" scope="row" size="small">
                    {`${reportValue.dispatcherFullName}`}
                  </TableCell>
                  {isAdmin && (
                    <TableCell align="right" size="small">
                      {`${reportValue.totalProfit}`}
                    </TableCell>
                  )}
                  <TableCell align="right" size="small">
                    {`${reportValue.totalTripCount} comenzi`}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={4}
                  >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                      <Box sx={{ marginBottom: 2 }}>
                        <Table size="small" aria-label="purchases">
                          <TableHead>
                            <TableRow>
                              <TableCell size="medium">Beneficiar</TableCell>
                              {isAdmin && (
                                <TableCell size="medium" align="right">
                                  Profit (RON)
                                </TableCell>
                              )}
                              <TableCell size="medium" align="right">
                                Numar comenzi
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {reportValue.tripCountPerShipper &&
                              reportValue.tripCountPerShipper?.map(
                                (shipperRow: ShipperRow) => {
                                  return (
                                    <>
                                      <TableRow key={shipperRow.shipperName}>
                                        <TableCell component="th" scope="row">
                                          {shipperRow.shipperName}
                                        </TableCell>
                                        {isAdmin && (
                                          <TableCell align="right">
                                            {shipperRow.profit}
                                          </TableCell>
                                        )}
                                        <TableCell align="right">
                                          {shipperRow.tripCount}
                                        </TableCell>
                                      </TableRow>
                                    </>
                                  );
                                }
                              )}
                          </TableBody>
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </>
            );
          })}
          <TableRow
            sx={{
              backgroundColor: (theme) =>
                getBackgroundColor(theme.palette.mode),
            }}
          >
            <TableCell align="right" />
            <TableCell align="right" />
            {isAdmin && (
              <TableCell align="right">
                Total profit: {maxProfit.toFixed(2)}
              </TableCell>
            )}
            <TableCell align="right">Total comenzi: {maxTripCount}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
