import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Autocomplete, Grid, IconButton } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ClearIcon from "@mui/icons-material/Clear";
import { useEffect, useState } from "react";
import { userRequest } from "../../../api/UserRequest";
import { useSelector } from "react-redux";
import { isAdminSelector } from "../../../redux/slice/selectors";

interface Props {
  open: boolean;
  onClose: AnyFunction;
  onUserCreated?: AnyFunction;
}

export default function AddUserDialog(props: Props) {
  const { open, onClose, onUserCreated } = props;

  const [formValues, setFormValues] = useState<any>({
    username: null,
    password: null,
    firstName: null,
    lastName: null,
    birthdate: null,
    phone: null,
    role: null,
  });

  const [roleValue, setRoleValue] = useState<Role | null>(null);

  const [error, setError] = useState({
    username: false,
    usernameIsDuplicate: false,
    password: false,
    passwordTooWeak: false,
    firstName: false,
    lastName: false,
    role: false,
  });

  const strongPasswordRegex =
    /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).{8,}$/;
  const isStrongPassword = Boolean(
    formValues.password?.match(strongPasswordRegex)
  );

  const [userData, setUserData] = useState<User[]>([]);

  const fetchUsers = () => {
    userRequest.findAll().then((data) => {
      setUserData(
        data.results.map((user: User) => ({
          id: user.id,
          username: user.username,
          password: user.password,
          firstName: user.firstName,
          lastName: user.lastName,
          birthdate: user.birthdate,
          phone: user.phone,
          role: user.role,
        }))
      );
    });
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const checkUsernameIsDuplicate = (user: User) =>
    user.username?.toLowerCase() === formValues.username?.toLowerCase();

  const handleErrors = () => {
    if (!formValues.username) {
      setError((prev: any) => ({
        ...prev,
        username: true,
      }));
    } else if (formValues.username) {
      setError((prev: any) => ({
        ...prev,
        username: false,
      }));
    }
    if (formValues.username) {
      setError((prev: any) => ({
        ...prev,
        usernameIsDuplicate: userData.some(checkUsernameIsDuplicate),
      }));
    }
    if (!formValues.password) {
      setError((prev: any) => ({
        ...prev,
        password: true,
      }));
    } else if (formValues.password) {
      setError((prev: any) => ({
        ...prev,
        password: false,
      }));
    }
    if (!isStrongPassword) {
      setError((prev: any) => ({
        ...prev,
        passwordTooWeak: true,
      }));
    } else if (isStrongPassword) {
      setError((prev: any) => ({
        ...prev,
        passwordTooWeak: false,
      }));
    }
    if (!formValues.firstName) {
      setError((prev: any) => ({
        ...prev,
        firstName: true,
      }));
    } else if (formValues.firstName) {
      setError((prev: any) => ({
        ...prev,
        firstName: false,
      }));
    }
    if (!formValues.lastName) {
      setError((prev: any) => ({
        ...prev,
        lastName: true,
      }));
    } else if (formValues.lastName) {
      setError((prev: any) => ({
        ...prev,
        lastName: false,
      }));
    }
    if (!formValues.role) {
      setError((prev: any) => ({
        ...prev,
        role: true,
      }));
    } else if (formValues.role) {
      setError((prev: any) => ({
        ...prev,
        role: false,
      }));
    }
  };

  const handleSubmit = () => {
    handleErrors();
    const formIsValid =
      formValues.username &&
      !userData.some(checkUsernameIsDuplicate) &&
      formValues.password &&
      isStrongPassword &&
      formValues.firstName &&
      formValues.lastName &&
      formValues.role;
    if (formIsValid) {
      return userRequest
        .post(formValues)
        .then(() => {
          setRoleValue(null);
          setFormValues(() => ({
            username: null,
            password: null,
            firstName: null,
            lastName: null,
            birthdate: null,
            phone: null,
            role: null,
          }));
          if (typeof onUserCreated === "function") {
            onUserCreated();
          }
        })
        .catch(() => {
          alert("S-a produs o eroare!");
        });
    }
  };

  const handleCloseAddUserDialog = () => {
    onClose();
    setRoleValue(null);
    setFormValues(() => ({
      username: null,
      password: null,
      firstName: null,
      lastName: null,
      birthdate: null,
      phone: null,
      role: null,
    }));
    setError(() => ({
      username: false,
      usernameIsDuplicate: false,
      password: false,
      passwordTooWeak: false,
      firstName: false,
      lastName: false,
      role: false,
    }));
  };

  const isAdmin = useSelector(isAdminSelector);
  const roleOptions: Role[] = [
    ...(isAdmin
      ? [
          {
            name: "admin",
            translation: "Administrator",
          },
        ]
      : []),
    {
      name: "manager",
      translation: "Manager",
    },
    {
      name: "dispatcher",
      translation: "Dispecer",
    },
    {
      name: "shipper_beneficiary",
      translation: "Beneficiar",
    },
  ];

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="xs">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <DialogTitle sx={{ pb: 0 }}>Adăugare utilizator</DialogTitle>
          </Grid>
          <Grid item sx={{ mr: 2, pt: 2 }}>
            <IconButton onClick={handleCloseAddUserDialog} tabIndex={-1}>
              <ClearIcon />
            </IconButton>
          </Grid>
        </Grid>
        <DialogContent>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={12}>
                  <TextField
                    required
                    size="small"
                    label="Nume utilizator"
                    fullWidth
                    error={error.username || error.usernameIsDuplicate}
                    helperText={
                      error.username
                        ? "Câmp obligatoriu!"
                        : error.usernameIsDuplicate &&
                          "Utilizator deja existent!"
                    }
                    onChange={(event) =>
                      setFormValues((prev: any) => ({
                        ...prev,
                        username: event.target.value.toUpperCase(),
                      }))
                    }
                    inputProps={{ style: { textTransform: "uppercase" } }}
                  ></TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    size="small"
                    label="Parolă"
                    fullWidth
                    error={error.password || error.passwordTooWeak}
                    helperText={
                      error.password
                        ? "Câmp obligatoriu!"
                        : error.passwordTooWeak && (
                            <>
                              Parola trebuie sa îndeplinească urmatoarele
                              condiții:
                              <br />
                              - să conțină minim 8 caractere
                              <br />
                              - să conțină minim o literă mică
                              <br />
                              - să conțină minim o majusculă
                              <br />- să conțină minim o cifră sau un caracter
                              special
                            </>
                          )
                    }
                    onChange={(event) =>
                      setFormValues((prev: any) => ({
                        ...prev,
                        password: event.target.value,
                      }))
                    }
                  ></TextField>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    value={roleValue}
                    onChange={(event, newValue) => {
                      setRoleValue(newValue);
                      setFormValues((prev: any) => ({
                        ...prev,
                        role: newValue?.name,
                      }));
                    }}
                    options={roleOptions}
                    filterSelectedOptions
                    noOptionsText="Fără opțiuni"
                    getOptionLabel={(option) =>
                      `${option.translation.toUpperCase()}`
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.name === value.name
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        fullWidth
                        error={error.role}
                        helperText={error.role && "Câmp obligatoriu!"}
                        size="small"
                        label="Rol"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    label="Nume"
                    fullWidth
                    error={error.lastName}
                    helperText={error.lastName && "Câmp obligatoriu!"}
                    onChange={(event) =>
                      setFormValues((prev: any) => ({
                        ...prev,
                        lastName: event.target.value.toUpperCase(),
                      }))
                    }
                    inputProps={{ style: { textTransform: "uppercase" } }}
                  ></TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    label="Prenume"
                    fullWidth
                    error={error.firstName}
                    helperText={error.firstName && "Câmp obligatoriu!"}
                    onChange={(event) =>
                      setFormValues((prev: any) => ({
                        ...prev,
                        firstName: event.target.value.toUpperCase(),
                      }))
                    }
                    inputProps={{ style: { textTransform: "uppercase" } }}
                  ></TextField>
                </Grid>
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Data nașterii"
                      value={formValues.birthdate}
                      inputFormat="dd/MM/yyyy"
                      onChange={(newValue) => {
                        setFormValues((prev: any) => ({
                          ...prev,
                          birthdate: newValue,
                        }));
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          fullWidth
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "zz/ll/aaaa",
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    label="Telefon"
                    fullWidth
                    onChange={(event) =>
                      setFormValues((prev: any) => ({
                        ...prev,
                        phone: event.target.value.toUpperCase(),
                      }))
                    }
                    inputProps={{ style: { textTransform: "uppercase" } }}
                  ></TextField>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={12} md={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    type="submit"
                    onClick={() => {
                      handleSubmit()
                        ?.then(handleCloseAddUserDialog)
                        .then(fetchUsers);
                    }}
                  >
                    Salvează
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    fullWidth
                    variant="outlined"
                    onClick={handleCloseAddUserDialog}
                  >
                    Renunță
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
