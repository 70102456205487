import RootRequest, { QueryParam } from "./RootRequest";

export default class CarrierRequest extends RootRequest {
  get apiResource() {
    return "api/carrier";
  }

  get apiRoutes(): Record<string, string> {
    return {
      // @ts-ignore
      ...super.apiRoutes,
      SEARCH_BY_NAME: `${this.apiRoute}/search-by-name/`,
    };
  }

  async findAll(limit: number = -1, offset: number = 0, activeOnly = true) {
    const queryParams: QueryParam[] = [
      { key: "limit", value: limit.toString() },
      { key: "offset", value: offset.toString() },
      { key: "activeOnly", value: activeOnly.toString() },
    ];

    const response = await this.makeRequest(
      RootRequest.METHOD.GET,
      this.apiRoutes.FIND_ALL + this.buildQueryParams(queryParams)
    );

    return response?.data;
  }

  async searchByName(searchText: string) {
    const response = await this.makeRequest(
      RootRequest.METHOD.GET,
      this.apiRoutes.SEARCH_BY_NAME + searchText
    );

    return response?.data;
  }
}

export const carrierRequest = new CarrierRequest();
