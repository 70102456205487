import RootRequest, { QueryParam } from "./RootRequest";
import adjustForTimezone from "../functions/adjustForTimezone";

export default class TripRequest extends RootRequest {
  get apiResource() {
    return "api/trip";
  }

  get dynamicIds() {
    return {
      TRIP_ID: "{tripId}",
      LINK_UUID: "{linkUuid}",
      FILE_ID: "{fileId}",
    };
  }

  get apiRoutes() {
    return {
      // @ts-ignore
      ...super.apiRoutes,
      CONFIRM_TRIP: `${this.apiRoute}/${this.dynamicIds.TRIP_ID}/confirm`,
      COMPLETE_TRIP: `${this.apiRoute}/${this.dynamicIds.TRIP_ID}/complete`,
      BILL_TRIP: `${this.apiRoute}/bill`,
      CONFIRM_PAYMENT_TRIP: `${this.apiRoute}/${this.dynamicIds.TRIP_ID}/confirm-payment`,
      TOGGLE_RECEPTION_PROBLEM_TRIP: `${this.apiRoute}/${this.dynamicIds.TRIP_ID}/toggle-reception-problem`,
      BLOCK_TRIP: `${this.apiRoute}/${this.dynamicIds.TRIP_ID}/block`,
      UNBLOCK_TRIP: `${this.apiRoute}/${this.dynamicIds.TRIP_ID}/unblock`,
      GET_CONFIRMABLE: `${this.apiRoute}/confirmable`,
      GET_BILLABLE: `${this.apiRoute}/billable`,
      GET_CONFIRM_PAYABLE: `${this.apiRoute}/confirm-payable`,
      SEARCH: `${this.apiRoute}/search`,
      SEARCH_CONFIRMABLE: `${this.apiRoute}/search-confirmable`,
      SEARCH_BILLABLE: `${this.apiRoute}/search-billable`,
      SEARCH_CONFIRM_PAYABLE: `${this.apiRoute}/search-confirm-payable`,
      SEARCH_SHIPPER_BENEFICIARY: `${this.apiRoute}/search-shipper-beneficiary`,
      SEARCH_WITH_PENDING_PUBLIC_UPLOADED_FILES: `${this.apiRoute}/search-with-pending-public-uploaded-files`,
      ADD_DOCUMENTS: `${this.apiRoute}/${this.dynamicIds.TRIP_ID}/add-documents`,
      FIND_SHIPPER_BENEFICIARY: `${this.apiRoute}/${this.dynamicIds.TRIP_ID}/shipper-beneficiary`,
      UPDATE_COMMENTS: `${this.apiRoute}/${this.dynamicIds.TRIP_ID}/update-comments`,
      GENERATE_PUBLIC_UPLOAD_LINK: `${this.apiRoute}/${this.dynamicIds.TRIP_ID}/generate-public-upload-link`,
      ACTIVATE_PUBLIC_UPLOAD_LINK: `${this.apiRoute}/activate-public-upload-link/${this.dynamicIds.LINK_UUID}`,
      FIND_PUBLIC_UPLOAD_LINK: `${this.apiRoute}/find-public-upload-link/${this.dynamicIds.LINK_UUID}`,
      ACCEPT_PUBLIC_UPLOADED_FILE: `${this.apiRoute}/accept-public-uploaded-file/${this.dynamicIds.FILE_ID}`,
      REJECT_PUBLIC_UPLOADED_FILE: `${this.apiRoute}/reject-public-uploaded-file/${this.dynamicIds.FILE_ID}`,
    };
  }

  async completeTrip(id: number) {
    const response = await this.makeRequest(
      RootRequest.METHOD.POST,
      this.apiRoutes.COMPLETE_TRIP.replace(
        this.dynamicIds.TRIP_ID,
        id?.toString()
      )
    );

    return response?.data;
  }

  async confirmTrip(id: number, attributes: any) {
    const response = await this.makeRequest(
      RootRequest.METHOD.POST,
      this.apiRoutes.CONFIRM_TRIP.replace(
        this.dynamicIds.TRIP_ID,
        id?.toString()
      ),
      attributes
    );

    return response?.data;
  }

  async billTrip(attributes: any) {
    const response = await this.makeRequest(
      RootRequest.METHOD.POST,
      this.apiRoutes.BILL_TRIP,
      attributes
    );

    return response?.data;
  }

  async confirmPaymentTrip(id: number) {
    const response = await this.makeRequest(
      RootRequest.METHOD.POST,
      this.apiRoutes.CONFIRM_PAYMENT_TRIP.replace(
        this.dynamicIds.TRIP_ID,
        id?.toString()
      )
    );

    return response?.data;
  }

  async blockTrip(id: number) {
    const response = await this.makeRequest(
      RootRequest.METHOD.POST,
      this.apiRoutes.BLOCK_TRIP.replace(this.dynamicIds.TRIP_ID, id?.toString())
    );

    return response?.data;
  }

  async toggleReceptionProblemTrip(id: number) {
    const response = await this.makeRequest(
      RootRequest.METHOD.POST,
      this.apiRoutes.TOGGLE_RECEPTION_PROBLEM_TRIP.replace(
        this.dynamicIds.TRIP_ID,
        id?.toString()
      )
    );

    return response?.data;
  }

  async unblockTrip(id: number) {
    const response = await this.makeRequest(
      RootRequest.METHOD.POST,
      this.apiRoutes.UNBLOCK_TRIP.replace(
        this.dynamicIds.TRIP_ID,
        id?.toString()
      )
    );

    return response?.data;
  }

  async findAllConfirmable(limit: number = 1000, offset: number = 0) {
    const queryParams: QueryParam[] = [
      { key: "limit", value: limit.toString() },
      { key: "offset", value: offset.toString() },
    ];

    const response = await this.makeRequest(
      RootRequest.METHOD.GET,
      this.apiRoutes.GET_CONFIRMABLE + this.buildQueryParams(queryParams)
    );

    return response?.data;
  }

  async findAllBillable(limit: number = 1000, offset: number = 0) {
    const queryParams: QueryParam[] = [
      { key: "limit", value: limit.toString() },
      { key: "offset", value: offset.toString() },
    ];

    const response = await this.makeRequest(
      RootRequest.METHOD.GET,
      this.apiRoutes.GET_BILLABLE + this.buildQueryParams(queryParams)
    );

    return response?.data;
  }

  async findAllConfirmPayable(limit: number = 1000, offset: number = 0) {
    const queryParams: QueryParam[] = [
      { key: "limit", value: limit.toString() },
      { key: "offset", value: offset.toString() },
    ];

    const response = await this.makeRequest(
      RootRequest.METHOD.GET,
      this.apiRoutes.GET_CONFIRM_PAYABLE + this.buildQueryParams(queryParams)
    );

    return response?.data;
  }

  async search(
    limit: number = 1000,
    offset: number = 0,
    searchText: string = "",
    status: any = undefined,
    startDate: Date | undefined,
    endDate: Date | undefined,
    carrierId: number | undefined,
    shipperId: number | undefined,
    dispatcherId: number | undefined,
    shipperInvoiceId?: number | undefined
  ) {
    const queryParams: QueryParam[] = [
      { key: "limit", value: limit.toString() },
      { key: "offset", value: offset.toString() },
    ];

    if (searchText) {
      queryParams.push({ key: "searchText", value: searchText });
    }
    if (status) {
      queryParams.push({ key: "status", value: status });
    }
    if (startDate) {
      queryParams.push({
        key: "startDate",
        value: adjustForTimezone(startDate).toISOString(),
      });
    }
    if (endDate) {
      queryParams.push({
        key: "endDate",
        value: adjustForTimezone(endDate).toISOString(),
      });
    }
    if (shipperId) {
      queryParams.push({ key: "shipperId", value: shipperId.toString() });
    }
    if (carrierId) {
      queryParams.push({ key: "carrierId", value: carrierId.toString() });
    }
    if (dispatcherId) {
      queryParams.push({ key: "dispatcherId", value: dispatcherId.toString() });
    }
    if (shipperInvoiceId) {
      queryParams.push({
        key: "shipperInvoiceId",
        value: shipperInvoiceId.toString(),
      });
    }

    const response = await this.makeRequest(
      RootRequest.METHOD.GET,
      this.apiRoutes.SEARCH + this.buildQueryParams(queryParams)
    );

    return response?.data;
  }

  async searchConfirmable(
    limit: number = 1000,
    offset: number = 0,
    searchText: string = ""
  ) {
    const queryParams: QueryParam[] = [
      { key: "limit", value: limit.toString() },
      { key: "offset", value: offset.toString() },
    ];

    if (searchText) {
      queryParams.push({ key: "searchText", value: searchText });
    }

    const response = await this.makeRequest(
      RootRequest.METHOD.GET,
      this.apiRoutes.SEARCH_CONFIRMABLE + this.buildQueryParams(queryParams)
    );

    return response?.data;
  }

  async searchBillable(
    limit: number = 1000,
    offset: number = 0,
    searchText: string = "",
    shipperId: number | undefined
  ) {
    const queryParams: QueryParam[] = [
      { key: "limit", value: limit.toString() },
      { key: "offset", value: offset.toString() },
    ];

    if (searchText) {
      queryParams.push({ key: "searchText", value: searchText });
    }

    if (shipperId) {
      queryParams.push({ key: "shipperId", value: shipperId.toString() });
    }

    const response = await this.makeRequest(
      RootRequest.METHOD.GET,
      this.apiRoutes.SEARCH_BILLABLE + this.buildQueryParams(queryParams)
    );

    return response?.data;
  }

  async searchConfirmPayable(
    limit: number = 1000,
    offset: number = 0,
    searchText: string = "",
    maxDaysUntilDueDate: number | undefined
  ) {
    const queryParams: QueryParam[] = [
      { key: "limit", value: limit.toString() },
      { key: "offset", value: offset.toString() },
    ];

    if (searchText) {
      queryParams.push({ key: "searchText", value: searchText });
    }

    if (maxDaysUntilDueDate) {
      queryParams.push({
        key: "maxDaysUntilDueDate",
        value: maxDaysUntilDueDate.toString(),
      });
    }

    const response = await this.makeRequest(
      RootRequest.METHOD.GET,
      this.apiRoutes.SEARCH_CONFIRM_PAYABLE + this.buildQueryParams(queryParams)
    );

    return response?.data;
  }

  async searchShipperBeneficiary(
    limit: number = 1000,
    offset: number = 0,
    searchText: string = ""
  ) {
    const queryParams: QueryParam[] = [
      { key: "limit", value: limit.toString() },
      { key: "offset", value: offset.toString() },
    ];

    if (searchText) {
      queryParams.push({ key: "searchText", value: searchText });
    }

    const response = await this.makeRequest(
      RootRequest.METHOD.GET,
      this.apiRoutes.SEARCH_SHIPPER_BENEFICIARY +
        this.buildQueryParams(queryParams)
    );

    return response?.data;
  }

  async searchWithPendingPublicUploadedFiles(
    limit: number = 1000,
    offset: number = 0,
    searchText: string = ""
  ) {
    const queryParams: QueryParam[] = [
      { key: "limit", value: limit.toString() },
      { key: "offset", value: offset.toString() },
    ];

    if (searchText) {
      queryParams.push({ key: "searchText", value: searchText });
    }

    const response = await this.makeRequest(
      RootRequest.METHOD.GET,
      this.apiRoutes.SEARCH_WITH_PENDING_PUBLIC_UPLOADED_FILES +
        this.buildQueryParams(queryParams)
    );

    return response?.data;
  }

  async addDocuments(id: number, fileIds: number[]) {
    const response = await this.makeRequest(
      RootRequest.METHOD.POST,
      this.apiRoutes.ADD_DOCUMENTS.replace(
        this.dynamicIds.TRIP_ID,
        id.toString()
      ),
      { fileIds }
    );

    return response?.data;
  }

  async findForShipperBeneficiary(id: number) {
    const response = await this.makeRequest(
      RootRequest.METHOD.GET,
      this.apiRoutes.FIND_SHIPPER_BENEFICIARY.replace(
        this.dynamicIds.TRIP_ID,
        id.toString()
      )
    );

    return response?.data;
  }

  async updateComments(id: number, comments: string) {
    const response = await this.makeRequest(
      RootRequest.METHOD.PATCH,
      this.apiRoutes.UPDATE_COMMENTS.replace(
        this.dynamicIds.TRIP_ID,
        id?.toString()
      ),
      { comments }
    );

    return response?.data;
  }

  async generatePublicUploadLink(id: number) {
    const response = await this.makeRequest(
      RootRequest.METHOD.POST,
      this.apiRoutes.GENERATE_PUBLIC_UPLOAD_LINK.replace(
        this.dynamicIds.TRIP_ID,
        id.toString()
      )
    );

    return response?.data;
  }

  async activatePublicUploadLink(uuid: string) {
    const response = await this.makeRequest(
      RootRequest.METHOD.POST,
      this.apiRoutes.ACTIVATE_PUBLIC_UPLOAD_LINK.replace(
        this.dynamicIds.LINK_UUID,
        uuid
      )
    );

    return response?.data;
  }

  async findPublicUploadLink(uuid: string) {
    const response = await this.makeRequest(
      RootRequest.METHOD.GET,
      this.apiRoutes.FIND_PUBLIC_UPLOAD_LINK.replace(
        this.dynamicIds.LINK_UUID,
        uuid
      )
    );

    return response?.data;
  }

  async acceptPublicUploadedFile(id: number) {
    const response = await this.makeRequest(
      RootRequest.METHOD.POST,
      this.apiRoutes.ACCEPT_PUBLIC_UPLOADED_FILE.replace(
        this.dynamicIds.FILE_ID,
        id.toString()
      )
    );

    return response?.data;
  }

  async rejectPublicUploadedFile(id: number) {
    const response = await this.makeRequest(
      RootRequest.METHOD.POST,
      this.apiRoutes.REJECT_PUBLIC_UPLOADED_FILE.replace(
        this.dynamicIds.FILE_ID,
        id.toString()
      )
    );

    return response?.data;
  }
}

export const tripRequest = new TripRequest();
