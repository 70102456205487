import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import { menuItems } from "./menuItems";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../../contexts/ThemeContext";
import { useContext } from "react";
import { Chip, Divider, Grid, IconButton, Tooltip } from "@mui/material";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { styled } from "@mui/material/styles";
import { LocalStorage } from "../../localStorage/LocalStorage";
import { TitleContext } from "../../contexts/TitleProviderContext";
import { useDispatch, useSelector } from "react-redux";
import {
  isAdminOrManagerSelector,
  isAdminSelector,
  isShipperBeneficiarySelector,
  userSelector,
} from "../../redux/slice/selectors";
import { grey } from "@mui/material/colors";
import efeImpexLogo from "../../assets/efe_logo_transparent.png";

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const StyledLightChip = styled(Chip)(() => ({
  backgroundColor: "#ffffff",
  color: "#1976D2",
}));

const StyledDarkChip = styled(Chip)(() => ({
  backgroundColor: grey[800],
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
    }),
  },
}));

function MenuBarContent() {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(
    !!LocalStorage.get(LocalStorage.KEYS.SIDEBAR_IS_OPEN)
  );
  const toggleDrawer = () => {
    setOpen(!open);
    LocalStorage.set(LocalStorage.KEYS.SIDEBAR_IS_OPEN, !open);
  };
  // @ts-ignore
  const { isDarkTheme, setLightTheme, setDarkTheme } = useContext(ThemeContext);
  const changeTheme = () => {
    if (isDarkTheme) {
      setLightTheme();
    } else {
      setDarkTheme();
    }
  };

  const [openReports, setOpenReports] = React.useState(
    !!LocalStorage.get(LocalStorage.KEYS.REPORTS_IS_OPEN)
  );

  const handleOpenReports = () => {
    setOpenReports(!openReports);
    LocalStorage.set(LocalStorage.KEYS.REPORTS_IS_OPEN, !openReports);
  };

  const [openManagement, setOpenManagement] = React.useState(
    !!LocalStorage.get(LocalStorage.KEYS.MANAGEMENT_IS_OPEN)
  );

  const handleOpenManagement = () => {
    setOpenManagement(!openManagement);
    LocalStorage.set(LocalStorage.KEYS.MANAGEMENT_IS_OPEN, !openManagement);
  };

  const { title, setTitle } = useContext(TitleContext);

  const dispatch = useDispatch();

  const user = useSelector(userSelector);

  const isAdminOrManager = useSelector(isAdminOrManagerSelector);
  const isAdmin = useSelector(isAdminSelector);
  const isShipperBeneficiary = useSelector(isShipperBeneficiarySelector);

  return (
    <>
      <AppBar position="absolute" open={open}>
        <Toolbar
          sx={{
            pr: "24px", // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                sx={{ flexGrow: 1 }}
              >
                {title}
              </Typography>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <IconButton onClick={changeTheme} color="inherit">
                    {isDarkTheme ? (
                      <Tooltip title="Aspect luminos">
                        <Brightness7Icon />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Aspect întunecat">
                        <Brightness4Icon />
                      </Tooltip>
                    )}
                  </IconButton>
                </Grid>
                <Grid item>
                  {isDarkTheme ? (
                    <StyledDarkChip
                      icon={<AccountCircleIcon />}
                      label={`${user.lastName} ${user.firstName}`}
                    ></StyledDarkChip>
                  ) : (
                    <StyledLightChip
                      icon={<AccountCircleIcon style={{ color: "#1976D2" }} />}
                      label={`${user.lastName} ${user.firstName}`}
                    ></StyledLightChip>
                  )}
                  {/*<Typography>{`${user.username}`}</Typography>*/}
                  {/*<Typography*/}
                  {/*  variant="button"*/}
                  {/*  fontSize="small"*/}
                  {/*>{`${user.lastName} ${user.firstName}`}</Typography>*/}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            px: [1],
          }}
        >
          <img
            alt={""}
            src={efeImpexLogo}
            style={{
              minWidth: 111,
              maxWidth: 111,
              minHeight: 64,
              maxHeight: 64,
              marginRight: 15,
            }}
          />
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav">
          {menuItems(
            openReports,
            openManagement,
            handleOpenReports,
            handleOpenManagement,
            setTitle,
            navigate,
            dispatch,
            isAdmin,
            isAdminOrManager,
            isShipperBeneficiary
          )}
        </List>
      </Drawer>
    </>
  );
}

export default function MenuBar() {
  return <MenuBarContent />;
}
