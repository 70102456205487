import React, { useEffect, useState } from "react";
import "./App.css";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import PATH from "./paths";
import Login from "./containers/Login";
import Trips from "./containers/trips/Trips";
import ConfirmTrip from "./containers/ConfirmTrip";
import ShipperInvoice from "./containers/ShipperInvoice";
import ConfirmPayment from "./containers/ConfirmPayment";
import { TitleContext } from "./contexts/TitleProviderContext";
import Users from "./containers/management/Users";
import InvoiceSeries from "./containers/management/InvoiceSeries";
import Shippers from "./containers/management/Shippers";
import Carriers from "./containers/management/Carriers";
import CargoTypes from "./containers/management/CargoTypes";
import CarrierCars from "./containers/management/CarrierCars";
import CarrierDrivers from "./containers/management/CarrierDrivers";
import TripView from "./containers/trips/TripView";
import routeTitleMapObject from "./helpers/routeTitleMapObject";
import { useDispatch, useSelector } from "react-redux";
import { me } from "./redux/slice/auth";
import { RequireAuth } from "./decorators/RequiresAuth";
import ShipperBranches from "./containers/management/ShipperBranches";
import Countries from "./containers/management/Countries";
import Counties from "./containers/management/Counties";
import Cities from "./containers/management/Cities";
import TripsShippersReport from "./containers/reports/TripsShippersReport";
import TripsDispatchersReport from "./containers/reports/TripsDispatchersReport";
import LoserTripsReport from "./containers/reports/LoserTripsReport";
import ProfitLessThanOneHundredTripsReport from "./containers/reports/ProfitLessThanOneHundredTripsReport";
import { isShipperBeneficiarySelector } from "./redux/slice/selectors";
import PublicUploadTripFiles from "./components/publicAccess/PublicUploadTripFiles";
import ValidateTripPublicUploadedFiles from "./containers/ValidateTripPublicUploadedFiles";
import TopClientsReport from "./containers/reports/TopClientsReport";

function App() {
  const dispatch: any = useDispatch();
  const [showAppContent, setShowAppContent] = useState(false);
  useEffect(() => {
    dispatch(me())
      .unwrap()
      .finally(() => setShowAppContent(true));
  }, []);
  const isShipperBeneficiary = useSelector(isShipperBeneficiarySelector);

  const location = useLocation();
  const [title, setTitle] = useState(routeTitleMapObject[location.pathname]);

  let routeContent = (
    <Routes>
      <Route
        path={PATH.TRIP.PUBLIC_UPLOAD_FILES}
        element={<PublicUploadTripFiles />}
      />
      <Route path="*" element={<Navigate to="/login" replace />} />
      <Route path={PATH.LOGIN} element={<Login />} />
      <Route
        path={PATH.TRIP.LIST}
        element={
          <RequireAuth>
            <Trips />
          </RequireAuth>
        }
      />
      <Route
        path={PATH.TRIP.VIEW}
        element={
          <RequireAuth>
            <TripView />
          </RequireAuth>
        }
      />
      <Route
        path={PATH.CONFIRM_TRIP}
        element={
          <RequireAuth>
            <ConfirmTrip />
          </RequireAuth>
        }
      />
      <Route
        path={PATH.SHIPPER_INVOICE}
        element={
          <RequireAuth>
            <ShipperInvoice />
          </RequireAuth>
        }
      />
      <Route
        path={PATH.DUE_INVOICES}
        element={
          <RequireAuth>
            <ConfirmPayment />
          </RequireAuth>
        }
      />
      <Route
        path={PATH.VALIDATE_TRIP_PUBLIC_UPLOADED_FILES}
        element={
          <RequireAuth>
            <ValidateTripPublicUploadedFiles />
          </RequireAuth>
        }
      />
      <Route
        path={PATH.REPORTS.TRIPS_SHIPPERS}
        element={
          <RequireAuth>
            <TripsShippersReport />
          </RequireAuth>
        }
      />
      <Route
        path={PATH.REPORTS.TRIPS_DISPATCHERS}
        element={
          <RequireAuth>
            <TripsDispatchersReport />
          </RequireAuth>
        }
      />
      <Route
        path={PATH.REPORTS.LOSER_TRIPS}
        element={
          <RequireAuth>
            <LoserTripsReport />
          </RequireAuth>
        }
      />
      <Route
        path={PATH.REPORTS.PROFIT_LESS_THAN_ONE_HUNDRED}
        element={
          <RequireAuth>
            <ProfitLessThanOneHundredTripsReport />
          </RequireAuth>
        }
      />
      <Route
        path={PATH.REPORTS.TOP_CLIENTS}
        element={
          <RequireAuth>
            <TopClientsReport />
          </RequireAuth>
        }
      />
      <Route
        path={PATH.USERS}
        element={
          <RequireAuth>
            <Users />
          </RequireAuth>
        }
      />
      <Route
        path={PATH.INVOICE_SERIES}
        element={
          <RequireAuth>
            <InvoiceSeries />
          </RequireAuth>
        }
      />
      <Route
        path={PATH.SHIPPERS}
        element={
          <RequireAuth>
            <Shippers />
          </RequireAuth>
        }
      />
      <Route
        path={PATH.SHIPPER_BRANCHES}
        element={
          <RequireAuth>
            <ShipperBranches />
          </RequireAuth>
        }
      />
      <Route
        path={PATH.CARRIERS}
        element={
          <RequireAuth>
            <Carriers />
          </RequireAuth>
        }
      />
      <Route
        path={PATH.CARGO_TYPES}
        element={
          <RequireAuth>
            <CargoTypes />
          </RequireAuth>
        }
      />
      <Route
        path={PATH.CARRIER_CARS}
        element={
          <RequireAuth>
            <CarrierCars />
          </RequireAuth>
        }
      />
      <Route
        path={PATH.CARRIER_DRIVERS}
        element={
          <RequireAuth>
            <CarrierDrivers />
          </RequireAuth>
        }
      />
      <Route
        path={PATH.COUNTRIES}
        element={
          <RequireAuth>
            <Countries />
          </RequireAuth>
        }
      />
      <Route
        path={PATH.COUNTIES}
        element={
          <RequireAuth>
            <Counties />
          </RequireAuth>
        }
      />
      <Route
        path={PATH.CITIES}
        element={
          <RequireAuth>
            <Cities />
          </RequireAuth>
        }
      />
    </Routes>
  );

  if (isShipperBeneficiary) {
    routeContent = (
      <Routes>
        <Route path="*" element={<Navigate to="/login" replace />} />
        <Route path={PATH.LOGIN} element={<Login />} />
        <Route
          path={PATH.TRIP.LIST}
          element={
            <RequireAuth>
              <Trips />
            </RequireAuth>
          }
        />
      </Routes>
    );
  }

  return showAppContent ? (
    <TitleContext.Provider value={{ title, setTitle }}>
      {routeContent}
    </TitleContext.Provider>
  ) : null;
}

export default App;
