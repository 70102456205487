import {
  DataGrid,
  GridColDef,
  GridFilterModel,
  GridRowClassNameParams,
  GridSelectionModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import {
  Autocomplete,
  Box,
  Button,
  darken,
  Grid,
  lighten,
  Stack,
  Tooltip,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import React from "react";
import GradingIcon from "@mui/icons-material/Grading";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { dataGridRoTextConstants } from "../../constants/dataGridRoTextConstants";
import ShipperInvoiceDialog, {
  OnTripsBilledMetadata,
} from "../dialogs/ShipperInvoiceDialog";
import {
  getCargoType,
  getCarrier,
  getCarrierCar,
  getCarrierDriver,
  getCarrierInvoice,
  getComments,
  getDate,
  getDispatcher,
  getLoadingAddress,
  getLoadingCity,
  getLoadingDate,
  getObservations,
  getCarrierPaymentTermInDays,
  getShipperPaymentTermInDays,
  getShipper,
  getShipperBranch,
  getShipperInvoice,
  getShipperReferenceId,
  getStatus,
  getUnloadingAddress,
  getUnloadingCity,
  getUnloadingDate,
} from "../../functions/valueGetterFunctions/tripValueGetterFunctions";
import { tripRequest } from "../../api/TripRequest";
import TextField from "@mui/material/TextField";
import { shipperRequest } from "../../api/ShipperRequest";
import { useNavigate } from "react-router-dom";
import { TitleContext } from "../../contexts/TitleProviderContext";
import IconButton from "@mui/material/IconButton";
import PATH from "../../paths";
import VisibilityIcon from "@mui/icons-material/Visibility";
import InvoicePDFDialog from "../dialogs/PDF/InvoicePDFDialog";
import ChooseInvoicePDFTemplateDialog from "../dialogs/PDF/ChooseInvoicePDFTemplateDialog";
import { useSkipFirstRun } from "../../helpers/hooks";
import { TripStatus } from "./TripsDataGrid";
import GenerateSagaXmlDialog from "../dialogs/GenerateSagaXmlDialog";
import { fileRequest } from "../../api/FileRequest";
import { useSearchParams } from "react-router-dom";

const getBackgroundColor = (color: string, mode: string) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

export default function ShipperInvoiceDataGrid() {
  const navigate = useNavigate();
  const { setTitle } = useContext(TitleContext);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = React.useState<number>(25);
  const [searchText, setSearchText] = React.useState("");
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [shipperId, setShipperId] = React.useState<number | undefined>(
    undefined
  );
  const [pageIsLoading, setPageIsLoading] = useState(false);
  const [rows, setRows] = useState<Trip[]>([]);
  const [rowCount, setRowCount] = useState(0);
  const [selectedShipper, setSelectedShipper] = useState<Shipper | null>(null);
  const [selectedTripsForBilling, setSelectedTripsForBilling] = useState<
    Trip[]
  >([]);
  const [tripsForPDF, setTripsForPDF] = useState<Trip[]>([]);

  const columns: GridColDef[] = [
    { field: "id", headerName: "Număr", minWidth: 70, flex: 0.5 },
    {
      field: "date",
      headerName: "Dată",
      minWidth: 90,
      flex: 0.7,
      valueGetter: getDate,
    },
    {
      field: "loadingDate",
      headerName: "Dată încărcare",
      width: 160,
      flex: 1,
      valueGetter: getLoadingDate,
      hide: true,
    },
    {
      field: "loadingCity",
      headerName: "Plecare",
      minWidth: 150,
      flex: 1,
      valueGetter: getLoadingCity,
    },
    {
      field: "loadingAddress",
      headerName: "Adresă încărcare",
      width: 160,
      flex: 1,
      valueGetter: getLoadingAddress,
      hide: true,
    },
    {
      field: "unloadingDate",
      headerName: "Dată descărcare",
      width: 160,
      flex: 1,
      valueGetter: getUnloadingDate,
      hide: true,
    },
    {
      field: "unloadingCity",
      headerName: "Sosire",
      minWidth: 150,
      flex: 1,
      valueGetter: getUnloadingCity,
    },
    {
      field: "unloadingAddress",
      headerName: "Adresă descărcare",
      width: 160,
      flex: 1,
      valueGetter: getUnloadingAddress,
      hide: true,
    },
    {
      field: "shipper",
      headerName: "Beneficiar",
      minWidth: 125,
      flex: 1,
      valueGetter: getShipper,
    },
    {
      field: "shipperBranch",
      headerName: "Punct de lucru beneficiar",
      width: 160,
      flex: 1,
      valueGetter: getShipperBranch,
    },
    {
      field: "carrier",
      headerName: "Transportator",
      minWidth: 125,
      flex: 1,
      valueGetter: getCarrier,
    },
    {
      field: "shipperPaymentTermInDays",
      headerName: "Termen beneficiar",
      width: 160,
      flex: 0.7,
      valueGetter: getShipperPaymentTermInDays,
      hide: true,
    },
    {
      field: "carrierCar",
      headerName: "Mașină",
      minWidth: 90,
      flex: 1,
      valueGetter: getCarrierCar,
    },
    {
      field: "carrierDriver",
      headerName: "Șofer",
      minWidth: 75,
      flex: 1,
      valueGetter: getCarrierDriver,
    },
    {
      field: "shipperInvoice",
      headerName: "Fact. ben.",
      minWidth: 75,
      flex: 1,
      valueGetter: getShipperInvoice,
    },
    {
      field: "carrierInvoice",
      headerName: "Fact. trans.",
      minWidth: 75,
      flex: 1,
      valueGetter: getCarrierInvoice,
    },
    {
      field: "carrierPaymentTermInDays",
      headerName: "T.t.",
      minWidth: 50,
      flex: 1,
      valueGetter: getCarrierPaymentTermInDays,
    },
    {
      field: "computedStatus",
      headerName: "Status",
      width: 160,
      flex: 1,
      valueGetter: getStatus,
      hide: true,
    },
    {
      field: "cargoType",
      headerName: "Tip marfă",
      width: 160,
      flex: 1,
      valueGetter: getCargoType,
      hide: true,
    },
    {
      field: "dispatcher",
      headerName: "Dispecer",
      width: 160,
      flex: 1,
      valueGetter: getDispatcher,
      hide: true,
    },
    {
      field: "shipperReferenceId",
      headerName: "Ref.",
      minWidth: 60,
      flex: 0.7,
      valueGetter: getShipperReferenceId,
    },
    {
      field: "observations",
      headerName: "Observații",
      width: 160,
      flex: 1,
      valueGetter: getObservations,
      hide: true,
    },
    {
      field: "comments",
      headerName: "Comentarii",
      width: 160,
      flex: 1,
      valueGetter: getComments,
    },
    {
      field: "actions",
      headerName: "Acțiuni",
      minWidth: 60,
      maxWidth: 60,
      filterable: false,
      renderCell: ({ row: trip }) => {
        return [
          <>
            <Tooltip title="Vizualizează" placement="bottom">
              <IconButton
                onClick={() => {
                  navigate(PATH.TRIP.VIEW.replace(":id", trip.id.toString()));
                  setTitle("Detalii comandă");
                }}
                color="primary"
              >
                <VisibilityIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </>,
        ];
      },
    },
  ];

  const fetchBillableTrips = () => {
    setPageIsLoading(true);
    tripRequest
      .searchBillable(pageSize, page * pageSize, searchText, shipperId)
      .then((data) => {
        setRows(
          data.results.map((trip: Trip) => ({
            id: trip.id,
            date: trip.date,
            cargoType: trip.cargoType,
            dispatcher: trip.dispatcher,
            loadingDate: trip.loadingDate,
            loadingAddress: trip.loadingAddress,
            loadingCity: trip.loadingCity,
            unloadingDate: trip.unloadingDate,
            unloadingAddress: trip.unloadingAddress,
            unloadingCity: trip.unloadingCity,
            carrier: trip.carrier,
            carrierCar: trip.carrierCar,
            carrierDriver: trip.carrierDriver,
            carrierInvoice: trip.carrierInvoice,
            carrierInvoiceRows: trip.carrierInvoiceRows,
            carrierPaymentTermInDays: trip.carrierPaymentTermInDays,
            shipper: trip.shipper,
            shipperBranch: trip.shipperBranch,
            shipperReferenceId: trip.shipperReferenceId,
            shipperInvoice: trip.shipperInvoice,
            shipperInvoiceRows: trip.shipperInvoiceRows,
            shipperPaymentTermInDays: trip.shipperPaymentTermInDays,
            observations: trip.observations,
            comments: trip.comments,
            computedStatus: trip.computedStatus,
            status: trip.status,
          }))
        );
        setRowCount(data.total);
      })
      .finally(() => {
        setPageIsLoading(false);
      });
  };

  const fetchTripsForPDF = (shipperInvoice: ShipperInvoice) => {
    setPageIsLoading(true);
    return tripRequest
      .search(
        -1,
        0,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        shipperInvoice.id
      )
      .then((data) => {
        setTripsForPDF(
          data.results.map((trip: Trip) => ({
            id: trip.id,
            date: trip.date,
            cargoType: trip.cargoType,
            dispatcher: trip.dispatcher,
            loadingDate: trip.loadingDate,
            loadingAddress: trip.loadingAddress,
            loadingCity: trip.loadingCity,
            unloadingDate: trip.unloadingDate,
            unloadingAddress: trip.unloadingAddress,
            unloadingCity: trip.unloadingCity,
            carrier: trip.carrier,
            carrierCar: trip.carrierCar,
            carrierDriver: trip.carrierDriver,
            carrierInvoice: trip.carrierInvoice,
            carrierInvoiceRows: trip.carrierInvoiceRows,
            carrierPaymentTermInDays: trip.carrierPaymentTermInDays,
            shipper: trip.shipper,
            shipperBranch: trip.shipperBranch,
            shipperReferenceId: trip.shipperReferenceId,
            shipperInvoice: trip.shipperInvoice,
            shipperInvoiceRows: trip.shipperInvoiceRows,
            shipperInvoiceDueDate: trip.shipperInvoiceDueDate,
            shipperPaymentTermInDays: trip.shipperPaymentTermInDays,
            observations: trip.observations,
            comments: trip.comments,
            computedStatus: trip.computedStatus,
            status: trip.status,
          }))
        );
      })
      .finally(() => {
        setPageIsLoading(false);
      });
  };

  useEffect(() => {
    fetchBillableTrips();
  }, [pageSize, page, searchText, shipperId]);

  const [shipperOptions, setShipperOptions] = useState<Shipper[]>([]);
  const fetchShippers = () => {
    shipperRequest.findAll(10, 0).then((data) => {
      setShipperOptions(
        data.results.map((shipper: Shipper) => ({
          id: shipper.id,
          name: shipper.name,
          tin: shipper.tin,
          businessRegistrationNumber: shipper.businessRegistrationNumber,
          bank: shipper.bank,
          iban: shipper.iban,
          city: shipper.city,
          address: shipper.address,
          phone: shipper.phone,
          email: shipper.email,
          contactPerson: shipper.contactPerson,
          isActive: shipper.isActive,
        }))
      );
    });
  };

  useEffect(() => {
    fetchShippers();
  }, []);

  const sortedShipperOptions = shipperOptions.sort(function (a, b) {
    if (a.name! < b.name!) {
      return -1;
    }
    if (a.name! > b.name!) {
      return 1;
    }
    return 0;
  });

  const [openShipperInvoiceDialog, setOpenShipperInvoiceDialog] =
    React.useState(false);
  const [openGenerateSagaXmlDialog, setOpenGenerateSagaXmlDialog] =
    React.useState(false);

  const handleOpenShipperInvoiceDialog = () => {
    setOpenShipperInvoiceDialog(true);
  };

  const [filterButtonEl, setFilterButtonEl] = React.useState(null);

  const CustomToolbar = React.useCallback(() => {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <Grid
            item
            sx={{
              ml: 1,
            }}
          >
            <GridToolbarQuickFilter />
          </Grid>
          <Grid
            item
            sx={{
              mr: 0.3,
            }}
          >
            <Grid container direction="row" spacing={1}>
              <Grid item>
                {/* @ts-ignore */}
                <GridToolbarColumnsButton ref={setFilterButtonEl} />
              </Grid>
              <Grid item>
                {/* @ts-ignore */}
                <GridToolbarDensitySelector ref={setFilterButtonEl} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </GridToolbarContainer>
    );
  }, [selectedTripsForBilling]);

  const [openInvoicePDFDialog, setOpenInvoicePDFDialog] = React.useState(false);
  const handleOpenInvoicePDFDialog = () => {
    setOpenInvoicePDFDialog(true);
  };

  const [invoiceTemplate, setInvoiceTemplate] = useState("");

  const [toGenerateInvoiceId, setToGenerateInvoiceId] = useState<
    number | undefined
  >(undefined);

  const openChooseInvoicePDFTemplateDialog = Boolean(toGenerateInvoiceId);
  const [uploadShipperInvoice, setUploadShipperInvoice] = React.useState(false);

  const handleOpenChooseInvoicePDFTemplateDialog = (
    shipperInvoice: ShipperInvoice
  ) => {
    setUploadShipperInvoice(true);
    setToGenerateInvoiceId(shipperInvoice.id);
  };

  const [searchShipperTextInput, setSearchShipperTextInput] = useState("");

  useSkipFirstRun(() => {
    if (!searchShipperTextInput) {
      fetchShippers();
      return;
    }

    const timeoutId = setTimeout(() => {
      shipperRequest
        .searchByName(searchShipperTextInput)
        .then((results) => setShipperOptions(results));
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchShipperTextInput]);

  const [onTripsBilledMetadata, setOnTripsBilledMetadata] = useState<
    OnTripsBilledMetadata | undefined
  >(undefined);

  const openMailToShipper = (metadata: OnTripsBilledMetadata) => {
    const archivePublicDownloadLink = `${fileRequest.apiRoute}/public-download-shipper-invoice-files/${metadata.mailToShipperInvoice.publicDownloadAssociatedFilesUuid}`;
    const invoice = metadata.mailToShipperInvoice;

    const a = document.createElement("a");
    a.href = `mailto:${metadata.mailToShipperInvoice.shipper?.email}?subject=Documente%20aferente%20facturii%20${invoice?.invoiceSeries?.name}%20${invoice.number}&body=Buna%20ziua,%0D%0A%0D%0APrin%20prezentul%20e-mail,%20va%20transmitem%20ca%20factura%20cu%20numarul%20${invoice?.invoiceSeries?.name}%20${invoice.number}%20a%20fost%20emisa.%0D%0A%0D%0ADocumentele%20aferente%20curselor%20pentru%20care%20a%20fost%20emisa%20factura%20pot%20fi%20descarcate%20in%20arhiva%20accesand%20urmatorul%20link:%20%0D%0A%0D%0A${archivePublicDownloadLink}%0D%0A%0D%0APentru%20informații%20legate%20de%20facturi%20respectiv%20documente%20va%20rugam%20folositi%20adresa%20de%20email%20Mioara@efe.ro%0D%0A%0D%0AVa%20multumim%20pentru%20colaborare!%0D%0A%0D%0AECHIPA%20EFE%20IMPEX%2072`;
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const prefilledTripId = searchParams.get("id");
  const [prefilledTripIdSelected, setPrefilledTripIdSelected] = useState(false);

  useEffect(() => {
    if (!prefilledTripId) {
      return;
    }
    setSearchText(prefilledTripId);
    setPageSize(1);
  }, [prefilledTripId]);

  useEffect(() => {
    if (!prefilledTripId) {
      return;
    }

    if (rows.length === 1) {
      setSelectedTripsForBilling(rows);
      setOpenShipperInvoiceDialog(true);
      setPrefilledTripIdSelected(true);
    }
  }, [prefilledTripId, rows, pageSize]);

  useEffect(() => {
    if (!openShipperInvoiceDialog && prefilledTripIdSelected) {
      setSearchText("");
      setPageSize(25);
      setSearchParams(new URLSearchParams());
    }
  }, [openShipperInvoiceDialog, prefilledTripIdSelected]);

  return (
    <Box
      sx={{
        height: 400,
        width: "100%",
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
        sx={{ mt: -1.7 }}
      >
        <Grid
          item
          xs={12}
          lg={9}
          marginBottom={{ xs: selectedShipper ? 1 : 0, lg: 0 }}
        >
          <Button
            size="small"
            disabled={!selectedTripsForBilling.length}
            onClick={handleOpenShipperInvoiceDialog}
            variant="contained"
          >
            <Stack
              direction="row"
              sx={{
                mt: 0.4,
              }}
            >
              <GradingIcon
                fontSize="small"
                sx={{
                  mr: 0.5,
                }}
              />
              Emite factură
            </Stack>
          </Button>
          <Button
            size="small"
            onClick={() => setOpenGenerateSagaXmlDialog(true)}
            variant="contained"
            sx={{
              ml: 2,
            }}
          >
            <Stack
              direction="row"
              sx={{
                mt: 0.4,
              }}
            >
              <FileDownloadIcon
                fontSize="small"
                sx={{
                  mr: 0.5,
                }}
              />
              Export facturi Saga
            </Stack>
          </Button>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Autocomplete
            value={selectedShipper}
            onChange={(event, value, reason) => {
              if (reason === "clear") {
                setSelectedShipper(null);
                setShipperId(undefined);
                fetchShippers();
              } else {
                setSelectedShipper(value);
                setShipperId(value?.id);
              }
              setSelectionModel([]);
              setSelectedTripsForBilling([]);
            }}
            options={sortedShipperOptions}
            filterSelectedOptions
            getOptionLabel={(option) => `${option.name}`}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={({
              inputProps: { onChange, ...restInputProps },
              ...params
            }) => (
              <form noValidate onSubmit={(event) => event.preventDefault()}>
                <TextField
                  {...params}
                  size="small"
                  inputProps={{
                    ...restInputProps,
                    onChange: (...args) => {
                      setSearchShipperTextInput(
                        // @ts-ignore
                        args[0].target.value
                      );
                      // @ts-ignore
                      onChange(...args);
                    },
                  }}
                  fullWidth
                  label="Beneficiar"
                  sx={{ minWidth: "200px" }}
                  // sx={{ width: "400px" }}
                />
              </form>
            )}
          />
        </Grid>
        <Grid item xs={12} sx={{ paddingBottom: 3 }}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="flex-end"
            spacing={1}
          >
            <Grid item xs={12}>
              <div style={{ width: "100%" }}>
                <div style={{ display: "flex", height: "100%" }}>
                  <div style={{ flexGrow: 1 }}>
                    <DataGrid
                      density="compact"
                      filterMode="server"
                      paginationMode="server"
                      loading={pageIsLoading}
                      onFilterModelChange={(filterModel: GridFilterModel) => {
                        setSearchText(
                          filterModel.quickFilterValues?.join(" ") || ""
                        );
                      }}
                      onPageChange={(page: number) => {
                        setPage(page);
                      }}
                      rowCount={rowCount}
                      rows={
                        shipperId
                          ? rows.filter(
                              (trip: Trip) => trip.shipper?.id === shipperId
                            )
                          : rows
                      }
                      getRowId={(row) => row.id}
                      columns={columns}
                      initialState={{
                        sorting: {
                          sortModel: [{ field: "id", sort: "desc" }],
                        },
                      }}
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                      rowsPerPageOptions={[10, 25, 50, 100]}
                      autoHeight
                      pagination
                      keepNonExistentRowsSelected
                      disableSelectionOnClick
                      checkboxSelection={!!selectedShipper}
                      selectionModel={selectionModel}
                      onSelectionModelChange={(ids) => {
                        setSelectionModel(ids);
                        const selectedIDs = new Set(ids);
                        const currentPageRows = rows.filter((row) =>
                          selectedIDs.has(row.id)
                        );
                        setSelectedTripsForBilling((prev) => {
                          const prevWithNewRows = [...prev];
                          currentPageRows.forEach((currentPageRow) => {
                            if (
                              !prevWithNewRows.find(
                                (row) => row.id === currentPageRow.id
                              )
                            ) {
                              prevWithNewRows.push(currentPageRow);
                            }
                          });
                          return prevWithNewRows.filter((prevSelectedTrip) => {
                            return selectedIDs.has(prevSelectedTrip.id);
                          });
                        });
                      }}
                      experimentalFeatures={{ newEditingApi: true }}
                      localeText={dataGridRoTextConstants}
                      components={{ Toolbar: CustomToolbar }}
                      componentsProps={{
                        pagination: {
                          labelRowsPerPage: "Număr de înregistrări pe pagină",
                        },
                        panel: {
                          anchorEl: filterButtonEl,
                          placement: "bottom-end",
                        },
                      }}
                      getRowClassName={(params: GridRowClassNameParams) => {
                        return params.row.computedStatus === TripStatus.Inserted
                          ? "inserted"
                          : params.row.computedStatus === TripStatus.Completed
                          ? "completed"
                          : params.row.computedStatus === TripStatus.Confirmed
                          ? "confirmed"
                          : params.row.computedStatus === TripStatus.Billed
                          ? "billed"
                          : params.row.computedStatus === TripStatus.Paid
                          ? "paid"
                          : params.row.computedStatus === TripStatus.Blocked
                          ? "blocked"
                          : "";
                      }}
                      sx={{
                        "& .MuiDataGrid-cellContent, & .MuiDataGrid-columnHeaderTitle":
                          {
                            fontSize: 12,
                          },
                        "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within":
                          {
                            outline: "none",
                          },

                        "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus":
                          {
                            outline: "none",
                          },
                        "& .confirmed": {
                          bgcolor: (theme) =>
                            getBackgroundColor(
                              theme.palette.warning.dark,
                              theme.palette.mode
                            ),
                          "&:hover": {
                            bgcolor: (theme) =>
                              getHoverBackgroundColor(
                                theme.palette.warning.dark,
                                theme.palette.mode
                              ),
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {openShipperInvoiceDialog && (
        <ShipperInvoiceDialog
          selectedTrips={selectedTripsForBilling}
          open={openShipperInvoiceDialog}
          onClose={() => setOpenShipperInvoiceDialog(false)}
          onTripsBilled={(metadata) => {
            fetchBillableTrips();
            setSelectedTripsForBilling([]);

            if (metadata) {
              if (metadata.isGenerating) {
                setOnTripsBilledMetadata(metadata);
              } else {
                openMailToShipper(metadata);
              }
            }
          }}
          onBillInPDF={(shipperInvoice) => {
            fetchTripsForPDF(shipperInvoice).then(() => {
              handleOpenChooseInvoicePDFTemplateDialog(shipperInvoice);
            });
          }}
        />
      )}
      {openChooseInvoicePDFTemplateDialog && (
        <ChooseInvoicePDFTemplateDialog
          open={openChooseInvoicePDFTemplateDialog}
          onClose={() => setToGenerateInvoiceId(undefined)}
          trip={selectedTripsForBilling[0]}
          toGenerateInvoiceId={toGenerateInvoiceId}
          onTemplateSubmit={(invoiceTemplate) => {
            setInvoiceTemplate(invoiceTemplate ? invoiceTemplate : "");
            handleOpenInvoicePDFDialog();
          }}
        />
      )}
      {openInvoicePDFDialog && (
        <InvoicePDFDialog
          open={openInvoicePDFDialog}
          trip={tripsForPDF && tripsForPDF[0]}
          trips={tripsForPDF}
          invoiceTemplate={invoiceTemplate}
          onClose={() => {
            setOpenInvoicePDFDialog(false);
            setUploadShipperInvoice(false);
            setTripsForPDF([]);
          }}
          uploadShipperInvoice={uploadShipperInvoice}
          onPdfUploaded={() => {
            if (onTripsBilledMetadata) {
              openMailToShipper(onTripsBilledMetadata);
              setOnTripsBilledMetadata(undefined);
            }
          }}
        />
      )}
      {openGenerateSagaXmlDialog && (
        <GenerateSagaXmlDialog
          open={openGenerateSagaXmlDialog}
          onClose={() => setOpenGenerateSagaXmlDialog(false)}
        />
      )}
    </Box>
  );
}
