import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { tripRequest } from "../../api/TripRequest";

interface Props {
  open: boolean;
  onClose: AnyFunction;
  trip?: Trip;
  onTripUnblocked?: AnyFunction;
}

export default function UnblockTripDialog(props: Props) {
  const { open, onClose, trip, onTripUnblocked } = props;

  const handleUnblockTrip = (id: number) => {
    return tripRequest.unblockTrip(id).catch(() => {
      alert("S-a produs o eroare și nu este posibilă deblocarea!");
    });
  };

  const handleCloseUnblockTripDialog = () => {
    onClose();
  };

  return (
    <>
      <Dialog open={open}>
        <Box
          sx={{
            mt: 2,
            mb: 1,
            ml: 1,
            mr: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CheckCircleIcon color="success" sx={{ fontSize: "75px" }} />
          <DialogTitle>
            Sunteți sigur că doriți să deblocați această comandă?
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Această comandă va fi deblocată imediat.
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              minWidth: 520,
            }}
          >
            <Button
              variant="contained"
              fullWidth
              size="large"
              color="success"
              onClick={() => {
                if (trip) {
                  handleUnblockTrip(trip.id)
                    .then(() => {
                      if (typeof onTripUnblocked === "function") {
                        onTripUnblocked();
                      }
                    })
                    .then(handleCloseUnblockTripDialog);
                }
              }}
              autoFocus
            >
              Deblochează
            </Button>
            <Button
              variant="outlined"
              fullWidth
              size="large"
              onClick={handleCloseUnblockTripDialog}
            >
              Renunță
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}
