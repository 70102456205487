import { createContext } from "react";

interface TitleContextInterface {
  title: string;
  setTitle: AnyFunction;
}

export const TitleContext = createContext<TitleContextInterface>({
  title: "",
  setTitle: () => null,
});
