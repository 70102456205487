import {
  DataGrid,
  GridColDef,
  GridFilterModel,
  GridRowClassNameParams,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { Box, darken, Grid, lighten, Tooltip } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import React from "react";
import { dataGridRoTextConstants } from "../../constants/dataGridRoTextConstants";
import {
  getCargoType,
  getCarrier,
  getCarrierCar,
  getCarrierDriver,
  getCarrierInvoice,
  getComments,
  getDate,
  getDispatcher,
  getLoadingAddress,
  getLoadingCity,
  getLoadingDate,
  getObservations,
  getShipperPaymentTermInDays,
  getCarrierPaymentTermInDays,
  getShipper,
  getShipperBranch,
  getShipperInvoice,
  getShipperReferenceId,
  getStatus,
  getUnloadingAddress,
  getUnloadingCity,
  getUnloadingDate,
} from "../../functions/valueGetterFunctions/tripValueGetterFunctions";
import { tripRequest } from "../../api/TripRequest";
import IconButton from "@mui/material/IconButton";
import PATH from "../../paths";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { TitleContext } from "../../contexts/TitleProviderContext";
import { useNavigate } from "react-router-dom";
import ConfirmTripDialog from "../dialogs/ConfirmTripDialog";
import { isDispatcherSelector } from "../../redux/slice/selectors";
import { useSelector } from "react-redux";
import UnblockTripDialog from "../dialogs/UnblockTripDialog";

enum TripStatus {
  Inserted = "Inserted",
  Completed = "Completed",
  Confirmed = "Confirmed",
  Billed = "Billed",
  Paid = "Paid",
  Blocked = "Blocked",
}

const getBackgroundColor = (color: string, mode: string) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

export default function ConfirmTripDataGrid() {
  const isDispatcher = useSelector(isDispatcherSelector);
  const navigate = useNavigate();
  const { setTitle } = useContext(TitleContext);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = React.useState<number>(25);
  const [searchText, setSearchText] = React.useState("");
  const [pageIsLoading, setPageIsLoading] = useState(false);
  const [rows, setRows] = useState<Trip[]>([]);
  const [rowCount, setRowCount] = useState(0);

  const columns: GridColDef[] = [
    { field: "id", headerName: "Număr", minWidth: 100, flex: 1 },
    {
      field: "date",
      headerName: "Dată",
      minWidth: 90,
      flex: 1,
      valueGetter: getDate,
    },
    {
      field: "loadingDate",
      headerName: "Dată încărcare",
      minWidth: 160,
      flex: 1,
      valueGetter: getLoadingDate,
      hide: true,
    },
    {
      field: "loadingCity",
      headerName: "Plecare",
      minWidth: 200,
      flex: 1,
      valueGetter: getLoadingCity,
    },
    {
      field: "loadingAddress",
      headerName: "Adresă încărcare",
      minWidth: 160,
      flex: 1,
      valueGetter: getLoadingAddress,
      hide: true,
    },
    {
      field: "unloadingDate",
      headerName: "Dată descărcare",
      minWidth: 160,
      flex: 1,
      valueGetter: getUnloadingDate,
      hide: true,
    },
    {
      field: "unloadingCity",
      headerName: "Sosire",
      minWidth: 200,
      flex: 1,
      valueGetter: getUnloadingCity,
    },
    {
      field: "unloadingAddress",
      headerName: "Adresă descărcare",
      minWidth: 160,
      flex: 1,
      valueGetter: getUnloadingAddress,
      hide: true,
    },
    {
      field: "shipper",
      headerName: "Beneficiar",
      minWidth: 150,
      flex: 1,
      valueGetter: getShipper,
    },
    {
      field: "carrier",
      headerName: "Transportator",
      minWidth: 150,
      flex: 1,
      valueGetter: getCarrier,
    },
    {
      field: "shipperBranch",
      headerName: "Punct de lucru beneficiar",
      minWidth: 160,
      flex: 1,
      valueGetter: getShipperBranch,
      hide: true,
    },
    {
      field: "shipperPaymentTermInDays",
      headerName: "Termen beneficiar",
      minWidth: 160,
      flex: 0.7,
      valueGetter: getShipperPaymentTermInDays,
      hide: true,
    },
    {
      field: "carrierCar",
      headerName: "Mașină",
      minWidth: 90,
      flex: 1,
      valueGetter: getCarrierCar,
    },
    {
      field: "carrierDriver",
      headerName: "Șofer",
      minWidth: 125,
      flex: 1,
      valueGetter: getCarrierDriver,
    },
    {
      field: "shipperInvoice",
      headerName: "Fact. ben.",
      minWidth: 100,
      flex: 1,
      valueGetter: getShipperInvoice,
    },
    {
      field: "carrierInvoice",
      headerName: "Fact. trans.",
      minWidth: 100,
      flex: 1,
      valueGetter: getCarrierInvoice,
    },
    {
      field: "carrierPaymentTermInDays",
      headerName: "T.t.",
      minWidth: 60,
      flex: 1,
      valueGetter: getCarrierPaymentTermInDays,
    },
    {
      field: "computedStatus",
      headerName: "Status",
      minWidth: 90,
      flex: 1,
      valueGetter: getStatus,
      hide: true,
    },
    {
      field: "cargoType",
      headerName: "Tip marfă",
      minWidth: 90,
      flex: 1,
      valueGetter: getCargoType,
      hide: true,
    },
    {
      field: "dispatcher",
      headerName: "Dispecer",
      minWidth: 80,
      flex: 1,
      valueGetter: getDispatcher,
      hide: true,
    },
    {
      field: "shipperReferenceId",
      headerName: "Ref.",
      minWidth: 75,
      flex: 0.7,
      valueGetter: getShipperReferenceId,
    },
    {
      field: "observations",
      headerName: "Observații",
      minWidth: 90,
      flex: 1,
      valueGetter: getObservations,
      hide: true,
    },
    {
      field: "comments",
      headerName: "Comentarii",
      minWidth: 80,
      flex: 1,
      valueGetter: getComments,
    },
    {
      field: "actions",
      headerName: "Acțiuni",
      minWidth: 90,
      maxWidth: 90,
      filterable: false,
      renderCell: ({ row: trip }) => {
        return [
          <>
            <Tooltip title="Vizualizează" placement="bottom">
              <IconButton
                onClick={() => {
                  navigate(PATH.TRIP.VIEW.replace(":id", trip.id.toString()));
                  setTitle("Detalii comandă");
                }}
                color="primary"
              >
                <VisibilityIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Confirmă" placement="bottom">
              <IconButton
                disabled={
                  isDispatcher && trip.computedStatus === TripStatus.Blocked
                }
                onClick={() => {
                  if (trip.computedStatus === TripStatus.Blocked) {
                    setUnblockTrip(trip);
                  } else {
                    setToConfirmTrip(trip);
                  }
                }}
                color="success"
              >
                <CheckCircleIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </>,
        ];
      },
    },
  ];

  const fetchTrips = () => {
    setPageIsLoading(true);
    tripRequest
      .searchConfirmable(pageSize, page * pageSize, searchText)
      .then((data) => {
        setRows(
          data.results.map((trip: Trip) => ({
            id: trip.id,
            date: trip.date,
            cargoType: trip.cargoType,
            dispatcher: trip.dispatcher,
            loadingDate: trip.loadingDate,
            loadingAddress: trip.loadingAddress,
            loadingCity: trip.loadingCity,
            intermediaryCities: trip.intermediaryCities,
            unloadingDate: trip.unloadingDate,
            unloadingAddress: trip.unloadingAddress,
            unloadingCity: trip.unloadingCity,
            carrier: trip.carrier,
            carrierCar: trip.carrierCar,
            carrierDriver: trip.carrierDriver,
            carrierInvoice: trip.carrierInvoice,
            carrierInvoiceRows: trip.carrierInvoiceRows,
            carrierPaymentTermInDays: trip.carrierPaymentTermInDays,
            shipper: trip.shipper,
            shipperBranch: trip.shipperBranch,
            shipperReferenceId: trip.shipperReferenceId,
            shipperInvoice: trip.shipperInvoice,
            shipperInvoiceRows: trip.shipperInvoiceRows,
            shipperPaymentTermInDays: trip.shipperPaymentTermInDays,
            observations: trip.observations,
            comments: trip.comments,
            computedStatus: trip.computedStatus,
            status: trip.status,
            publicUploadLink: trip.publicUploadLink,
            hasPublicUploadedFiles: trip.hasPublicUploadedFiles,
            hasReceptionProblem: trip.hasReceptionProblem,
          }))
        );
        setRowCount(data.total);
      })
      .finally(() => {
        setPageIsLoading(false);
      });
  };

  useEffect(() => {
    fetchTrips();
  }, [pageSize, page, searchText]);

  const [toConfirmTrip, setToConfirmTrip] = React.useState<Trip | undefined>(
    undefined
  );
  const [unblockTrip, setUnblockTrip] = React.useState<Trip | undefined>(
    undefined
  );

  const [filterButtonEl, setFilterButtonEl] = React.useState(null);

  const CustomToolbar = React.useCallback(() => {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <Grid
            item
            sx={{
              ml: 1,
            }}
          >
            <GridToolbarQuickFilter />
          </Grid>
          <Grid
            item
            sx={{
              mr: 0.3,
            }}
          >
            <Grid container direction="row" spacing={1}>
              <Grid item>
                {/* @ts-ignore */}
                <GridToolbarColumnsButton ref={setFilterButtonEl} />
              </Grid>
              <Grid item>
                {/* @ts-ignore */}
                <GridToolbarDensitySelector ref={setFilterButtonEl} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </GridToolbarContainer>
    );
  }, []);

  return (
    <Box
      sx={{
        height: 400,
        width: "100%",
      }}
    >
      <Grid item xs={12}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="flex-end"
          spacing={1}
        >
          <Grid item xs={12} sx={{ paddingBottom: 3 }}>
            <div style={{ width: "100%" }}>
              <div style={{ display: "flex", height: "100%" }}>
                <div style={{ flexGrow: 1 }}>
                  <DataGrid
                    density="compact"
                    filterMode="server"
                    paginationMode="server"
                    loading={pageIsLoading}
                    onFilterModelChange={(filterModel: GridFilterModel) => {
                      setSearchText(
                        filterModel.quickFilterValues?.join(" ") || ""
                      );
                    }}
                    onPageChange={(page: number) => {
                      setPage(page);
                    }}
                    rowCount={rowCount}
                    rows={rows}
                    getRowId={(row) => row.id}
                    columns={columns}
                    initialState={{
                      sorting: {
                        sortModel: [{ field: "id", sort: "desc" }],
                      },
                    }}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    autoHeight
                    pagination
                    disableSelectionOnClick
                    experimentalFeatures={{ newEditingApi: true }}
                    localeText={dataGridRoTextConstants}
                    components={{ Toolbar: CustomToolbar }}
                    componentsProps={{
                      pagination: {
                        labelRowsPerPage: "Număr de înregistrări pe pagină",
                      },
                      panel: {
                        anchorEl: filterButtonEl,
                        placement: "bottom-end",
                      },
                    }}
                    getRowClassName={(params: GridRowClassNameParams) => {
                      return params.row.computedStatus === TripStatus.Blocked
                        ? "blocked"
                        : "";
                    }}
                    sx={{
                      "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within":
                        {
                          outline: "none",
                        },

                      "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus":
                        {
                          outline: "none",
                        },
                      "& .blocked": {
                        bgcolor: (theme) =>
                          getBackgroundColor(
                            theme.palette.error.dark,
                            theme.palette.mode
                          ),
                        "&:hover": {
                          bgcolor: (theme) =>
                            getHoverBackgroundColor(
                              theme.palette.error.dark,
                              theme.palette.mode
                            ),
                        },
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>

      {toConfirmTrip && (
        <ConfirmTripDialog
          open={Boolean(toConfirmTrip)}
          onClose={() => setToConfirmTrip(undefined)}
          trip={toConfirmTrip}
          onTripConfirmed={fetchTrips}
        />
      )}
      {unblockTrip && (
        <UnblockTripDialog
          open={Boolean(unblockTrip)}
          trip={unblockTrip}
          onTripUnblocked={() => {
            fetchTrips();
            setToConfirmTrip(unblockTrip);
          }}
          onClose={() => {
            setUnblockTrip(undefined);
          }}
        />
      )}
    </Box>
  );
}
