import Grid from "@mui/material/Grid";
import { InputAdornment, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import { reportRequest } from "../../api/ReportRequest";
import blockInvalidCharNumberInput from "../../functions/blockInvalidCharNumberInput";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { styled } from "@mui/material/styles";
import ProfitLessThanOneHundredTripsReportTable from "../tables/ProfitLessThanOneHundredTripsReportTable";

interface TripRow {
  tripNumber: number;
  shipperName: string;
  carrierName: string;
  loadingCity: string;
  unloadingCity: string;
  dispatcherId: number;
  dispatcherFullName: string;
  shipperTotal: string;
  carrierTotal: string;
  profit: string;
  carrierPaymentTermInDays: number;
}

interface Report {
  dispatcherId: number;
  dispatcherFullName: string;
  trips: TripRow[];
}

const NumberField = styled(TextField)`
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

export default function ProfitLessThanOneHundredTripsReportForm() {
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [carrierPaymentTermInDays, setCarrierPaymentTermInDays] = useState<
    number | undefined
  >(undefined);
  const [carrierPaymentTermInDaysValue, setCarrierPaymentTermInDaysValue] =
    useState("");
  const handleClearClick = () => {
    setCarrierPaymentTermInDaysValue("");
    setCarrierPaymentTermInDays(undefined);
  };

  const [error, setError] = useState({
    startDate: false,
    endDate: false,
  });

  const handleErrors = () => {
    if (!startDate) {
      setError((prev: any) => ({
        ...prev,
        startDate: true,
      }));
    } else if (startDate) {
      setError((prev: any) => ({
        ...prev,
        startDate: false,
      }));
    }
    if (!endDate) {
      setError((prev: any) => ({
        ...prev,
        endDate: true,
      }));
    } else if (endDate) {
      setError((prev: any) => ({
        ...prev,
        endDate: false,
      }));
    }
  };

  const [report, setReport] = useState<Report[]>([]);
  const fetchReport = () => {
    handleErrors();
    const formIsValid = startDate && endDate;
    if (formIsValid) {
      return reportRequest
        .getProfitLessThanOneHundredTrips(
          startDate,
          endDate,
          carrierPaymentTermInDays
        )
        .then((data) => {
          setReport(
            data?.map((report: Report) => ({
              dispatcherId: report.dispatcherId,
              dispatcherFullName: report.dispatcherFullName,
              trips: report.trips,
            }))
          );
        })
        .catch(() => {
          alert("S-a produs o eroare!");
        });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={3}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Început dată încărcare"
            value={startDate}
            inputFormat="dd/MM/yyyy"
            onChange={(newValue: any) => {
              setStartDate(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                fullWidth
                error={error.startDate}
                helperText={error.startDate && "Câmp obligatoriu!"}
                inputProps={{
                  ...params.inputProps,
                  placeholder: "zz/ll/aaaa",
                }}
              />
            )}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} lg={3}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Sfârșit dată încărcare"
            value={endDate}
            inputFormat="dd/MM/yyyy"
            onChange={(newValue: any) => {
              setEndDate(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                fullWidth
                error={error.endDate}
                helperText={error.endDate && "Câmp obligatoriu!"}
                inputProps={{
                  ...params.inputProps,
                  placeholder: "zz/ll/aaaa",
                }}
              />
            )}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} lg={3}>
        <NumberField
          size="small"
          fullWidth
          value={carrierPaymentTermInDaysValue}
          onChange={(newValue) => {
            setCarrierPaymentTermInDays(+newValue.target.value);
            setCarrierPaymentTermInDaysValue(newValue.target.value);
          }}
          label="Termen transportator"
          type="number"
          onKeyDown={blockInvalidCharNumberInput}
          inputProps={{ min: 0 }}
          InputProps={{
            endAdornment: (
              <>
                <IconButton
                  size="small"
                  sx={{
                    visibility:
                      carrierPaymentTermInDays !== undefined
                        ? "visible"
                        : "hidden",
                    marginLeft: 1,
                  }}
                  onClick={handleClearClick}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
                <InputAdornment position="end">zile</InputAdornment>
              </>
            ),
          }}
        ></NumberField>
      </Grid>
      <Grid item xs={12} lg={3}>
        <Button
          fullWidth
          variant="contained"
          onClick={() => {
            fetchReport();
          }}
          sx={{ height: "40px" }}
        >
          Generează raport
        </Button>
      </Grid>
      {report.length > 0 && (
        <Grid item xs={12}>
          <ProfitLessThanOneHundredTripsReportTable reportValues={report} />
        </Grid>
      )}
    </Grid>
  );
}
