import * as React from "react";
import { useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import InvoiceRoPDF from "../../../pdfRenderer/InvoiceRoPDF";
import InvoiceAppendixRoPDF from "../../../pdfRenderer/InvoiceAppendixRoPDF";
import InvoiceRo2PDF from "../../../pdfRenderer/InvoiceRo2PDF";
import { fileRequest } from "../../../api/FileRequest";
import { shipperInvoiceRequest } from "../../../api/ShipperInvoiceRequest";

interface Props {
  open: boolean;
  onClose: AnyFunction;
  trip?: Trip;
  trips?: Trip[];
  invoiceTemplate?: string;
  uploadShipperInvoice?: boolean;
  onPdfUploaded?: AnyFunction;
}

export default function InvoicePDFDialog(props: Props) {
  const {
    open,
    onClose,
    trip,
    trips,
    invoiceTemplate,
    uploadShipperInvoice,
    onPdfUploaded,
  } = props;

  const handleClosePDFDialog = () => {
    onClose();
  };

  const onBlobSet = useCallback((blob: Blob) => {
    if (!trip?.id) {
      return;
    }

    const file = new File(
      [blob],
      `${trip.id}${
        trip.shipperReferenceId ? `-${trip.shipperReferenceId}` : ""
      }-factura.pdf`,
      {
        type: "application/pdf",
      }
    );
    fileRequest
      .upload(file)
      .then((result) => {
        if (!trip?.shipperInvoice?.id) {
          throw new Error("trip shipper invoice not set!");
        }
        return shipperInvoiceRequest.addDocument(
          trip.shipperInvoice.id,
          result.id
        );
      })
      .then(() => {
        if (typeof onPdfUploaded === "function") {
          onPdfUploaded();
        }
      })
      .catch((e) => {
        console.error(e);
        alert(
          "S-a produs o eroare, factura nu a fost asociată automat la documente!"
        );
      });
  }, []);

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="xl"
        open={open}
        onClose={handleClosePDFDialog}
      >
        {invoiceTemplate === "Romanian" && (
          <InvoiceRoPDF
            trip={trip}
            trips={trips}
            onBlobSet={uploadShipperInvoice ? onBlobSet : undefined}
          />
        )}
        {invoiceTemplate === "RomanianAppendix" && (
          <InvoiceAppendixRoPDF
            trip={trip}
            trips={trips}
            onBlobSet={uploadShipperInvoice ? onBlobSet : undefined}
          />
        )}
        {invoiceTemplate === "English" && (
          <InvoiceRo2PDF
            trip={trip}
            trips={trips}
            onBlobSet={uploadShipperInvoice ? onBlobSet : undefined}
          />
        )}
      </Dialog>
    </>
  );
}
