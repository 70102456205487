import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useState } from "react";
import InvoiceRoPDFSmall from "../../../pdfRenderer/InvoiceRoPdfSmall";
import InvoiceAppendixRoPDFSmall from "../../../pdfRenderer/InvoiceAppendixRoPDFSmall";
import InvoiceRo2PDFSmall from "../../../pdfRenderer/InvoiceRo2PDFSmall";
import { shipperInvoiceRequest } from "../../../api/ShipperInvoiceRequest";

interface Props {
  open: boolean;
  onClose: AnyFunction;
  trip?: Trip;
  trips?: Trip[];
  onTemplateSubmit?: (template: string) => void;
  toGenerateInvoiceId?: number;
}

export default function ChooseInvoicePDFTemplateDialog(props: Props) {
  const { open, onClose, onTemplateSubmit, trip, trips, toGenerateInvoiceId } =
    props;

  const handleCloseChooseInvoicePDFTemplateDialog = () => {
    onClose();
  };

  const [selectedInvoiceTemplate, setSelectedInvoiceTemplate] =
    useState("Romanian");

  const handleCheckSelectedInvoiceTemplate = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedInvoiceTemplate((event.target as HTMLInputElement).value);
  };

  const handleSubmitTemplate = async () => {
    if (toGenerateInvoiceId) {
      await shipperInvoiceRequest.generateInvoiceType(toGenerateInvoiceId, {
        type: selectedInvoiceTemplate,
      });
      if (typeof onTemplateSubmit === "function") {
        onTemplateSubmit(selectedInvoiceTemplate);
      }
      handleCloseChooseInvoicePDFTemplateDialog();
    }
  };

  return (
    <>
      <Dialog open={open} maxWidth={false}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <DialogTitle sx={{ pb: 0 }}>Template factură</DialogTitle>
          </Grid>
          <Grid item sx={{ mr: 2, pt: 2 }}>
            <IconButton
              onClick={handleCloseChooseInvoicePDFTemplateDialog}
              tabIndex={-1}
            >
              <ClearIcon />
            </IconButton>
          </Grid>
        </Grid>
        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item container direction="column" alignItems="center">
              <FormControl
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography>
                  Alegeți un template pentru factura generată
                </Typography>
                <RadioGroup
                  row
                  value={selectedInvoiceTemplate}
                  onChange={handleCheckSelectedInvoiceTemplate}
                >
                  <FormControlLabel
                    value="Romanian"
                    control={<Radio />}
                    label={
                      <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                      >
                        <Grid item>
                          <Typography>Detalii complete</Typography>
                        </Grid>
                        <Grid item>
                          <InvoiceRoPDFSmall trip={trip} trips={trips} />
                        </Grid>
                      </Grid>
                    }
                    labelPlacement="bottom"
                  />
                  <FormControlLabel
                    value="RomanianAppendix"
                    control={<Radio />}
                    label={
                      <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                      >
                        <Grid item>
                          <Typography>
                            Număr de comandă + detalii cursă
                          </Typography>
                        </Grid>
                        <Grid item>
                          <InvoiceAppendixRoPDFSmall
                            trip={trip}
                            trips={trips}
                          />
                        </Grid>
                      </Grid>
                    }
                    labelPlacement="bottom"
                  />
                  <FormControlLabel
                    value="English"
                    control={<Radio />}
                    label={
                      <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                      >
                        <Grid item>
                          <Typography>Număr de comandă</Typography>
                        </Grid>
                        <Grid item>
                          <InvoiceRo2PDFSmall trip={trip} trips={trips} />
                        </Grid>
                      </Grid>
                    }
                    labelPlacement="bottom"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Divider sx={{ mb: 2, opacity: 0 }} />
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={12} sm={6}>
              <Button
                variant="contained"
                fullWidth
                size="large"
                color="primary"
                onClick={() => {
                  handleSubmitTemplate();
                }}
                autoFocus
              >
                Deschide&nbsp;factura
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant="outlined"
                fullWidth
                size="large"
                onClick={() => {
                  handleCloseChooseInvoicePDFTemplateDialog();
                }}
                autoFocus
              >
                Renunță
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
