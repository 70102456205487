import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Font,
  Image,
  pdf,
} from "@react-pdf/renderer";
import dateFormat from "dateformat";
import * as React from "react";
import { useSelector } from "react-redux";
import { vatSelector } from "../redux/slice/selectors";
import efeImpexLogo from "../assets/efe_logo_transparent.png";
import { useEffect } from "react";

Font.registerHyphenationCallback((word) => {
  return [word];
});

const styles = StyleSheet.create({
  viewer: {
    width: "1536px",
    height: window.innerHeight,
  },
  page: {
    backgroundColor: "white",
    color: "black",
    padding: "20px 50px 30px 50px",
  },
  section: {
    marginBottom: 10,
  },
  smallSection: {
    marginBottom: 5,
  },
  header: {
    padding: "0px 30px 0px 30px",
  },
  bold12: {
    fontSize: 12,
    textAlign: "left",
    fontFamily: "Helvetica-Bold",
  },
  bold9: {
    fontSize: 9,
    textAlign: "left",
    fontFamily: "Helvetica-Bold",
  },
  bold7: {
    fontSize: 7,
    textAlign: "left",
    fontFamily: "Helvetica-Bold",
  },
  regular9: {
    fontSize: 9,
    textAlign: "left",
    fontFamily: "Helvetica",
  },
  regular8: {
    fontSize: 8,
    textAlign: "left",
    fontFamily: "Helvetica",
  },
  regular7: {
    fontSize: 7,
    textAlign: "left",
    fontFamily: "Helvetica",
  },
  container: {
    flexDirection: "row",
    borderColor: "#000000",
    borderTopWidth: 0.5,
    borderBottomWidth: 0.5,
    alignItems: "center",
    flexGrow: 1,
    paddingTop: 3,
    paddingBottom: 3,
  },
  content: {
    flexDirection: "row",
    borderColor: "#000000",
    // borderTopWidth: 0.5,
    borderBottomWidth: 0.5,
    // alignItems: "center",

    flexGrow: 1,
    paddingTop: 3,
    paddingBottom: 3,
  },
  cell: {
    width: "10%",
    fontSize: 7,
    fontFamily: "Helvetica",
    paddingRight: 10,
  },
  smallCell: {
    width: "5%",
    fontSize: 7,
    fontFamily: "Helvetica",
    paddingRight: 10,
  },
  largeCell: {
    width: "45%",
    fontSize: 7,
    fontFamily: "Helvetica",
    paddingRight: 10,
  },
  rowCell: {
    fontSize: 7,
    fontFamily: "Helvetica",
    paddingRight: 10,
  },
});

interface Props {
  trip?: Trip;
  trips?: Trip[];
  onBlobSet?: AnyFunction;
}

export default function InvoiceRoPDF(props: Props) {
  const { trip, trips, onBlobSet } = props;
  const vat = useSelector(vatSelector);
  let shipperFinalTotalValue = 0;
  let shipperFinalTotalVat = 0;

  const document = (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignContent: "center",
            }}
          >
            <div>
              <View style={styles.smallSection}>
                <Text style={styles.bold12}>FACTURA</Text>
              </View>
              <View style={styles.smallSection}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignContent: "center",
                  }}
                >
                  <div style={{ marginRight: 10 }}>
                    <Text style={styles.bold9}>
                      {trip
                        ? `Serie ${trip?.shipperInvoice?.invoiceSeries?.name?.toUpperCase()}`
                        : trips
                        ? `Serie ${trips[0]?.shipperInvoice?.invoiceSeries?.name?.toUpperCase()}`
                        : "-"}
                    </Text>
                  </div>
                  <Text style={styles.bold9}>
                    {trip
                      ? `Numar ${trip?.shipperInvoice?.number}`
                      : trips
                      ? `Numar ${trips[0]?.shipperInvoice?.number}`
                      : "-"}
                  </Text>
                </div>
              </View>
            </div>
            <div>
              <Image
                src={efeImpexLogo}
                style={{
                  minWidth: 59,
                  maxWidth: 59,
                  minHeight: 34,
                  maxHeight: 34,
                  marginRight: 10,
                }}
              />
            </div>
          </div>
          <View style={styles.smallSection}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignContent: "center",
                }}
              >
                <div style={{ marginRight: 10 }}>
                  <Text style={styles.bold9}>
                    {trip
                      ? `Data ${dateFormat(
                          trip?.shipperInvoice?.date?.toString(),
                          "dd.mm.yyyy"
                        )}`
                      : trips
                      ? `Data ${dateFormat(
                          trips[0]?.shipperInvoice?.date?.toString(),
                          "dd.mm.yyyy"
                        )}`
                      : "-"}
                  </Text>
                </div>
                <Text style={styles.bold9}>
                  {trip
                    ? `Scadenta la ${dateFormat(
                        trip?.shipperInvoiceDueDate?.toString(),
                        "dd.mm.yyyy"
                      )}`
                    : trips
                    ? `Scadenta la ${dateFormat(
                        trips[0]?.shipperInvoiceDueDate?.toString(),
                        "dd.mm.yyyy"
                      )}`
                    : "-"}
                </Text>
              </div>
              <Text style={styles.bold9}>- RON -</Text>
            </div>
          </View>
        </View>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            flex: 1,
          }}
        >
          <View style={styles.section}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignContent: "center",
                borderBottomColor: "#000000",
                borderBottomWidth: 0.5,
                paddingLeft: 7,
                paddingBottom: 2,
                paddingTop: 10,
              }}
            >
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignContent: "center",
                }}
              >
                <Text style={styles.regular8}>Furnizor</Text>
              </div>
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignContent: "center",
                }}
              >
                <Text style={styles.regular8}>Client</Text>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignContent: "center",
                paddingLeft: 7,
                paddingTop: 5,
              }}
            >
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignContent: "center",
                }}
              >
                <Text style={styles.bold9}>EFE IMPEX 72 SRL</Text>
              </div>
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignContent: "center",
                }}
              >
                <Text style={styles.bold9}>
                  {trip
                    ? `${trip?.shipper?.name?.toUpperCase()}`
                    : trips
                    ? `${trips[0]?.shipper?.name?.toUpperCase()}`
                    : "-"}
                </Text>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignContent: "center",
                paddingLeft: 7,
                paddingTop: 15,
              }}
            >
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignContent: "center",
                  }}
                >
                  <div style={{ paddingRight: 25 }}>
                    <Text style={styles.bold9}>CIF RO5720754</Text>
                  </div>
                  <Text style={styles.regular9}>RC J40/7234/1994</Text>
                </div>
              </div>
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignContent: "center",
                  }}
                >
                  <div style={{ paddingRight: 25 }}>
                    <Text style={styles.regular9}>
                      {trip
                        ? `CIF ${trip?.shipper?.tin?.toUpperCase()}`
                        : trips
                        ? `CIF ${trips[0]?.shipper?.tin?.toUpperCase()}`
                        : "-"}
                    </Text>
                  </div>
                  <div>
                    <Text style={styles.regular9}>
                      {trip
                        ? `RC ${trip?.shipper?.businessRegistrationNumber?.toUpperCase()}`
                        : trips
                        ? `RC ${trips[0]?.shipper?.businessRegistrationNumber?.toUpperCase()}`
                        : "-"}
                    </Text>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignContent: "center",
                paddingLeft: 7,
                paddingTop: 7.5,
              }}
            >
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignContent: "center",
                  }}
                >
                  <div style={{ paddingRight: 25 }}>
                    <Text style={styles.regular9}>
                      Capital soc. 110 000 RON
                    </Text>
                  </div>
                  <Text style={styles.regular9}>
                    BUCURESTI sect. 3 str. PARULUI nr. 32 bl. 59 sc. 2 ap. 82
                  </Text>
                  <Text style={styles.regular9}>Telefon 0213242504</Text>
                  <Text style={styles.regular9}>Email OFFICE@EFE.RO</Text>
                  <Text style={styles.regular9}>
                    Banca ING BANK ROMANIA DRISTOR
                  </Text>
                  <Text style={styles.regular9}>
                    IBAN RO75INGB0000999901646039 RON
                  </Text>
                  <Text style={styles.regular9}>SWIFT CODE: INGBROBU</Text>
                </div>
              </div>
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignContent: "center",
                  }}
                >
                  <div style={{ paddingRight: 25, marginBottom: 5 }}>
                    <Text style={styles.regular9}>
                      {trip
                        ? `${trip?.shipper?.city?.name?.toUpperCase()}, ${trip?.shipper?.address?.toUpperCase()}, jud. ${trip?.shipper?.city?.county?.name?.toUpperCase()}`
                        : trips
                        ? `${trips[0]?.shipper?.city?.name?.toUpperCase()}, ${trips[0]?.shipper?.address?.toUpperCase()}, jud. ${trips[0]?.shipper?.city?.county?.name?.toUpperCase()}`
                        : "-"}
                    </Text>
                  </div>
                  <div>
                    <Text style={styles.regular9}>
                      {trip && trip?.shipper?.bank
                        ? `Banca ${trip?.shipper?.bank?.toUpperCase()}`
                        : trips && trips[0]?.shipper?.bank
                        ? `Banca ${trips[0]?.shipper?.bank?.toUpperCase()}`
                        : "Banca -"}
                    </Text>
                    <Text style={styles.regular9}>
                      {trip && trip?.shipper?.iban
                        ? `IBAN ${trip?.shipper?.iban?.toUpperCase()}`
                        : trips && trips[0]?.shipper?.iban
                        ? `IBAN ${trips[0]?.shipper?.iban?.toUpperCase()}`
                        : "IBAN -"}
                    </Text>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                borderBottomColor: "#000000",
                borderBottomWidth: 0.5,
                paddingTop: 10,
                marginBottom: 3,
              }}
            ></div>
            <View style={styles.section}>
              <View style={styles.container} wrap={false}>
                <Text style={styles.smallCell}>Nr. crt.</Text>
                <Text style={styles.largeCell}>Denumire produse/servicii</Text>
                <Text style={styles.cell}>UM</Text>
                <Text style={styles.cell}>Cantitate</Text>
                <Text style={styles.cell}>Pret unitar</Text>
                <Text style={styles.cell}>Valoare</Text>
                <Text style={styles.cell}>TVA (19%)</Text>
              </View>
              {trips?.map((trip) => {
                let shipperValue = 0;
                trip?.shipperInvoiceRows &&
                  trip.shipperInvoiceRows.map((shipperInvoiceRow) => {
                    const currentShipperValue =
                      Number(shipperInvoiceRow.distanceKm) *
                      Number(shipperInvoiceRow.value);
                    shipperValue += currentShipperValue;
                    shipperFinalTotalValue +=
                      Number(shipperInvoiceRow.distanceKm) *
                      Number(shipperInvoiceRow.value);
                    shipperFinalTotalVat += (currentShipperValue * vat) / 100;
                  });
                return (
                  <View style={styles.content} wrap={false}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            width: "5%",
                          }}
                        >
                          <Text style={styles.rowCell}>{trip.id}</Text>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            width: "45%",
                          }}
                        >
                          <Text
                            style={styles.rowCell}
                          >{`C/V TRANSPORT MARFA PE RUTA ${trip.loadingCity?.name?.toUpperCase()} - ${trip.unloadingCity?.name?.toUpperCase()} IN ${dateFormat(
                            trip.date?.toString(),
                            "dd.mm.yy"
                          )} CU AUTO ${trip?.carrierCar?.vehicleRegistrationPlate?.toUpperCase()}`}</Text>
                          {/*<Text style={styles.rowCell}></Text>*/}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            width: "10%",
                          }}
                        >
                          <Text style={styles.rowCell}>KM</Text>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            width: "10%",
                          }}
                        >
                          {trip?.shipperInvoiceRows &&
                            trip.shipperInvoiceRows.map((shipperInvoiceRow) => {
                              return (
                                <>
                                  <Text style={styles.rowCell}>
                                    {Number(
                                      shipperInvoiceRow.distanceKm
                                    ).toFixed(3)}
                                  </Text>
                                </>
                              );
                            })}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            width: "10%",
                          }}
                        >
                          {trip?.shipperInvoiceRows &&
                            trip.shipperInvoiceRows.map((shipperInvoiceRow) => {
                              return (
                                <>
                                  <Text style={styles.rowCell}>
                                    {Number(shipperInvoiceRow.value).toFixed(2)}
                                  </Text>
                                </>
                              );
                            })}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            width: "10%",
                          }}
                        >
                          <Text style={styles.rowCell}>
                            {shipperValue.toFixed(2)}
                          </Text>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            width: "10%",
                          }}
                        >
                          <Text style={styles.rowCell}>
                            {Number((shipperValue * vat) / 100).toFixed(2)}
                          </Text>
                        </div>
                      </div>
                    </div>
                  </View>
                );
              })}
              <View style={styles.content}>
                <Text style={styles.regular7}>
                  Factura valabila fara semnatura si stampila cf art. V, alin(2)
                  din Ordonanta nr.17/2015 si art 319 alin (29) din Legea
                  227/2015 privind Codul fiscal
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  borderColor: "#000000",
                  flexGrow: 1,
                  paddingTop: 3,
                  paddingBottom: 3,
                }}
              >
                <div style={{ width: "25%" }}>
                  <Text style={styles.regular7}>Emis de</Text>
                  <Text style={styles.regular7}>TARUS MARICICA</Text>
                  <Text style={styles.regular7}>CI: RX 391641</Text>
                </div>
                <div style={{ width: "55%", paddingLeft: 10 }}>
                  <Text style={styles.regular7}>Date privind expeditia</Text>
                  <Text style={styles.regular7}>Numele delegatului: POSTA</Text>
                  <Text style={styles.regular7}>
                    {`C.I. seria:         nr:                  eliberat de:`}
                  </Text>
                  <Text style={styles.regular7}>Mijlocul de transport:</Text>
                </div>
                <div style={{ width: "10%" }}>
                  <Text style={styles.bold7}>
                    {shipperFinalTotalValue.toFixed(2)}
                  </Text>
                </div>
                <div style={{ width: "10%" }}>
                  <Text style={styles.bold7}>
                    {shipperFinalTotalVat.toFixed(2)}
                  </Text>
                </div>
              </View>
              <div
                style={{
                  flexDirection: "row",
                  flexGrow: 1,
                }}
              >
                <div style={{ width: "70%" }}></div>
                <div
                  style={{
                    width: "10%",
                    borderColor: "#000000",
                    borderTopWidth: 0.5,
                    flexGrow: 1,
                    paddingTop: 3,
                    paddingBottom: 3,
                  }}
                ></div>
                <div
                  style={{
                    width: "10%",
                    borderColor: "#000000",
                    borderTopWidth: 0.5,
                    flexGrow: 1,
                    paddingTop: 3,
                    paddingBottom: 3,
                  }}
                >
                  <Text style={styles.bold7}>Total</Text>
                </div>
                <div
                  style={{
                    width: "10%",
                    borderColor: "#000000",
                    borderTopWidth: 0.5,
                    flexGrow: 1,
                    paddingTop: 3,
                    paddingBottom: 3,
                  }}
                >
                  <Text style={styles.bold7}>
                    {Number(
                      shipperFinalTotalValue + shipperFinalTotalVat
                    ).toFixed(2)}
                  </Text>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  borderBottomColor: "#000000",
                  borderBottomWidth: 0.5,
                }}
              ></div>
              <div
                style={{
                  width: "100%",
                  borderBottomColor: "#000000",
                  borderBottomWidth: 0.5,
                  marginTop: 3,
                }}
              ></div>
            </View>
          </View>
          <div>
            <Text style={styles.regular7}>
              PREZENTA FACTURA TINE LOC DE CONTRACT. NEPLATA LA TERMEN ATRAGE
              PENALITATI IN CUANTUM DE 0.1% PE ZI
            </Text>
          </div>
        </div>
      </Page>
    </Document>
  );

  useEffect(() => {
    if (onBlobSet) {
      pdf(document)
        .toBlob()
        .then((blob) => onBlobSet(blob));
    }
  }, [onBlobSet]);

  return <PDFViewer style={styles.viewer}>{document}</PDFViewer>;
}
