import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { Box, Button, darken, Grid, lighten, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { dataGridRoTextConstants } from "../../../constants/dataGridRoTextConstants";
import EditShipperDialog from "../../dialogs/shipper/EditShipperDialog";
import DeleteRowDialog from "../../dialogs/DeleteRowDialog";
import AddShipperDialog from "../../dialogs/shipper/AddShipperDialog";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import {
  getName,
  getTin,
  getAddress,
  getPhone,
  getEmail,
  getCity,
  getContactPerson,
  getPaymentTermInDays,
  getBusinessRegistrationNumber,
  getBank,
  getIban,
} from "../../../functions/valueGetterFunctions/shipperGetterFunctions";
import { shipperRequest } from "../../../api/ShipperRequest";

const getBackgroundColor = (color: string, mode: string) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

export default function ShippersDataGrid() {
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 90, flex: 1, hide: true },
    {
      field: "name",
      headerName: "Nume",
      width: 150,
      flex: 1,
      valueGetter: getName,
    },
    {
      field: "tin",
      headerName: "CUI",
      width: 150,
      flex: 1,
      valueGetter: getTin,
    },
    {
      field: "businessRegistrationNumber",
      headerName: "Registrul comerțului",
      width: 150,
      flex: 1,
      valueGetter: getBusinessRegistrationNumber,
    },
    {
      field: "bank",
      headerName: "Bancă",
      width: 150,
      flex: 1,
      valueGetter: getBank,
    },
    {
      field: "iban",
      headerName: "IBAN",
      width: 150,
      flex: 1,
      valueGetter: getIban,
    },
    {
      field: "city",
      headerName: "Localitate",
      width: 160,
      flex: 1,
      valueGetter: getCity,
    },
    {
      field: "address",
      headerName: "Adresă",
      width: 160,
      flex: 1,
      valueGetter: getAddress,
    },
    {
      field: "phone",
      headerName: "Telefon",
      width: 160,
      flex: 1,
      valueGetter: getPhone,
    },
    {
      field: "email",
      headerName: "Email",
      width: 160,
      flex: 1,
      valueGetter: getEmail,
    },
    {
      field: "contactPerson",
      headerName: "Contact",
      width: 160,
      flex: 1,
      valueGetter: getContactPerson,
    },
    {
      field: "paymentTermInDays",
      headerName: "Termen de plată",
      width: 160,
      flex: 1,
      valueGetter: getPaymentTermInDays,
    },
    {
      field: "isActive",
      headerName: "Activ",
      minWidth: 75,
      maxWidth: 75,
      filterable: false,
      renderCell: (params: GridRenderCellParams) => {
        return params.row.isActive ? (
          <CheckCircleOutlineIcon
            fontSize="small"
            color="success"
            sx={{ ml: 0.5 }}
          />
        ) : (
          <HighlightOffRoundedIcon
            fontSize="small"
            color="error"
            sx={{ ml: 0.5 }}
          />
        );
      },
    },
    {
      field: "actions",
      headerName: "Acțiuni",
      minWidth: 90,
      maxWidth: 90,
      renderCell: ({ row: shipper }) => {
        return [
          <IconButton>
            <EditIcon
              fontSize="small"
              color="warning"
              onClick={() => {
                handleOpenEditShipperDialog(shipper);
              }}
            />
          </IconButton>,
          <IconButton>
            <DeleteIcon
              fontSize="small"
              color="error"
              onClick={() => handleOpenDeleteRowDialog(shipper.id)}
            />
          </IconButton>,
        ];
      },
    },
  ];

  const [pageSize, setPageSize] = React.useState<number>(10);
  // const [rows, setRows] = useState<Shipper[]>(shippers);

  const [rows, setRows] = useState<Shipper[]>([]);

  const fetchShippers = () => {
    shipperRequest.findAll(-1, 0, false).then((data) => {
      setRows(
        data.results.map((shipper: Shipper) => ({
          id: shipper.id,
          name: shipper.name,
          tin: shipper.tin,
          businessRegistrationNumber: shipper.businessRegistrationNumber,
          bank: shipper.bank,
          iban: shipper.iban,
          city: shipper.city,
          address: shipper.address,
          phone: shipper.phone,
          email: shipper.email,
          contactPerson: shipper.contactPerson,
          isActive: shipper.isActive,
          paymentTermInDays: shipper.paymentTermInDays,
        }))
      );
    });
  };

  useEffect(() => {
    fetchShippers();
  }, []);

  const [selectedShipperForEdit, setSelectedShipperForEdit] = useState<
    Shipper | undefined
  >(undefined);

  const [openAddShipperDialog, setOpenAddShipperDialog] = React.useState(false);
  const handleOpenAddShipperDialog = () => {
    setOpenAddShipperDialog(true);
  };

  const [openEditShipperDialog, setOpenEditShipperDialog] =
    React.useState(false);
  const handleOpenEditShipperDialog = (shipper: Shipper) => {
    setOpenEditShipperDialog(true);
    setSelectedShipperForEdit(shipper);
  };

  const [toBeDeleted, setToBeDeleted] = React.useState<number | null>(null);
  const handleOpenDeleteRowDialog = (id: any) => {
    setToBeDeleted(id);
  };
  const handleCloseDeleteRowDialog = () => {
    setToBeDeleted(null);
  };
  const openDeleteRowDialog = Boolean(toBeDeleted);

  const [filterButtonEl, setFilterButtonEl] = React.useState(null);

  const CustomToolbar = React.useCallback(() => {
    return (
      <GridToolbarContainer>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <Grid
            item
            sx={{
              ml: 1,
            }}
          >
            <GridToolbarQuickFilter />
          </Grid>
          <Grid
            item
            sx={{
              mr: 0.3,
            }}
          >
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <Button size="small" onClick={handleOpenAddShipperDialog}>
                  <Stack direction="row">
                    <AddIcon
                      fontSize="small"
                      sx={{
                        mr: 1,
                      }}
                    />
                    Adaugă beneficiar
                  </Stack>
                </Button>
              </Grid>
              <Grid item>
                {/* @ts-ignore */}
                <GridToolbarFilterButton ref={setFilterButtonEl} />
              </Grid>
              <Grid item>
                {/* @ts-ignore */}
                <GridToolbarColumnsButton ref={setFilterButtonEl} />
              </Grid>
              <Grid item>
                {/* @ts-ignore */}
                <GridToolbarDensitySelector ref={setFilterButtonEl} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </GridToolbarContainer>
    );
  }, []);

  return (
    <Box
      sx={{
        height: 400,
        width: "100%",
        "& .super-app-theme--Open": {
          bgcolor: (theme) =>
            getBackgroundColor(theme.palette.info.main, theme.palette.mode),
          "&:hover": {
            bgcolor: (theme) =>
              getHoverBackgroundColor(
                theme.palette.info.main,
                theme.palette.mode
              ),
          },
        },
        "& .super-app-theme--Filled": {
          bgcolor: (theme) =>
            getBackgroundColor(theme.palette.success.main, theme.palette.mode),
          "&:hover": {
            bgcolor: (theme) =>
              getHoverBackgroundColor(
                theme.palette.success.main,
                theme.palette.mode
              ),
          },
        },
        "& .super-app-theme--PartiallyFilled": {
          bgcolor: (theme) =>
            getBackgroundColor(theme.palette.warning.main, theme.palette.mode),
          "&:hover": {
            bgcolor: (theme) =>
              getHoverBackgroundColor(
                theme.palette.warning.main,
                theme.palette.mode
              ),
          },
        },
        "& .super-app-theme--Rejected": {
          bgcolor: (theme) =>
            getBackgroundColor(theme.palette.error.main, theme.palette.mode),
          "&:hover": {
            bgcolor: (theme) =>
              getHoverBackgroundColor(
                theme.palette.error.main,
                theme.palette.mode
              ),
          },
        },
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="flex-end"
        spacing={1}
      >
        <Grid item xs={12}>
          <div style={{ width: "100%" }}>
            <div style={{ display: "flex", height: "100%" }}>
              <div style={{ flexGrow: 1 }}>
                <DataGrid
                  rows={rows}
                  getRowId={(row) => row.id}
                  columns={columns}
                  initialState={{
                    sorting: {
                      sortModel: [{ field: "id", sort: "desc" }],
                    },
                  }}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  autoHeight
                  pagination
                  disableSelectionOnClick
                  checkboxSelection={false}
                  experimentalFeatures={{ newEditingApi: true }}
                  localeText={dataGridRoTextConstants}
                  components={{ Toolbar: CustomToolbar }}
                  componentsProps={{
                    pagination: {
                      labelRowsPerPage: "Număr de înregistrări pe pagină",
                    },
                    panel: {
                      anchorEl: filterButtonEl,
                      placement: "bottom-end",
                    },
                  }}
                  getRowClassName={(params) =>
                    `super-app-theme--${params.row.status}`
                  }
                  sx={{
                    "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within":
                      {
                        outline: "none",
                      },

                    "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus":
                      {
                        outline: "none",
                      },
                  }}
                />
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      {openAddShipperDialog && (
        <AddShipperDialog
          open={openAddShipperDialog}
          onShipperCreated={fetchShippers}
          onClose={() => {
            setOpenAddShipperDialog(false);
          }}
        />
      )}
      {openEditShipperDialog && (
        <EditShipperDialog
          open={openEditShipperDialog}
          shipper={selectedShipperForEdit}
          onShipperEdited={fetchShippers}
          onClose={() => {
            setOpenEditShipperDialog(false);
            setSelectedShipperForEdit(undefined);
          }}
        />
      )}
      {openDeleteRowDialog && (
        <DeleteRowDialog
          open={openDeleteRowDialog}
          onClose={handleCloseDeleteRowDialog}
          onDelete={() => {
            if (toBeDeleted) {
              shipperRequest
                .delete(toBeDeleted)
                .then(fetchShippers)
                .catch(() =>
                  alert(
                    "S-a produs o eroare! Este posibil ca înregistrarea aleasă să fie asociată cu alte tipuri de înregistrări și nu este permisă ștergerea!"
                  )
                );
            }
          }}
        />
      )}
    </Box>
  );
}
